<ion-card>

  <ion-spinner [hidden]="!loading"></ion-spinner>

  <ion-grid *ngIf="!!sources && !!sources.length" [hidden]="!!loading">

    <!-- Iterate sources -->
    <ion-row *ngFor="let source of sources" [class.danger]="!mappings[source]"
      [class.warning]="mappings[source] === 'ignore'"
      [class.success]="!!mappings[source] && (mappings[source] !== 'ignore')">

      <!-- Source -->
      <ion-col>
        <ion-item lines="none">

          <ion-label class="ion-text-wrap">
            <p [innerHTML]="'source'|translate"></p>
            <h2 [innerHTML]="source"></h2>
          </ion-label>

          <ion-icon name="arrow-forward-outline" slot="end"></ion-icon>

        </ion-item>
      </ion-col>

      <!-- Target -->
      <ion-col>

        <!-- Mapping -->
        <ion-item lines="none">
          <ion-select [(ngModel)]="mappings[source]" [label]="'target'|translate" labelPlacement="floating"
            interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate" (ionChange)="_changed()">
            <ion-select-option [innerHTML]="'ignore'|translate" value="ignore"></ion-select-option>
            <ion-select-option [innerHTML]="'custom_attribute'|translate" value="custom"></ion-select-option>
            <ion-select-option *ngFor="let target of targets" [innerHTML]="target|translate"
              [value]="target"></ion-select-option>
          </ion-select>
        </ion-item>

        <!-- Attributes -->
        <ion-item lines="none" [hidden]="mappings[source] !== 'custom'" *ngIf="!!attributes && !!attributes.length">
          <ion-select [(ngModel)]="selectedAttributes[source]" [label]="'attribute'|translate" labelPlacement="floating"
            interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate" (ionChange)="_changed()">
            <ion-select-option [innerHTML]="'create_new'|translate" value="new"></ion-select-option>
            <ion-select-option *ngFor="let attribute of attributes" [innerHTML]="attribute.name|translate"
              [value]="attribute.uid"></ion-select-option>
          </ion-select>
        </ion-item>

        <!-- If target = custom -->
        <ion-item lines="none" [hidden]="(mappings[source] !== 'custom') || (selectedAttributes[source] !== 'new')">
          <ion-input [placeholder]="'attribute_name'|translate"></ion-input>
        </ion-item>

      </ion-col>

    </ion-row>

  </ion-grid>

</ion-card>