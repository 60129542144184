import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {

  _detailItem: whitelabelApp|null;

  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  addSourceToApp(sourceId: number, appId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        source: sourceId,
        app: appId,
      },
      ['whitelabel', 'addSourceToApp'],
    );
  }

  calculateAppId(appName: string) {
    return (!!appName ? `page.pipeline.${this.calculateAppPackageNameSuffix(appName || '')}` : '');  
  }

  calculateAppPackageName(appName: string) {
    return (!!appName ? `${this.calculateAppPackageNameSuffix(appName)}-pipeline-app` : '');  
  }

  calculateAppPackageNameSuffix(appName: string) {
    return (!!appName ? appName.split(' ').join('').toLowerCase().replace('ä', 'ae').replace('ö', 'oe').replace('ü', 'ue').replace(/[^a-zA-Z0-9]/g, '') : '');
  }

  deleteApp(app: whitelabelApp) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['whitelabel', 'apps', app.uid, 'delete']);
  }

  deleteSourceFromApp(sourceId: number, appId: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      source: sourceId,
      app: appId,
    }, ['whitelabel', 'deleteSourceFromApp']);
  }

  detailItem(item: whitelabelApp|null = null) {

    if(item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }
  
  getAppByUid(appId: number, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['whitelabel', 'apps', appId],
      null,
      blForceRefresh
    );
  }

  getApps(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['whitelabel', 'apps'],
      null,
      blForceRefresh
    );
  }

  getAppsByPlanUid(planId: number, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['whitelabel', 'apps', 'plan', planId],
      null,
      blForceRefresh
    );
  }

  getFeatures(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['whitelabel', 'apps', 'features'],
      null,
      blForceRefresh
    );
  }

  getPlans(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['whitelabel', 'apps', 'plans'],
      null,
      blForceRefresh
    );
  }

  getPlansByUserUid(userId: number, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['user', userId, 'plans'],
      null,
      blForceRefresh
    );
  }

  submitWhitelabelApp(app: whitelabelApp) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        app: app,
      },
      ['whitelabel', 'apps', 'create'],
    );
  }

  updateGroup2Apps(groupId: number, appIds: number[]) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        group: groupId,
        appIds: appIds,
      },
      ['whitelabel', 'apps', 'updateGroup2Apps']
    );
  }

  updateWhitelabelApp(app: whitelabelApp) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        app: app,
      },
      ['whitelabel', 'apps', app.uid, 'update'],
    );
  }

}
