<ion-header class="ion-no-border">
  
  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>
      
    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="submitMultiple()" shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'done'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view" (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>
  
  <!-- Selection toolbar -->
  <pipeline-selection-toolbar [(items)]="view.data" [options]="selectionOptions" [(view)]="view" (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <div class="container">

    <pipeline-chooser
      (selectedItemsChanged)="selectedItemsChanged($event)"
      [(data)]="view.data" [(item)]="view.item" [options]="options" [service]="service">
    </pipeline-chooser>

  </div>
</ion-content>