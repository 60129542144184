<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <ion-button (click)="dismiss()" *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="create()" color="primary" shape="round" fill="solid" icon-only
        *ngIf="!!newsletter && !newsletter.uid && !!view.startManually">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'create'|translate"></ion-label>
      </ion-button>

      <ion-button (click)="update()" color="primary" shape="round" fill="solid" icon-only
        *ngIf="!!newsletter && !!newsletter.uid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <ion-toolbar class="ion-no-border container second-toolbar">

    <!-- Mobile back button -->
    <ion-buttons slot="start" *ngIf="!view.isDesktop && !!view.executed">
      <ion-button (click)="back()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title size="large" [innerHTML]="'edit_newsletter'|translate"></ion-title>

    <!-- Mobile preview button -->
    <ion-buttons slot="end" *ngIf="!view.isDesktop && !view.executed">
      <ion-button (click)="preview()">
        <ion-icon name="eye-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <!-- Create newsletter intro mode -->
  <pipeline-intro-overlay *ngIf="(!!newsletter && !newsletter.uid) && !view.startManually" [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions"
    [config]="introOverlayConfig" [hidden]="!!view.startManually" [(view)]="view" (onContinue)="aiCreate()"
    (onInput)="onOverlayInputChanged($event)">
  </pipeline-intro-overlay>

  <!-- Newsletter edit mode -->
  <div class="container" [class.has-nav]="!!newsletters && !!newsletters.length">

    <ion-button *ngIf="!!newsletters && !!newsletters.length" fill="clear" color="primary" shape="round"
      class="nav-btn back" icon-only [disabled]="!view.canNavigateBack" (click)="navBack()">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-button>

    <ion-button *ngIf="!!newsletters && !!newsletters.length" fill="clear" color="primary" shape="round"
      class="nav-btn next" icon-only [disabled]="!view.canNavigateNext" (click)="navNext()">
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </ion-button>

    <ion-grid>

      <ion-row>

        <!-- Sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" [hidden]="!view.isDesktop && !!view.executed">

          <!-- General -->
          <ion-card>

            <ion-card-header>

              <ion-card-title (click)="toggleCard('general')" [innerHTML]="'general'|translate"></ion-card-title>

              <ion-button class="card-toggle" (click)="toggleCard('general')" icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.general && !!cards.general.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-list lines="none" [hidden]="!!cards && !!cards.general && !cards.general.open">

              <ion-item>
                <ion-icon name="eye-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'name'|translate" position="stacked"></ion-label>
                <ion-input type="text" name="name" [(ngModel)]="newsletter.title"
                  [placeholder]="'name'|translate"></ion-input>
              </ion-item>

            </ion-list>

          </ion-card>

          <!-- ai styles -->
          <pipeline-ai-style-picker [(cards)]="cards" [(view)]="view"></pipeline-ai-style-picker>

          <!-- Media -->
          <pipeline-media-picker-card [(item)]="view.media" [(items)]="view.mediaList" [options]="mediaPickerOptions"
            [(view)]="view" [hidden]="!view.executed"></pipeline-media-picker-card>

          <!-- Target groups -->
          <pipeline-target-groups-picker></pipeline-target-groups-picker>

          <!-- Call to action -->
          <ion-card>

            <ion-card-header>

              <ion-card-title (click)="toggleCard('cta')" [innerHTML]="'call_to_action'|translate"></ion-card-title>

              <ion-button class="card-toggle" (click)="toggleCard('cta')" icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.cta && !!cards.cta.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-searchbar [(ngModel)]="view.ctaInput" [hidden]="!cards || !cards.cta || !cards.cta.open"
              [placeholder]="'newsletter_cta_input_placeholder'|translate"
              (ionInput)="validateCTAInput()"></ion-searchbar>

            <ion-grid [hidden]="!cards || !cards.cta || !cards.cta.open">

              <ion-row>

                <ion-col *ngFor="let type of view.newsletterTypes" size="6">

                  <ion-button lines="none" [fill]="view.newsletterType === type.uid ? 'outline' : 'clear'"
                    color="primary" expand="block" (click)="useNewsletterType(type)">
                    <ion-icon [name]="type.icon" slot="start"></ion-icon>
                    <ion-label class="ion-text-wrap">
                      <h2 [innerHTML]="type.name|translate"></h2>
                    </ion-label>
                  </ion-button>

                </ion-col>

              </ion-row>
            </ion-grid>

          </ion-card>

          <!-- Templates (If text overlay is set)-->
          <ion-card *ngIf="!!view.templates && !!view.templates.length">

            <ion-card-header>

              <ion-card-title (click)="toggleCard('templates')">
                <span [innerHTML]="'templates'|translate"></span>
              </ion-card-title>

              <ion-button class="card-toggle" (click)="toggleCard('templates')" icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.templates && !!cards.templates.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-searchbar [(ngModel)]="view.templateSearch"
              [hidden]="!cards && !!cards.templates && !cards.templates.open"
              [placeholder]="'search_media_templates'|translate" (ionInput)="searchTemplates()"></ion-searchbar>

            <ion-radio-group [(ngModel)]="view.template"
              [hidden]="!cards && !!cards.templates && !cards.templates.open">

              <ion-grid class="templates-grid">
                <ion-row>
                  <ion-col size="6" *ngFor="let template of view.templates; let i = index; trackBy:trackItems">

                    <ion-card [class.active]="!!view.template && (template.uid === view.template.uid)">

                      <div class="iframe-wrapper" *ngIf="!!template.preview">
                        <iframe [srcdoc]="template.preview" (click)="loadTemplate(template)"></iframe>
                      </div>

                      <ion-thumbnail *ngIf="!template.preview" (click)="loadTemplate(template)">
                        <ion-img [src]="template.photo" (ionError)="thumbnailLoadingFailed(template)"></ion-img>
                      </ion-thumbnail>

                      <ion-item lines="none">

                        <ion-radio [value]="template" slot="start" (click)="loadTemplate(template)"></ion-radio>

                        <ion-label class="ion-text-wrap" (click)="loadTemplate(template)">
                          <h3 [innerHTML]="template.name"></h3>
                        </ion-label>

                      </ion-item>

                    </ion-card>

                  </ion-col>
                </ion-row>
              </ion-grid>

            </ion-radio-group>

          </ion-card>

          <!-- Look and feel -->
          <ion-card>

            <ion-card-header>

              <ion-card-title (click)="toggleCard('look_and_feel')"
                [innerHTML]="'look_and_feel'|translate"></ion-card-title>

              <ion-button class="card-toggle" (click)="toggleCard('look_and_feel')" icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.look_and_feel && !!cards.look_and_feel.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-list [hidden]="!cards && !!cards.look_and_feel && !cards.look_and_feel.open">

              <ion-item *ngIf="!!newsletter && !!newsletter.settings">
                <ion-label [innerHTML]="'color_primary'|translate"></ion-label>
                <pipeline-color-picker (colorChange)="colorChanged($event, newsletter.settings.branding.colors.primary)"
                  [(color)]="newsletter.settings.branding.colors.primary"></pipeline-color-picker>
                <ion-badge slot="end" [style.background]="newsletter.settings.branding.colors.primary"
                  [innerHTML]="newsletter.settings.branding.colors.primary"></ion-badge>
              </ion-item>

              <ion-item *ngIf="!!newsletter && !!newsletter.settings">
                <ion-label [innerHTML]="'color_secondary'|translate"></ion-label>
                <pipeline-color-picker
                  (colorChange)="colorChanged($event, newsletter.settings.branding.colors.secondary)"
                  [(color)]="newsletter.settings.branding.colors.secondary"></pipeline-color-picker>
                <ion-badge slot="end" [style.background]="newsletter.settings.branding.colors.secondary"
                  [innerHTML]="newsletter.settings.branding.colors.secondary"></ion-badge>
              </ion-item>

            </ion-list>

          </ion-card>

          <!-- Multilingual -->
          <ion-card [class.success]="!!newsletter.language">

            <ion-card-header>

              <ion-card-title (click)="toggleCard('multilingual')">
                <span [innerHTML]="'multilingual'|translate"></span>
              </ion-card-title>

              <ion-button class="card-toggle" (click)="toggleCard('multilingual')" icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.multilingual && !!cards.multilingual.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-card-content [hidden]="!cards && !!cards.multilingual && !cards.multilingual.open">
              <p [innerHTML]="'newsletter_creator_multilingual_text'|translate"></p>
            </ion-card-content>

            <pipeline-languages-select [hidden]="!cards && !!cards.multilingual && !cards.multilingual.open"
              [(key)]="newsletter.language"></pipeline-languages-select>

          </ion-card>

          <!-- ai settings -->
          <pipeline-ai-settings-card [(cards)]="cards" [options]="aiSettingsOptions"
            [(view)]="view"></pipeline-ai-settings-card>

        </ion-col>

        <!-- Preview -->
        <ion-col class="preview" [size]="view.isDesktop ? 9 : 12" [hidden]="!view.isDesktop && !view.executed">

          <div class="loading-overlay" [hidden]="!view.loading">
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-spinner></ion-spinner>
          </div>

          <div class="newsletter-editor-wrapper" [hidden]="!newsletter || !newsletter.uid">

            <div id="blocks"></div>

            <div id="gjs" #gjs></div>

            <div id="info-panel" style="display:none"></div>

          </div>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer *ngIf="!!newsletter && !!newsletter.uid">
  <ion-toolbar class="container ion-no-border">

    <!-- ai prompt toolbar -->
    <ion-card class="aiPromptCard">

      <form (ngSubmit)="runAiPrompt()">

        <ion-item lines="none">

          <ion-textarea name="aiPrompt" #aiPromptnewsletterInput rows="2" [(ngModel)]="view.aiPrompt"
            [disabled]="!!view.loading" [placeholder]="'ai_prompt_newsletter_modification'|translate"></ion-textarea>

          <ion-button (click)="runAiPrompt()" expand="block" [disabled]="view.loading"
            [size]="view.isDesktop ? 'medium' : 'small'" color="primary" shape="round">
            <ion-label [innerHTML]="'continue' | translate" [hidden]="!view.isDesktop"></ion-label>
            <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
          </ion-button>

        </ion-item>

      </form>

    </ion-card>

    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>