import { Injectable } from '@angular/core';

import { ApiExplorerService } from "src/app/services/api/api-explorer.service";

@Injectable({
    providedIn: 'root'
})
export class FilesExplorerService {

    _detailItem: any;

    constructor(
        private apiExplorer: ApiExplorerService,
    ) {

    }

    calculateApiUrl(integrationName: string, params: any = {}) {
        console.log('files-service: calculateApiUrl: integrationName', integrationName);
        console.log('files-service: calculateApiUrl: params', params);

        return this.apiExplorer.calculateApiUrl(integrationName, params);
    }

    detailItem(item: mediaTemplate | null = null) {

        if (item !== null) {
            this._detailItem = item;
            return this;
        }

        return this._detailItem;
    }

    fetchRoute(options: any = {}) {
        return new Promise(async (resolve, reject) => {
            console.log('files-service: fetchRoute', options);
        });
    }

    getRoutesByIntegration() {
        return this.apiExplorer.getRoutesByIntegration();
    }

}