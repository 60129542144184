<ion-header class="ion-no-border" *ngIf="!!post">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Settings (mobile) -->
      <ion-button (click)="settings()"
        *ngIf="!(view.isDesktop || (!view.startManually && (!post || !post.post_content || !post.post_content.length)))"
        fill="clear" color="dark" icon-only>
        <ion-icon [name]="!!view.settingsView ? 'settings' : 'settings-outline'" slot="start"></ion-icon>
      </ion-button>

      <!-- Options button -->
      <ion-button *ngIf="!(!view.startManually && (!post || !post.post_content || !post.post_content.length))"
        (click)="showHeaderPopover($event)" fill="clear" icon-only color="dark" [disabled]="!!view.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Save -->
      <ion-button (click)="create()" color="primary" shape="round" fill="solid" [hidden]="!view.startManually"
        [disabled]="!post.post_content || !post.name || !view.canPublish" *ngIf="!view.canUpdate">
        <ion-icon name="save-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

      <!-- Update -->
      <ion-button (click)="update()" color="primary" shape="round" fill="solid"
        [disabled]="!post.post_content || !post.name || !view.canUpdate" *ngIf="!view.canPublish">
        <ion-icon name="save-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content fullscreen="true" *ngIf="!!post" [class.settings-view]="!!view.settingsView">

  <!-- Popover for Header actions -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list>

        <!-- Rebuild all -->
        <ion-item button (click)="rebuild()">
          <ion-icon name="sync-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'recreate'|translate"></ion-label>
        </ion-item>

        <!-- Rebuild images -->
        <ion-item button (click)="rebuildImages()">
          <ion-icon name="images-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'recreate_images'|translate"></ion-label>
        </ion-item>

        <!-- Rebuild texts -->
        <ion-item button (click)="rebuildTexts()">
          <ion-icon name="text-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'recreate_texts'|translate"></ion-label>
        </ion-item>

        <!-- Delete post -->
        <ion-item button (click)="deletePost()">
          <ion-icon name="trash-outline" slot="start" color="danger"></ion-icon>
          <ion-label [innerHTML]="'delete'|translate" color="danger"></ion-label>
        </ion-item>

      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Nav: Go Back -->
  <ion-button *ngIf="!!posts && !!posts.length" fill="clear" color="primary" shape="round" class="nav-btn back"
    icon-only [disabled]="!view.canNavigateBack" (click)="navBack()">
    <ion-icon name="chevron-back-outline"></ion-icon>
  </ion-button>

  <!-- Nav: Go next -->
  <ion-button *ngIf="!!posts && !!posts.length" fill="clear" color="primary" shape="round" class="nav-btn next"
    icon-only [disabled]="!view.canNavigateNext" (click)="navNext()">
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-button>

  <!-- Select project card -->
  <div class="container" *ngIf="!!view.forceProjectToBeSet && (!view.project || !view.project.uid)">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <!-- Create post intro mode -->
  <pipeline-intro-overlay
    *ngIf="(!view.forceProjectToBeSet || (!!view.project && !!view.project.uid)) && !!post && !post.uid && !view.startManually && !post.post_content"
    [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions" [config]="introOverlayConfig"
    [hidden]="!!view.startManually" [(view)]="view" (onContinue)="aiCreate()" (onInput)="onOverlayInputChanged($event)">
  </pipeline-intro-overlay>

  <!-- Post edit mode-->
  <div class="container" *ngIf="!view.forceProjectToBeSet || (!!view.project && !!view.project.uid)"
    [class.has-nav]="!!posts && !!posts.length">

    <ion-grid>
      <ion-row>

        <!-- Sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" [hidden]="!view.isDesktop && !view.settingsView">

          <!-- Types -->
          <!--
          <ion-card *ngIf="!!view.types && !!view.types.length">

            <ion-card-header>
              <ion-card-title>
                <span [innerHTML]="'post_types_headline'|translate"></span>
              </ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <p [innerHTML]="'post_types_text'|translate"></p>
            </ion-card-content>

            <ion-grid class="types-grid">
              <ion-row>

                <ion-col size="6" *ngFor="let type of view.types; let iType = index;">

                  <ion-button class="types-btn" [class.active]="type.checked" size="block"
                    [fill]="type.checked ? 'solid' : 'outline'" color="primary" (click)="toggleType(type, iType)">
                    <ion-icon [name]="type.icon"></ion-icon>
                    <ion-label [innerHTML]="type.name|translate"></ion-label>
                  </ion-button>

                </ion-col>

              </ion-row>
            </ion-grid>

          </ion-card>
          -->

          <!-- step 1: configure post-->
          <pipeline-post-settings [(cards)]="cards" [(post)]="post" [view]="view"
            (settingsChanged)="onPostSettingsChanged($event)"></pipeline-post-settings>

          <!-- step 2: select post media -->
          <pipeline-media-picker-card [(cards)]="cards" [(item)]="post" [(items)]="view.mediaList"
            [options]="mediaPickerOptions" [(view)]="view"
            (onMediaChanged)="onMediaChanged($event)"></pipeline-media-picker-card>

          <!-- ai styles -->
          <pipeline-ai-style-picker *ngIf="!!post && !!post.uid" [(cards)]="cards"
            [(view)]="view"></pipeline-ai-style-picker>

          <!-- Channels -->
          <!-- (!!view.selectedTypesList && !!view.selectedTypesList.social) &&  -->
          <!--<pipeline-channels-picker
            *ngIf="!!post && !!post.uid"
            [hidden]="!view.hasSelectedTypes" [item]="post"></pipeline-channels-picker>
          -->

          <!-- step 3: create social media caption -->
          <!-- (!!view.selectedTypesList && !!view.selectedTypesList.social) &&  -->
          <pipeline-caption-generator [(cards)]="cards" [config]="captionGeneratorConfig" [(post)]="post"
            [disabled]="!post || !post.post_content" [(view)]="view"
            (captionChanged)="onCaptionChanged($event)"></pipeline-caption-generator>

          <!-- step 4: create voiceover (optional) -->
          <pipeline-voiceover-card [(cards)]="cards" [options]="{ input: (view.audioInputText || '')}"
            (settingsChanged)="onVoiceoverSettingsChanged($event)"
            *ngIf="!!post && !!view.audioInputText"></pipeline-voiceover-card>

          <!-- ai settings -->
          <pipeline-ai-settings-card [(cards)]="cards" [(config)]="aiSettings" [options]="aiSettingsOptions"
            [(view)]="view"></pipeline-ai-settings-card>

        </ion-col>

        <!-- Main content -->
        <ion-col [size]="view.isDesktop ? (!!post.uid && !!view.expertMode ? 6 : 9) : 12"
          [hidden]="!view.isDesktop && !!view.settingsView">

          <ion-segment
            *ngIf="!!view.platforms && !!view.platforms.length && ((view.platforms.length > 1) || (!!view.availablePlatforms && !!view.availablePlatforms.length))"
            [hidden]="!post || !post.uid || !post.post_content || !post.post_content.length" [(ngModel)]="view.platform"
            (ionChange)="platformChanged()" scrollable value="main">

            <ion-segment-button *ngFor="let platform of view.platforms" [value]="platform.uid">

              <ion-thumbnail slot="start" *ngIf="!!platform.photo">
                <ion-img [src]="platform.photo" (ionError)="thumbnailLoadingFailed(platform)"></ion-img>
              </ion-thumbnail>

              <ion-icon *ngIf="!platform.photo && !!platform.icon" [name]="platform.icon"></ion-icon>

              <ion-label [innerHTML]="platform.name|translate" class="ion-text-wrap"></ion-label>

            </ion-segment-button>

            <ion-segment-button [value]="'add'" *ngIf="!!view.availablePlatforms && !!view.availablePlatforms.length"
              (click)="addPlatform()">
              <ion-icon name="add-outline"></ion-icon>
            </ion-segment-button>

          </ion-segment>

          <!-- History list wrapper -->
          <div class="history-wrapper">

            <!-- History list -->
            <div class="history-list" #historyWrapper>

              <!-- History item -->
              <div class="history-item" *ngFor="let item of view.history; let i = index; trackBy:trackItems">

                <!-- View mode post content -->
                <ion-card *ngIf="item.mode === 'view'" [class.role-assistant]="item.role === 'assistant'"
                  [class.role-user]="item.role === 'user'">

                  <ion-card-content>

                    <!-- Default / Text preview -->
                    <div *ngIf="!item.post_content_formatted" [class.code-preview]="!!item.post_content_formatted"
                      [innerHTML]="item.post_content">
                    </div>

                    <!-- Code preview -->
                    <pre *ngIf="!!item.post_content_formatted">
                      <code [highlight]="item.post_content_formatted" [language]="view.codeLanguage || 'html'"></code>
                    </pre>

                  </ion-card-content>

                  <ion-card-header *ngIf="!!i && (item.role !== 'user')">
                    <pipeline-rate-item-buttons [(item)]="view.history[i]" type="idea"></pipeline-rate-item-buttons>
                  </ion-card-header>

                </ion-card>

                <!-- Edit mode post content -->
                <ion-card *ngIf="item.mode !== 'view'" class="editor">

                  <pipeline-editor *ngIf="!view.rebuild" [id]="'postEditor' + (i+1)" [disabled]="!!view.loading"
                    [input]="!!view.platform && !!view.variationsByPlatform && !!view.variationsByPlatform[view.platform] ? view.variationsByPlatform[view.platform] : item.post_content"
                    (inputChange)="onEditorInputChanged($event, item, i)"></pipeline-editor>

                  <ion-item lines="none" [hidden]="!!view.rebuild || !!view.loading">

                    <!-- Copy text -->
                    <ion-button slot="start" fill="clear" shape="round" color="dark" [disabled]="!item.post_content"
                      (click)="copy(item.post_content)">
                      <ion-icon name="copy-outline" slot="start"></ion-icon>
                    </ion-button>

                    <pipeline-rate-item-buttons *ngIf="!!i" [(item)]="view.history[i]" type="post"
                      slot="start"></pipeline-rate-item-buttons>

                    <ion-button *ngIf="!!i" slot="end" fill="clear" (click)="useAsPrimary(view.history[i], i)"
                      color="dark" icon-only>
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>

                  </ion-item>

                </ion-card>

              </div>

              <ion-spinner [hidden]="!view.loading"></ion-spinner>

            </div>

          </div>

        </ion-col>

        <!-- Statistics & comments -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" *ngIf="!!post && !!post.uid"
          [hidden]="!view.expertMode">

          <!-- Statistics -->
          <pipeline-post-statistics-card [(cards)]="cards" [(post)]="post" type="post"
            [(view)]="view"></pipeline-post-statistics-card>

          <!-- Reactions -->
          <pipeline-reactions-toolbar *ngIf="!view.rebuild" [disabled]="true" [item]="post" type="post"
            [hidden]="!post || post.vip" mode="summarized" [(view)]="view"></pipeline-reactions-toolbar>

          <!-- Comments -->
          <ion-card class="sidebar-accordion-card comments-card" *ngIf="!!post && !!post.uid">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'comments'|translate"></span>
              </ion-card-title>

              <ion-button [hidden]="!!cards && !!cards.comments && !cards.comments.open" class="second"
                (click)="syncComments()" icon-only color="dark" fill="clear">
                <ion-icon name="sync-outline"></ion-icon>
              </ion-button>

              <ion-button *ngIf="!!cards && !!cards.comments" class="card-toggle" (click)="toggleCard('comments')"
                icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.comments && cards.comments.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <pipeline-comments-list [hidden]="!!cards && !!cards.comments && !cards.comments.open" *ngIf="!view.rebuild"
              type="post" [uid]="post.uid" [context]="post.post_content"
              [options]="{ allowWriteComment: (!!appConfig && !!appConfig.allowUserCommentPost) }"
              (onChanged)="onCommentsChanged($event)"></pipeline-comments-list>

          </ion-card>

        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!post && (!!post.uid || !!view.startManually)">

  <!-- Mobile AI modification bar -->
  <ion-toolbar *ngIf="!view.isDesktop" [hidden]="!!view.settingsView">
    <ion-card class="aiPromptCard">
      <form (ngSubmit)="runAiPrompt()">

        <ion-item lines="none">

          <ion-textarea name="aiPrompt" #aiPromptPostInput rows="2" [(ngModel)]="view.aiPrompt"
            [disabled]="!!view.loading" [placeholder]="'ai_prompt_post_modification'|translate"></ion-textarea>

          <ion-button (click)="runAiPrompt()" expand="block" [disabled]="view.loading"
            [size]="view.isDesktop ? 'medium' : 'small'" color="primary" shape="round">
            <ion-label [innerHTML]="'continue' | translate" [hidden]="!view.isDesktop"></ion-label>
            <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
          </ion-button>

        </ion-item>

      </form>
    </ion-card>
  </ion-toolbar>

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Use as -->
      <ion-button icon-only fill="clear" (click)="share()" color="dark"
        *ngIf="!(!view.startManually && (!post || !post.post_content || !post.post_content.length))">
        <ion-icon name="hammer-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'convert'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

      <!-- Shortcodes -->
      <ion-button icon-only fill="clear" (click)="toolsMenu()" color="dark" [hidden]="!view.expertMode">
        <ion-icon name="sparkles-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'ai_tools'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

      <!-- Translations -->
      <ion-button (click)="toggleLanguage()" icon-only [disabled]="!post.uid" [hidden]="!view.expertMode">
        <ion-icon name="language-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'translations'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

    </ion-buttons>

    <!-- Desktop modifications bar -->
    <ion-card *ngIf="!!view.isDesktop" class="aiPromptCard">
      <form (ngSubmit)="runAiPrompt()">

        <ion-item lines="none">

          <ion-textarea name="aiPrompt" #aiPromptPostInput rows="2" [(ngModel)]="view.aiPrompt"
            [disabled]="!!view.loading" [placeholder]="'ai_prompt_post_modification'|translate"></ion-textarea>

          <ion-button (click)="runAiPrompt()" expand="block" [disabled]="view.loading"
            [size]="view.isDesktop ? 'medium' : 'small'" color="primary" shape="round">
            <ion-label [innerHTML]="'continue' | translate" [hidden]="!view.isDesktop"></ion-label>
            <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
          </ion-button>

        </ion-item>

      </form>
    </ion-card>

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>