<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-menu-button *ngIf="!view.isModal"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Update -->
      <ion-button (click)="update()" *ngIf="!!view.integration && !!view.integration.uid" color="primary" shape="round"
        fill="solid">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

      <!-- Save -->
      <ion-button (click)="save()" *ngIf="!!view.integration && !view.integration.uid" color="primary" shape="round"
        fill="solid">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

    </ion-buttons>
  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <!-- Intro card -->
    <pipeline-intro-card *ngIf="!!view && !!view.integrationName && !!introCard" [hidden]="introCard.hidden"
      class="introCard" [(view)]="introCard"></pipeline-intro-card>

    <!-- Loading spinner -->
    <ion-spinner name="circular" *ngIf="!view || !view.integration || !view.integration.settings"></ion-spinner>

    <!-- No entries -->
    <pipeline-no-entries-card
      *ngIf="(!view.integration || !view.integration.settings || (view.integration.settings && !view.integration.settings.length)) && !view.loading"></pipeline-no-entries-card>

    <div *ngIf="!!view && !!view.integration && !!view.integration.settings">

      <!-- Pagination -->
      <pipeline-pagination *ngIf="!!view.showPagination && !!paginationConfig && !view.loading"
        [config]="paginationConfig" [(view)]="view"></pipeline-pagination>

      <ion-card *ngFor="let item of view.integration.settings; let i = index; trackBy:trackItems">
        <ion-list lines="none">

          <ion-item-sliding>

            <ion-item lines="none">

              <ion-thumbnail slot="start"
                *ngIf="!options || (options.showPhoto !== false) && (!!options.photoKey || (!!item.thumbnail || !!item.photo))">
                <ion-img *ngIf="!!item.uid"
                  [src]="(!!options.photoKey ? item[options.photoKey] : (item.thumbnail || item.photo)) || fallbackImg"
                  (ionError)="thumbnailLoadingFailed(item)"></ion-img>
                <ion-skeleton-text class="image" *ngIf="!item.uid" animated></ion-skeleton-text>
              </ion-thumbnail>

              <ion-icon [name]="item.icon || 'folder-outline'" slot="start"
                *ngIf="!!item.uid && !item.photo && !options.photoKey && !!item.icon"></ion-icon>

              <!-- View mode -->
              <ion-checkbox *ngIf="!mode || (mode !== 'edit')" class="ion-text-wrap" [(ngModel)]="item.checked"
                justify="start" labelPlacement="end" (ionChange)="onItemChecked(item)" [disabled]="!item.uid">

                <p *ngIf="!!item[options.labelKey]" [innerHTML]="item[options.labelKey]|translate"></p>
                <small *ngIf="!!item[options.subLabelKey]" [innerHTML]="item[options.subLabelKey]|translate"></small>

                <ion-skeleton-text *ngIf="!item[options.labelKey]" style="float:left;width:25%;margin-right:10px;"
                  [animated]="!item.uid"></ion-skeleton-text>

                <ion-skeleton-text *ngIf="!item[options.labelKey]" style="float:left;width:35%;margin-right:25%;"
                  [animated]="!item.uid"></ion-skeleton-text>

                <ion-skeleton-text *ngIf="!item[options.labelKey]" style="float:left;width:15%;margin-right:10px;"
                  [animated]="!item.uid"></ion-skeleton-text>

                <ion-skeleton-text *ngIf="!item[options.labelKey]" style="float:left;width:25%;margin-right:25%;"
                  [animated]="!item.uid"></ion-skeleton-text>

              </ion-checkbox>

              <!-- Edit mode -->
              <ion-input *ngIf="!!mode && (mode === 'edit')" [placeholder]="item[options.labelKey]|translate"
                [(ngModel)]="item[options.valueKey || options.subLabelKey]"></ion-input>

              <ion-button *ngFor="let button of buttons; trackBy:trackItems" [color]="button.color || 'primary'"
                [fill]="button.fill || 'clear'" [slot]="button.slot || 'end'" [disabled]="!item.uid"
                (click)="onButtonClick(button, item)">
                <ion-icon *ngIf="!!button.icon" [name]="button.icon" slot="start"></ion-icon>
                <ion-label [innerHTML]="button.label|translate"></ion-label>
              </ion-button>

            </ion-item>

            <ion-item-options
              *ngIf="((!!item && !!item.buttons && !!item.buttons.length) || (!!buttons && !!buttons.length)) && !!item.uid && !view.isDesktop"
              slot="end">

              <ion-item-option *ngFor="let button of (item.buttons || buttons); trackBy:trackItems"
                [color]="button.color || 'primary'" (click)="onButtonClick(button, item)">
                <ion-icon *ngIf="!!button.icon" [name]="button.icon" slot="start"></ion-icon>
                <ion-label [innerHTML]="button.label|translate"></ion-label>
              </ion-item-option>

            </ion-item-options>

          </ion-item-sliding>

        </ion-list>
      </ion-card>

    </div>

  </div>

</ion-content>