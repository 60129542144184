import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CsvService {

    autoCsvToList(text: string) {
        var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
        var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;

        // Return NULL if input string is not well formed CSV string.
        if (!re_valid.test(text)) return null;

        var a: any[] = [];                     // Initialize array to receive values.

        text.replace(re_value, // "Walk" the string using replace with callback.
            function (m0, m1, m2, m3) {
                // Remove backslash from \' in single quoted values.
                if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
                // Remove backslash from \" in double quoted values.
                else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
                else if (m3 !== undefined) a.push(m3);
                return ''; // Return empty string.
            });

        // Handle special case of empty last value.
        if (/,\s*$/.test(text)) a.push('');

        return a;
    };

    csvRowToList(row: string, delimiter: string = ',', quoteChar: string = '"') {
        const result: string[] = [];
        let currentField = '';
        let inQuotes = false;

        // Handle empty row
        if (!row?.trim()) return [];

        for (let i = 0; i < row.length; i++) {
            const char = row[i];
            const nextChar = row[i + 1];

            if (char === quoteChar) {
                if (inQuotes && nextChar === quoteChar) {
                    // Handle escaped quotes
                    currentField += char;
                    i++; // Skip next quote
                } else {
                    // Toggle quote mode
                    inQuotes = !inQuotes;
                }
            } else if (char === delimiter && !inQuotes) {
                // End of field
                result.push(currentField.trim());
                currentField = '';
            } else {
                currentField += char;
            }
        }

        // Push last field
        result.push(currentField.trim());
        return result;
    }

    csvToList(content: string, delimiter: string = ',', quoteChar: string = '"') {
        const rows: string[] = [];
        let currentRow = '';
        let inQuotes = false;

        for (let i = 0; i < content.length; i++) {
            const char = content[i];

            if (char === quoteChar) {
                inQuotes = !inQuotes;
            }

            if (char === '\n' && !inQuotes) {
                if (currentRow.trim()) {
                    rows.push(currentRow);
                }
                currentRow = '';
            } else {
                currentRow += char;
            }
        }

        // Add last row if exists
        if (currentRow.trim()) {
            rows.push(currentRow);
        }

        // Parse each row
        return rows.map(row => this.csvRowToList(row, delimiter, quoteChar));
    }

}