import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { AiWorkerService } from 'src/app/services/ai/ai-worker.service';

import { EventsService } from 'src/app/services/core/events.service';
import { IconPickerService } from 'src/app/services/utils/icon-picker.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ToolsService } from "src/app/services/utils/tools.service";

@Component({
  selector: 'app-ai-shortcode',
  standalone: false,
  templateUrl: './ai-shortcode.page.html',
  styleUrls: ['./ai-shortcode.page.scss'],
})
export class AiShortcodePage implements OnInit {

  ai: any;

  aiSettings: aiSettings = {
    context: 'text_generation',
  };

  aiSettingsOptions: aiSettingsOptions = {
    operations: ['text_generation'],
  };

  @ViewChild('aiSettingsPopover') aiSettingsPopover;

  cards: any = {};

  introCard: introCardConfig = {
    uid: 'ai_shortcode_top_card',
    text: 'ai_shortcode_top_card_text',
    title: 'ai_shortcode_top_card_title',
  };

  isAiSettingsPopoverOpen: boolean = false;

  shortcode: aiShortcode = {
    icon: 'extension-puzzle-outline',
    input: '',
    name: '',
    promptType: 'user',
    type: 'user',
  };

  state: state = {};

  types: any[] = [
    {
      label: 'input_email',
      uid: 'email',
    },
    {
      label: 'input_number',
      uid: 'number',
    },
    {
      label: 'input_phone',
      uid: 'phone',
    },
    {
      label: 'input_select',
      uid: 'select',
    },
    {
      label: 'input_text',
      uid: 'text',
    },
    {
      label: 'input_textarea',
      uid: 'textarea',
    },
    {
      label: 'input_url',
      uid: 'url',
    },
  ];

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    route: 'ai/shortcode',
    showMenuButton: true,
    tab: 'custom',
    title: 'create_ai_shortcode',
  };

  constructor(
    private aiTools: AiToolsService,
    private aiWorker: AiWorkerService,

    private events: EventsService,
    private iconPicker: IconPickerService,
    private modalService: ModalService,
    private navParams: NavParams,
    private toolsService: ToolsService,
  ) {
    this.ai = this.navParams.get('ai');

    this.view.promptFunctionActions = this.ai.getPromptFunctionActions();
    this.view.promptTypes = this.ai.getPromptTypes();

    const shortcode: aiShortcode | null = this.navParams.get('shortcode');
    console.log('ai-shortcode: provided shortcode', shortcode);

    this.shortcode = (shortcode || this.shortcode) as aiShortcode;
    console.log('ai-shortcode: init shortcode', shortcode);
  }

  addVariable() {

    if (!this.view.variables) {
      this.view.variables = [];
    }

    this.view.variables.push({
      action: '',
      type: 'text',
      uid: '',
    });

  }

  calcInputVars() {
    setTimeout(() => {
      const varsInShortcodeInput: any = [...new Set(`${this.shortcode.input}`.match(/\{(.*?)\}/g))];

      if (!!varsInShortcodeInput) {
        this.view.variables = varsInShortcodeInput.map((input: any, index: number) => {
          input = input.replace(/{/g, '').replace(/}/g, '');

          return {
            action: 'auto',
            input_variable: input,
            name: input,
            output_variable: '',
            required: true,
            type: 'text',
            uid: input,
            value: (this.view.variables && !!this.view.variables[index] ? this.view.variables[index].value : ''),
          };
        });
      }
    });
  }

  calcViewVars() {
    this.view.isDesktop = this.toolsService.isDesktop();
    this.view.isModal = this.modalService.isModal();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);

    this.calcInputVars();
  }

  chooseIcon() {
    this.iconPicker.pick()
      .then((response: any) => {
        if (!!response && !!response.icon) {
          this.shortcode.icon = response.icon;
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  create() {
    const methodName: string = (!!this.shortcode.uid ? 'updateShortcode' : 'saveShortcode');
    console.log('methodName', methodName);

    this.aiWorker[methodName](this.shortcode)
      .then((response: any) => {
        this.dismiss(response, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deleteVariable(iVariable: number) {
    this.view.variables = this.view.variables.filter((_var: any, _i: number) => {
      return iVariable !== _i;
    });
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  ngOnInit() {
    this.calcViewVars();
  }

  onAiSettingsChanged(event: any) {
    console.log('onAiSettingsChanged: event', event);
  }

  @HostListener('window:resize')
  onResize() {
    this.view.isDesktop = this.toolsService.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  onShortcodeInputChanged(event: any | null = null) {
    this.calcViewVars();
  }

  onShortcodePromptTypeChanged() {

  }

  async optimizeTaskInputPrompt(shortcode: aiShortcode, event: any | null = null) {
    console.log('optimizeTaskInputPrompt: shortcode', shortcode);

    if (!shortcode.input || !shortcode.input.length) {
      return false;
    }

    this.view.loadingInput = true;

    try {

      const optimize: any = await this.aiTools.optimizePrompt(shortcode.input, {
        history: [
          {
            role: 'system',
            content: `Optimize only the following AI prompt and return the improved prompt (text string) only.
            Please always try to improve the existing variables / add more variables ({...}) if provided based on the provided user prompt for a better detailed prompt and more configuration options to make the prompt reusable.`,
          }
        ]
      });

      if (!!optimize && !!optimize.output) {
        shortcode.input = `${optimize.output || ''}`;
        this.calcInputVars();
      }

      this.view.loadingInput = false;
    } catch (e) {
      this.view.loadingInput = false;
      this.events.publish('error', e);
    }
  }

  showAiSettingsPopover(event: any | null = null) {
    this.aiSettings = this.aiSettings || {};
    this.aiSettingsPopover.event = event;
    this.isAiSettingsPopoverOpen = true;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}