import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

import { AccountsService } from "src/app/services/core/accounts.service";
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { AuthService } from 'src/app/services/core/auth.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { DevicesService } from "src/app/services/core/devices.service";
import { EventsService } from "src/app/services/core/events.service";
import { TokenService } from 'src/app/services/core/token.service';
import { TrackingService } from "src/app/services/core/tracking.service";
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: "root",
})
export class PipelineService {

  view: any = {
    categoriesLoaded: false,
  };

  constructor(
    private accounts: AccountsService,
    private AppCMS: AppcmsService,
    private authService: AuthService,
    private cache: CacheService,
    private device: DevicesService,
    private events: EventsService,
    private loading: LoadingController,
    private token: TokenService,
    private tracking: TrackingService,
    private userService: UserService
  ) {
  }

  authorize(options: any = {}) {
    return this.login(options);
  }

  async getDislikedUids() {
    const fromCache: cacheItem = (await this.cache.get('pipeline_disliked_uids', -1));
    return fromCache && fromCache.data ? fromCache.data : [];
  }

  async getLikedUids() {
    const fromCache: cacheItem = (await this.cache.get('pipeline_liked_uids', -1));
    return (fromCache && fromCache.data ? fromCache.data : []);
  }

  getPublicUsers(blForceRefresh: boolean = false, config: any = {}) {
    return this.AppCMS.loadPluginData("pipeline", config, ["users", "public"], {}, blForceRefresh);
  }

  getRegionalSources(blForceRefresh: boolean = false, config: any = {}) {
    config.user = config.user || this.userService.getApiCredentials();
    config.userId = config.userId || this.userService.getUid();

    return this.AppCMS.loadPluginData("pipeline", config, ["sources", "regional"], {}, blForceRefresh);
  }

  login(userData: user, options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loading.create({
        backdropDismiss: false,
        spinner: 'circular',
      });

      loading.present();

      let validate: boolean = true;

      // if api url is provided, validate it first
      try {
        if (!!options.api_url) {
          validate = await this.validateAPIUrl(options.api_url) as boolean;

          if (!!validate) {
            this.AppCMS.setApiUrl(options.api_url);
          }

        }
      } catch (e) {
        validate = false;
        loading.dismiss();
        console.warn('api url validation failed', e);
        reject(e);
        return false;
      }

      // if validation failed, reject
      if (!validate) {
        loading.dismiss();
        reject('error_validation_failed');
        return false;
      }

      // then, login
      this.AppCMS.postPluginData("pipeline", "login", {
        user: userData,
        uid: this.userService.getUid(),
        expanded: false,
        //language: this.userService.getLanguage(),
        categories: false,
        home: false,
        people: false,
        posts: false,
        stories: false,
      })
        .then(async (response: loginResponse) => {
          loading.dismiss();

          if (!!response && !!response.user && !!response.success) {
            let user: user = response.user as user;

            if (!!response && !!response.token && !!response.token.token) {
              // new device detection + setup handling

              // store token to token service
              this.token.set(response.token.token, 'auth_token');
              
              // load token to auth service
              await this.authService.loadToken();

              if (!response.token.known) {
                this.device.setup(response);
              }
            } else
              if (!!user && !!user.token) {
                // legacy, only set token
                this.token.set(user.token, 'auth_token');
              }

            this.authService.validateUser(user, false)
              .then((response: any) => {

                if (!!userData.password) {
                  user.password = userData.password;
                }

                this.accounts.add(user);
                this.userService.setUser(user);

                this.events.publish("appcms:user:loggedin");

                if (this.userService.shouldConnectWithSIWA()) {
                  setTimeout(() => {
                    this.userService.connectWithSIWA({}, 2);
                  }, 1000);
                }

                setTimeout(() => {
                  try {
                    this.tracking.askTrackingPermission();
                  } catch (e) {
                    console.warn('> tracking prompt error', e);
                  }
                }, 3000);

                resolve(response);
              })
              .catch((error) => {
                loading.dismiss();
                reject(error);
              });
          } else {
            reject(
              response.message || 'error_unknown',
            );
          }
        })
        .catch(reject);
    });
  }

  move(postId: number) {
    return new Promise((resolve, reject) => {
      console.log("move", postId);
    });
  }

  rejectProduct(product: product) {
    return this.AppCMS.loadPluginData('pipeline', {
      product: product,
    }, ['shop', 'products', 'reject']);
  }

  setDislikedUids(uids: number[]) {
    return this.cache.set('pipeline_disliked_uids', uids);
  }

  setLikedUids(uids: number[]) {
    return this.cache.set('pipeline_liked_uids', uids);
  }

  async validateAPIUrl(apiUrl: string) {
    return new Promise((resolve, reject) => {
      let blSuccess: boolean = false;

      this.AppCMS.loadPluginData('config', {}, ['plugins'], { apiUrl: apiUrl })
        .then(() => {
          blSuccess = true;
          resolve(blSuccess);
        })
        .catch(reject);
    });
  }

}