import { Injectable } from '@angular/core';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Injectable({
  providedIn: 'root'
})
export class FoldersService {

  constructor(
    private aiBridge: AiBridgeService,

    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private tools: ToolsService,
  ) {

  }

  async chooseFolder(options: any = {}) {

    const chooseConfig: chooseConfig = Object.assign(options || {}, {
      data: await this.getFolders(options.filter || {}, true),
      labelKey: "title",
      multiple: false,
      service: this,
      valueKey: "uid",
    });

    return this.chooser.choose(chooseConfig);
  }

  copyItemToFolder(data: number | number[], type: string, targetFolder: number, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      if (!!targetFolder) {
        this.executeMove(data, targetFolder, type).then(resolve).catch(reject);
      } else {
        try {

          const chooseResponse: chooseResponse = await this.chooseFolder({
            filter: { location: type }
          });

          const target: folder = (chooseResponse && chooseResponse.data && chooseResponse.data.item ? chooseResponse.data.item : []);

          if (!!target && !!target.uid) {
            this.executeCopy(data, target.uid, type).then(resolve).catch(reject);
          } else {
            reject('error_missing_target');
          }
        } catch (e) {
          reject(e);
          return false;
        }
      }
    });
  }

  create(folder: folder) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        folder: folder,
      },
      ["folders", "create"]
    );
  }

  createIdeas(options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const demoResponse: any = [
        {
          title: 'Folder name A',
          uid: -1,
        },
        {
          title: 'Folder name B',
          uid: -1,
        },
      ];

      const initPrompt: string = `Return a list of at least 5 matching folder names for the project's ${options.location || 'items'}. `;
      const languagePrompt: string = (!!options && !!options.project && !!options.project.language ? `The project language is "${options.project.language}". ` : '');
      const formatPrompt: string = `Return only the JSON without any further output or annotation. Your output must be formatted like this: ${JSON.stringify(demoResponse)}`;
      const prompt: string = `${initPrompt}${languagePrompt}${formatPrompt}`;

      try {

        const response: any = await this.aiBridge.execute({
          history: (options.history || []),
          post_content: prompt,
        });

        const data: any = this.tools.extractJson(response.output);

        resolve(data || []);
      } catch (e: any) {
        reject(e);
      }
    });
  }

  delete(folderId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        folder_uid: folderId,
      },
      ["folders", "delete", folderId]
    );
  }

  executeCopy(data: number | number[], folderId: number, location: string) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        location: location,
        source: data,
        target: folderId,
      },
      ["folders", "copy"]
    );
  }

  executeMove(data: number | number[], folderId: number, location: string) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        location: location,
        source: data,
        target: folderId,
      },
      ["folders", "move"]
    );
  }

  get(filter: any = {}, blForceRefresh: boolean = false, params: any = {}) {

    if (!params.hasOwnProperty("include_items")) {
      params.include_items = false;
    }

    return this.AppCMS.loadPluginData("pipeline", Object.assign(params, {
      options: filter,
    }), ["folders"], params, blForceRefresh);
  }

  getByUid(
    folderId: number,
    blForceRefresh: boolean = false,
  ) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {},
      ["folders", folderId],
      {},
      blForceRefresh
    );
  }

  getFolderItems(folderId: number, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData("pipeline", options, ["folders", folderId, 'items'], {}, blForceRefresh);
  }

  getFolders(filter: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return this.get(filter, blForceRefresh, params);
  }

  moveItemToFolder(data: number | number[], location: string, targetFolder: number | null = null, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      if (!!targetFolder) {
        this.executeMove(data, targetFolder, location).then(resolve).catch(reject);
      } else {
        try {

          const chooseResponse: chooseResponse = await this.chooseFolder({
            filter: { location: location }
          });

          const target: folder = (chooseResponse && chooseResponse.data && chooseResponse.data.item ? chooseResponse.data.item : []);

          if (!!target && !!target.uid) {
            this.executeMove(data, target.uid, location).then(resolve).catch(reject);
          } else {
            reject('error_missing_target');
          }
        } catch (e) {
          reject(e);
          return false;
        }
      }
    });
  }

  /* Handler for legacy folder implementations */
  moveFolder(itemIds: number[], type: string, targetFolder: number | null = null, options: any = {}) {
    return this.moveItemToFolder(itemIds, type, targetFolder, options);
  }

  update(folder: folder) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        folder: folder,
      },
      ["folders", "update"]
    );
  }

}