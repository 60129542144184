import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import * as moment from 'moment';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { ClassificationsService } from 'src/app/services/core/classifications.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { UserService } from 'src/app/services/core/user.service';
import { EventsService } from 'src/app/services/core/events.service';
import { CategoriesService } from 'src/app/services/pipeline/categories.service';
import { PostsService } from 'src/app/services/posts/posts.service';

import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { ILocalNotification, LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';

@Injectable({
  providedIn: 'root'
})
export class PushnotificationsService {

  key: string = 'notificationList';

  notificationsSettings: notificationsSettings;

  pushApiKey: string = 'cd36525a-889e-4aad-89d5-0d9d127b7df5';

  pushAppId: string = '';

  pushFirebaseKey: string = '567674698014';

  pushInited: boolean = false;

  constructor(
    private AppCMS: AppcmsService,
    private badge: Badge,
    private categories: CategoriesService,
    private cache: CacheService,
    private classifications: ClassificationsService,
    private events: EventsService,
    private localNotifications: LocalNotifications,
    private navCtrl: NavController,
    private posts: PostsService,
    private tools: ToolsService,
    private userService: UserService,
  ) {

    this.events.subscribe('push:send', (config: any) => {
      this.send(config)
        .catch((error: any) => {
          console.error('push: send event error (2)', error);
        });
    });

    this.events.subscribe('appcms:user:updated', (user: user) => {
      this.validateAndUpdatePushSettings(user);
    });

  }

  addNotification(notification: any) {
    return new Promise((resolve, reject) => {

      try {
        this.badge.increase(1);
      } catch (e) {
        console.warn('badge error', e);
      }

      this.getAll()
        .then((list: notification[]) => {
          list = list || [];
          list.push(notification);

          this.setAllNotifications(list);
          resolve(list);
        })
        .catch(reject);
    });
  }

  createInboxMessage(notification: any) {
    this.events.publish('message:send', {
      description: notification.contents.en,
      partner: notification.data.parent,
    });
  }

  createNotification(notification: notification) {
    return this.AppCMS.loadPluginData('pipeline', {
      notification: notification
    }, ['notifications', 'create']);
  }

  // @todo
  // This method should only return the local notifications the device received instead of looking up for remote messages
  getAll(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return new Promise(async (resolve, reject) => {
    });
  }

  getCachedNotifications() {
    return this.cache.get(this.key);
  }

  getFullNotification(notification: notification) {
    return new Promise((resolve, reject) => {
      notification.uid = parseInt(notification.uid + '');

      if (notification && notification.value && typeof notification.value === 'string') {
        notification = Object.assign(notification, JSON.parse(notification.value));
      }

      if (notification && notification.payload && notification.payload.rawPayload) {
        notification = notification.payload.rawPayload;
      }

      if ((notification.custom && notification.custom.a) && notification.aps && notification.aps.alert) {
        notification.aps.alert = Object.assign((notification.aps.alert || {}), (notification.custom.a || {}));
      }

      if (notification.aps && notification.aps.alert) {
        notification = notification.aps.alert;
      }

      if (notification.timestamp) {
        let timestamp = typeof notification.timestamp === 'number' ? moment.unix(notification.timestamp) : moment(notification.timestamp, 'YYYY-MM-DD HH:mm'),
          timestampFormatted = timestamp.format('DD.MM.') != moment().format('DD.MM.') ? timestamp.format('DD.MM.') : timestamp.format('HH:mm');
        notification.timestamp_formatted = timestampFormatted;
      }

      if (notification.action && notification.action === 'viewPost' && notification.item && typeof notification.item === 'number') {
        this.posts.getPostByUid(notification.item)
          .then((post: post) => {
            notification.item = post;
            resolve(notification);
          })
          .catch((error: any) => {
            resolve(notification);
          });
      } else {
        resolve(notification);
      }

    });
  }

  getNotificationsSettings() {
    return new Promise(async (resolve, reject) => {
      const fromCache: cacheItem = await this.cache.get('notificationsSettings', -1);

      if (fromCache && fromCache.data) {
        resolve(fromCache.data as notificationsSettings);
      } else {
        resolve({} as notificationsSettings);
      }
    });
  }

  getPushApiKey() {
    return this.pushApiKey;
  }

  getPushAppId() {
    return this.pushAppId;
  }

  getPushFirebaseKey() {
    return this.pushFirebaseKey;
  }

  getPushTargetGroups(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['notifications', 'target_groups'], null, blForceRefresh);
  }

  init() {

    if (this.pushInited) {
      let blUsePush = !!this.classifications.getClassification('push');
      this.setSubscription(blUsePush);
      return;
    }

    this.pushInited = true;

    /*
    try {
      OneSignal.setAppId(this.getPushAppId());

      OneSignal.setNotificationOpenedHandler((event: any) => {
        this.onNotificationOpened(event);
        // do something when a notification is opened
      });
  
      OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        //console.log("User accepted notifications: " + accepted);
      });

    } catch(e) {
      //console.warn('OneSignal init error', e);
    }

    OneSignal.getIds()
    .then((response: any) => {
      console.log('getIds response', response);

      let user = this.userService.getUser() || {};
      if(user && user.uid) {
        user.classifications = user.classifications ? user.classifications : {};
        if(response.userId != user.classifications.pushId) {
          user.classifications.push = user.classifications.hasOwnProperty('push') ? !!user.classifications.push : true;
          user.classifications.pushId = response.userId;
          this.userService.setUser(user, true)
          .then(() => {})
          .catch((error: any) => {
            console.warn('error', error);
          });
        } 
      }
    })
    .catch((error: any) => {
      console.error('push getIds error', error);
    });
    */

  }

  onNotificationClick(notification: notification) {
    console.log('onNotificationClick', notification);

    if (notification.action) {
      switch (notification.action) {
        case 'route':
          if (notification.item) {
            this.navCtrl.navigateForward(notification.item);
          }
          break;
        case 'viewPost':
          if (notification.item) {
            this.events.publish('view:post', notification.item);
          }
          break;
        case 'viewProfile':
          if (notification.user) {
            this.events.publish('view:profile', notification.user);
          }
          break;
        default:
          console.warn('unknown action', notification.action);
          break;
      }
    } else {
      console.warn('no action', notification);
    }
  }

  onNotificationOpened(event: any) {
    event.notification = this.getFullNotification(event.notification);
    this.addNotification(event.notification)
      .catch((error) => {
        console.warn('add notification error', error);
      });
    return this.onNotificationClick(event.notification);
  }

  send(config: any) {
    return new Promise(async (resolve, reject) => {
      try {

        let notification: any = {
          "app_id": this.getPushApiKey(),
          "include_player_ids": config.recipients || [],
          "data": config.data || {},
          "headings": { "en": config.title },
          "contents": { "en": this.tools.stripHtml(config.description) }
        };

        const cacheKey: string = 'notification_' + JSON.stringify(notification);
        const fromCache: cacheItem = await this.cache.get(cacheKey, 15 * 60);

        if (fromCache && fromCache.data) {
          reject('error_already_send');
        } else {

          const user: any = this.userService.getUser() || {};

          if (config.data && config.data.user && (config.data.user === user.uid) && (user.photo && user.photo.length)) {
            notification.large_icon = user.photo;
            notification.small_icon = user.photo;
          }

          /*
          OneSignal.postNotification(
            notification,
            (response: any) => {
              this.cache.set(cacheKey, true);
              resolve(response);
            },
            (r: any) => {
              console.warn(r);
              reject(r.errors ? r.errors[0] : ((r.error || (r.errorMessage || r.message)) || 'Ein unbekannter Fehler ist aufgetreten'));
            }
          );
          */

          // not needed, use message:send instead, which calls push:send then
          //this.createInboxMessage(notification);
        }

      } catch (e) {
        reject(e);
      }
    });
  }

  sendLocal(options: ILocalNotification) {
    return this.localNotifications.schedule(options);
  }

  sendTag(tag: string, value: any) {
    /*
    try {
      OneSignal.sendTag(tag, value);
    } catch(e) {
      console.warn('sendTag error', e);
    }
    */
  }

  setAllNotifications(notifications: any[]) {
    this.cache.set(this.key, notifications);
  }

  setNotificationSettings(notificationsSettings: notificationsSettings) {
    this.notificationsSettings = notificationsSettings;
    return this.cache.set('notificationsSettings', this.notificationsSettings);
  }

  setSubscription(bl: boolean) {
    try {
      //OneSignal.disablePush(!bl);
    } catch (e) {
      console.warn('setSubscription error', e);
    }
  }

  toggle() {
    return new Promise((resolve, reject) => {
      const user = this.userService.getUser() || {};

      if (!user || !user.uid) {
        reject('error_missing_user');
      }

      user.classifications = (user.classifications || {}) as userClassifications;
      user.classifications.push = !user.classifications.push;

      if (user.classifications.push) {
        this.init();
      } else {
        this.setSubscription(false);
      }

      this.userService.setUser(user, true).then(resolve).catch(reject);
    });
  }

  /**
   * @todo Also remove tags from OneSignal
   * @param user 
   */
  async validateAndUpdatePushSettings(user: user) {
    try {
      if (this.userService.isLoggedIn(user)) {
        let categoryNames: any = (await this.categories.getSelectedCategoriesNames());

        if (categoryNames && categoryNames.length) {
          categoryNames.forEach((categoryName: string) => {
            this.sendTag(categoryName, '1');
          });
        }
      }
    } catch (e) {
      console.warn('validate push error', e);
    }

  }

}