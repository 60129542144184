<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Small generate button (if buttonAction = use)-->
      <ion-button icon-only
        *ngIf="!!view.startManually && (view.phase !== 'presenting' && view.buttonAction === 'generate')"
        [hidden]="!!view.showSplineView" (click)="aiCreate()" color="dark" fill="clear"
        [disabled]="!!view.loading || (view.phase === 'loading')">
        <ion-icon name="sync-outline"></ion-icon>
      </ion-button>

      <!-- Download button -->
      <!--
      <ion-button *ngIf="!!view.finalResultUrl" [href]="view.finalResultUrl" download
        [color]="view.downloaded ? 'success' : 'primary'" fill="clear">
        <ion-icon [name]="view.downloaded ? 'download' : 'download-outline'" slot="start"></ion-icon>
        <ion-label [innerHTML]="(view.downloaded ? 'downloaded' : 'download')|translate"></ion-label>
      </ion-button>
      -->

      <!-- Use button -->
      <ion-button *ngIf="view.phase !== 'presenting' && view.buttonAction === 'use'"
        [hidden]="!!view.showSplineView || (view.phase === 'loading') || (view.phase === 'presenting')"
        [disabled]="!!view.loading || !view.selectedItems || !view.selectedItems.length" (click)="generate()"
        shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'use'|translate"></ion-label>
      </ion-button>

      <!-- Main generate button -->
      <ion-button *ngIf="!!view.startManually && (view.phase !== 'presenting' && view.buttonAction === 'generate')"
        [hidden]="!!view.showSplineView || (view.phase === 'loading') || (view.phase === 'presenting')"
        (click)="generate()" [disabled]="!!view.loading || !view.canGenerate" shape="round" color="primary"
        fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'browse'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Progress bar -->
  <ion-toolbar class="ion-no-border container second-toolbar progress-toolbar" *ngIf="!!view.progress">
    <pipeline-progress-bar [progress]="view.progress" [view]="view"></pipeline-progress-bar>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Create browser automation intro mode -->
  <pipeline-intro-overlay *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)"
    [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions"
    [config]="introOverlayConfig" [hidden]="!!view.startManually" (onContinue)="aiCreate()" (onSkip)="startManually()"
    [(view)]="view"></pipeline-intro-overlay>

  <!-- Intro card -->
  <div class="container" [hidden]="introCard.hidden">
    <pipeline-intro-card [hidden]="introCard.hidden" class="introCard" [(view)]="introCard"></pipeline-intro-card>
  </div>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container main-container"
    *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <div class="main-view">

      <ion-grid class="main-grid">

        <ion-row>

          <!-- Sidebar -->
          <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" [hidden]="!view.expertMode">

            <!-- General -->
            <ion-card>

              <ion-card-header>

                <ion-card-title (click)="toggleCard('general')" [innerHTML]="'general'|translate"></ion-card-title>

                <ion-button class="card-toggle" (click)="toggleCard('general')" icon-only color="dark" fill="clear">
                  <ion-icon
                    [name]="!!cards && !!cards.general && !!cards.general.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                </ion-button>

              </ion-card-header>

              <ion-list lines="none" [hidden]="!cards || !cards.general || !cards.general.open">

                <ion-item>
                  <ion-icon name="eye-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'name'|translate" position="stacked"></ion-label>
                  <ion-input type="text" name="name" rows="6" [(ngModel)]="view.codingInput"
                    [placeholder]="'name'|translate"></ion-input>
                </ion-item>

                <ion-item lines="none" class="ion-text-wrap">
                  <ion-checkbox [(ngModel)]="browserConfig.use_proxy" justify="start" labelPlacement="end"
                    (ionChange)="useProxyChanged()" [innerHTML]="'use_proxy'|translate"
                    class="ion-text-wrap"></ion-checkbox>
                </ion-item>

              </ion-list>

            </ion-card>

            <!-- AI Settings -->
            <div class="col-inner">
              <pipeline-ai-settings-card [(cards)]="cards" [(config)]="aiSettings" [options]="aiSettingsOptions"
                [(view)]="view"></pipeline-ai-settings-card>
            </div>

          </ion-col>

          <!-- Content -->
          <ion-col [size]="view.isDesktop && !!view.expertMode ? 9 : 12">

            <!-- DANI -->
            <pipeline-spline-viewer [hidden]="!!view.finalResultUrl" *ngIf="!!splineOptions && !!view.showSplineView"
              [options]="splineOptions"></pipeline-spline-viewer>

            <ion-grid>

              <ion-row>

                <!-- Browser Preview -->
                <ion-col size="8" *ngIf="!!view.step">

                  <ion-card>

                    <ion-card-header>
                      <ion-card-title [innerHTML]="'preview'|translate"></ion-card-title>
                    </ion-card-header>

                    <!--
                    AI Browser component:

                    Step 1: onPageLoaded    = Content received
                    Step 2: onPageRendered  = View has been rendered
                    Step 3: onPageDone      = AI finished handling the current page
                    -->

                    <pipeline-ai-browser [config]="browserConfig" (onPageDone)="onPageDone($event)"
                      (onPageLoaded)="onPageLoaded($event)" (onPageRendered)="onPageRendered($event)">
                    </pipeline-ai-browser>

                  </ion-card>

                </ion-col>

                <!-- Steps list -->
                <ion-col size="4" *ngIf="!!view.steps">

                  <ion-list>

                    <ion-card-header>
                      <ion-card-title [innerHTML]="'steps'|translate"></ion-card-title>
                    </ion-card-header>

                    <ion-item *ngFor="let step of view.steps; let iStep = index;">

                      <ion-label>
                        <h3 [innerHTML]="('step'|translate) + ' ' + (iStep+1)"></h3>
                        <p [innerHTML]="step.description"></p>
                      </ion-label>

                      <ion-spinner slot="end" [hidden]="view.stepIndex !== iStep"></ion-spinner>

                    </ion-item>

                  </ion-list>

                </ion-col>

              </ion-row>

            </ion-grid>
          </ion-col>

        </ion-row>

      </ion-grid>

    </div>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!view.startManually">
  <ion-toolbar class="container ion-no-border">

    <!-- Prompt input card -->
    <pipeline-ai-prompt-card type="code" [disabled]="!!view.loading" [(input)]="view.aiPrompt"
      (onSubmit)="runAiPrompt($event)"></pipeline-ai-prompt-card>

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>