<div #layerElement class="stage-layer stage-layer-{{index}} stage-layer-type-{{layer.type}} resizeBox {{layer.settings.cssClasses}}" [class.running]="view.running" [id]="layer.settings.id" [class.active]="!!(!!view.editable && !!layer.active)" [class.has-line-clamp]="!!layer && !!layer.settings && !!layer.settings['-webkit-line-clamp']" (click)="onTimelineLayerClick(layer, index)">
     
  <!-- drag handlers -->
  <span [cdkDragLockAxis]="view.lockAxis" #dragHandleCorner class="dragHandle dragHandleCorner corner" [hidden]="!view.editable" [cdkDrag]="view.editable" [cdkDragDisabled]="!view.editable" (cdkDragMoved)="dragMove('dragHandleCorner', index, $event)"></span>
  <span cdkDragLockAxis="x" #dragHandleRight class="dragHandle dragHandleRight right" [hidden]="!view.editable" [cdkDrag]="view.editable" [cdkDragDisabled]="!view.editable" (cdkDragMoved)="dragMove('dragHandleRight', index, $event)"></span>
  <span cdkDragLockAxis="y" #dragHandleBottom class="dragHandle dragHandleBottom bottom" [hidden]="!view.editable" [cdkDrag]="view.editable" [cdkDragDisabled]="!view.editable" (cdkDragMoved)="dragMove('dragHandleBottom', index, $event)"></span>

  <div class="animation-wrapper animate__{{layer.settings.animation_name}} animate__delay-{{layer.settings.animation_delay}}s" [style.animation-direction]="layer.settings['animation-direction']" [style.animation-duration]="layer.settings['animation-duration']" [style.animation-fill-mode]="layer.settings['animation-fill-mode']" [style.animation-iteration-count]="layer.settings['animation-iteration-count']" [style.animation-name]="layer.settings['animation-name']" [style.animation-timing-function]="layer.settings['animation-timing-function']" [class.animate__animated]="view.running || (view.templateType === 'image')" [class.animate__infinite]="(view.running || (view.templateType === 'image')) && layer.settings.animation_loop">

    <!-- audio -->
    <audio *ngIf="layer.type === 'audio'" autoplay playsinline webkit-playsinline [loop]="layer.settings.audio_loop" [src]="layer.settings.src"></audio>

    <!-- text in edit mode -->
    <ion-textarea *ngIf="layer.type === 'text' && (!!view.editable && !!layer.active)" class="stage-layer-text" [style.line-height]="layer.settings['line-height']" [style.-webkit-line-clamp]="layer.settings['-webkit-line-clamp']" [(ngModel)]="layer.settings.value"></ion-textarea>

    <!-- text in view mode -->
    <p *ngIf="layer.type === 'text' && (!view.editable || !layer.active)" class="stage-layer-text" [style.line-height]="layer.settings['line-height']" [style.-webkit-line-clamp]="layer.settings['-webkit-line-clamp']" [innerHTML]="layer.settings.value"></p>

    <!-- image -->
    <img *ngIf="layer.type === 'image'" class="stage-layer-img" [style.object-fit]="layer.settings['object-fit'] || 'cover'" [style.object-position]="layer.settings['object-position'] || 'center center'" [src]="layer.settings.src" />

    <!-- video -->
    <video *ngIf="layer.type === 'video'" autoplay [muted]="layer.settings.video_muted" [poster]="layer.settings.video_poster" playsinline webkit-playsinline [loop]="layer.settings.video_loop" [src]="layer.settings.src"></video>

    <!-- lottie -->
    <!--
    <ng-lottie *ngIf="layer.type === 'lottie' && !!layer.settings.lottieOptions"
      width="100%"
      height="100%"
      containerClass="layer-lottie-element"
      [options]="layer.settings.lottieOptions"
      (animationCreated)="lottieAnimationCreated($event)"
      (configReady)="lottieConfigReady()"
      (dataReady)="lottieDataReady()"
      (domLoaded)="lottieDomLoaded()"
      (destroy)="lottieDestroy($event)"
      (error)="lottieError($event)"></ng-lottie>
    -->

    <!-- html -->
    <div class="inner-html" *ngIf="!!layer.settings.html" [innerHTML]="layer.settings.html"></div>
    
  </div>
</div>