<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!--
      <ion-button (click)="upload()" fill="clear" color="dark">
        <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
        <ion-label [hidden]="!view.isDesktop" [innerHTML]="'upload'|translate"></ion-label>
      </ion-button>
      -->

      <!-- Import -->
      <ion-button slot="end" shape="round" color="primary" (click)="importSelected()"
        [disabled]="!view.hasSelectedItems">
        <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'import'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

    </ion-buttons>


  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <ion-toolbar class="ion-no-border container second-toolbar">
    <ion-searchbar autocorrect="on" [(ngModel)]="search.query" #searchInput id="searchInput"
      [placeholder]="'search_something'|translate" (ionInput)="runSearch()"></ion-searchbar>
  </ion-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar [(items)]="view.items" [options]="selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <!-- Intro card (mobile) -->
    <div *ngIf="!view.isDesktop" class="ion-padding" [hidden]="introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="introCard"></pipeline-intro-card>
    </div>

    <ion-grid>

      <ion-row>

        <!-- Sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" [hidden]="!view.isDesktop && !!view.task">

          <!-- Item Types-->
          <ion-card>

            <ion-card-header>
              <ion-card-title [innerHTML]="'types'|translate"></ion-card-title>
            </ion-card-header>

            <!-- Full automations -->
            <ion-button [fill]="view.segment === 'tasks' ? 'solid' : 'clear'" shape="round"
              [color]="view.segment === 'tasks' ? 'primary' : 'dark'" (click)="segmentChanged('tasks')">
              <ion-icon name="terminal-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'ai_tasks'|translate"></ion-label>
            </ion-button>

            <!-- Single shortcodes -->
            <ion-button [fill]="view.segment === 'shortcodes' ? 'solid' : 'clear'" shape="round"
              [color]="view.segment === 'shortcodes' ? 'primary' : 'dark'" (click)="segmentChanged('shortcodes')">
              <ion-icon name="code-download-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'ai_shortcodes'|translate"></ion-label>
            </ion-button>

          </ion-card>

          <!-- Prompt types -->
          <ion-card [hidden]="view.segment !== 'shortcodes'">

            <ion-card-header>
              <ion-card-title [innerHTML]="'prompt_types'|translate"></ion-card-title>
            </ion-card-header>

            <ion-button *ngFor="let promptType of view.promptTypes; trackBy:trackItems"
              [fill]="view.promptType === promptType.uid ? 'solid' : 'clear'"
              [color]="view.promptType === promptType.uid ? 'primary' : 'dark'" (click)="promptTypeChanged(promptType)"
              shape="round">
              <ion-icon *ngIf="!!promptType.icon" [name]="promptType.icon" slot="start"></ion-icon>
              <ion-label [innerHTML]="promptType.name|translate"></ion-label>
            </ion-button>

          </ion-card>

          <!-- job titles -->
          <!--
          <ion-card [hidden]="view.segment !== 'tasks'">

            <ion-card-header>
              <ion-card-title [innerHTML]="'job_titles'|translate"></ion-card-title>
            </ion-card-header>

            <ion-button *ngFor="let job_title of view.job_titles; trackBy: trackItems" fill="clear" color="dark"
             >
              <ion-label [innerHTML]="job_title.name|translate"></ion-label>
            </ion-button>

          </ion-card>
          -->

          <!-- industries -->
          <!--
          <ion-card [hidden]="view.segment !== 'tasks'">

            <ion-card-header>
              <ion-card-title [innerHTML]="'industries'|translate"></ion-card-title>
            </ion-card-header>

            <ion-button *ngFor="let industry of view.industries; trackBy: trackItems" fill="clear" color="dark"
             >
              <ion-label [innerHTML]="industry.name|translate"></ion-label>
            </ion-button>

          </ion-card>
          -->

        </ion-col>

        <!-- Content -->
        <ion-col [size]="view.isDesktop ? 9 : 12">

          <!-- Intro card (desktop) -->
          <pipeline-intro-card *ngIf="!view.isDesktop" [hidden]="introCard.hidden" class="introCard"
            [(view)]="introCard"></pipeline-intro-card>

          <!-- Loading spinner -->
          <ion-spinner [hidden]="!!view.items"></ion-spinner>

          <!-- No entries card -->
          <pipeline-no-entries-card
            *ngIf="view.items && !view.items.length && !view.loading"></pipeline-no-entries-card>

          <!-- Items grid -->
          <ion-grid *ngIf="!!view.items && !!view.items.length">
            <ion-row>

              <!-- results -->
              <ion-col [size]="view.isDesktop ? 4 : 12" [hidden]="!view.isDesktop && !!view.task">

                <ion-card *ngFor="let task of view.items; let i = index; trackBy: trackItems">

                  <ion-item lines="none">

                    <ion-icon [name]="task.icon" *ngIf="!!task.icon" slot="start" (click)="expand(task)"></ion-icon>

                    <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="task.checked" (click)="expand(task)"
                      (ionChange)="onItemChecked(task)" [disabled]="task.disabled" [innerHTML]="task.name"
                      class="ion-text-wrap"></ion-checkbox>

                    <ion-icon slot="end" name="chevron-forward" (click)="expand(task)"></ion-icon>

                    <!--
                    <ion-button slot="end" fill="clear" color="dark" icon-only (click)="expand(task)">
                      <ion-icon [name]="task.expanded ? 'chevron-up' : 'chevron-down'"></ion-icon>
                    </ion-button>
                    -->

                  </ion-item>

                </ion-card>

              </ion-col>

              <!-- item preview -->
              <ion-col [size]="view.isDesktop ? 8 : 12" *ngIf="!!view.task">

                <ion-card>

                  <ion-item>

                    <ion-button slot="start" color="dark" fill="clear" (click)="deselectItem()"
                      [hidden]="!!view.isDesktop" icon-only>
                      <ion-icon name="arrow-back-outline"></ion-icon>
                    </ion-button>

                    <ion-label class="ion-text-wrap">
                      <h2 [innerHTML]="view.task.name"></h2>
                    </ion-label>

                  </ion-item>

                  <ion-item lines="none" *ngIf="!!view.task.input" class="ion-text-wrap">
                    <ion-label [innerHTML]="view.task.input" class="ion-text-wrap"></ion-label>
                  </ion-item>

                  <ion-item lines="none">

                    <ion-button [hidden]="!!view.task.coins" slot="end" shape="round" color="primary"
                      (click)="import(view.task)">
                      <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'import'|translate" class="ion-text-wrap"></ion-label>
                    </ion-button>

                    <ion-button [hidden]="!view.task.coins" slot="end" shape="round" color="primary"
                      (click)="buy(view.task)">
                      <ion-icon name="card-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'buy'|translate" class="ion-text-wrap"></ion-label>
                    </ion-button>

                  </ion-item>

                </ion-card>

                <ion-card *ngIf="!!view.task.children && !!view.task.children.length">

                  <ion-card-header>
                    <ion-card-title [innerHTML]="'workflow'|translate"></ion-card-title>
                  </ion-card-header>

                  <ion-list>
                    <ion-item *ngFor="let child of view.task.children; let iSub = index; trackBy:trackItems">
                      <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="('step'|translate) + ' ' + (iSub+1)"></h3>
                        <h3 [innerHTML]="(child.name || 'no_name')|translate"></h3>

                        <p *ngIf="!!child.input" [innerHTML]="child.input" [hidden]="!view.expertMode"></p>

                      </ion-label>
                    </ion-item>
                  </ion-list>

                  <!-- Admin actions -->
                  <ion-item lines="none" *ngIf="!!view.isAdmin">

                    <!-- Remove from store -->
                    <ion-button slot="end" color="danger" (click)="removeFromStore(view.task)" fill="clear">
                      <ion-icon name="trash-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'delete'|translate"></ion-label>
                    </ion-button>

                  </ion-item>

                </ion-card>

              </ion-col>

            </ion-row>
          </ion-grid>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>