import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AiWorkerService } from 'src/app/services/ai/ai-worker.service';

import { BrowserService } from "src/app/services/utils/browser.service";
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from "src/app/services/core/events.service";
import { ExportService } from 'src/app/services/utils/export.service';
import { ImporterService } from 'src/app/services/utils/importer.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { RatingsService } from 'src/app/services/pipeline/ratings.service';
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { AiModelPage } from '../ai-model/ai-model.page';

@Component({
  selector: 'app-ai-training',
  standalone: false,
  templateUrl: './ai-training.page.html',
  styleUrls: ['./ai-training.page.scss'],
})
export class AiTrainingPage implements OnDestroy, OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;
  @ViewChild('headerPopover') headerPopover;

  appConfig: pipelineAppConfig;

  fallbackImg: string = './assets/img/fallback.webp';

  introCard: introCardConfig = {
    uid: 'ai_training_top_card',
    text: 'ai_training_top_card_text',
    title: 'ai_training_top_card_title',
  };

  isHeaderPopoverOpen: boolean = false;

  search: searchOptions = {
    itemsKey: 'tasks',
    keys: ['name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    log: [],
    multiple: true,
    output: '',
    overview: {

    },
    route: 'ai/training',
    showMenuButton: true,
    showProjectsSelect: true,
    title: 'ai_training',
  };

  constructor(
    private aiWorker: AiWorkerService,
    private browser: BrowserService,
    private configService: ConfigService,
    private events: EventsService,
    private exportService: ExportService,
    private importService: ImporterService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private projects: ProjectsService,
    private ratings: RatingsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  async add(event: any | null = null) {

    const modal: any = await this.modalService.create({
      component: AiModelPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.colSize = (!!this.view.isDesktop ? 3 : 12);
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    return new Promise((resolve, reject) => {

    });
  }

  doRefresh(event: any | null = null) {
    this.load(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {

        if (!!event) {
          event.target.complete();
        }

        if (!!error) {
          this.events.publish('error', error);
        }

      });
  }

  duplicateSelected(event: any | null = null) {
    console.log('duplicate selected: event', event);
    console.log('duplicate selected: view', this.view);
  }

  async export(event: any | null = null, options: any = {}) {
    this.isHeaderPopoverOpen = false;

    this.exportService.exportUsingUI({
      data: (this.view.tasks || []),
      service: this.aiWorker,
      source_tool: 'ai_training',
      source_type: 'tool',
    })
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });
  }

  importData(event: any | null = null) {
    this.isHeaderPopoverOpen = false;

    const params: dataImportOptions = {
      service: this.aiWorker,
      target_tool: 'ai_training',
      target_type: 'tool',
    };

    this.importService.importUsingUI(params)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
      this.view.colSize = (!!this.view.isDesktop ? 3 : 12);
    });
  }

  ionViewWillEnter() {
    this.initEvents();
    this.loadProject();

    this.load()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async load(blForceRefresh: boolean = false) {
    try {
      await this.loadProject();
      await this.loadLog();
      await this.loadTasks();
    } catch (e) {
      console.warn('loading training data failed', e);
    }
  }

  async loadLog() {
    this.ratings.getAiRatings()
      .then(async (response: any) => {
        this.view.log = (!!response && !!response.data ? response.data : []).reverse();
        return this.view.log;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        return false;
      });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadTasks(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.aiWorker.getTrainingTasks({}, blForceRefresh)
        .then((response: any) => {
          this.view.tasks = (!!response && !!response.tasks ? response.tasks : []);
          resolve(this.view);
        })
        .catch(reject);
    });
  }

  log() {
    this.navCtrl.navigateForward('/system/log/ratings');
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.load();
  }

  onItemChecked(item: any) {

    this.view.selectedItems = this.view.tasks.filter((_item: any) => {
      return _item.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }
  }

  overview() {
    this.navCtrl.navigateForward('/system/log/training');
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  toggleActive(task: aiTask) {
    task.active = !task.active;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  viewLogItem(logItem: any) {
    this.browser.create(logItem.photo || logItem.url);
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}