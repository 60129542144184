import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { IntroOverlayComponent } from './intro-overlay.component';

import { SharedModule } from 'src/app/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import { AiSettingsPickerComponentModule } from 'src/app/components/ai/ai-settings-picker/ai-settings-picker.component.module';

/* Custom Components */
import { MediaPickerCardComponentModule } from 'src/app/components/media/media-picker-card/media-picker-card.component.module';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    IntroOverlayComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    SharedModule,
    AiSettingsPickerComponentModule,
    MediaPickerCardComponentModule,
  ],
  exports: [
    IntroOverlayComponent,
  ]
})
export class IntroOverlayComponentModule { }