import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

import { AbonnementService } from 'src/app/services/core/abonnement.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { GetgeniusService } from "src/app/services/getgenius/getgenius.service";
import { PurchaseService } from "src/app/services/ecommerce/purchase.service";
import { ViewService } from 'src/app/services/core/view.service';

import Chart from 'chart.js/auto';

@Component({
  selector: 'app-getgenius-wallet',
  standalone: false,
  templateUrl: './getgenius-wallet.page.html',
  styleUrls: ['./getgenius-wallet.page.scss'],
})
export class GetgeniusWalletPage implements OnInit {
  @ViewChild('usageChart', { read: ElementRef, }) usageChart: ElementRef;

  appConfig: pipelineAppConfig;

  earningsChartConfig: any = {
    type: 'line',
    data: {
      labels: [],
      datasets: []
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  };

  fallbackImg: string = './assets/img/fallback.webp';

  introCard: introCardConfig = {
    uid: 'getgenius_wallet_top_card',
    text: 'getgenius_wallet_top_card_text',
    title: 'getgenius_wallet_top_card_title',
  };

  state: state = {};

  usageChartConfig: any = {
    type: 'line',
    data: {
      labels: [],
      datasets: []
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
  };

  user: user;

  view: any = {
    currencyPrefixSymbols: {
      eur: '',
      usd: '$',
    },
    currencySuffixSymbols: {
      eur: ' €',
      usd: '',
    },
    dani: {
      config: {
        hasChat: true,
        userCanWrite: true,
        zoom: 0.55,
      },
      state: {
        speaking: false,
      },
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    history: [],
    route: 'getgenius/wallet',
    showMenuButton: true,
    title: 'getgenius_wallet',
    verified: false,
  };

  wallet: wallet;

  constructor(
    private abonnement: AbonnementService,
    private configService: ConfigService,
    private events: EventsService,
    private GetGenius: GetgeniusService,
    private purchaseService: PurchaseService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  buyPackage(_package: any) {
    try {
      this.purchaseService.purchase(_package);
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.colSize = (!!this.view.isDesktop ? 6 : 12);
  }

  ionViewWillEnter() {
    this.preloadWallet();
    this.loadData();
  }

  async loadCurrentWallet() {
    try {
      this.wallet = await this.GetGenius.getCurrentWallet();
    } catch (e) {
      console.warn('loading wallet failed', e);
    }
  }

  loadData() {
    this.GetGenius.getWalletInfo()
      .then(async (walletInfo: walletInfo) => {

        if (!!walletInfo && !!walletInfo.wallets) {
          this.view.wallets = walletInfo.wallets;
        }

        this.loadCurrentWallet();
        this.loadPackages();

        try {
          await this.loadSubscriptions();
        } catch (e) {
          this.events.publish('error', e);
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  loadPackages() {
    const edition: string = 's';

    this.view.packages = [
      {
        currency: 'eur',
        uid: `getgenius_package_${edition}_1000`,
        name: 'getgenius_package_1000_tokens',
        price: 99,
      },
      {
        currency: 'eur',
        uid: `getgenius_package_${edition}_5000`,
        name: 'getgenius_package_5000_tokens',
        price: 399,
      },
      {
        currency: 'eur',
        uid: `getgenius_package_${edition}_10000`,
        name: 'getgenius_package_10000_tokens',
        price: 699,
      },
    ];

  }

  loadSubscriptions(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.abonnement.getActiveExtensions(this.view.options, blForceRefresh)
        .then((abonnements: any[]) => {

          this.view.subscriptions = abonnements.map((abonnement: any) => {
            abonnement.setup_price = (!!abonnement.setup_price ? parseFloat(`${abonnement.setup_price || ''}`).toFixed(2) : null);

            return abonnement;
          });

          resolve(abonnements);
        })
        .catch(reject);
    });
  }

  ngAfterContentInit() {
    this.renderChart();
  }

  ngOnInit() {
    this.calcViewVars();
  }

  node() {

  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
    this.view.colSize = (!!this.view.isDesktop ? 6 : 12);
  }

  async preloadWallet() {
    this.GetGenius.init();
    this.loadCurrentWallet();
  }

  renderChart() {
    setTimeout(async () => {

      if (!this.wallet || !this.wallet.history || !this.wallet.history.length) {
        return false;
      }

      let datasets = [], labels: string[] = [];

      /*
      for(let i=0; i<30; i++){
          let date = new Date();
          date.setDate(date.getDate() - i);
          labels.push(date.toISOString().split('T')[0]);
      }
      */

      const datasetTemplate = {
        'label': 'statistics',
        'data': [],
        'backgroundColor': [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        'borderColor': [
          'rgba(255,99,132,1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        'borderWidth': 1
      };

      this.view.usage = [];

      let balanceByDates: any = {}, inData: number[] = [], outData: number[] = [];

      this.wallet.history.forEach((historyItem: any) => {
        let date: string | null = (!!historyItem.timestamp ? historyItem.timestamp.split(' ')[0] : null);

        if (!!date) {

          if (!balanceByDates.hasOwnProperty(date)) {
            balanceByDates[date] = { in: 0, out: 0 };
          }

          if (!!historyItem.amount) {
            balanceByDates[date].out += historyItem.amount;
          } else {
            balanceByDates[date].in += historyItem.amount;
          }
        }
      });

      const keys: string[] = Object.keys(balanceByDates),
        iOffset: number = (keys.length - 30 > 0 ? keys.length - 30 : 0);

      labels = keys.slice(iOffset, (30 + iOffset));

      labels.forEach((label: string) => {
        if (!!balanceByDates[label]) {
          inData.push(balanceByDates[label].in || 0);
          outData.push(balanceByDates[label].out || 0);
        }
      });

      let dataset = JSON.parse(JSON.stringify(datasetTemplate));

      dataset.label = 'Verbrauch';
      dataset.data = outData;
      datasets.push(JSON.parse(JSON.stringify(dataset)));

      dataset.label = 'Einnahmen';
      dataset.data = inData;
      datasets.push(JSON.parse(JSON.stringify(dataset)));

      this.usageChartConfig.data.labels = labels;
      this.usageChartConfig.data.datasets = datasets;

      if (!!this.view.usageChart) {
        this.view.usageChart.destroy();
      }

      this.view.usageChart = new Chart(this.usageChart.nativeElement, this.usageChartConfig);
      this.view.usageChart.canvas.parentNode.style.height = (100 / window.innerHeight) * 95;
    }, 100);
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }
  
}