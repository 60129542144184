<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Menu button -->
      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Dismiss button -->
      <ion-button (click)="dismiss()" *ngIf="!!view.isModal">
          <ion-icon name="close"></ion-icon>
      </ion-button>
      
    </ion-buttons>
    
    <ion-buttons slot="end">

      <!-- Run automation -->
      <ion-button fill="solid" shape="round" *ngIf="!view.isSaveMode && !view.running" color="primary"
        [disabled]="!view.canRun" (click)="runSet()">
        <ion-icon name="play" slot="start"></ion-icon>
        <ion-label [innerHTML]="'run'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <div *ngIf="!!task && !!task.children">

      <div *ngFor="let child of task.children">

        <ion-card *ngIf="!!child.inputs">

          <ion-card-header>
            <ion-card-title [innerHTML]="child.name"></ion-card-title>
          </ion-card-header>

          <ion-list lines="none">

            <ion-item *ngFor="let input of child.inputs">
              <ion-icon [name]="!input.value ? 'warning-outline' : 'checkmark'" [color]="!input.value ? (input.uid !== 'output' ? 'danger' : 'warning') : 'success'" slot="start"></ion-icon>
              <ion-input type="text" [(ngModel)]="input.value" [label]="input.name|translate" labelPlacement="floating"></ion-input>
            </ion-item>

          </ion-list>
        </ion-card>

      </div>

    </div>

  </div>

</ion-content>
