import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { EventsService } from 'src/app/services/core/events.service';

@Injectable({
    providedIn: 'root'
})
export class ObjectsService {

    constructor(
        private AppCMS: AppcmsService,
        private events: EventsService,
    ) {
    }

    createObject(object: any, typeId: number) {
        return this.AppCMS.loadPluginData('pipeline', {
            object: object,
            type_uid: typeId,
        }, ['objects', 'create']);
    }

    createType(type: any) {
        return new Promise((resolve, reject) => {
            this.AppCMS.loadPluginData('pipeline', {
                type: type,
            }, ['objects', 'types', 'create'])
                .then((response: any) => {

                    if (!!response.type) {
                        this.events.publish('objects:type:created', response.type);
                    }

                    resolve(response);
                })
                .catch(reject);
        });
    }

    deleteObject(objectId: number, typeId: number | null = null) {
        return this.AppCMS.loadPluginData('pipeline', {
            object_uid: objectId,
            type_uid: typeId,
        }, ['objects', objectId, 'delete']);
    }

    deleteObjectType(objectTypeId: number) {
        return new Promise((resolve, reject) => {
            this.AppCMS.loadPluginData('pipeline', {
                type_uid: objectTypeId,
            }, ['objects', 'types', 'delete'])
                .then(async (response: any) => {
                    console.log('objects-service: delete object type response', response);

                    // removes entry from (custom) menu if exists
                    this.events.publish('objects:type:deleted');

                    resolve(response);
                })
                .catch(reject);
        });
    }

    getAll(options: any = {}, blForceRefresh: boolean = false) {
        return this.getObjects(options, blForceRefresh);
    }

    getObjects(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', options, ['objects'], {}, blForceRefresh);
    }

    getObjectsByTypeUid(typeId: number, blForceRefresh: boolean = false, options: any = {}) {
        options.filter = options.filter || {};
        options.filter.type_uid = typeId;
        return this.AppCMS.loadPluginData('pipeline', options, ['objects'], {}, blForceRefresh);
    }

    getTypes(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', options, ['objects', 'types'], {}, blForceRefresh);
    }

    getTypeByUid(typeId: number, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', {}, ['objects', 'types', typeId], {}, blForceRefresh);
    }

    updateObject(object: any, typeId: number) {
        return this.AppCMS.loadPluginData('pipeline', {
            object: object,
            type_uid: typeId,
        }, ['objects', 'update']);
    }

    updateType(type: any) {
        return new Promise((resolve, reject) => {
            this.AppCMS.loadPluginData('pipeline', {
                type: type,
            }, ['objects', 'types', 'update'])
                .then((response: any) => {

                    if (!!response.type) {
                        this.events.publish('objects:type:updated', response.type);
                    }

                    resolve(response);
                })
                .catch(reject);
        });
    }

}