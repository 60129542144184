import { Component, Input, OnInit } from '@angular/core';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { EventsService } from "src/app/services/core/events.service";
import { RatingsService } from 'src/app/services/pipeline/ratings.service';

@Component({
  selector: 'pipeline-rate-item-buttons',
  standalone: false,
  templateUrl: './rate-item-buttons.component.html',
  styleUrls: ['./rate-item-buttons.component.scss']
})
export class RateItemButtonsComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() item: any;
  @Input() slot: string;
  @Input() type: string;

  constructor(
    private aiTools: AiToolsService,
    private events: EventsService,
    private ratings: RatingsService,
  ) {

  }

  favorite() {
    this.aiTools.addIdea(this.item);

    return this.thumb(5);
  }  

  ngOnInit() {

  }

  thumb(i: number) {

    const options: aiRatingOptions = {
      item: this.item,
      type: this.type || (this.item.type || 'idea'),
      value: i,
    };

    this.ratings.submitAiRating(this.item, i, options.type)
    .then(() => {
      this.item.favorite = (i === 5);
      this.item.rating = i;
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

}