<div class="getgeniusAboOverlayCard" [hidden]="view.aboVerified">

    <ion-card>
        <ion-card-header>
            <ion-card-title [innerHTML]="'getgenius_abo_overlay_card_title'|translate"></ion-card-title>
        </ion-card-header>

        <ion-card-content>
            <p [innerHTML]="'getgenius_abo_overlay_card_text'|translate"></p>
        </ion-card-content>

    </ion-card>

</div>