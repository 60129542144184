import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { ConfigService } from 'src/app/services/core/config.service';
import { StripeService } from 'src/app/services/payments/stripe.service';
import { TranslationService } from "src/app/services/core/translation.service";

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  appConfig: pipelineAppConfig;

  basketProductPrices: any = {};

  productList: any[];

  constructor(
    private configService: ConfigService,
    private platform: Platform,
    private stripe: StripeService,
    private translations: TranslationService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  getBasketProduct(product: any) {
    const productList: any = this.getBasketProductList();

    const select = productList.filter((productListItem: any) => {
      return productListItem.id === (product.name || product);
    });

    return (select && select[0] ? select[0] : null);
  }

  getBasketProductList() {
    const productList: any = this.getProductList();

    if (productList && productList.length) {
      productList.forEach((basketProduct: any) => {
        basketProduct.price = this.basketProductPrices[basketProduct.id];
      });
    }

    return productList;
  }

  getProductList() {
    // @todo refactor using stripe
  }

  init() {
    // @todo refactor using stripe
  }

  initProduct(product: any) {
    // @todo refactor using stripe
  }

  purchase(product: any) {
    return this.purchaseUsingWebPayments(product);

    /*
    return new Promise((resolve, reject) => {
      if(this.platform.is('ios')) {
        this.purchaseUsingApple(product).then(resolve).catch(reject);
      } else
      if(this.platform.is('android')) {
        this.purchaseUsingGoogle(product).then(resolve).catch(reject);
      } else {
        this.purchaseUsingWebPayments(product).then(resolve).catch(reject);
      }
    });
    */
  }

  /*
  purchaseUsingApple(product: any) {

    if(this.tools.isWeb()) {
      return this.purchaseUsingWebPayments(product);
    }

    return new Promise(async (resolve, reject) => {
      const loading: any = await this.loading.create();
      loading.present();

      this.store.order(product.name || product)
      .then(
        (response: any) => {
          loading.dismiss();
          resolve(response);
        },
        (error: any) => {
          loading.dismiss();
          console.error('in app purchase error', error);
          reject(error.errorMessage || error);
        }
      );
    });
  }

  purchaseUsingGoogle(product: any) {

    if(this.tools.isWeb()) {
      return this.purchaseUsingWebPayments(product);
    }

    return new Promise((resolve, reject) => {
      this.store.order(product.name || product)
      .then(
        (response: any) => {
          resolve(response);
        },
        (error: any) => {
          console.log('in app purchase error', error);
          reject(error.errorMessage || error);
        }
      );
    });
  }
  */

  purchaseUsingWebPayments(product: any) {
    return new Promise((resolve, reject) => {
      product = JSON.parse(JSON.stringify(product));

      const productNameKey: string = `${product.name || ''}`.toLowerCase();

      this.translations.get([productNameKey, 'setup_price'])
        .subscribe(async (translations: any) => {
          ;
          const items: any[] = [JSON.parse(JSON.stringify(product))];
          items[0].name = translations[productNameKey] || product.name;

          if (!!product.setup_price) {

            items[0].recurring = {
              interval: 'month',
              interval_count: 1,
            };

            const productName: string = translations.setup_price || 'setup_price';

            let setupProduct: any = Object.assign(JSON.parse(JSON.stringify(product)) || {}, {
              name: productName,
              price: parseFloat(`${product.setup_price || 0}`)
            });

            delete items[0].setup_price;
            delete product.setup_price;
            delete setupProduct.recurring;

            items.push(setupProduct);
          }

          // set current product as stripe payment summary items
          this.stripe.setPaymentSummaryItems(items);

          try {
            const stripeProcess: any = await this.stripe.purchaseUsingWeb();
            resolve(stripeProcess);
          } catch (e) {
            console.warn('stripe payment sheet failed', e);
            reject(e);
          }
        });
    });
  }

  validator(product: any, callback) {

    const transactionType: string = (this.platform.is('ios') ? 'ios' : 'android');
    const transactionReceipt: string = (this.platform.is('ios') ?
      (product.transaction as any).appStoreReceipt :
      (product.transaction as any).receipt);

    console.log('> transactionType', transactionType);
    console.log('> transactionReceipt', transactionReceipt);

    callback(true, product.transaction);
    //this.store.dispatch(new RefreshUserAction());

    //this.showSuccessPurchaseMessage();
    product.finish();

    console.log('inAppPurchase.validator', JSON.stringify(product));
  }

}