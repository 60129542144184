import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { EventsService } from "src/app/services/core/events.service";
import { UserService } from "src/app/services/core/user.service";
import { BrowserService } from 'src/app/services/utils/browser.service';
import { AbonnementService } from 'src/app/services/core/abonnement.service';
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-settings',
  standalone: false,
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {

  config: pipelineAppConfig;

  state: state = {};

  view: any = {
    colSizes: {},
    contentPage: 'account',
    environment: '',
    environments: [],
    hideOrderByBtn: true,
    hideSearch: true,
    preferredTextLength: 1000,
    route: 'settings',
    title: 'settings',
  };

  user: user;

  constructor(
    private abo: AbonnementService,
    private AppCMS: AppcmsService,
    private browser: BrowserService,
    private configService: ConfigService,
    private events: EventsService,
    private navCtrl: NavController,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.config = this.configService.getConfig();
    this.user = this.userService.getUser() || {};
    this.view.environment = this.configService.getEnvironmentUrl('production', 'api');

    this.view.appVersion = this.configService.getAppVersionName();

    this.view.aboVersion = this.abo.getCurrentVersion();
  }

  account() {

    if (!!this.view.isDesktop) {
      return this.setContentPage('account');
    }

    this.navCtrl.navigateForward('/account', { animated: true });
  }

  appearance() {

    if (!!this.view.isDesktop) {
      return this.setContentPage('appearance');
    }
    
    this.navCtrl.navigateForward('/appearance');
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.colSizes = {
      left: (window.innerWidth > 768 ? 3 : 12),
      right: (window.innerWidth > 768 ? 9 : 12),
    };
  }

  devices() {

    if (!!this.view.isDesktop) {
      return this.setContentPage('devices');
    }

    this.navCtrl.navigateForward('/account/devices', { animated: true });
  }

  dynamic(pageId: number) {
    this.events.publish('view:page', pageId);
  }

  experiments() {
    this.navCtrl.navigateForward('/experiments');
  }

  feedback() {
    this.navCtrl.navigateForward('/feedback');
  }

  filters() {
    this.navCtrl.navigateForward('/word-list');
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};
    this.view.aboVersion = this.abo.getCurrentVersion();

    if (this.user && this.user.uid && (this.user.uid !== -1)) {
      this.view.admin = this.userService.isType('Admin');

      if (this.view.admin) {
        this.loadUserGroups();
      }

    }
  }

  initEvents() {

    this.events.subscribe("appcms:user:updated", () => {
      this.user = this.userService.getUser() || {};
      this.view.aboVersion = this.abo.getCurrentVersion();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  interests() {
    this.navCtrl.navigateForward('/interests');
  }

  loadEnvironments() {
    const environments: pipelineEnvironmentConfig = this.configService.getEnvironment('api');
    const keys = Object.keys(environments);

    let _environments: any[] = [];

    keys.forEach((key: string) => {
      _environments.push({
        label: `environment_${key}`,
        url: environments[key],
      });
    });

    this.view.environments = _environments;

    return _environments;
  }

  async loadUserGroups() {
    try {
      this.view.user_groups = await this.userService.getUserGroups();
    } catch (e) {
      console.warn('> load user groups failed', e);
    }
  }

  ngOnInit() {
    this.user = this.userService.getUser() || {};
    this.view.aboVersion = this.abo.getCurrentVersion();

    this.calcViewVars();
    this.initEvents();

    this.loadEnvironments();
  }

  notifications() {
    this.navCtrl.navigateForward('/notifications/settings');
  }

  onLinkClick(link: link) {
    console.log('onLinkClick: link', link);

    if (!link || !link.url || !link.url.length) {
      return false;
    }

    if (link.url[0] === '/') {
      this.navCtrl.navigateForward(link.url);
    } else {
      this.browser.create(link.url);
    }
  }

  setContentPage(pageName: string) {
    this.view.contentPage = pageName;
  }

  updatedEnvironment() {
    if (this.view.environment && this.view.environment.length) {
      this.AppCMS.setApiUrl(this.view.environment);
    }
  }

  updatedUserType() {
  }

  wallet() {

    if (!!this.view.isDesktop) {
      return this.setContentPage('wallet');
    }

    this.navCtrl.navigateForward('/getgenius/wallet');
  }

}