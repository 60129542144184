import { Injectable } from '@angular/core';

import { ModalService } from 'src/app/services/core/modal.service';

import { IconPickerPage } from 'src/app/pages/core/chooser/icon-picker/icon-picker.page';

@Injectable({
  providedIn: 'root'
})
export class IconPickerService {

  constructor(
    private modalService: ModalService,
  ) {
  }

  pick() {
    return new Promise(async (resolve, reject) => {

      const pickIconModal: any = await this.modalService.create({
        component: IconPickerPage,
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: "defaultModal",
      });

      pickIconModal.onWillDismiss().then((response: any) => {
        response.data = response.data || {};
        resolve(response.data);
      });

      this.modalService.present(pickIconModal);
    });

  }

}
