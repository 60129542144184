<!-- Grid mode thumbnail -->
<ion-thumbnail class="gridThumbnail" slot="start" [hidden]="view.viewType !== 'grid'"
  *ngIf="!options || (options.showPhoto !== false) && (!!options.photoKey || (!!item.thumbnail || !!item.photo) || fallbackImg)">
  <ion-img *ngIf="!!item.uid"
    [src]="(!!options.photoKey ? item[options.photoKey] : (item.thumbnail || item.photo)) || fallbackImg"
    (ionError)="thumbnailLoadingFailed(item)"></ion-img>
  <ion-skeleton-text class="image" *ngIf="!item.uid" animated></ion-skeleton-text>
</ion-thumbnail>

<!-- Item settings popover -->
<ion-popover #itemSettingsPopover [isOpen]="isItemSettingsPopoverOpen" (didDismiss)="isItemSettingsPopoverOpen = false">
  <ng-template>
      <ion-content>
          <ion-list *ngIf="!!selectionOptions">

              <ion-item button class="btn-item" *ngFor="let option of selectionOptions"
                  [hidden]="option.uid === 'combine'"
                  [class.danger]="option.color == 'danger' || option.uid === 'delete'"
                  [class.success]="option.color == 'success'"
                  [class.warning]="option.color == 'warning'"
                  (click)="runItemSelectionOption(option)">
                  <ion-icon [name]="option.icon" slot="start"></ion-icon>
                  <ion-label [innerHTML]="option.label|translate"></ion-label>
              </ion-item>

          </ion-list>
      </ion-content>
  </ng-template>
</ion-popover>

<ion-item-sliding [class.gridMode]="view.viewType === 'grid'">

  <!-- Main item -->
  <ion-item [lines]="lines" [disabled]="!!item.disabled" [class.disabled]="!!item.disabled"
    [class.error]="!!item.type && (item.type === 'error')" [class.success]="!!item.type && (item.type === 'success')"
    [class.warning]="!!item.type && (item.type === 'warning')">

    <!-- List mode thumbnail -->
    <ion-thumbnail slot="start" [hidden]="view.viewType === 'grid'"
      *ngIf="!options || (options.showPhoto !== false) && (!!options.photoKey || (!!item.thumbnail || !!item.photo) || fallbackImg)">
      <ion-img *ngIf="!!item.uid"
        [src]="(!!options.photoKey ? item[options.photoKey] : (item.thumbnail || item.photo)) || fallbackImg"
        (ionError)="thumbnailLoadingFailed(item)"></ion-img>
      <ion-skeleton-text class="image" *ngIf="!item.uid" animated></ion-skeleton-text>
    </ion-thumbnail>

    <ion-icon [name]="item.icon || 'folder-outline'" slot="start"
      *ngIf="!!item.uid && !item.photo && !options.photoKey && !!item.icon"></ion-icon>

    <!-- View mode -->
    <ion-checkbox *ngIf="!mode || (mode !== 'edit')" class="ion-text-wrap" [(ngModel)]="item.checked" justify="start"
      labelPlacement="end" (ionChange)="onItemChecked()" [disabled]="!!item.disabled || !item.uid">

      <!-- Timestamp -->
      <small *ngIf="!!options.showTimestamp && (!!item.timestamp_formatted || !!item.timestamp)"
        [innerHTML]="item.timestamp_formatted || item.timestamp"></small>

      <!-- Label -->
      <p *ngIf="!!item[options.labelKey] || !!options.labelFallback" class="ion-text-wrap" [innerHTML]="(item[options.labelKey] || options.labelFallback)|translate"></p>

      <!-- Sublabel -->
      <small *ngIf="!!item[options.subLabelKey]" class="ion-text-wrap"
        [innerHTML]="item[options.subLabelKey]|translate"></small>

      <ion-skeleton-text *ngIf="!item[options.labelKey] && !options.labelFallback" style="float:left;width:25%;margin-right:10px;"
        [animated]="!item.uid"></ion-skeleton-text>

      <ion-skeleton-text *ngIf="!item[options.labelKey] && !options.labelFallback" style="float:left;width:35%;margin-right:25%;"
        [animated]="!item.uid"></ion-skeleton-text>

    </ion-checkbox>

    <!-- Edit mode -->
    <ion-input *ngIf="!!mode && (mode === 'edit')" [placeholder]="item[options.labelKey]|translate"
      [disabled]="!!item.disabled"
      [(ngModel)]="item[!!options.valueKey ? options.valueKey : options.subLabelKey]"></ion-input>

    <!-- List mode buttons -->
    <ion-button *ngFor="let button of buttons; trackBy:trackItems" [color]="button.color || 'primary'"
      [fill]="button.fill || 'clear'" [slot]="button.slot || 'end'" [disabled]="!!item.disabled || !item.uid"
      [hidden]="!!item.loading || (view.viewType === 'grid')" (click)="onButtonClick(button, item)">
      <ion-icon *ngIf="!!button.icon" [name]="button.icon" slot="start"></ion-icon>
      <ion-label [innerHTML]="button.label|translate"></ion-label>
    </ion-button>

    <ion-spinner slot="end" [hidden]="!item.loading"></ion-spinner>

    <!-- Options button -->
    <ion-button slot="end" class="drag-handle" (click)="presentPopover($event, item)" fill="clear" icon-only color="dark" *ngIf="!!selectionOptions && (mode !== 'pick') && !!item.uid">
      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
    </ion-button>

  </ion-item>

  <div class="child-list" *ngIf="!!item && !!item.children && !!item.children.length">
    <ion-item *ngFor="let child of item.children" [lines]="lines" [disabled]="!!child.disabled" [class.disabled]="!!child.disabled"
    [class.error]="!!child.type && (child.type === 'error')" [class.success]="!!child.type && (child.type === 'success')"
    [class.warning]="!!child.type && (child.type === 'warning')">

    <!-- List mode thumbnail -->
    <ion-thumbnail slot="start" [hidden]="view.viewType === 'grid'"
      *ngIf="!options || (options.showPhoto !== false) && (!!options.photoKey || (!!child.thumbnail || !!child.photo) || fallbackImg)">
      <ion-img *ngIf="!!child.uid"
        [src]="(!!options.photoKey ? child[options.photoKey] : (child.thumbnail || child.photo)) || fallbackImg"
        (ionError)="thumbnailLoadingFailed(child)"></ion-img>
      <ion-skeleton-text class="image" *ngIf="!child.uid" animated></ion-skeleton-text>
    </ion-thumbnail>

    <ion-icon [name]="child.icon || 'folder-outline'" slot="start"
      *ngIf="!!child.uid && !child.photo && !options.photoKey && !!child.icon"></ion-icon>

    <!-- View mode -->
    <ion-checkbox *ngIf="!mode || (mode !== 'edit')" class="ion-text-wrap" [(ngModel)]="child.checked" justify="start"
      labelPlacement="end" (ionChange)="onItemChecked()" [disabled]="!!child.disabled || !child.uid">

      <!-- Timestamp -->
      <small *ngIf="!!options.showTimestamp && (!!child.timestamp_formatted || !!child.timestamp)"
        [innerHTML]="child.timestamp_formatted || child.timestamp"></small>

      <!-- Label -->
      <p *ngIf="!!child[options.labelKey] || !!options.labelFallback" class="ion-text-wrap" [innerHTML]="(child[options.labelKey] || options.labelFallback)|translate"></p>

      <!-- Sublabel -->
      <small *ngIf="!!child[options.subLabelKey]" class="ion-text-wrap"
        [innerHTML]="child[options.subLabelKey]|translate"></small>

      <ion-skeleton-text *ngIf="!child[options.labelKey] && !options.labelFallback" style="float:left;width:25%;margin-right:10px;"
        [animated]="!child.uid"></ion-skeleton-text>

      <ion-skeleton-text *ngIf="!child[options.labelKey] && !options.labelFallback" style="float:left;width:35%;margin-right:25%;"
        [animated]="!child.uid"></ion-skeleton-text>

    </ion-checkbox>

    <!-- Edit mode -->
    <ion-input *ngIf="!!mode && (mode === 'edit')" [placeholder]="child[options.labelKey]|translate"
      [disabled]="!!child.disabled"
      [(ngModel)]="child[!!options.valueKey ? options.valueKey : options.subLabelKey]"></ion-input>

    <!-- List mode buttons -->
    <ion-button *ngFor="let button of buttons; trackBy:trackItems" [color]="button.color || 'primary'"
      [fill]="button.fill || 'clear'" [slot]="button.slot || 'end'" [disabled]="!!child.disabled || !child.uid"
      [hidden]="!!child.loading || (view.viewType === 'grid')" (click)="onButtonClick(button, child)">
      <ion-icon *ngIf="!!button.icon" [name]="button.icon" slot="start"></ion-icon>
      <ion-label [innerHTML]="button.label|translate"></ion-label>
    </ion-button>

    <ion-spinner slot="end" [hidden]="!child.loading"></ion-spinner>

    <!-- Options button -->
    <ion-button slot="end" class="drag-handle" (click)="presentPopover($event, child)" fill="clear" icon-only color="dark" *ngIf="!!selectionOptions && (mode !== 'pick') && !!child.uid">
      <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
    </ion-button>

  </ion-item>
  </div>

  <!-- Grid view bottom bar -->
  <ion-item lines="none" [hidden]="!!item.loading || view.viewType !== 'grid'">

    <ion-button *ngFor="let button of buttons; trackBy:trackItems" [color]="button.color || 'primary'"
      [fill]="button.fill || 'clear'" [slot]="button.slot || 'end'" [disabled]="!!item.disabled || !item.uid"
      (click)="onButtonClick(button, item)">
      <ion-icon *ngIf="!!button.icon" [name]="button.icon" slot="start"></ion-icon>
      <ion-label [innerHTML]="button.label|translate"></ion-label>
    </ion-button>

  </ion-item>

  <ion-item-options
    *ngIf="((!!item && !!item.buttons && !!item.buttons.length) || (!!buttons && !!buttons.length)) && !!item.uid && !view.isDesktop"
    slot="end">

    <ion-item-option *ngFor="let button of (item.buttons || buttons); trackBy:trackItems"
      [color]="button.color || 'primary'" (click)="onButtonClick(button, item)">
      <ion-icon *ngIf="!!button.icon" [name]="button.icon" slot="start"></ion-icon>
      <ion-label [innerHTML]="button.label|translate"></ion-label>
    </ion-item-option>

  </ion-item-options>

</ion-item-sliding>