<ion-card class="ai-styles-card">

  <ion-card-header>

    <ion-card-title [innerHTML]="'target_groups'|translate"></ion-card-title>

  </ion-card-header>

  <div *ngIf="!!view.target_groups && !!view.target_groups.length">

    <ion-list *ngFor="let type of view.target_groups; let iType = index;">

      <!-- type header -->
      <ion-item button (click)="toggleTypeState(type)" [disabled]="!!disabled">

        <ion-icon *ngIf="!!typeIcons[type]" [name]="typeIcons[type]" slot="start"></ion-icon>

        <ion-label class="ion-text-wrap">
          <h3 [innerHTML]="('ai_styles_type_' + type) | translate"></h3>
          <p *ngIf="!!view.style[type]" [innerHTML]="('ai_style_' + view.style[type])|translate"
            [hidden]="typeStates[type]"></p>
        </ion-label>

        <ion-button slot="end" fill="clear" color="dark" icon-only [disabled]="!!disabled">
          <ion-icon [name]="typeStates[type] ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
        </ion-button>

      </ion-item>

      <ion-list [hidden]="!typeStates[type]">

        <!-- style picker -->
        <ion-item [lines]="!!view.target_groups[iType+1] ? true : 'none'">

          <ion-icon name="flask-outline" slot="start"></ion-icon>

          <ion-button slot="end" fill="clear" color="dark" icon-only (click)="configureStyle(type)" [disabled]="!!disabled"
            *ngIf="!!view.style[type] && (view.style[type] !== 'auto') && (view.style[type] !== 'custom')">
            <ion-icon name="cog-outline"></ion-icon>
          </ion-button>

        </ion-item>

        <!-- custom style input -->
        <ion-item [disabled]="!!disabled" *ngIf="!!view.style && !!view.style[type] && (view.style[type] === 'custom')">
          <ion-icon name="build-outline" slot="start"></ion-icon>
          <ion-textarea type="text" rows="4" [disabled]="!!disabled" [(ngModel)]="view.style[type].input"
            [placeholder]="'ai_style_custom_placeholder'|translate"></ion-textarea>
        </ion-item>

      </ion-list>

    </ion-list>

  </div>

  <ion-card-content>
    <ion-grid>
      <ion-row>
        
        <ion-col size="6">
          <ion-button [disabled]="!!disabled" size="block" icon-only fill="outline" color="dark" (click)="addTargetGroup()">
            <ion-icon name="folder-outline"></ion-icon>
            <ion-label [innerHTML]="'select'|translate"></ion-label>
          </ion-button>
        </ion-col>

        <ion-col size="6">
          <ion-button [disabled]="!!disabled" size="block" icon-only fill="outline" color="dark" (click)="createTargetGroup()">
            <ion-icon name="add-outline"></ion-icon>
            <ion-label [innerHTML]="'create'|translate"></ion-label>
          </ion-button>
        </ion-col>

      </ion-row>
    </ion-grid>
  </ion-card-content>

</ion-card>