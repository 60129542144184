import { Injectable } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {

    cards: any = {
        ai: { open: false },
        ai_style: { open: true },
        answer: { open: true },
        articles: { open: true },
        attachments: { open: true },
        attributes: { open: true },
        audio_transcription: { open: false },
        authors: { open: true },
        caption: { open: false },
        categories: { open: false },
        channels: { open: false },
        code: { open: true },
        comments: { open: true },
        connections: { open: true },
        creation: { open: true },
        cta: { open: false },
        distribution: { open: true },
        duration: { open: true },
        files: { open: true },
        filters: { open: true },
        folders: { open: true },
        general: { open: true },
        information: { open: true },
        integrations: { open: false },
        inputMode: { open: true },
        item_attributes: { open: true },
        library_names: { open: true },
        lookAndFeel: { open: false }, // duplicate
        look_and_feel: { open: false }, // duplicate
        media: { open: true },
        multilingual: { open: false },
        music: { open: false },
        overlay: { open: true },
        output: { open: true },
        parameters: { open: true },
        platforms: { open: true },
        preview: { open: true },
        prompts: { open: true },
        providers: { open: false },
        publishing: { open: true },
        request: { open: true },
        source: { open: true },
        sources: { open: true },
        statistics: { open: true },
        storages: { open: true },
        styles: { open: false },
        supplier_requests: { open: true },
        tags: { open: true },
        templates: { open: false },
        types: { open: true },
        visualSpeaker: { open: false },
        voiceover: { open: false },
    };

    constructor(
        private cache: CacheService,
    ) {

    }

    getCacheKey(page: string | null = 'all') {
        return `pipeline_sidebar_cards_${page || 'all'}`;
    }

    async getCards(page: string | null = 'all') {
        page = page || 'all';

        const cacheKey: string = this.getCacheKey(page);
        const fromCache: cacheItem|null = await this.cache.get(cacheKey, -1);
        
        return (!!fromCache && !!fromCache.data ? fromCache.data : this.cards);
    }

    async setCards(cards: any, page: string | null = 'all') {
        page = page || 'all';

        // load config from cache
        let cachedConfig: any = (await this.getCards() || {});

        const cardUpdateKeys: string[] = Object.keys(cards);

        // append updated values to config
        if (!!cardUpdateKeys && !!cardUpdateKeys.length) {
            cardUpdateKeys.forEach((key: string) => {
                if (!cachedConfig.hasOwnProperty(key)) {
                    cachedConfig[key] = {};
                }
                cachedConfig[key] = Object.assign(cachedConfig[key], cards[key]);
            });
        }

        // update cache storage (@todo: sync server-side)
        return this.cache.set(this.getCacheKey(page), cachedConfig);
    }

}