<ion-card *ngIf="!!template.config" id="stage" #templatePreviewInnerStage
  class="stage-template-uid-{{(template.template_uid || template.uid) || 0}}"
  [class.square]="!template.config.aspect_ratio || template.config.aspect_ratio === '1x1'"
  [class.portrait]="template.config.aspect_ratio === '9x16'" [class.landscape]="template.config.aspect_ratio === '16x9'"
  [class.editable]="view.editable" [style.transform]="view.transformStyle">

  <div id="stage-inner"
    *ngIf="!!template && !!template.config && !!template.config.aspect_ratios && !!template.config.aspect_ratios[template.config.aspect_ratio]">

    <pipeline-media-template-preview-layer
      *ngFor="let layer of template.config.aspect_ratios[template.config.aspect_ratio]; let i = index; trackBy:trackItems"
      [cdkDrag]="view.editable" (cdkDragEnded)="dragEnd($event, layer, i)"
      [id]="'layer-' + (layer.settings.id || i) + '-wrapper'" [class.active]="layer.active"
      [class.running]="view.running" [index]="i"
      [hidden]="!!layer.hidden || !((template.config.timeline.current >= layer.settings.start) && (template.config.timeline.current < layer.settings.end))"
      [(layer)]="template.config.aspect_ratios[template.config.aspect_ratio][i]" [(template)]="template" [(view)]="view"
      [style]="layer.formatted_style || layer.style"
      [style.background-color]="layer.style['background-color'] || layer.backgroundColor" [style.z-index]="(99 - i)"
      [style.width]="layer.settings.width + '%'" [style.height]="layer.settings.height + '%'">
    </pipeline-media-template-preview-layer>

  </div>

</ion-card>