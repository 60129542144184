import { Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ItemReorderEventDetail } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

import { ClipboardService } from "src/app/services/utils/clipboard.service";
import { ConfigService } from 'src/app/services/core/config.service';
import { DaniService } from 'src/app/services/getgenius/dani.service';
import { EventsService } from 'src/app/services/core/events.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ParamsService } from 'src/app/services/core/params.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { TemplatesService } from 'src/app/services/media/templates.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { AiStoreService } from '../../../../services/ai/ai-store.service';
import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { AiWorkerService } from 'src/app/services/ai/ai-worker.service';

import { AiTaskBenchmarkPage } from '../ai-task-benchmark/ai-task-benchmark.page';
import { AiToolsPage } from '../ai-tools/ai-tools.page';

import { EditorComponent } from 'src/app/components/generic/editor/editor.component';

@Component({
  selector: 'app-ai-task',
  standalone: false,
  templateUrl: './ai-task.page.html',
  styleUrls: ['./ai-task.page.scss'],
})
export class AiTaskPage implements OnInit {

  aiSettings: aiSettings = {
    context: 'text_generation',
  };

  aiSettingsOptions: aiSettingsOptions = {
    operations: [],
  };

  appConfig: pipelineAppConfig;

  browserConfig: aiBrowserConfig = {
    use_proxy: true,
  };

  cards: any = {
    //ai: { open: true },
  };

  @ViewChildren(EditorComponent) editorList: QueryList<any>;

  @ViewChild('headerPopover') headerPopover;

  isHeaderPopoverOpen: boolean = false;

  isSharePostPopoverOpen: boolean = false;

  isTaskAiSettingsPopoverOpen: boolean = false;

  fallbackImg: string = './assets/img/fallback.webp';

  introOverlayConfig: introOverlayConfig = {
    allowManually: true,
    headline: 'create_automation_ai_helper_text',
    input_placeholder: 'create_automation_ai_helper_input_placeholder',
    showAiCreate: true,
    showAiSettings: true,
    showInput: true,
    showIntegrations: false,
  };

  search: searchOptions = {
    keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  @ViewChild('sharePostPopover') sharePostPopover;

  selectionOptions: selectionOption[] = [
    {
      icon: 'stats-chart-outline',
      label: 'create_benchmark',
      uid: 'create_benchmark',
    },
    {
      icon: 'sparkles-outline',
      label: 'optimize',
      uid: 'optimize',
    },
    {
      color: 'warning',
      icon: 'cloud-upload-outline',
      label: 'publish_to_store',
      uid: 'publish_to_store',
    },
  ];

  state: state = {};

  @ViewChild('taskAiSettingsPopover') taskAiSettingsPopover;

  view: any = {
    canRun: false,
    canSave: false,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'ai_quick_top_card',
      lottieSrc: './assets/lottie/ai_chip.json',
      text: 'ai_quick_top_card_text',
      title: 'ai_quick_top_card_title',
    },
    progress: 0,
    promptFunctionActions: [],
    promptTypes: [],
    route: 'ai/task',
    showMenuButton: true,
    showProjectsSelect: true,
    task: {},
    tasks: [],
    title: 'automation',
    triggerEvents: [
      'email_received',
      'find_content_in_documents',
      'find_content_in_emails',
      'find_content_in_posts',
      'find_content_in_social_media_comments',
      'find_content_in_urls',
    ],
    triggerEventTypes: [
      {
        photo: './assets/img/icon.webp',
        label: 'general',
        uid: 'general',
      }
    ],
  };

  constructor(
    private aiStore: AiStoreService,
    private aiTools: AiToolsService,
    private aiWorker: AiWorkerService,

    private clipboard: ClipboardService,
    private configService: ConfigService,
    private dani: DaniService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private modalService: ModalService,
    private params: ParamsService,
    private projects: ProjectsService,
    private templates: TemplatesService,
    private tools: ToolsService,

    private route: ActivatedRoute,
  ) {
    this.appConfig = this.configService.getConfig();

    const contexts: aiContext[] = this.aiTools.getContexts() || [];
    const operations: string[] = contexts.map((context: aiContext) => {
      return `${context.uid || ''}`;
    }) as string[];

    this.aiSettings = this.aiWorker.getConfig();
    this.aiSettingsOptions.operations = operations;

    this.view.contexts = contexts;
    this.view.data = this.aiTools.getQuickData();
    this.view.promptFunctionActions = this.aiTools.getPromptFunctionActions();
    this.view.promptTypes = this.aiTools.getPromptTypes();
    this.view.taskUidFromRoute = this.route.snapshot.paramMap.get('taskId');
  }

  async addTask(options: any = {}) {

    const task: any = Object.assign({
      active: false,
      input: '',
      output: '',
      promptType: 'user',
      type: 'custom',
    }, options);

    this.view.tasks.push(task);

    this.view.tasks.forEach((task: any, index: number) => {
      task.active = (index === (this.view.tasks.length - 1));
    });
  }

  aiCreate() {

    if (!this.view.aiCreateInput || !this.view.aiCreateInput.length) {
      return false;
    }

    this.view.generating = true;
    this.view.loading = true;

    this.dani.executeCreateAutomation({
      input: this.view.aiCreateInput
    })
      .then(async (response: any) => {
        this.view.generating = false;
        this.view.loading = false;

        if (!!response && !!response.data) {

          this.view.tasks = (response.data || []).map((task: aiTask, index: number) => {
            task.active = task.active || (index === 0);
            task.promptType = task.promptType || 'user';
            task.type = task.type || 'custom';

            return task;
          });

          // show tasks UI
          this.calcInputVars();
          this.startManually();

          // run set if tasks provided
          if (!!this.view.tasks && !!this.view.tasks.length) {
            this.runSet();
          }

        }
      })
      .catch((error: any) => {
        this.view.generating = false;
        this.view.loading = false;

        this.events.publish('error', error);
      });
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  animateProgress() {
    this.stopProgressAnimation();

    if (!this.view.progressAnimation) {
      this.view.progressAnimation = setInterval(() => {
        if (this.view.progress < 100) {
          this.view.progress += 0.001;
        } else {
          clearInterval(this.view.progressAnimation);
          this.view.progressAnimation = null;
        }
      }, 500);
    }

  }

  calcColSize() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 6 : 12);
  }

  calcInputVars() {
    let inputs: any[] = [];

    this.view.tasks.forEach((task: any) => {

      if ((!task.input || !task.input.length) && !!task.inputs) {
        return false;
      }

      const varsInTaskInput = [...new Set(`${task.input}`.match(/\{(.*?)\}/g))];

      if (!!varsInTaskInput) {
        task.inputs = varsInTaskInput.map((input: any, index: number) => {
          input = input.replace(/{/g, '').replace(/}/g, '');

          return {
            name: input,
            uid: input,
            value: (task.inputs && !!task.inputs[index] ? task.inputs[index].value : ''),
          };
        });
      } else {
        delete task.inputs;
      }

      inputs = inputs.concat(task.inputs);
    });

    this.view.inputs = inputs;
  }

  calcViewVars() {
    this.view.canRun = true;
    this.view.canSave = true;
  }

  copyText(text: string) {
    this.clipboard.copyText(text);
  }

  async create_benchmark(item: any | null = null) {
    item = (item || this.view.task) || {};

    const modal: any = await this.modalService.create({
      component: AiTaskBenchmarkPage,
      componentProps: {
        tasks: [item],
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  deleteTask(task: any, index: number) {
    this.view.tasks = this.view.tasks.filter((_task: any, _index: number) => {
      return _index !== index;
    });
  }

  doRefresh() {
    this.load(true)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  editTask(task: any, index: number) {
    this.calcInputVars();
    this.setActiveTaskIndex(index);
  }

  expandView(task: any, index: number, key: string = 'input') {
    task.fullscreenView = !task.fullscreenView;
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    this.view.tasks = ev.detail.complete();
  }

  handleSetStateDone(response: any) {
    console.info('handleSetStateDone', response);

    this.stopSet();
  }

  handleSetStateError(response: any) {
    console.error('handleSetStateError', response);

    this.stopSet();
  }

  initEvents() {
    this.view.events = {};

    this.view.events.aiQuickCalcInputVars = this.events.subscribe('ai:quick:calcInputVars', () => {
      this.calcInputVars();
    });

    this.view.events.aiResponse = this.events.subscribe('ai:response', (response: any) => {

      // handle by response type
      switch (response.type) {
        case 'done':
          this.handleSetStateDone(response);
          break;
        case 'error':
          this.handleSetStateError(response);
          break;
        default:
          this.view.progress = ((!!response && !!response.data ? response.data.progress || 1 : 1) / 100);
          this.animateProgress();
          break;
      }

      // handle by message
      if (!!response && !!response.message) {
        switch (response.message) {
          case 'task:child:updated':

            if (!!response.data && !!response.data.task && response.data.hasOwnProperty('childIndex') && response.data.hasOwnProperty('taskIndex')) {
              this.onTaskChildUpdated(response.data.task, response.data.childIndex, response.data.taskIndex);
            }

            break;
          case 'task:updated':

            if (!!response.data && !!response.data.task) {
              this.onTaskUpdated(response.data.task, response.data.index);
            }

            break;
          case 'tasks:updated':
            if (!!response.data && !!response.data.tasks) {
              this.view.tasks = response.data.tasks;
            }
            break;
          default:
            console.warn('[AI] > unknown handler for response: ', response);
            break;
        }
      }
    });

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    this.events.stop(this.view.events);
  }

  itemInfo(task: any, event: any | null = null) {
  }

  async load(blForceRefresh: boolean = false) {
    await this.loadProject();

    if (!!this.view.project && !!this.view.project.uid) {
      this.loadIdeaData(blForceRefresh);
    }
  }

  async loadIdeaData(blForceRefresh: boolean = false) {
    this.view.data = this.aiTools.getQuickData();

    const createTaskParams: any = this.params.get('viewData_createTask') || {};
    console.log('ai-task: loadIdeaData: createTaskParams', createTaskParams);

    // load remote task if no current data is set
    if ((!this.view.data || !this.view.data.uid) && !!this.view.taskUidFromRoute) {
      try {
        const remoteTask: aiTask = await this.aiTools.getTaskByUid(parseInt(this.view.taskUidFromRoute));

        if (!!remoteTask && !!remoteTask.uid) {
          this.view.data = remoteTask;
          this.view.title = `${remoteTask.name || 'automation'}`;
        }

      } catch (e) {
        this.events.publish('error', e);
      }
    }

    if (!!this.view.data && !!this.view.data.uid) {
      this.view.task = this.view.data;
      this.view.title = `${this.view.task.name || 'automation'}`;

      this.view.tasks = (this.view.data.children || []).map((task: any, index: number) => {

        if (!task.hasOwnProperty('active')) {
          task.active = (index === 0);
        }

        if (!task.hasOwnProperty('input') && !!task.name) {
          task.input = task.name;
        }

        task.promptType = task.promptType || 'user';
        task.type = task.type || 'custom';

        return task;
      });

      this.startManually();
    } else
      if (!!this.view.data && !!this.view.data.idea) {

        if (!!this.view.tasks && !!this.view.tasks[0]) {
          this.view.tasks[0].input = this.view.data.idea.input;
          this.view.tasks[0].output = this.view.data.idea.output;
        }

        this.calcInputVars();
      } else
        if (!!createTaskParams) {

        }

    this.calcViewVars();
  }

  async loadIntegrations(blForceRefresh: boolean = false) {
    await this.loadProject();

    if (!this.view.project || !this.view.project.uid) {
      return false;
    }

    this.view.loading = true;

    try {
      this.view.integrations = await this.integrations.getEnabled({}, blForceRefresh);
      this.view.integrations_backup = (this.view.integrations || []);
      this.view.loading = false;

      this.view.triggerEventTypes = [
        {
          photo: './assets/img/icon.webp',
          label: 'general',
          uid: 'general',
        }
      ].concat(this.view.integrations || []);

    } catch (e) {
      this.view.loading = false;
      console.warn('> loading integrations failed', e);
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  modelChanged(event: any | null = null, task: aiTask | null = null, index: number | null = null) {
    this.aiSettings = this.aiSettings || {};
    this.aiSettings.model = event;

    if (!!task) {
      task.config = task.config || {};
      task.config.ai = task.config.ai || {};
      task.config.ai.model = this.aiSettings.model;

      if (index !== null) {
        this.view.tasks[index] = task;
      }

      console.log('updated task config', task.config);
    }

    this.aiWorker.setConfig(this.aiSettings);
  }

  ngOnInit() {
    this.calcColSize();

    this.load();
    this.loadIntegrations();
  }

  onBrowserPageDone(event: any | null = null) {
    console.log('ai-task: onPageDone', event);
  }

  onBrowserPageLoaded(event: any | null = null) {
    console.log('ai-task: onPageLoaded', event);
  }

  onBrowserPageRendered(event: any | null = null) {
    console.log('ai-task: onPageRendered', event);
  }

  onCaptionChanged(caption: string | null = null) {
    console.log('onCaptionChanged', caption);
  }

  onEditorInputChanged(value: string, task: aiTask, index: number | null = null, key: string = 'input') {

    if (!!value && (index !== null) && !!task.children && !!task.children[index]) {
      // update child key
      task.children[index][key] = value;
    } else
      if (!!value && !!task) {
        // update main key
        task[key] = value;
      }

    this.calcViewVars();
  }

  onOverlayInputChanged(event: any = null) {
    console.log('onOverlayInputChanged', event);
  }

  @HostListener('window:resize')
  onResize() {
    this.calcColSize();
  }

  onTaskAiSettingsChanged(event: any | null = null) {
    this.view.aiSettingsTask.config = this.view.aiSettingsTask.config || {};
    this.view.aiSettingsTask.config.ai = Object.assign(this.view.aiSettingsTask.config.ai || this.aiSettings, event || {});

    this.view.tasks[this.view.aiSettingsTaskIndex] = this.view.aiSettingsTask;
  }

  onTaskChildClick(child: any, task: aiTask, iChild: number) {

    if (!!child.loading) {
      return false;
    }

    this.view.rebuild = true;

    task.uiChildIndex = iChild;

    if (!task.loading) {
      task.childIndex = task.uiChildIndex;
    }

    // set child to active 
    if (!!task.children && !!task.children.length) {
      task.children.forEach((child: any, _iChild: number) => {
        task.children[_iChild].active = (_iChild === iChild);
      });
    }

    if (task.promptType === 'function') {
      task.children[iChild].execute = false;

      setTimeout(() => {
        task.children[iChild].execute = true;
      }, 250);
    }

    setTimeout(() => {

      if (!!child.output) {
        task.output = child.output;
      }

      this.view.rebuild = false;
    }, 250);
  }

  onTaskPromptTypeChanged(task: aiTask) {
  }

  // update task child editor if update received
  async onTaskChildUpdated(child: any, iChild: number, iTask: number) {

    /*
    console.log('editorList', this.editorList);

    let taskEditor: any = this.editorList.get(index);
    console.log('task editor', taskEditor);
    */

    /*
    if(!!taskEditor) {
      taskEditor.setInput()
    }
    */
  }

  // update task if update received
  async onTaskUpdated(task: aiTask, index: number = 0) {

    if (!!task.inputs) {
      this.view.tasks[index] = task.inputs;
    }

    this.view.tasks[index] = task;
  }

  optimize() {
    console.log('optimize: view', this.view);
  }

  async optimizeTaskInputPrompt(task: aiTask, event: any | null = null) {

    if (!task.input || !task.input.length) {
      return false;
    }

    task.loadingInput = true;

    try {

      const optimize: any = await this.aiTools.optimizePrompt(task.input, {
        history: [
          {
            role: 'system',
            content: `Optimize only the following AI prompt and return the improved prompt (text string) only.
            Please always try to improve the existing variables / add more variables ({...}) if provided based on the provided user prompt for a better detailed prompt and more configuration options to make the prompt reusable.`,
          }
        ]
      });

      if (!!optimize && !!optimize.output) {
        task.input = `${optimize.output || ''}`;
        this.calcInputVars();
      }

      task.loadingInput = false;
    } catch (e) {
      task.loadingInput = false;
      this.events.publish('error', e);
    }
  }

  pickTaskMediaTemplate(task: any, type: string, index: number) {
    this.templates.pick({
      filter: {
        active: true,
        public: true,
        type: type,
      },
      multiple: true,
      project_uid: 0,
    })
      .then((chooseResponse: chooseResponse) => {
        const templates: mediaTemplate[] = (chooseResponse && chooseResponse.data && chooseResponse.data.items ? chooseResponse && chooseResponse.data && chooseResponse.data.items : (chooseResponse && chooseResponse.data && chooseResponse.data.item ? chooseResponse.data.item : []));

        task.save_params = task.save_params || {};
        task.save_params.templates = task.save_params.templates || {};
        task.save_params.templates[type] = (templates || []);

        this.view.tasks[index] = task;
      })
      .catch((error: any) => {
        delete task.save_paths;

        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  async pickTaskShortcode(task: any) {
    this.setTaskType(task, 'shortcode');

    this.view.toolsMenuModal = await this.modalService.create({
      backdropDismiss: false,
      component: AiToolsPage,
      componentProps: {
        executeTool: false,
        multiple: false,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    this.view.toolsMenuModal.onWillDismiss().then((response: any) => {
      console.log('response: ', response);

      this.view.rebuild = true;

      if (!response || !response.data) {
        this.setTaskType(task, 'custom');
      } else {

        if (!!response.data.name) {
          task.name = response.data.name;
        }

        if (!!response.data.promptType) {
          task.promptType = response.data.promptType;
        }

        if (!!response && !!response.data && !!response.data.input && !!response.data.input.length) {
          task.input = `${response.data.input}`;
        }

        if (!!response && !!response.data && !!response.data.output && !!response.data.output.length) {
          task.output = `${response.data.output}`;
        }
      }

      setTimeout(() => {
        this.view.rebuild = false;
        this.calcInputVars();
      });
    });

    this.modalService.present(this.view.toolsMenuModal);
  }

  async publish_to_store() {
    console.log('publish_to_store', this.view.task);

    if (!this.view.task || !this.view.task.uid) {
      return false;
    }

    try {
      const response: any = await this.aiStore.publishTask(this.view.task);
      console.log('publish response', response);
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  removeTaskSavePath(path: any, task: aiTask, i: number) {
  }

  run(task: any | null = null) {
    this.aiWorker.executeAITask(task, null, {

      onTaskChildUpdated: (event: any) => {
        this.onTaskChildUpdated(event.task, event.childIndex, event.taskIndex);
      },

      onTaskUpdated: (event: any) => {
        this.onTaskUpdated(event.task, event.taskIndex);
      },

    })
      .then((response: any) => {
        console.log('ai-task: execution done', response);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async runItemSelectionOption(item: any, option: any) {
    try {

      if (!option || !option.uid) {
        return false;
      }

      const exec: any = await this[option.uid](item);

      this.isHeaderPopoverOpen = false;
    } catch (e) {
      console.warn('executing single selection on item failed', e);
      this.events.publish('error', e);
    }
  }

  runSet() {
    this.view.loading = true;
    this.view.task.children = JSON.parse(JSON.stringify(this.view.tasks));

    let task: aiTask = this.view.task;

    this.aiWorker.validateSet(task)
      .then(async (response: any) => {
        console.log('validate ai task set response', response);

        this.view.loading = false;
        this.view.running = true;

        this.loadProject();

        if (!!response && !!response.task) {
          task = response.task;
        }

        this.aiWorker.setCurrentTask(task);
        this.aiWorker.setCurrentProject(this.view.project);

        this.aiWorker.executeAITaskSet({

          onTaskChildUpdated: (event: any) => {
            this.onTaskChildUpdated(event.task, event.childIndex, event.taskIndex);
          },

          onTaskUpdated: (event: any) => {
            this.onTaskUpdated(event.task, event.taskIndex);
          },

        })
          .then((response: any) => {
            console.log('execute ai task set response', response);

            this.stopSet();
          })
          .catch((error: any) => {
            console.warn('> run set error', error);

            this.view.running = false;

            this.events.publish('error', error);
          });
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  save() {

    if (!this.view.isSaveMode) {
      this.view.isSaveMode = true;
      return false;
    }

    let task: aiTask = JSON.parse(JSON.stringify(this.view.task));
    task.children = JSON.parse(JSON.stringify(this.view.tasks));

    if (!!task.uid) {
      return this.update(task);
    }

    this.aiTools.addTask(task)
      .then(() => {
        this.view.isSaveMode = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  setActiveTaskIndex(index: number) {

    if (!this.view.tasks || !this.view.tasks.length) {
      return false;
    }

    this.view.tasks.forEach((task: any, _index: number) => {
      task.active = (_index === index);
    });

  }

  setTaskSavePath(task: any, index: number) {
    this.aiTools.pickTaskSavePath(task, {
      multiple: true,
    })
      .then((paths: any[]) => {
        task.save_paths = paths;

        if (!!paths) {
          paths.forEach((path: any) => {
            switch (path.uid) {
              case 'image':
                this.pickTaskMediaTemplate(task, 'image', index);
                break;
              case 'video':
                this.pickTaskMediaTemplate(task, 'video', index);
                break;
            }
          });
        }

      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  setTaskType(task: any, type: string) {
    task.type = type;
  }

  share(post: any, event: any | null = null) {

    if (!!post && (!!post.output || !!post.input)) {
      post.post_content = (post.output || post.input);
    }

    this.view.sharePost = post;

    this.sharePostPopover.event = event;
    this.isSharePostPopoverOpen = true;
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  showTaskAiSettingsPopover(task: aiTask, index: number, event: any | null = null) {
    task.config = task.config || {};
    task.config.ai = task.config.ai || this.aiSettings;

    this.taskAiSettingsPopover.event = event;
    this.isTaskAiSettingsPopoverOpen = true;

    this.view.aiSettingsTask = task;
    this.view.aiSettingsTaskIndex = index;
  }

  startManually() {
    this.view.startManually = true;

    if (!this.view.tasks || !this.view.tasks.length) {
      this.addTask();
    }
  }

  stopProgressAnimation() {
    try {
      if (!!this.view.progressAnimation) {
        clearInterval(this.view.progressAnimation);
        this.view.progressAnimation = null;
      }
    } catch (e) {
      console.warn('> stopping progress animation failed', e);
    }

    this.view.progress = 0;
  }

  stopTask(task: any) {

  }

  stopSet() {
    this.stopProgressAnimation();

    this.aiWorker.stopSet(this.view.setHandler)
      .then(() => {
        this.view.running = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  toggleShouldPause(task: any, i: number) {
    task.shouldPause = !task.shouldPause;
  }

  toggleTaskListOutput(task: any, index: number = 0) {
    task.loop = !task.loop;

    if (!!task.loop) {

      const listItems: string[] = `${task.output_content || ''}`.split("<br />").filter((line: string) => {
        return !!line && !!line.length;
      }).map((line: string) => {
        return `${line}`.replace('• ', '').replace('- ', '').trim();
      }).filter((line: string) => {
        return !!line && !!line.length;
      });

      const children: any[] = listItems.map((line: string, _index: number) => {
        return {
          active: !_index,
          loading: false,
          name: line,
          value: line,
        };
      });

      if (this.view.tasks[index + 1]) {
        this.view.tasks[index + 1].children = children;

        if (!!this.view.tasks[index + 1].children) {
          this.view.tasks[index + 1].loop = true;
        }

        if (task.promptType !== 'function') {
          this.aiTools.lookupImagesOnChildren(this.view.tasks[index + 1].children, (index + 1));
        }

      } else {
        task.children = children;

        if (task.promptType !== 'function') {
          this.aiTools.lookupImagesOnChildren(task.children, index);
        }
      }

    }
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  update(task: aiTask | null = null) {
    task = task || JSON.parse(JSON.stringify(this.view.task));

    this.aiTools.updateTask(task)
      .then(() => {
        this.view.isSaveMode = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

}