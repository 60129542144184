import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";

import { EventsService } from "src/app/services/core/events.service";
import { FilesExplorerService } from "src/app/services/files/files-explorer.service";
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'pipeline-files-explorer',
  standalone: false,
  templateUrl: './files-explorer.component.html',
  styleUrls: ['./files-explorer.component.scss']
})
export class FilesExplorerComponent implements AfterViewInit, OnDestroy, OnInit {

  appConfig: pipelineAppConfig;

  @Input() autostart: boolean = false;

  @Input() buttons: button[] = [

  ];

  @Input() config: filesExplorerConfig = {};
  @Input() connection: integrationConnection;

  @Input() disabled: boolean = false;

  dynamicLocalItemOptions: dynamicItemOptions = {
    labelKey: 'title',
    showPhoto: true,
    subLabelKey: 'post_mime_type',
    valueKey: 'uid',
  };
  
  dynamicRemoteItemOptions: dynamicItemOptions = {
    labelKey: 'title',
    showPhoto: false,
    valueKey: 'uid',
  };
  
  fallbackImg: string = './assets/img/fallback.webp';

  @Input() files: any = {
    local: [],
    remote: [],
  };

  @Input() input: string = '';

  integrationsByType: any;

  @Input() items: any[] = [];

  @Input() paginationConfig: paginationConfig = {
    //limit: 1000,
  };

  response: string = '';

  search: searchOptions = {
    itemsKey: 'items',
    keys: ['post_content', 'guid', 'title', 'host', 'url', 'uid'],
    query: "",
  };

  @Input() selectionOptions: selectionOption[];

  @Input() service: any;

  @Input() view: any = {};

  views: any = {
    local: {

    },
    remote: {

    },
  };

  constructor(
    public ai: AiToolsService,

    private events: EventsService,
    public filesExplorer: FilesExplorerService,
    private integrations: IntegrationsService,
    private viewService: ViewService,
  ) {
    this.integrationsByType = this.integrations.getByType();
  }

  calcSearchItems() {
    this.views.local.items = (this.files.local || []);
    this.views.remote.items = (this.files.remote || []);
    console.log('calculated semi-views: ', this.views);
  }

  calcSelectedItems(item: any | null = null) {

    if (!!this.view.response && !!this.view.response.length) {
      this.view.selectedItems = this.view.response.filter((_item: any) => {
        return !!_item.checked || (!!item && (_item.uid === item.uid && !!item.checked));
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  calcViewVars() {
    console.log('files-explorer: calcViewVars: config', this.config);

    this.view = this.viewService.calcVars(this.view);
    this.view.viewType = 'list';

    this.calcSearchItems();
    console.log('files-explorer: calcViewVars: view', this.view);
  }

  fetchRoute() {

  }

  initEvents() {
    this.view.events = {};
  }

  load() {

  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.service = this.service || this.filesExplorer;

    this.calcViewVars();
    this.initEvents();
  }

  onButtonClick(button, item) {
    try {
      button.handler(item, button);
    } catch (e) {
      console.warn('executing button action failed', e);
    }
  }

  onIntegrationChanged(event: any | null = null) {
    console.log('onIntegrationChanged', event);
  }

  onRouteChanged(event: any | null = null) {
    console.log('onRouteChanged', event);
  }

  onSearchChanged(searchOptions: any | null = null) {

  }

  onSelectionActionChanged(event: any | null = null) {
    console.log('onSelectionActionChanged', event);
    switch (this.view.selectionAction) {
    }
  }

  onSelectedItemsChanged(event: any | null = null) {
    this.calcSelectedItems();
    this.calcViewVars();
  }

  thumbnailLoadingFailed(item: any, key: string = 'photo') {
    item[key] = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}