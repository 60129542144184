import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';

import { CacheService } from 'src/app/services/core/cache.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ObjectsService } from 'src/app/services/core/objects.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { TranslationService } from "src/app/services/core/translation.service";
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  appPages: any;

  availableAppPages: appPage[] = [
    {
      expertMode: true,
      icon: 'musical-note-outline',
      key: 'text_to_audio',
      name: 'text_to_audio',
      requiresAboExtension: ['basic', 'pro', 'enterprise'],
      url: '/ai/text-to-audio',
    },
    {
      icon: 'images-outline',
      key: 'create_media',
      name: 'create_media',
      requiresAboExtension: ['basic', 'pro', 'enterprise'],
      url: '/media/create',
    },
    {
      icon: 'film-outline',
      key: 'ai_video_creator_length_60',
      name: 'ai_video_creator_length_60',
      requiresAboExtension: ['basic', 'pro', 'enterprise'],
      url: '/ai/video-creator',
    },
    {
      expertMode: true,
      icon: 'recording-outline',
      key: 'text_to_speech',
      name: 'text_to_speech',
      requiresAboExtension: ['basic', 'pro', 'enterprise'],
      url: '/ai/text-to-speech',
    },
    {
      icon: 'text-outline',
      key: 'create_post',
      name: 'create_post',
      requiresAboExtension: ['basic', 'pro', 'enterprise'],
      url: '/post/new',
    },
    {
      icon: 'calendar-outline',
      key: 'ai_planner',
      name: 'ai_planner',
      requiresAboExtension: ['basic', 'pro', 'enterprise'],
      url: '/ai/planner',
    },
    {
      expertMode: true,
      icon: 'code-outline',
      key: 'ai_coder',
      name: 'ai_coder',
      requiresAboExtension: ['basic', 'pro', 'enterprise'],
      url: '/ai/coder',
    },
    {
      allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
      key: "ai_assistants",
      url: "/ai/assistants",
      icon: "happy-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
      key: "ai_training_log",
      url: "/ai/training/log",
      icon: "pulse-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
      key: "ai_models",
      url: "/ai/models",
      icon: "hardware-chip-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
      key: "ai_knowledgebase",
      url: "/ai/training/knowledgebase",
      icon: "library-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "campaigns",
      url: "/campaigns",
      icon: "calendar-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "ai_tasks",
      url: "/ai/tasks",
      icon: "terminal-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
      key: "statistics_admin",
      url: "/statistics",
      icon: "stats-chart-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['marketing', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "newsletters",
      url: "/newsletters",
      icon: "mail-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['basic', 'pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "shop_products",
      url: "/shop/products",
      icon: "grid-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "ads",
      url: "/ads",
      icon: "cash-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['basic', 'pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "ai_url_lookup",
      url: "/ai/url-lookup",
      icon: "search-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['basic', 'pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Moderator', 'Vertrieb'],
      key: "account",
      url: "/account",
      icon: "person-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['basic', 'pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Moderator', 'Vertrieb'],
      key: "projects_admin",
      url: "/projects",
      icon: "briefcase-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "integrations",
      url: "/integrations",
      icon: "extension-puzzle-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['basic', 'pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
      key: "translations_admin",
      url: "/translations",
      icon: "language-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "shop_categories",
      url: "/shop/categories",
      icon: "list-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "orders",
      url: "/orders",
      icon: "card-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "apps",
      url: "/whitelabel-apps",
      icon: "phone-portrait-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Moderator', 'Vertrieb'],
      key: "creators_blog_admin",
      url: "/sources/blogs",
      icon: "folder-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "creators_shop_admin",
      url: "/sources/shops",
      icon: "storefront-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "integration_mail",
      url: "/mail/inbox",
      icon: "mail-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "integration_facebook",
      url: "/integrations/integration/facebook/connections",
      icon: "logo-facebook",
      hideIfLoggedOut: true,
      requiresAboExtension: ['enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "integration_instagram",
      url: "/integrations/integration/instagram/connections",
      icon: "logo-instagram",
      hideIfLoggedOut: true,
      requiresAboExtension: ['enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "integration_whatsapp",
      url: "/integrations/integration/whatsapp/connections",
      icon: "logo-whatsapp",
      hideIfLoggedOut: true,
      requiresAboExtension: ['enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "meta_comments",
      url: "/integrations/integration/meta/comments",
      icon: "chatbubble-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "meta_groups",
      url: "/integrations/integration/custom/meta/groups",
      icon: "people-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "mail_inbox",
      url: "/mail/inbox",
      icon: "file-tray-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "mail_crawlers",
      url: "/mail/crawlers",
      icon: "link-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
      key: "mail_blacklist",
      url: "/mail/blacklist",
      icon: "hand-left-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin', 'Moderator'],
      key: "teams",
      url: "/teams",
      icon: "people-circle-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin'],
      key: "customers",
      url: "/customers-admin",
      icon: "person-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
    {
      allowedUserTypes: ['Admin'],
      key: "user_groups_admin",
      url: "/user-groups",
      icon: "hand-right-outline",
      hideIfLoggedOut: true,
      requiresAboExtension: ['pro', 'enterprise'],
    },
  ];

  cacheKey: string = 'menu_app_pages';

  project: project;

  constructor(
    private cache: CacheService,
    private config: ConfigService,
    private events: EventsService,
    private menu: MenuController,
    private objects: ObjectsService,
    private tools: ToolsService,
    private translations: TranslationService,
    private userService: UserService,
  ) {
    this.initEvents();
  }

  applyObjectTypesToMenu(types: any[], appPages: any | null = null) {

    if (!types || !types.length) {
      return false;
    }

    appPages = appPages || {};
    appPages.main = appPages.main || [];

    const existingKeys: string[] = appPages.main.map((entry: any) => {
      return entry.key;
    });

    types.forEach((type: any) => {
      if (!!type.active) {

        const appPageByType: any = {
          active: true,
          description: `${type.value.description || ''}`,
          icon: type.value.icon || 'file-tray-outline',
          key: type.value.name,
          name: type.value.name,
          label: type.value.name,
          uid: `object_type_${type.uid}`,
          url: `objects/types/${type.uid}`,
        };

        if (existingKeys.indexOf(appPageByType.uid) === -1) {
          appPages.main.push(appPageByType);
        }
      }
    });

    appPages.main = this.tools.unique(appPages.main, ['key']);

    return appPages;
  }

  close(blForce: boolean = false) {
    if (blForce || (window.innerWidth <= 768)) {
      this.menu.close();
    }
  }

  enable() {
    this.events.publish('menu:show');

    if (window.innerWidth > 768) {
      //this.open();
    }
  }

  async getAppPages(key: string, blAppendObjectTypes: boolean = false, blLoadCustom: boolean = true, blForceRefresh: boolean = false) {

    if (!this.appPages || (!!blAppendObjectTypes || !!blLoadCustom || !!blForceRefresh)) {
      try {
        this.appPages = await this.initAppPages(blAppendObjectTypes, blLoadCustom, blForceRefresh);
      } catch (e) {
        console.warn('loading appPages failed', e);
      }
    }

    return (!!this.appPages && !!this.appPages[key] ? this.appPages[key] : []);
  }

  getAppPagesKeys() {
    return Object.keys(this.appPages);
  }

  getAvailableAppPages(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      let availableAppPages: appPage[] = (this.availableAppPages || []);

      const keys: string[] = availableAppPages.map((appPage: appPage) => {
        return `${(appPage.label || (appPage.key || appPage.uid)) || ''}`;
      });

      // get appPage label translations
      this.translations.get(keys).subscribe((translations: any) => {

        // apply label translations
        availableAppPages = availableAppPages.map((appPage: appPage) => {
          if (!!appPage.label || !!appPage.key || !!appPage.uid) {
            const key: string = (appPage.label || (appPage.key || appPage.uid)) || '';
            appPage.label = (translations[key] || key);
          }
          return appPage;
        });

        // filter by user type
        availableAppPages = availableAppPages.filter((appPage: appPage) => {

          if (!appPage.hasOwnProperty('allowedUserTypes')) {
            return true;
          }

          return this.userService.isType(appPage.allowedUserTypes);
        });

        // sort by translated labels
        availableAppPages = availableAppPages.sort((a: appPage, b: appPage) => {
          const _a: string = `${a.label || ''}`.toLowerCase();
          const _b: string = `${b.label || ''}`.toLowerCase();
          if (_a < _b) return -1;
          if (_a > _b) return 1;
          return 0;
        });

        resolve(availableAppPages);
      });
    });
  }

  async getCacheKey() {
    return `${this.cacheKey}_${!!this.project && this.project.uid ? this.project.uid : 0}`;
  }

  async getCustomPages(blForceRefresh: boolean = false) {
    const cacheKey: string = await this.getCacheKey();
    const fromCache: cacheItem | null = await this.cache.get(cacheKey, -1);
    return (!!fromCache && !!fromCache.data ? fromCache.data : []);
  }

  async getParentMenuName(menuName: string) {

    if (!this.appPages || !this.appPages.length) {
      return false;
    }

    let parentMenuName: string | null = null;
    const appPageMainNames: string[] = Object.keys(this.appPages);

    if (appPageMainNames && appPageMainNames.length) {
      appPageMainNames.forEach((appPageMainName: string) => {
        const appPagesByName: appPage[] = this.appPages[appPageMainName];

        if (appPagesByName && appPagesByName.length) {
          appPagesByName.forEach((appPage: appPage) => {
            if (appPage.key === menuName && (appPageMainName !== appPage.key)) {
              parentMenuName = appPageMainName;
            }
          });
        }
      });
    }

    return parentMenuName;
  }

  hide() {
    this.events.publish('menu:hide');
  }

  initAppPages(blAppendObjectTypes: boolean = false, blLoadCustom: boolean = true, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const customPages: any = (!!blLoadCustom ? await this.getCustomPages(blForceRefresh) : []);
        const customPagesKeys: string[] = Object.keys(customPages);

        this.appPages = null;
        let appPages = (!!customPagesKeys && !!customPagesKeys.length ? customPages : this.config.getConfigParam('appPages')) || [];

        if (!!blAppendObjectTypes) {
          try {
            const objectTypes: any = await this.objects.getTypes({}, blForceRefresh);

            if (!!objectTypes && !!objectTypes.length) {
              appPages = await this.applyObjectTypesToMenu(objectTypes, appPages);
            }
          } catch (e: any) {
            console.warn('initAppPages: loading objectTypes failed', e);
          }
        }

        resolve(appPages);
      } catch (e) {
        reject(e);
      }
    });
  }

  initEvents() {

    this.events.subscribe('objects:type:deleted', async (type: any) => {
      this.onObjectTypeDeleted(type);
    });

    this.events.subscribe('project:current:updated', (project: project) => {
      this.project = project;
    });

    this.events.subscribe('appcms:user:loggedout', () => {
      this.close(true);
    });
  }

  isOpen() {
    return this.menu.isOpen();
  }

  async onObjectTypeDeleted(type: any) {
    console.log('menu-service: onObjectTypeDeleted: type', type);

    const update: any = await this.initAppPages(true, true, true);
    console.log('menu-service: onObjectTypeDeleted: update', update);
  }

  open() {
    return this.menu.open();
  }

  async setCustomPages(pages: appPage[], key: string) {
    this.appPages[key] = pages;

    const cacheKey: string = await this.getCacheKey();
    return this.cache.set(cacheKey, this.appPages);
  }

  toggle() {
    return this.menu.toggle();
  }

}