import { AfterContentInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { DaniService } from 'src/app/services/getgenius/dani.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-ai-tts',
  standalone: false,
  templateUrl: './ai-tts.page.html',
  styleUrls: ['./ai-tts.page.scss'],
})
export class AiTtsPage implements AfterContentInit, OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  aiSettings: aiSettings = {};

  appConfig: pipelineAppConfig;

  fallbackAvatarImg: string = './assets/img/avatars/1.webp';
  fallbackImg: string = './assets/img/fallback.webp';

  search: searchOptions = {
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  splineOptions: splineOptions = {
    url: './assets/spline/dani/dani_working.splinecode',
  };

  state: state = {};

  stateKeys: string[] = ['failed', 'waiting', 'validating', 'starting', 'done'];

  view: any = {
    buttonAction: 'generate',
    canGenerate: false,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    inputModes: [
      {
        icon: 'text-outline',
        uid: 'text',
        name: 'input_mode_text',
      },
      {
        icon: 'images-outline',
        uid: 'media',
        name: 'input_mode_media',
      }
    ],
    introCard: {
      uid: 'ai_tts_top_card',
      lottieSrc: './assets/lottie/light_bulb.json',
      text: 'ai_tts_top_card_text',
      title: 'ai_tts_top_card_title',
    },
    progress: 0,
    route: 'ai/items',
    sections: [],
    showBackButton: true,
    showMenuButton: false,
    showProjectsSelect: true,
    showSplineView: false,
    showViewModeSelect: true,
    title: 'ai_tts',
  };

  constructor(
    private configService: ConfigService,
    private dani: DaniService,
    private events: EventsService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  aiCreate() {

    if (!this.view.aiCreateInput || !this.view.aiCreateInput.length) {
      return false;
    }

    this.view.finalResultUrl = null;
    this.view.generating = true;
    this.view.loading = true;
    this.view.progress = 0;
    this.view.showSplineView = true;

    setTimeout(() => {
      this.startManually();
    }, 3500);

    this.dani.executeCreateTextToSpeech({
      input: this.view.aiCreateInput,
    })
      .then(async (response: any) => {
        this.startManually();

        this.view.generating = false;
        this.view.loading = false;
        this.view.showSplineView = false;
        this.view.introCard.hidden = true;

        if (!!response && !!response.data) {
          this.view.sections = (response.data || []).map((section: any) => {
            section.mode = section.mode || 'text';
            return section;
          });
        }
      })
      .catch((error: any) => {
        this.view.generating = false;
        this.view.loading = false;
        this.view.showSplineView = false;

        this.events.publish('error', error);
      });
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  calcColSize() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  calcInputModeStates() {
    this.view.selectedInputModesList = {};

    this.view.selectedInputModes = (this.view.inputModes || []).filter((mode: any) => {
      this.view.selectedInputModesList[mode.uid] = !!mode.checked;

      return !!mode.checked;
    });

    this.view.hasSelectedInputModes = !!this.view.selectedInputModes.length;
  }

  calcViewStates() {
    this.view.canGenerate = true;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.calcColSize();
  }

  dismiss(data: any | null = null) {
    this.modalService.dismiss(data);
  }

  importToLibrary() {

  }

  ionViewWillEnter() {
  }

  ionViewWillLeave() {
  }

  loadTts(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.loading = true;
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  mediaItemActionsToolbarButtonClicked(event: any | null = null) {
    switch (event.uid) {
      case 'addResponseImagesToHistory':
        //this.addResponseImagesToHistory(event.images || []);
        break;
      case 'addResponseQueueItemToHistory':
        //this.addResponseQueueItemToHistory(event.item || {});
        break;
      case 'inpaint':
        //this.inpaint();
        break;
      case 'useAsPrimary':
        //this.useAsPrimary(event.item, event.index || 0);
        break;
    }
  }

  ngAfterContentInit() {
  }

  ngOnInit() {
    this.calcViewVars();
    this.loadProject();
  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
    this.calcColSize();
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

  }

  runAiPrompt(event: any = null) {

  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  startManually() {
    this.view.canGenerate = true;
    this.view.startManually = true;
  }

  thumbnailLoadingFailed(item: any, key: string = 'photo') {
    item[key] = this.fallbackImg;
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}