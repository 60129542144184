import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class GetgeniusService {

  appConfig: pipelineAppConfig;

  cacheKey: string = 'getgenius_wallet';

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private configService: ConfigService,
    private userService: UserService,
  ) {
    this.appConfig = this.configService.getConfig();

    this.initEvents();
  }

  createWallet(wallet: any = {}) {
    return this.AppCMS.loadPluginData('getgenius', {
      wallet: wallet,
    }, ['wallet', 'create']);
  }

  async getCurrentWallet(blForceRefresh: boolean = false) {
    const fromCache: cacheItem|null = await this.cache.get(this.cacheKey, -1);

    return (!!fromCache && !!fromCache.data ? fromCache.data : null);
  }

  getWalletInfo(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData('getgenius', options, ['wallet'], {}, blForceRefresh);
  }

  getWallets(blForceRefresh: boolean = true) {
    return this.AppCMS.loadPluginData('getgenius', {}, ['wallet', 'list'], null, blForceRefresh);
  }

  async init() {
    try {

      if (!this.userService.getUid()) {
        return false;
      }

      const wallet: any = await this.getCurrentWallet();

      if (!wallet || !wallet.uid) {
        await this.setupWallet();
      }
    } catch (e) {
      console.warn('initializing wallet failed', e);
    }
  }

  initEvents() {

  }

  interruptIfWalletBalanceIsInsufficient() {
    return new Promise((resolve, reject) => {
      console.log('interruptIfWalletBalanceIsInsufficient');
    });
  }

  registerAction(action: any) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('getgenius', {
        action: action,
      }, ['wallet', 'actions', 'register'])
        .then(resolve)
        .catch((error: any) => {
          console.warn('wallet: registering action failed', error);

          switch (error) {
            case 'Error_wallet_balance_insufficient':
              this.interruptIfWalletBalanceIsInsufficient().then(resolve).catch(reject);
            default:
              reject(error);
              break;
          }

        });
    });
  }

  setCurrentWallet(wallet: wallet) {
    return this.cache.set(this.cacheKey, wallet);
  }

  setupWallet() {
    return new Promise((resolve, reject) => {
      try {
        this.getWalletInfo()
          .then((response: any) => {
            if (!!response && !!response.wallets && !!response.wallets.length) {
              this.setCurrentWallet(response.wallets[0]);
            } else {
              this.createWallet()
                .then((response: any) => {
                  if (!!response && !!response.wallet && !!response.wallet.uid) {
                    this.setCurrentWallet(response.wallet);
                  }

                  resolve(response);
                })
                .catch((e: any) => {
                  console.warn('initializing wallet failed (3)', e);
                  reject(e);
                });
            }
          })
          .catch((e: any) => {
            console.warn('initializing wallet failed (2)', e);
            reject(e);
          });
      } catch (e) {
        console.warn('initializing wallet failed (1)', e);
        reject(e);
      }
    });
  }

  shouldInterruptIfWalletBalanceIsInsufficient() {
    return !!this.appConfig.interruptIfWalletBalanceIsInsufficient;
  }

  unsetWallet() {
    return this.cache.set(this.cacheKey, null);
  }

}