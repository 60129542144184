import { Injectable } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from "src/app/services/core/events.service";
import { SettingsService } from "src/app/services/core/settings.service";

@Injectable({
    providedIn: 'root'
})
export class WindowManagerConfigService {

    appConfig: pipelineAppConfig;

    blUseWindowManager: boolean | null;

    constructor(
        private configService: ConfigService,
        private events: EventsService,
        private settings: SettingsService,
    ) {
        this.appConfig = this.configService.getConfig();

        this.initEvents();
    }


    initEvents() {
        this.events.subscribe('window:manager:state:updated', (bl: boolean) => {
            if (!!this.appConfig.useWindowManagerExtension) {
                this.blUseWindowManager = !!bl;
            }
        });
    }

    async useWindowManager() {

        // init variable if not set
        if ((this.blUseWindowManager === null || this.blUseWindowManager === undefined) && !!this.appConfig.useWindowManagerExtension) {
            try {

                // load user settings
                const userSettings: userSettings = await this.settings.getSettings();

                this.blUseWindowManager = !!userSettings && !!userSettings.useWindowManager;
            } catch (e) {
                console.warn('[ WINDOW MANAGER ] useWindowManager: failed', e);
            }
        }

        return !!this.blUseWindowManager;
    }

}