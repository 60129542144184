<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Save -->
      <ion-button *ngIf="!!view.startManually" shape="round" [fill]="view.isSaveMode ? 'solid' : 'clear'"
        [color]="view.isSaveMode ? 'primary' : 'dark'" (click)="save()" [disabled]="!view.canSave">
        <ion-icon [name]="view.isSaveMode ? 'checkmark-outline' : 'save-outline'" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate" [hidden]="!view.isSaveMode && !view.isDesktop"></ion-label>
      </ion-button>

      <!-- Rebuild button -->
      <ion-button *ngIf="!!view.startManually && !view.isSaveMode && view.buttonAction === 'rebuild'"
        [hidden]="!!view.showSplineView" (click)="rebuildAll()" [disabled]="!!view.loading || !view.canGenerate"
        shape="round" color="primary" fill="solid">
        <ion-icon name="sync-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'rebuild'|translate"></ion-label>
      </ion-button>

      <!-- Main generate button -->
      <ion-button *ngIf="!!view.startManually && !view.isSaveMode && view.buttonAction === 'generate'"
        [hidden]="!!view.showSplineView" (click)="generate()" [disabled]="!!view.loading || !view.canGenerate"
        shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'generate'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Progress bar -->
  <ion-toolbar class="ion-no-border container second-toolbar progress-toolbar"
    *ngIf="!!view.progress && !view.isSaveMode">
    <pipeline-progress-bar [progress]="view.progress" [view]="view"></pipeline-progress-bar>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Create code intro mode -->
  <pipeline-intro-overlay *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)"
    [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions"
    [config]="introOverlayConfig" [hidden]="!!view.startManually" (onContinue)="aiCreate()" (onSkip)="startManually()"
    [(view)]="view"></pipeline-intro-overlay>

  <!-- Mobile intro card -->
  <div class="container" [hidden]="introCard.hidden" *ngIf="!view.isDesktop">
    <pipeline-intro-card class="introCard" [(view)]="introCard"></pipeline-intro-card>
  </div>

  <!-- Select project card -->
  <div class="container" [hidden]="!!view.isSaveMode"
    *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <!-- Main container -->
  <div class="container main-container"
    *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <div class="main-view">

      <ion-grid class="main-grid">

        <ion-row>

          <!-- Sidebar -->
          <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" [hidden]="!view.expertMode">

            <!-- General -->
            <div class="col-inner">

              <ion-card class="sidebar-accordion-card">

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('general')" [innerHTML]="'general'|translate"></ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('general')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.general && !!cards.general.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <ion-list lines="none" [hidden]="!!cards && !!cards.general && !cards.general.open">

                  <ion-item>
                    <ion-icon name="eye-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'name'|translate" position="stacked"></ion-label>
                    <ion-input type="text" name="name" rows="6" [(ngModel)]="aiCoderProject.name"
                      [placeholder]="'name'|translate"></ion-input>
                  </ion-item>

                  <!-- Use proxy -->
                  <ion-item lines="none" class="ion-text-wrap" [hidden]="!!view.isSaveMode">
                    <ion-checkbox [(ngModel)]="aiSettings.use_proxy" justify="start" labelPlacement="end"
                      (ionChange)="useProxyChanged()" [innerHTML]="'use_proxy'|translate"
                      class="ion-text-wrap"></ion-checkbox>
                  </ion-item>

                </ion-list>

              </ion-card>
            </div>

            <!-- Files -->
            <div class="col-inner" [hidden]="!!view.isSaveMode">
              <ion-card class="sidebar-accordion-card files-card">

                <!-- Files header -->
                <ion-card-header>

                  <ion-card-title (click)="toggleCard('files')" [innerHTML]="'files'|translate"></ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('files')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.files && cards.files.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <!-- Loading files -->
                <ion-spinner [hidden]="!view.loadingFiles"></ion-spinner>

                <!-- No files -->
                <pipeline-no-entries-card
                  *ngIf="(!aiCoderProject.files || (aiCoderProject.files && !aiCoderProject.files.length)) && !view.loadingFiles"></pipeline-no-entries-card>

                <!-- Files list -->
                <ion-list lines="none" [hidden]="!!view.loadingFiles || (!cards || !cards.files.open)"
                  *ngIf="!!aiCoderProject.files">

                  <div *ngFor="let file of aiCoderProject.files">

                    <!-- Main item -->
                    <ion-item button class="ion-text-wrap level-1" (click)="onProjectFileClicked(file, $event)">
                      <ion-icon [name]="file.icon || 'rocket-outline'" slot="start"></ion-icon>
                      <ion-label [innerHTML]="file.label" class="ion-text-wrap"></ion-label>
                      <ion-icon *ngIf="!!file.children" [name]="file.checked ? 'chevron-up' : 'chevron-down'"
                        slot="end"></ion-icon>
                    </ion-item>

                    <!-- Children (if item type = folder) -->
                    <div *ngIf="!!file.children" [hidden]="!file.checked">

                      <div *ngFor="let child of file.children">

                        <!-- Main child -->
                        <ion-item button class="ion-text-wrap level-2" (click)="onProjectFileClicked(child, $event)">
                          <ion-icon [name]="child.icon || 'document-outline'" slot="start"></ion-icon>
                          <ion-label [innerHTML]="child.label" class="ion-text-wrap"></ion-label>
                          <ion-icon *ngIf="!!child.children" [name]="child.checked ? 'chevron-up' : 'chevron-down'"
                            slot="end"></ion-icon>
                        </ion-item>

                        <!-- Sub-Children (if item type = folder) -->
                        <div *ngIf="!!child.children" [hidden]="!child.checked">

                          <div *ngFor="let sub of child.children">

                            <!-- Main child -->
                            <ion-item button class="ion-text-wrap level-3" (click)="onProjectFileClicked(sub, $event)">
                              <ion-icon [name]="sub.icon || 'document-outline'" slot="start"></ion-icon>
                              <ion-label [innerHTML]="sub.label" class="ion-text-wrap"></ion-label>
                              <ion-icon *ngIf="!!sub.children" [name]="sub.checked ? 'chevron-up' : 'chevron-down'"
                                slot="end"></ion-icon>
                            </ion-item>

                          </div>

                        </div>
                      </div>

                    </div>

                  </div>

                </ion-list>

              </ion-card>
            </div>

            <!-- AI Settings -->
            <div class="col-inner" [hidden]="!!view.isSaveMode">
              <pipeline-ai-settings-card [(cards)]="cards" [(config)]="aiSettings" [options]="aiSettingsOptions"
                [(view)]="view"></pipeline-ai-settings-card>
            </div>

          </ion-col>

          <!-- Content -->
          <ion-col class="content" [size]="view.isDesktop && !!view.expertMode ? 9 : 12">

            <!-- Desktop intro card -->
            <pipeline-intro-card class="introCard" [hidden]="!!view.isSaveMode || introCard.hidden"
              *ngIf="!!view.isDesktop" [(view)]="introCard"></pipeline-intro-card>

            <!-- DANI -->
            <pipeline-spline-viewer [hidden]="!!view.isSaveMode || !!view.finalResultUrl"
              *ngIf="!!splineOptions && !!view.showSplineView" [options]="splineOptions"></pipeline-spline-viewer>

            <!-- Code & Preview -->
            <ion-grid class="code-preview-grid" [hidden]="!!view.isSaveMode || !!view.showSplineView">
              <ion-row>

                <!-- Code -->
                <ion-col [hidden]="view.fullscreen && view.fullscreenCard === 'preview'"
                  [size]="view.isDesktop && !!view.code_preview ? (!!view.fullscreen && view.fullscreenCard === 'code' ? 12 : 6) : 12">

                  <ion-card class="sidebar-accordion-card" *ngIf="!!view.code || !!view.startManually">

                    <ion-card-header [hidden]="!!view.isDesktop">

                      <ion-card-title (click)="toggleCard('code')" [innerHTML]="'code'|translate"></ion-card-title>

                      <ion-button class="card-toggle" (click)="toggleCard('code')" icon-only color="dark" fill="clear">
                        <ion-icon
                          [name]="!!cards && !!cards.code && !!cards.code.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                      </ion-button>

                    </ion-card-header>

                    <!-- Code editor -->
                    <pipeline-editor *ngIf="!view.rebuild && !view.loading"
                      [hidden]="!!cards && !!cards.code && !cards.code.open" [input]="view.code"
                      (inputChange)="onEditorInputChanged($event)" [language]="view.codeLanguage || 'typescript'"
                      [mode]="view.editorMode"></pipeline-editor>

                    <!-- Code bottom actions -->
                    <ion-item lines="none" *ngIf="!!view.code" [hidden]="!!cards && !!cards.code && !cards.code.open">

                      <!-- Copy output to clipboard -->
                      <ion-button slot="end" fill="clear" color="dark" *ngIf="!!view.code"
                        (click)="copyText(view.code)">
                        <ion-icon name="clipboard-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'copy_to_clipboard'|translate" [hidden]="!view.isDesktop"></ion-label>
                      </ion-button>

                      <!-- Rebuild file -->
                      <ion-button slot="end" fill="clear" color="dark" icon-only (click)="rebuildFile()">
                        <ion-icon name="sync-outline"></ion-icon>
                      </ion-button>

                      <!-- Toggle fullscreen view -->
                      <ion-button slot="end" fill="clear" [color]="!!view.fullscreen ? 'primary' : 'dark'" icon-only
                        (click)="toggleFullscreen('code')">
                        <ion-icon [name]="!!view.fullscreen ? 'expand' : 'expand-outline'"></ion-icon>
                      </ion-button>

                    </ion-item>

                  </ion-card>

                </ion-col>

                <!-- Preview -->
                <ion-col [hidden]="view.fullscreen && view.fullscreenCard === 'code'"
                  [size]="view.isDesktop ? (!!view.fullscreen && view.fullscreenCard === 'preview' ? 12 : 6) : 12"
                  *ngIf="!!view.code_preview">

                  <ion-card class="sidebar-accordion-card code-preview-browser">

                    <ion-card-header [hidden]="!!view.isDesktop">

                      <ion-card-title (click)="toggleCard('preview')"
                        [innerHTML]="'preview'|translate"></ion-card-title>

                      <ion-button class="card-toggle" (click)="toggleCard('preview')" icon-only color="dark"
                        fill="clear">
                        <ion-icon
                          [name]="!!cards && !!cards.preview && !!cards.preview.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                      </ion-button>

                    </ion-card-header>

                    <!-- Code execution / preview / simulation -->
                    <iframe class="code-preview"
                      [hidden]="!view.isDesktop && (!!cards && !!cards.preview && !cards.preview.open)"
                      [srcdoc]="view.code_preview"></iframe>

                    <!-- Preview bottom actions -->
                    <ion-item lines="none"
                      [hidden]="!view.isDesktop && (!!cards && !!cards.preview && !cards.preview.open)">

                      <!-- Copy output to clipboard -->
                      <ion-button slot="end" fill="clear" color="dark" *ngIf="!!view.simulate_output"
                        (click)="copyText(view.simulate_output)">
                        <ion-icon name="clipboard-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'copy_to_clipboard'|translate" [hidden]="!view.isDesktop"></ion-label>
                      </ion-button>

                      <!-- Reload -->
                      <ion-button slot="end" fill="clear" color="dark" icon-only (click)="refreshView()">
                        <ion-icon name="sync-outline"></ion-icon>
                      </ion-button>

                      <!-- Toggle fullscreen view -->
                      <ion-button slot="end" fill="clear" [color]="!!view.fullscreen ? 'primary' : 'dark'" icon-only
                        (click)="toggleFullscreen('preview')">
                        <ion-icon [name]="!!view.fullscreen ? 'expand' : 'expand-outline'"></ion-icon>
                      </ion-button>

                    </ion-item>
                  </ion-card>

                </ion-col>

              </ion-row>
            </ion-grid>

          </ion-col>

        </ion-row>

      </ion-grid>

    </div>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!view.isSaveMode && !!view.startManually">
  <ion-toolbar class="container ion-no-border">

    <!-- Run AI Prompt -->
    <pipeline-ai-prompt-card type="code" [disabled]="!!view.loading" [(input)]="view.aiCreateInput"
      (onSubmit)="runAiPrompt($event)"></pipeline-ai-prompt-card>

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>