<!-- Popover for Start date picker -->
<ion-popover #datePickerModal [keepContentsMounted]="true">
  <ng-template>
    <ion-datetime [(ngModel)]="view.selected_date" id="datePicker" #datePicker (ionChange)="dateChanged($event)"
      (ionInput)="dateChanged($event)"></ion-datetime>
  </ng-template>
</ion-popover>

<div class="tool-intro-wrapper" *ngIf="!view.startManually">
  <div class="container">

    <ion-card>

      <!-- Header -->
      <ion-card-header *ngIf="!!config.headline || !!config.description">
        <ion-card-title *ngIf="!!config.headline" [innerHTML]="config.headline|translate"></ion-card-title>
        <p *ngIf="!!config.description" [innerHTML]="config.description|translate"></p>
      </ion-card-header>

      <!-- Iterate groups -->
      <div class="swiper-stage" *ngIf="!!config && !!config.groups && !!config.groups.length" [hidden]="!!view.loading">

        <!-- Swiper container -->
        <swiper-container #introOverlaySwiper id="introOverlaySwiper" [options]="sliderOptions" [pagination]="true"
          class="step-{{ view.step }}" (slidechangetransitionend)="slidechangetransitionend($event)"
          (slidechangetransitionstart)="slidechangetransitionstart($event)">

          <!-- Single group -->
          <swiper-slide *ngFor="let group of config.groups; let iGroup = index;">

            <!-- Single group header -->
            <ion-card-header *ngIf="!!group.headline || !!group.description">
              <ion-card-title *ngIf="!!group.headline" [innerHTML]="group.headline|translate"></ion-card-title>
              <p *ngIf="!!group.description" [innerHTML]="group.description|translate"></p>
            </ion-card-header>

            <!-- Full loading (if ai generate is disabled) -->
            <ion-spinner [hidden]="!group.loading || (activeIndex !== iGroup)"></ion-spinner>

            <!-- Buttons -->
            <div class="buttons-wrapper" [hidden]="!!group.loading || (activeIndex !== iGroup)"
              *ngIf="!!group && !!group.buttons && !!group.buttons.length">

              <ion-grid class="types-grid">
                <ion-row>

                  <ion-col size="6" *ngFor="let button of group.buttons; let iButton = index; trackBy:trackItems">

                    <ion-button class="types-btn" [class.active]="button.checked" size="block"
                      [disabled]="!!group.loading || !!button.loading || !!view.loading || !!view.generating"
                      [fill]="button.checked ? 'solid' : 'outline'" color="primary"
                      (click)="toggleButton(button, iButton, iGroup)">
                      <ion-icon [name]="button.icon"></ion-icon>
                      <ion-label [innerHTML]="button.name|translate"></ion-label>
                    </ion-button>

                  </ion-col>

                </ion-row>
              </ion-grid>

            </div>

            <!-- Components -->
            <div class="components-wrapper" [hidden]="!!group.loading || (activeIndex !== iGroup)"
              *ngIf="!!group && !!group.components && !!group.components.length">

              <!-- Iterate components -->
              <div *ngFor="let componentName of group.components; let iComponent = index;">

                <!-- Media Picker -->        
                <pipeline-media-picker-card *ngIf="componentName === 'MediaPicker'"
                  #mediaPickerComponent [(cards)]="cards" [(item)]="media"
                  [items]="mediaList" [options]="mediaPickerOptions" [view]="view"
                  (onMediaChanged)="_onMediaChanged($event)"></pipeline-media-picker-card>
                  
              </div>

            </div>

            <!-- Inputs -->
            <form class="inputs-wrapper" [hidden]="!!group.loading"
              *ngIf="!!group && !!group.inputs && !!group.inputs.length && (activeIndex === iGroup)">

              <!-- Single input -->
              <ion-card *ngFor="let input of group.inputs; let iInput = index; trackBy:trackItems">
                <ion-item lines="none">

                  <!-- Input icon -->
                  <ion-icon *ngIf="!!input.icon" [name]="input.icon" slot="start"></ion-icon>

                  <!-- Input Type: Checkbox -->
                  <ion-checkbox *ngIf="input.type === 'checkbox'" [(ngModel)]="input.value"
                    [disabled]="input.loading || !!input.disabled" [name]="'checkbox' + (input.uid || iInput)"
                    [placeholder]="input.placeholder|translate"></ion-checkbox>

                  <!-- Input Type: Date -->
                  <ion-label *ngIf="input.type === 'date'"
                    [innerHTML]="(input.label || input.name)|translate"></ion-label>

                  <ion-datetime-button *ngIf="input.type === 'date'" datetime="datePicker" [disabled]="input.disabled"
                    slot="end"></ion-datetime-button>

                  <!-- Input Type: Email, Number, Password, Text, URL -->
                  <ion-input [name]="'input' + (input.uid || iInput)"
                    *ngIf="input.type === 'email' || input.type === 'number' || input.type === 'password' || input.type === 'text' || input.type === 'url'"
                    [type]="input.type" [(ngModel)]="input.value" [disabled]="input.loading || !!input.disabled"
                    [label]="(input.label || input.name)|translate" labelPlacement="stacked"
                    [placeholder]="(input.placeholder || input.label)|translate">
                  </ion-input>

                  <!-- Input Type: Select -->
                  <ion-select *ngIf="input.type === 'select' && !!input.values" [(ngModel)]="input.value"
                    justify="space-between" alignment="end" labelPlacement="start" [okText]="'okay'|translate"
                    [cancelText]="'cancel'|translate" interface="popover" [multiple]="!!input.multiple"
                    [name]="'select' + (input.uid || iInput)"
                    [disabled]="input.loading || !!input.disabled || (!input.values || !input.values.length)"
                    [label]="input.label|translate" [placeholder]="(input.placeholder || 'select')|translate">
                    <ion-select-option *ngFor="let option of input.values" [innerHTML]="option.name|translate"
                      [value]="option.uid"></ion-select-option>
                  </ion-select>

                  <!-- Input Type: Textarea -->
                  <ion-textarea *ngIf="input.type === 'textarea'" [name]="'textarea' + (input.uid || iInput)"
                    [rows]="input.rows || 5" [(ngModel)]="input.value" [disabled]="input.loading || !!input.disabled"
                    [placeholder]="input.placeholder|translate"></ion-textarea>

                </ion-item>
              </ion-card>

            </form>

          </swiper-slide>

        </swiper-container>

        <!-- Swiper buttons -->
        <ion-grid [hidden]="!!view.loading || !config.showSliderButtons">
          <ion-row>

            <ion-col size="6">

              <!-- Slide back -->
              <ion-button [disabled]="!view.canSlideBack" color="dark" expand="block" fill="clear"
                (click)="slideBack()">
                <ion-icon name="arrow-back-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'back'|translate"></ion-label>
              </ion-button>

            </ion-col>

            <ion-col size="6">

              <!-- Slide next -->
              <ion-button [hidden]="!!view.canSlideDone" shape="round" [disabled]="!view.canSlideNext" color="primary"
                expand="block" (click)="slideNext()">
                <ion-label [innerHTML]="'next'|translate"></ion-label>
                <ion-icon name="arrow-forward" slot="end"></ion-icon>
              </ion-button>

              <!-- Done -->
              <!--
              <ion-button [hidden]="!view.canSlideDone" shape="round" color="primary" expand="block"
                (click)="slideDone()">
                <ion-label [innerHTML]="'next'|translate"></ion-label>
                <ion-icon name="arrow-forward" slot="end"></ion-icon>
              </ion-button>
              -->

            </ion-col>

          </ion-row>
        </ion-grid>
      </div>

      <!-- Input textarea -->
      <ion-textarea *ngIf="!!config.showInput"
        [rows]="config.input_type === 'email' || config.input_type === 'url' ? 1 : 6" [(ngModel)]="view.aiCreateInput"
        [disabled]="view.loading" [placeholder]="config.input_placeholder|translate"></ion-textarea>

      <!-- Full loading (if ai generate is disabled) -->
      <ion-spinner *ngIf="!config.showAiCreate" [hidden]="!view.loading"></ion-spinner>

      <!-- Sources -->
      <ion-list [hidden]="!!view.loading" *ngIf="!!config.showSources && (!!config.sources && !!config.sources.length)">

        <!-- Source -->
        <ion-item lines="none">

          <!-- Selected source icon -->
          <ion-icon name="funnel-outline" slot="start"></ion-icon>

          <ion-select [okText]="'okay'|translate" [cancelText]="'cancel'|translate" interface="popover"
            [label]="'source'|translate" [(ngModel)]="config.source" (ionChange)="_onSourceChanged($event)">
            <ion-select-option *ngFor="let source of config.sources; trackBy:trackItems" [value]="source"
              [innerHTML]="source.name|translate"></ion-select-option>
          </ion-select>

        </ion-item>

        <!-- Source Type: Object Type -->
        <ion-item lines="none"
          *ngIf="!!view.object_types && (!!config.source && (config.source.uid === 'object_type'))">

          <ion-icon name="server-outline" slot="start"></ion-icon>

          <!-- Object type picker -->
          <ion-select interface="popover" [label]="'object_type'|translate" [(ngModel)]="config.source_object_type">
            <ion-select-option *ngFor="let object_type of view.object_types; trackBy:trackItems"
              [value]="object_type.uid" [innerHTML]="object_type.name|translate"></ion-select-option>
          </ion-select>

        </ion-item>

        <!-- Source Type: Tool -->
        <ion-item lines="none" *ngIf="!!view.tools && (!!config.source && (config.source.uid === 'tool'))">

          <ion-icon name="sparkles-outline" slot="start"></ion-icon>

          <!-- Tool picker -->
          <ion-select interface="popover" [label]="'tool'|translate" [(ngModel)]="config.source_tool">
            <ion-select-option *ngFor="let tool of view.tools; trackBy:trackItems" [value]="tool.uid"
              [innerHTML]="tool.label|translate"></ion-select-option>
          </ion-select>

        </ion-item>

        <!-- Source Type: URL -->
        <ion-item lines="none" *ngIf="!!config.source && (config.source.uid === 'url')">
          <ion-icon name="link-outline" slot="start"></ion-icon>
          <ion-input type="url" [(ngModel)]="view.source_url" [placeholder]="'source_url'|translate"></ion-input>
        </ion-item>

      </ion-list>

      <!-- Integrations -->
      <ion-list *ngIf="!!config.showIntegrations && (!!view.integrations && !!view.integrations.length)"
        [hidden]="!!view.loading || ((!!config.sourceRequired || (!!config.sources && !!config.sources.length)) && (!config.source || (config.source.uid !== 'integration')))">
        <ion-item lines="none">

          <!-- Selected integration icon -->
          <ion-icon
            [name]="!!view.integration && !!view.integration.icon ? view.integration.icon : 'extension-puzzle-outline'"
            slot="start"></ion-icon>

          <ion-select [okText]="'okay'|translate" [cancelText]="'cancel'|translate" interface="popover"
            [label]="'integration'|translate" [(ngModel)]="view.integration"
            (ionChange)="_onIntegrationChanged($event)">
            <ion-select-option *ngFor="let integration of view.integrations; trackBy:trackItems" [value]="integration"
              [innerHTML]="integration.name|translate"></ion-select-option>
          </ion-select>

        </ion-item>

      </ion-list>

      <!-- Bottom actions -->
      <ion-grid>
        <ion-row>

          <!-- Generate -->
          <ion-col [size]="view.isDesktop ? 6 : 12"
            *ngIf="config.showAiCreate && (!config.groups || !config.groups.length || (activeIndex === config.groups.length))">

            <ion-spinner [hidden]="!view.loading"></ion-spinner>

            <ion-button size="block" (click)="aiCreate()" shape="round" [hidden]="view.loading"
              [disabled]="!view.aiCreateInput || !view.aiCreateInput.length && (!config.groups || !config.groups.length)">
              <ion-icon name="sparkles-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="(config.submitLabel || 'generate')|translate"></ion-label>
            </ion-button>

          </ion-col>

          <!-- AI settings -->
          <ion-col [size]="view.isDesktop ? 6 : 12" *ngIf="config.showAiSettings">

            <pipeline-ai-settings-picker [config]="aiSettings" size="block" fill="clear" [mini]="false" [view]="view"
              (changed)="aiSettingsChanged($event)"></pipeline-ai-settings-picker>

          </ion-col>

          <!-- Generate manually -->
          <ion-col
            [size]="view.isDesktop && (!config.showAiSettings || (!!config.showAiSettings && (!!config.groups && !!config.groups.length && (activeIndex !== config.groups.length)))) ? 6 : 12"
            *ngIf="config.allowManually">

            <ion-button class="start-manually-btn" (click)="startManually()" color="dark" size="block" fill="clear">
              <ion-icon name="create-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'manually'|translate"></ion-label>
            </ion-button>

          </ion-col>

        </ion-row>
      </ion-grid>

    </ion-card>

  </div>
</div>