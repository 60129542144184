<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Menu button -->
      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Update -->
      <ion-button (click)="update()" *ngIf="entry && !!entry.uid" shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

      <!-- Save -->
      <ion-button (click)="save()" *ngIf="!entry || !entry.uid" shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <ion-grid>

      <ion-row>

        <!-- Information -->
        <ion-col [size]="view.isDesktop ? 6 : 12">

          <pipeline-intro-card [hidden]="introCard.hidden" class="introCard" [(view)]="introCard"></pipeline-intro-card>

          <!-- Tool -->
          <ion-card *ngIf="!!tools && !!tools.length">
            <ion-item lines="none">

              <ion-icon name="extension-puzzle-outline" slot="start"></ion-icon>

              <ion-select multiple interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                [(ngModel)]="entry.tools" [label]="'tool'|translate">
                <ion-select-option *ngFor="let tool of tools" [innerHTML]="tool.label|translate"
                  [value]="tool.uid"></ion-select-option>
              </ion-select>

            </ion-item>
          </ion-card>

          <!-- Context -->
          <ion-card *ngIf="!!contexts && !!contexts.length"
            [hidden]="!entry.uid && (!entry.tools || !entry.tools.length)">
            <ion-item lines="none">

              <ion-icon name="funnel-outline" slot="start"></ion-icon>

              <ion-select multiple interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                [(ngModel)]="entry.contexts" [label]="'contexts'|translate">
                <ion-select-option *ngFor="let context of contexts" [innerHTML]="context.label|translate"
                  [value]="context.uid"></ion-select-option>
              </ion-select>

            </ion-item>
          </ion-card>

          <!-- Loading spinner -->
          <ion-spinner
            [hidden]="!view.loadingInput || (!entry.uid && ((!entry.contexts || !entry.contexts.length) || (!entry.tools || !entry.tools.length)))"></ion-spinner>

          <!-- Input -->
          <ion-card
            [hidden]="!!view.loadingInput || (!entry.uid && ((!entry.contexts || !entry.contexts.length) || (!entry.tools || !entry.tools.length)))">

            <ion-item>
              <ion-textarea rows="15" [(ngModel)]="entry.input"
                [placeholder]="'ai_knowledgebase_input_key_placeholder'|translate"></ion-textarea>
            </ion-item>

            <ion-item lines="none">

              <!-- Optimize input prompt -->
              <ion-button slot="end" (click)="optimizePrompt('input', $event)" fill="clear" icon-only color="dark"
                [disabled]="!!entry.loading || !!view.loading">
                <ion-icon name="sparkles-outline"></ion-icon>
              </ion-button>

            </ion-item>

          </ion-card>

          <!-- Output -->
          <ion-card
            [hidden]="!!view.loadingInput || (!entry.uid && ((!entry.contexts || !entry.contexts.length) || (!entry.tools || !entry.tools.length)))">

            <ion-item>
              <ion-textarea rows="15" [(ngModel)]="entry.output"
                [placeholder]="'ai_knowledgebase_output_value_placeholder'|translate"></ion-textarea>
            </ion-item>

            <ion-item lines="none">

              <!-- Optimize input prompt -->
              <ion-button slot="end" (click)="optimizePrompt('output', $event)" fill="clear" icon-only color="dark"
                [disabled]="!!entry.loading || !!view.loading">
                <ion-icon name="sparkles-outline"></ion-icon>
              </ion-button>

            </ion-item>

          </ion-card>

        </ion-col>

        <!-- Preview -->
        <ion-col [size]="view.isDesktop ? 6 : 12">

          <ion-card class="test-card">

            <ion-card-header>
              <ion-card-title [innerHTML]="'test_changes_effects'|translate"></ion-card-title>
            </ion-card-header>

            <getgenius-dani #daniComponent [(config)]="daniConfig" [state]="view.dani.state"></getgenius-dani>

          </ion-card>
        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>