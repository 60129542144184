import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { EventsService } from "src/app/services/core/events.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'pipeline-ai-style-picker',
  standalone: false,
  templateUrl: './ai-style-picker.component.html',
  styleUrls: ['./ai-style-picker.component.scss']
})
export class AiStylePickerCardComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() cards: any;
  @Input() disabled: boolean;
  @Input() view: any;

  styles: any = {};

  typeIcons: any = {
    media: 'images-outline',
    text: 'text-outline',
  };

  constructor(
    public ai: AiToolsService,
    private events: EventsService,
    private navCtrl: NavController,
    private projects: ProjectsService,
    private sidebar: SidebarService,
  ) {
    this.view = this.view || {};
    this.view.style = this.view.style || {};
  }

  create() {
    console.log('create', this.view);
  }

  configureStyle(type: any) {
    console.log('configure style', type);
  }

  doRefresh(blForceRefresh: boolean = true) {
    this.loadStyles(blForceRefresh)
      .catch((error: any) => {
        console.warn('loading styles failed', error);
      })
  }

  async edit(style: any) {
    console.log('edit', style);
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh(false);
    });
  }

  initViewVars() {
    this.view.aiStyleTypes = this.view.aiStyleTypes || ['media', 'text'];
    this.view.style = this.view.style || {};

    // set first style to current
    if (!!this.view.aiStyleTypes && !!this.view.aiStyleTypes.length) {
      this.view.aiStyleTypes.forEach((type: string) => {
        switch (type) {
          case 'media':
            if (!!this.styles && !!this.styles.media && !!this.styles.media[0]) {
              this.view.style[type] = this.styles.media[0];
            }
            break;
          case 'text':
            if (!!this.styles && !!this.styles.text && !!this.styles.text[0]) {
              this.view.style[type] = this.styles.text[0];
            }
            break;
        }
      });
    }
  }

  async loadStyles(blForceRefresh: boolean = false) {
    this.view.project = await this.projects.getCurrent();

    if (!this.view.project || !this.view.project.uid) {
      return false;
    }

    this.view.loadingStyles = true;

    return new Promise((resolve, reject) => {
      this.ai.getStyles(blForceRefresh)
        .then((styles: aiStyle[]) => {
          this.styles = styles;
          this.view.loadingStyles = false;

          resolve(styles);
        })
        .catch((error: any) => {
          this.view.loadingStyles = false;
          reject(error);
        });
    })
  }

  ngAfterViewInit() {
    this.initViewVars();

    this.loadStyles()
      .then(() => {

        // set first style to current
        if (!!this.styles && !!this.styles[0] && !!this.styles[0].uid) {
          this.view.style = this.styles[0];
        }

      })
      .catch((error: any) => {
        console.warn('loading styles failed', error);
      });
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.initEvents();
  }

  styleChanged(type: any) {
    console.log('styleChanged', type);
  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.ai_style = this.cards.ai_style || {};
    this.cards.ai_style.open = !this.cards.ai_style.open;

    this.sidebar.setCards(this.cards);
  }

  viewAll() {
    this.events.publish('menu:load:appPages', { key: 'styles_admin' });
    this.navCtrl.navigateForward('/ai/styles');
  }

}