import { Component, HostListener, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { ModalService } from 'src/app/services/core/modal.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-cookies',
  standalone: false,
  templateUrl: './cookies.page.html',
  styleUrls: ['./cookies.page.scss'],
})
export class CookiesPage implements OnInit {

  cookies: any;
  
  view: any = {};

  constructor(
    private modalService: ModalService,
    private navParams: NavParams,
    private viewService: ViewService,
  ) {
    this.cookies = this.navParams.get('cookiesService');
  }

  accept() {
    this.cookies.accept()
    .then(() => {
      this.dismiss(null, 'done');
    })
    .catch((error: any) => {
      console.error('error', error);
    });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  async dismiss(data: any|null = null, role: string|null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  more() {
    this.cookies.more();
  }

  ngOnInit() {
    this.calcViewVars();
  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
  }

}
