import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from 'src/app/services/core/events.service';
import { TranslationService } from "src/app/services/core/translation.service";

@Component({
  selector: 'pipeline-selection-toolbar',
  standalone: false,
  templateUrl: './selection-toolbar.component.html',
  styleUrls: ['./selection-toolbar.component.scss']
})
export class SelectionToolbarComponent implements AfterViewInit, OnInit {

  actionSheetButtons: any;

  cacheKey: string = 'selection_toolbar_pref';

  cachedPreferences: any = {};

  @Input() filters: any;

  isSelectionActionPopoverOpen: boolean = false;

  @Input() items: any[];
  @Input() options: selectionOption[];
  @Input() sizeFactor: number = 1;
  @Input() languageOptions: languagesSelectOptions;
  @Input() paginationConfig: paginationConfig = {};
  @Input() view: any;

  @Output() onLanguageChanged = new EventEmitter();
  @Output() onSelectionActionChanged = new EventEmitter();
  @Output() onViewTypeChanged = new EventEmitter();

  @ViewChild('selectionActionPopover') selectionActionPopover;

  constructor(
    private cache: CacheService,
    private events: EventsService,
    private translations: TranslationService,
  ) {

  }

  calcActionSheetButtons() {
    this.actionSheetButtons = [];

    if (!!this.options && !!this.options.length) {

      let keys: string[] = this.options.map((option: any) => {
        return option.label;
      }).concat(['cancel']);

      this.translations.get(keys)
        .subscribe((translations: any) => {

          this.options.forEach((option: any) => {

            let button: button = {
              text: `${translations[option.label] || option.label}`,
              data: {
                action: option.uid,
              },
            };

            if (option.uid === 'delete') {
              button.role = 'destructive';
            }

            this.actionSheetButtons.push(button);
          });

          // add cancel button
          this.actionSheetButtons.push({
            text: translations.cancel || 'Cancel',
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          });

        });
    }
  }

  calcViewVars() {

    if (!!this.items) {
      this.view.selectedItems = this.items.filter((item: any) => {
        return !!item.checked;
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = !!(!!this.view.selectedItems && !!this.view.selectedItems.length);
    this.view.isLargeScreen = (window.innerWidth > 1080);

    if (!this.hasOwnProperty('languageOptions')) {
      this.languageOptions = {
        allowMini: true,
        mode: 'view',
      };
    }

    if (!this.view.hasOwnProperty('viewTypes')) {
      this.view.viewTypes = [
        { icon: 'list', label: 'list', uid: 'list', expertMode: false },
        { icon: 'grid', label: 'grid', uid: 'grid', expertMode: false },
      ];
    }
  }

  dateChanged(event: any | null = null) {
  }

  doRefresh() {
    this.calcViewVars();
  }

  async getCacheKey() {
    return `${this.cacheKey}_${!!this.view && !!this.view.route ? this.view.route.replace('/', '_') : 'global'}`;
  }

  async getPreference() {
    try {

      /*
      if(!!this.view.viewType) {
        console.warn('view type already defined:', this.view);
        return false;
      }
      */

      const cacheKey: string = await this.getCacheKey();
      const fromCache: cacheItem = await this.cache.get(cacheKey, -1);

      if (!!fromCache && !!fromCache.data) {
        this.cachedPreferences = fromCache.data || {};
        this.view.viewType = `${this.cachedPreferences.viewType || (this.view.viewType || 'grid')}`;

        // apply cached filters if found
        const filterKeys: string[] = Object.keys(this.cachedPreferences.filters || {});

        if (!!this.filters && !!this.filters.length && !!filterKeys && !!filterKeys.length) {
          this.filters.forEach((filter: any, index: number) => {
            if (this.cachedPreferences.filters.hasOwnProperty(filter.uid)) {
              this.filters[index].value = this.cachedPreferences.filters[filter.uid];
            }
          });
        }

      }
    } catch (e) {
      console.warn('loading preferred view mode failed', e);
    }
  }

  initEvents() {

    this.events.subscribe('window:resized', () => {
      this.view.isLargeScreen = (window.innerWidth > 1080);
    });

  }

  ngAfterViewInit() {
    this.view.viewType = this.view.viewType || 'grid';
    this.getPreference();
  }

  ngOnInit() {
    this.initEvents();

    this.calcViewVars();
    this.calcActionSheetButtons();
  }

  onSelectionOptionsActionSheetDismiss(event: any) {
    if (!!event && !!event.detail) {
      console.log('selection is', event.detail);
    }
  }

  _onFilterChange(filter: any, event: any) {
    this.saveFilter(filter);

    if (!!filter.hasOwnProperty('handler')) {
      let exec: any = filter.handler(filter, event);
    }
  }

  _onFilterClick(filter: any, event: any) {
    if (!!filter.hasOwnProperty('handler')) {
      let exec: any = filter.handler(filter, event);
    }
  }

  _onLanguageChanged(event: string | null = null) {
    //console.log('selection-toolbar: _onLanguageChanged', event);
    this.view.language = event;
    this.onLanguageChanged.emit(this.view.language);
  }

  presentSelectionActionPicker(event: any | null = null) {
    this.selectionActionPopover.event = event;
    this.isSelectionActionPopoverOpen = true;
  }

  async runSelectionOption(option: any, event: any | null = null) {

    if (!option.uid) {
      return false;
    }

    this.onSelectionActionChanged.emit({
      event: event,
      items: this.view.selectedItems,
      option: option,
    });

    this.isSelectionActionPopoverOpen = false;

    try {
      if (!!event) {
        event.target.complete();
      }
    } catch (e) {

    }
  }

  async saveFilter(filter: any) {

    if (!filter || !filter.uid || !filter.hasOwnProperty('value')) {
      return false;
    }

    // update settings
    const cacheKey: string = await this.getCacheKey();

    this.cachedPreferences = this.cachedPreferences || {};
    this.cachedPreferences.filters = this.cachedPreferences.filters || {};
    this.cachedPreferences.filters[filter.uid] = filter.value;

    this.cache.set(cacheKey, this.cachedPreferences || {});
  }

  selectAll() {

    this.items.forEach((item: any) => {
      item.checked = !item.hidden && !this.view.allSelected;
    });

    this.view.allSelected = !this.view.allSelected;

    this.calcViewVars();

    this.events.publish('selection:changed', {
      items: this.view.selectedItems,
      last: (!!this.view.selectedItems ? this.view.selectedItems[0] : null),
    });
  }

  async setView(viewType: string) {

    // update view
    this.view.viewType = viewType;

    // update settings
    const cacheKey: string = await this.getCacheKey();

    this.cachedPreferences = this.cachedPreferences || {};
    this.cachedPreferences.viewType = viewType;

    this.cache.set(cacheKey, this.cachedPreferences || {});

    this.onViewTypeChanged.emit(this.view.viewType);
  }

  toggleFilters() {
    this.view.showFilters = !this.view.showFilters;

    // on mobile, set to expert mode to show folders & co
    if (!this.view.isDesktop && !this.view.expertMode && !!this.view.showFilters) {
      this.view.expertMode = true;
    }

  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }
  
}