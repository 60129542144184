import { Component, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from "src/app/services/core/modal.service";
import { PostsAdminService } from 'src/app/services/posts/posts-admin.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-rate-post',
  standalone: false,
  templateUrl: './rate-post.page.html',
  styleUrls: ['./rate-post.page.scss'],
})
export class RatePostPage implements OnInit {

  rating: any = {
    quality: 3,
    usefulness: 3,
    time: 3,
  };

  ratings: any;

  view: any = {};

  constructor(
    private events: EventsService,
    private modalService: ModalService,
    private postsAdmin: PostsAdminService,
    private viewService: ViewService,
  ) {
    this.rating.post = (this.postsAdmin.detailItem() || {} as any);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  async dismiss(data: any|null = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  submit() {
    this.ratings.submit(this.rating)
      .then(() => {

        this.events.publish('toast', {
          icon: 'checkmark-outline',
          message: 'feedback_success',
          color: 'primary',
        });

        this.dismiss(this.rating);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

}
