export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDUHIArTAHJCsmpY_G_04FWn18TKBuUMjI",
    authDomain: "thepipelinemagapp.firebaseapp.com",
    projectId: "thepipelinemagapp",
    storageBucket: "thepipelinemagapp.appspot.com",
    messagingSenderId: "199405241952",
    appId: "1:199405241952:web:b456d49e75184373b82505",
    measurementId: "G-9SLQ6FPEGZ"
  }
};