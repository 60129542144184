<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Options button -->
      <ion-button (click)="showHeaderPopover($event)" fill="clear" icon-only color="dark" [disabled]="!!view.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Refresh -->
      <ion-button (click)="doRefresh()" color="dark" fill="clear" [disabled]="!!view.loading">
        <ion-icon name="sync-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'refresh'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Selection -->
  <pipeline-selection-toolbar *ngIf="!view.rebuild && !!view.inbox && !!view.inbox.length"
    [sizeFactor]="view.isDesktop ? 4 : 1" [(items)]="view.inbox" [(view)]="view" [options]="mailSelectionOptions"
    [(paginationConfig)]="paginationConfig"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Selection options popover -->
  <pipeline-selection-options-picker (onSelectionActionChanged)="runItemSelectionOption($event)"
    [options]="selectionOptions" [(view)]="view"></pipeline-selection-options-picker>

  <!-- Header actions popover -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list>

        <!-- Import -->
        <ion-item button (click)="importEntries()" [disabled]="!!view.loading">
          <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'import'|translate"></ion-label>
        </ion-item>

        <!-- Export -->
        <ion-item lines="none" button (click)="export()" [disabled]="!!view.loading"
          *ngIf="!!view.inbox && !!view.inbox.length">
          <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'export'|translate"></ion-label>
        </ion-item>

      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Refresher -->
  <ion-refresher *ngIf="!view.dragging && (!view.dragula || !view.dragula.drake || !view.dragula.drake.dragging)"
    slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Mobile intro card -->
  <pipeline-intro-card class="introCard" [(view)]="introCard" *ngIf="!view.isDesktop"
    [hidden]="introCard.hidden"></pipeline-intro-card>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div [class.container]="!view.expertMode" [class.container-fluid]="!!view.expertMode"
    *ngIf="!!view.project && !!view.project.uid">

    <ion-grid class="mail-inbox-grid" gridId="MAIL_INBOX_GRID" [iMinSize]="2" [resizable]="!!view.isDesktop"
      (sizesChanges)="onSizesChange($event)">
      <ion-row>

        <!-- Main Sidebar -->
        <ion-col [size]="!!view.colSize && !!view.colSize.left ? view.colSize.left : 12" class="left"
          [class.resizable-col]="view.expertMode" [hidden]="!view.expertMode || (!view.isDesktop && !view.showFilters)">

          <!-- Folders -->
          <pipeline-folders-filter-card *ngIf="!!view.expertMode" [(cards)]="cards" [service]="mail" [(view)]="view"
            location="mail_inbox" (onItemsChanged)="onFolderLoaded($event)"
            (onChanged)="loadMailsByFolder($event)"></pipeline-folders-filter-card>

          <!-- Filters -->
          <pipeline-items-filters-card *ngIf="!!view.expertMode" [(cards)]="cards" [(view)]="view"
            (filtersChanged)="onFiltersChanged($event)"></pipeline-items-filters-card>

          <!-- Connections -->
          <pipeline-connections-filter-card *ngIf="!!view.expertMode" [(cards)]="cards" [(view)]="view"
            (onChanged)="onConnectionsFilterChanged($event)"></pipeline-connections-filter-card>

          <!-- Attributes -->
          <pipeline-attributes-filter-card *ngIf="!!view.expertMode" [(cards)]="cards" [(view)]="view"
            location="mail_inbox"
            (filtersChanged)="onAttributeFiltersChanged($event)"></pipeline-attributes-filter-card>

          <!-- Tags -->
          <pipeline-tags-filter-card *ngIf="!!view.expertMode" [(cards)]="cards" [items]="view.tags" [service]="mail"
            [(view)]="view" (selectionChanged)="onSelectedTagChanged($event)"></pipeline-tags-filter-card>

        </ion-col>

        <!-- Chats -->
        <ion-col [size]="!!view.colSize && !!view.colSize.left ? view.colSize.left : 12" class="left"
          [class.resizable-col]="view.expertMode" [hidden]="!view.isDesktop && !!view.showFilters">

          <!-- Loading spinner -->
          <ion-spinner [hidden]="!view.loading"></ion-spinner>

          <!-- No entries -->
          <pipeline-no-entries-card [cta]="cta" [hidden]="!!view.loading" [text]="'mail_admin_cta_text'|translate"
            *ngIf="view.inbox && !view.inbox.length">
          </pipeline-no-entries-card>

          <!-- Entries view -->
          <div class="cdk-wrapper" *ngIf="!view.rebuild && !!view.inbox" [hidden]="!!view.loading">
            <cdk-virtual-scroll-viewport [itemSize]="view.itemSize" [minBufferPx]="view.itemSize * 25"
              [maxBufferPx]="view.itemSize * 25" class="ion-content-scroll-host">

              <!-- Iterate inbox mails -->
              <div *cdkVirtualFor="let item of view.inbox; let _iInbox = index;" [attr.data-location]="'mail_inbox'"
                [attr.data-type]="'mail'" [attr.data-uid]="item.uid" [attr.data-model]="view.inbox"
                [hidden]="item.hidden">

                <!-- Single inbox mail -->
                <ion-card class="inbox-message" [attr.data-location]="'mail_inbox'" [attr.data-type]="'mail'"
                  [attr.data-uid]="item.uid">

                  <ion-item-sliding [attr.data-location]="'mail_inbox'" [attr.data-type]="'mail'"
                    [attr.data-uid]="item.uid">

                    <!-- Top bar -->
                    <ion-item lines="none">

                      <ion-button slot="start" [hidden]="!view.expertMode" class="drag-handle" icon-only fill="clear">
                        <ion-icon name="reorder-two-outline"></ion-icon>
                      </ion-button>

                      <!-- Item is loading / updating -->
                      <ion-spinner slot="start"
                        [hidden]="!item.loading && !item.loadingArticles && !item.loadingAttributes"></ion-spinner>

                      <ion-checkbox [(ngModel)]="item.checked" (ionChange)="onItemChecked(item, _iInbox)"
                        slot="start"></ion-checkbox>

                      <ion-thumbnail (click)="open(item, _iInbox)" slot="start">
                        <ion-img [src]="item.photo || fallbackImg" (ionError)="thumbnailLoadingFailed(item)"></ion-img>
                      </ion-thumbnail>

                      <!-- Label -->
                      <ion-label class="ion-text-wrap">

                        <!-- Timestamp -->
                        <p [innerHTML]="item.timestamp" *ngIf="item.timestamp"></p>

                        <!-- Subject -->
                        <h3 (click)="open(item, _iInbox)" [innerHTML]="item.name" *ngIf="item.name"></h3>

                        <ion-skeleton-text *ngIf="!item.name" style="float:left;width:25%;margin-right:10px;"
                          [animated]="!item.uid"></ion-skeleton-text>

                        <ion-skeleton-text *ngIf="!item.name" style="float:left;width:35%;margin-right:25%;"
                          [animated]="!item.uid"></ion-skeleton-text>

                        <!-- Email address -->
                        <h3 (click)="open(item, _iInbox)" [innerHTML]="item.email" *ngIf="!item.name"></h3>

                        <ion-badge color="medium" (click)="filterBy('email', item.email)" [innerHTML]="item.email"
                          *ngIf="item.name"></ion-badge>
                        <ion-badge color="medium" *ngIf="item.articles && !!item.articles.length"
                          [hidden]="!view.expertMode || !view.isDesktop"
                          [innerHTML]="item.articles.length + ' ' + ('articles'|translate)"></ion-badge>
                        <ion-badge color="medium" *ngIf="item.attachments && !!item.attachments.length"
                          [hidden]="!view.expertMode || !view.isDesktop"
                          [innerHTML]="item.attachments.length + ' ' + ('attachments'|translate)"></ion-badge>

                        <ion-skeleton-text *ngIf="!item.name" style="float:left;width:15%;margin-right:10px;"
                          [animated]="!item.uid"></ion-skeleton-text>

                        <ion-skeleton-text *ngIf="!item.name" style="float:left;width:25%;margin-right:25%;"
                          [animated]="!item.uid"></ion-skeleton-text>

                      </ion-label>

                      <!-- Mail options button -->
                      <ion-button slot="end" (click)="presentMailPopover($event, item)" fill="clear" icon-only
                        color="dark" *ngIf="!!mailSelectionOptions">
                        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                      </ion-button>

                      <!-- State icon -->
                      <ion-icon [hidden]="!!item.loading || !!item.loadingArticles || !!item.loadingAttributes"
                        (click)="open(item, _iInbox)"
                        [name]="item.active ? 'radio-button-on-outline' : 'radio-button-off-outline'"
                        [color]="item.done ? 'success' : (!!item.answer || (!!item.articles && !!item.articles.length) ? 'warning' : 'danger')"
                        slot="end"></ion-icon>

                    </ion-item>

                    <ion-item-options slot="end" *ngIf="!!item.uid && !view.isDesktop">

                      <!-- Open -->
                      <ion-item-option color="primary" (click)="open(item, $event)">
                        <ion-icon name="link-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'open'|translate"></ion-label>
                      </ion-item-option>

                      <!-- Delete -->
                      <ion-item-option color="danger" (click)="delete(item, $event)">
                        <ion-icon name="trash-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'delete'|translate"></ion-label>
                      </ion-item-option>

                    </ion-item-options>

                  </ion-item-sliding>

                </ion-card>
              </div>

            </cdk-virtual-scroll-viewport>
          </div>

        </ion-col>

        <!-- Content -->
        <ion-col [size]="!!view.colSize ? (view.colSize.right || 12) : 12" class="right"
          [class.resizable-col]="view.expertMode" [hidden]="!view.isDesktop && !!view.showFilters">

          <!-- Desktop intro card -->
          <div *ngIf="!!view.isDesktop" [hidden]="introCard.hidden">
            <pipeline-intro-card class="introCard" [(view)]="introCard"></pipeline-intro-card>
          </div>

          <!-- Single inbox mail -->
          <ion-card *ngIf="!!detailItem && !!detailItem.uid" class="inbox-message" [attr.data-location]="'mail_inbox'"
            [attr.data-type]="'mail'" [attr.data-uid]="detailItem.uid">

            <!-- Chat preview -->
            <ion-grid class="chat-view">

              <ion-row>

                <ion-col [size]="view.isDesktop ? 6 : 12">

                  <!-- Actions -->
                  <ion-card>

                    <ion-card-header>
                      <ion-card-title [innerHTML]="'actions'|translate"></ion-card-title>
                    </ion-card-header>

                    <!-- Fetch articles -->
                    <ion-button size="small" [disabled]="!!detailItem.loading" (click)="calcItemAttributes(detailItem)"
                      fill="clear" color="primary">
                      <ion-icon name="search-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'analyse'|translate"></ion-label>
                    </ion-button>

                    <!-- Generate answer -->
                    <ion-button size="small" [disabled]="!!detailItem.loading" (click)="fetchArticles(detailItem)"
                      fill="clear" color="primary">
                      <ion-icon name="list-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'extract_articles'|translate"></ion-label>
                    </ion-button>

                    <!-- Generate customer answer -->
                    <ion-button size="small" [disabled]="!!detailItem.loading" (click)="generateAnswer(detailItem)"
                      fill="clear" color="primary">
                      <ion-icon name="chatbox-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'generate_customer_answer'|translate"></ion-label>
                    </ion-button>

                    <!-- Generate supplier answer -->
                    <ion-button size="small" [disabled]="!!detailItem.loading"
                      (click)="generateSupplierRequest(detailItem)" fill="clear" color="primary">
                      <ion-icon name="share-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'generate_supplier_request'|translate"></ion-label>
                    </ion-button>

                    <!-- Crawl article data -->
                    <ion-button size="small" [disabled]="!!detailItem.loading" (click)="crawl_article_data(detailItem)"
                      fill="clear" color="primary">
                      <ion-icon name="sync-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'crawl_article_data'|translate"></ion-label>
                    </ion-button>

                  </ion-card>

                  <!-- Request -->
                  <ion-card>

                    <ion-card-header>
                      <ion-card-title [innerHTML]="'request'|translate"></ion-card-title>

                      <ion-button *ngIf="!!cards && !!cards.request" class="card-toggle" (click)="toggleCard('request')"
                        icon-only color="dark" fill="clear">
                        <ion-icon
                          [name]="!!cards && !!cards.request && cards.request.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                      </ion-button>

                    </ion-card-header>

                    <p class="content-text" [hidden]="!cards.request.open" [innerHTML]="detailItem.value || ''"></p>

                  </ion-card>

                </ion-col>

                <ion-col [size]="view.isDesktop ? 6 : 12">

                  <!-- Attributes -->
                  <ion-card>

                    <pipeline-item-attributes-card
                      *ngIf="!view.inbox[iInbox].rebuild && !view.inbox[iInbox].loadingAttributes"
                      [(item)]="view.inbox[iInbox]" type="mail_inbox" [(cards)]="cards" [forceRefresh]="true"
                      [(view)]="view"></pipeline-item-attributes-card>

                    <ion-spinner
                      [hidden]="(!!cards && !!cards.item_attributes && !cards.item_attributes.open) || (!view.inbox[iInbox].rebuild && !view.inbox[iInbox].loadingAttributes)"></ion-spinner>

                  </ion-card>

                  <!-- Answer -->
                  <ion-card *ngIf="!!detailItem.answer">

                    <ion-card-header>
                      <ion-card-title [innerHTML]="'answer'|translate"></ion-card-title>

                      <ion-button *ngIf="!!cards && !!cards.answer" class="card-toggle" (click)="toggleCard('answer')"
                        icon-only color="dark" fill="clear">
                        <ion-icon
                          [name]="!!cards && !!cards.answer && cards.answer.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                      </ion-button>

                    </ion-card-header>

                    <!-- Loading answer -->
                    <ion-spinner [hidden]="!cards.answer.open || !detailItem.loading"></ion-spinner>

                    <!-- Answer editor -->
                    <pipeline-editor [hidden]="!cards.answer.open"
                      *ngIf="!!detailItem.answer && !!detailItem.answer.length && !detailItem.loading"
                      [(editor)]="detailItem.output_editor" [input]="detailItem.answer"></pipeline-editor>

                    <!-- Rate answer -->
                    <pipeline-rate-item-buttons [hidden]="!cards.answer.open" [disabled]="!!detailItem.loading"
                      *ngIf="!!detailItem.answer" [item]="detailItem"
                      type="pipeline_mail_answer"></pipeline-rate-item-buttons>

                  </ion-card>

                </ion-col>

              </ion-row>

              <!-- Articles -->
              <ion-row>

                <ion-col size="12">
                  <ion-card class="item-articles-card">

                    <ion-card-header>

                      <ion-card-title [innerHTML]="'articles'|translate"></ion-card-title>

                      <!-- Add article -->
                      <ion-button color="primary" fill="clear" (click)="addArticleToMail(detailItem)">
                        <ion-icon name="add-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'new'|translate"></ion-label>
                      </ion-button>

                      <ion-button *ngIf="!!cards && !!cards.articles" class="card-toggle"
                        (click)="toggleCard('articles')" icon-only color="dark" fill="clear">
                        <ion-icon
                          [name]="!!cards && !!cards.articles && cards.articles && cards.articles.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                      </ion-button>

                    </ion-card-header>

                    <!-- Loading articles -->
                    <ion-spinner [hidden]="!cards.articles.open || !detailItem.loadingArticles"></ion-spinner>

                    <!-- No articles found -->
                    <pipeline-no-entries-card [cta]="ctaProducts"
                      [hidden]="!!cards && !!cards.articles && !cards.articles.open || !!detailItem.loadingArticles"
                      *ngIf="!detailItem.articles || !detailItem.articles.length"></pipeline-no-entries-card>

                    <!-- Articles list -->
                    <ion-grid class="articles-grid" *ngIf="!!detailItem.articles && !!detailItem.articles.length"
                      [hidden]="!cards.articles.open || !!detailItem.loadingArticles">

                      <ion-row>

                        <ion-col *ngFor="let article of detailItem.articles; let iArticle = index; trackBy:trackItems"
                          [size]="view.isDesktop ? 4 : 12">

                          <ion-card class="article-card">

                            <!-- Main item -->
                            <ion-item>

                              <!-- Article photo -->
                              <ion-thumbnail slot="start">
                                <ion-img [src]="article.photo || fallbackImg"
                                  (ionError)="thumbnailLoadingFailed(article)"></ion-img>
                              </ion-thumbnail>

                              <!-- Select article -->
                              <ion-checkbox [(ngModel)]="article.checked" class="ion-text-wrap" justify="start"
                                labelPlacement="end" [disabled]="!article.uid"
                                (ionChange)="onArticleItemChecked(article)">

                                <ion-label class="ion-text-wrap">
                                  <h3 [innerHTML]="article.name"></h3>
                                  <p [innerHTML]="article.price" *ngIf="!!article.price"></p>
                                </ion-label>

                              </ion-checkbox>

                              <!-- Article options button -->
                              <ion-button slot="end" (click)="presentArticlePopover($event, article)" fill="clear"
                                icon-only color="dark" *ngIf="!!articleSelectionOptions">
                                <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                              </ion-button>

                              <!-- Article state -->
                              <ion-icon
                                [name]="!!article.matching_storage_items ? 'radio-button-on-outline' : 'radio-button-off-outline'"
                                [color]="!!article.matching_storage_items && !!article.matching_storage_items.length ? 'warning' : 'danger'"
                                slot="end"></ion-icon>

                            </ion-item>

                            <!-- Matches -->
                            <ion-item lines="none" button (click)="toggleArticleMatches(article, detailItem)">

                              <ion-label [innerHTML]="'matches'|translate"></ion-label>

                              <ion-badge slot="end"
                                [color]="!!article.matching_storage_items && !!article.matching_storage_items.length ? 'success' : 'danger'">
                                <span
                                  [innerHTML]="!!article.matching_storage_items && !!article.matching_storage_items.length ? article.matching_storage_items.length : 0"></span>&nbsp;
                                <span [innerHTML]="'results'|translate"></span>
                              </ion-badge>

                              <ion-button class="copy-btn" (click)="searchProduct(article, iArticle)" icon-only
                                [hidden]="article.mode === 'search'" fill="clear" color="primary" slot="end">
                                <ion-icon name="search-outline"></ion-icon>
                              </ion-button>

                              <ion-button class="copy-btn" (click)="searchProduct(article, iArticle)" icon-only
                                [hidden]="article.mode !== 'search'" fill="clear" color="primary" slot="end">
                                <ion-icon name="checkmark-outline"></ion-icon>
                              </ion-button>

                            </ion-item>

                            <!-- Products searchbar -->
                            <ion-searchbar [hidden]="article.mode !== 'search'"
                              [placeholder]="'search_articles'|translate"></ion-searchbar>

                            <!-- Matches list -->
                            <ion-list
                              *ngIf="!!article.matching_storage_items && !!article.matching_storage_items.length"
                              [hidden]="!article.show_matches">
                              <ion-item
                                *ngFor="let match of article.matching_storage_items; let i = index; trackBy:trackItems"
                                (click)="onArticleMatchClick(match, article, i)">

                                <ion-thumbnail (click)="openArticle(match)" slot="end">
                                  <ion-img [src]="(match.photo || match.image) || fallbackImg"
                                    (ionError)="thumbnailLoadingFailed(match)"></ion-img>
                                </ion-thumbnail>

                                <ion-checkbox [(ngModel)]="match.checked" class="ion-text-wrap" justify="start"
                                  labelPlacement="end" [disabled]="!match.uid">
                                  <ion-label class="ion-text-wrap">
                                    <h3 [innerHTML]="match.name"></h3>
                                    <p [innerHTML]="match.sku"></p>
                                  </ion-label>
                                </ion-checkbox>

                              </ion-item>
                            </ion-list>

                            <!-- Description -->
                            <ion-item [hidden]="!article.description" color="warning" lines="none">
                              <ion-icon name="text-outline" slot="start"></ion-icon>
                              <ion-textarea disabled [label]="'description'|translate" labelPlacement="stacked"
                                [(ngModel)]="article.description" rows="4"></ion-textarea>
                            </ion-item>

                            <!-- SKU -->
                            <ion-item [hidden]="!article.sku" lines="none">
                              <ion-icon name="finger-print-outline" slot="start"></ion-icon>

                              <ion-input disabled [label]="'sku'|translate" [(ngModel)]="article.sku"></ion-input>

                              <ion-button class="copy-btn" (click)="copyToClipboard(article.sku)" icon-only fill="clear"
                                color="primary" slot="end">
                                <ion-icon name="copy-outline"></ion-icon>
                              </ion-button>

                            </ion-item>

                            <!-- Manufacter -->
                            <ion-item lines="none">
                              <ion-icon name="construct-outline" slot="start"></ion-icon>
                              <ion-select interface="popover" [label]="'manufacturer'|translate"
                                (ionChange)="learnInputChanged(iInbox, iArticle, 'manufacturer_uid', $event)"
                                [(ngModel)]="article.manufacturer_uid">
                                <ion-select-option *ngFor="let manufacturer of view.manufacturers; trackBy:trackItems"
                                  [innerHTML]="manufacturer.name" [value]="manufacturer.uid"></ion-select-option>
                              </ion-select>
                            </ion-item>

                            <!-- Supplier -->
                            <ion-item lines="none">
                              <ion-icon name="business-outline" slot="start"></ion-icon>
                              <ion-select interface="popover" [label]="'supplier'|translate"
                                (ionChange)="learnInputChanged(iInbox, iArticle, 'supplier_uid', $event)"
                                [(ngModel)]="article.supplier_uid">
                                <ion-select-option *ngFor="let supplier of view.suppliers; trackBy:trackItems"
                                  [innerHTML]="supplier.name" [value]="supplier.uid"></ion-select-option>
                              </ion-select>
                            </ion-item>

                            <!-- Amount -->
                            <ion-item lines="none">
                              <ion-icon name="layers-outline" slot="start"></ion-icon>
                              <ion-input [label]="'amount'|translate" [(ngModel)]="article.amount"></ion-input>
                            </ion-item>

                            <!-- Price -->
                            <ion-item [hidden]="!article.price" color="warning">
                              <ion-icon name="pricetag-outline" slot="start"></ion-icon>
                              <ion-input [label]="'price'|translate" [(ngModel)]="article.price"></ion-input>
                            </ion-item>

                            <!-- Country -->
                            <ion-item lines="none">
                              <ion-icon name="globe-outline" slot="start"></ion-icon>
                              <ion-select interface="popover" [label]="'country'|translate"
                                (ionChange)="learnInputChanged(iInbox, iArticle, 'country_uid', $event)"
                                [(ngModel)]="article.country_uid">
                                <ion-select-option *ngFor="let country of view.countries; trackBy:trackItems"
                                  [innerHTML]="country.name" [value]="country.uid"></ion-select-option>
                              </ion-select>
                            </ion-item>

                            <!-- Currency -->
                            <ion-item [hidden]="!article.currency" lines="none">
                              <ion-icon name="cash-outline" slot="start"></ion-icon>
                              <ion-input [label]="'currency'|translate" [(ngModel)]="article.currency"></ion-input>
                            </ion-item>

                            <!-- URL -->
                            <ion-item [hidden]="!article.url" lines="none">
                              <ion-icon name="link-outline" slot="start"></ion-icon>
                              <ion-input disabled [label]="'url'|translate" [(ngModel)]="article.url"></ion-input>

                              <ion-button class="copy-btn" (click)="copyToClipboard(article.url)" icon-only fill="clear"
                                color="primary" slot="end">
                                <ion-icon name="copy-outline"></ion-icon>
                              </ion-button>

                              <ion-button class="copy-btn" (click)="openURL(article.url)" icon-only fill="clear"
                                color="primary" slot="end">
                                <ion-icon name="open-outline"></ion-icon>
                              </ion-button>

                            </ion-item>

                          </ion-card>

                        </ion-col>
                      </ion-row>
                    </ion-grid>

                  </ion-card>
                </ion-col>

              </ion-row>

              <!-- Attachments -->
              <ion-row>
                <ion-col size="12">
                  <ion-card>

                    <ion-card-header>

                      <ion-card-title [innerHTML]="'attachments'|translate"></ion-card-title>

                      <ion-button *ngIf="!!cards && !!cards.attachments" class="card-toggle"
                        (click)="toggleCard('attachments')" icon-only color="dark" fill="clear">
                        <ion-icon
                          [name]="!!cards && !!cards.attachments && cards.attachments.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                      </ion-button>

                    </ion-card-header>

                    <!-- Loading attachments -->
                    <ion-spinner
                      [hidden]="(!!cards && !!cards.attachments && !cards.attachments.open) || !detailItem.loading && !detailItem.loadingAttachments"></ion-spinner>

                    <!-- No attachments -->
                    <pipeline-no-entries-card
                      [hidden]="(!!cards && !!cards.attachments && !cards.attachments.open) || !!detailItem.loading || !!detailItem.loadingAttachments"
                      *ngIf="detailItem.attachments && !detailItem.attachments.length"></pipeline-no-entries-card>

                    <!-- Attachments grid -->
                    <ion-grid
                      [hidden]="(!!cards && !!cards.attachments && !cards.attachments.open) || !!detailItem.loading || !!detailItem.loadingAttachments"
                      class="attachments-grid" *ngIf="!!detailItem.attachments && !!detailItem.attachments.length">

                      <ion-row>

                        <ion-col [size]="view.isDesktop ? 2 : 4"
                          *ngFor="let attachment of detailItem.attachments; trackBy:trackItems">
                          <ion-card (click)="onAttachmentClick(attachment)">

                            <!-- Documents -->
                            <ion-icon *ngIf="attachment.type === 'attachment'" name="document-text-outline"></ion-icon>

                            <!-- Image attachment -->
                            <ion-thumbnail *ngIf="attachment.type === 'image'">
                              <ion-img [src]="attachment.thumbnail || fallbackImg"
                                (ionError)="thumbnailLoadingFailed(attachment)"></ion-img>
                            </ion-thumbnail>

                            <!-- Video attachment -->
                            <video *ngIf="attachment.type === 'video'" muted="muted" loop autoplay controls
                              webkit-playsinline playsinline [src]="attachment.guid"></video>

                            <!-- Label -->
                            <ion-item lines="none">
                              <ion-label [innerHTML]="attachment.title"></ion-label>
                            </ion-item>

                          </ion-card>
                        </ion-col>

                      </ion-row>
                    </ion-grid>

                  </ion-card>
                </ion-col>
              </ion-row>

              <!-- Supplier requests -->
              <ion-row>
                <ion-col size="12">

                  <ion-card>

                    <ion-card-header>

                      <ion-card-title [innerHTML]="'supplier_requests'|translate"></ion-card-title>

                      <!-- Create supplier request -->
                      <ion-button color="primary" fill="clear" (click)="generateSupplierRequest(detailItem)">
                        <ion-icon name="add-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'new'|translate"></ion-label>
                      </ion-button>

                      <ion-button *ngIf="!!cards && !!cards.supplier_requests" class="card-toggle"
                        (click)="toggleCard('supplier_requests')" icon-only color="dark" fill="clear">
                        <ion-icon
                          [name]="!!cards && !!cards.supplier_requests && cards.supplier_requests.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                      </ion-button>

                    </ion-card-header>

                    <!-- Loading supplier requests -->
                    <ion-spinner
                      [hidden]="(!!cards && !!cards.supplier_requests && !cards.supplier_requests.open) || !detailItem.loading && !detailItem.loadingSupplierRequests"></ion-spinner>

                    <!-- No supplier requests -->
                    <pipeline-no-entries-card
                      [hidden]="(!!cards && !!cards.supplier_requests && !cards.supplier_requests.open) || !!detailItem.loading || !!detailItem.loadingSupplierRequests"
                      *ngIf="!detailItem.supplier_requests || !detailItem.supplier_requests.length"></pipeline-no-entries-card>

                  </ion-card>

                </ion-col>

              </ion-row>

            </ion-grid>

          </ion-card>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>