import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

@Injectable({
    providedIn: 'root'
})
export class TargetgroupsService {

    constructor(
        private AppCMS: AppcmsService,
        private chooser: ChooserService,
        private loadingCtrl: LoadingController,
    ) {

    }

    async create(targetGroup: targetGroup) {

        const loading: any = await this.loadingCtrl.create({
            spinner: 'circular',
        });

        loading.present();

        return new Promise((resolve, reject) => {
            this.AppCMS.loadPluginData('pipeline', {
                targetGroup: targetGroup,


            }, ['ads', 'target_groups', 'create'])
                .then((response: any) => {
                    loading.dismiss();
                    resolve(response);
                })
                .catch((error) => {
                    loading.dismiss();
                    reject(error);
                });
        });
    }

    async delete(groupId: number) {

        const loading: any = await this.loadingCtrl.create({
            spinner: 'circular',
        });
        loading.present();

        return new Promise((resolve, reject) => {
            this.AppCMS.loadPluginData('pipeline', {
                group: groupId,
            }, ['ads', 'target_groups', 'delete'])
                .then((response: any) => {
                    loading.dismiss();
                    resolve(response);
                })
                .catch((error) => {
                    loading.dismiss();
                    reject(error);
                });
        });
    }

    async getAll(blForceRefresh: boolean = false, filter: any = {}) {
        return new Promise((resolve, reject) => {
            this.AppCMS.loadPluginData('pipeline', {
            }, ['ads', 'target_groups'], {
                filter: filter
            }, blForceRefresh)
                .then((target_groups: targetGroup[]) => {
                    if (target_groups && target_groups.length) {
                        target_groups.forEach((targetGroup: targetGroup) => {
                            targetGroup = this.getFullTargetGroup(targetGroup);
                        });
                    }
                    resolve(target_groups);
                })
                .catch(reject);
        });
    }

    getFullTargetGroup(targetGroup: targetGroup) {
        return targetGroup;
    }

    pick(options: any = {}) {
        return new Promise(async (resolve, reject) => {
            const loading: any = await this.loadingCtrl.create();
            loading.present();

            let data: any = await this.getAll(true, options);

            loading.dismiss();

            const chooseConfig: chooseConfig = Object.assign(options || {}, {
                data: data,
                labelKey: "label",
                multiple: !!options.multiple,
                service: this,
                title: "target_groups",
                type: 'target_group',
                valueKey: "uid",
            });

            this.chooser.choose(chooseConfig).then(resolve).catch(reject);
        });
    }

    async update(targetGroup: targetGroup) {

        const loading: any = await this.loadingCtrl.create({
            spinner: 'circular',
        });
        loading.present();

        return new Promise((resolve, reject) => {
            this.AppCMS.loadPluginData('pipeline', {
                targetGroup: targetGroup,
            }, ['ads', 'target_groups', 'update'])
                .then((response: any) => {
                    loading.dismiss();
                    resolve(response);
                })
                .catch((error) => {
                    loading.dismiss();
                    reject(error);
                });
        });
    }

}