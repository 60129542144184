import { Component, OnInit } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { ToolsService } from "src/app/services/utils/tools.service";
import { WhitelabelService } from 'src/app/services/pipeline/whitelabel.service';

import { WhitelabelAppPage } from '../whitelabel-app/whitelabel-app.page';

@Component({
  selector: 'app-whitelabel-apps',
  standalone: false,
  templateUrl: './whitelabel-apps.page.html',
  styleUrls: ['./whitelabel-apps.page.scss'],
})
export class WhitelabelAppsPage implements OnInit {

  buttons: button[] = [
    {
      icon: 'create-outline',
      label: 'edit',
      uid: 'edit',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    }
  ];
  
  cta: any = {
    handler: () => {
    },
    icon: 'add-outline',
    label: 'create',
  };
  
  headerOptions: selectionOption[] = [
    {
      icon: 'cloud-download-outline',
      label: 'export',
      uid: 'export',
    },
    {
      icon: 'cloud-upload-outline',
      label: 'import',
      uid: 'import',
    },
  ];
  
  introCard: introCardConfig = {
    uid: 'whitelabel_apps_top_card',
    text: 'whitelabel_apps_top_card_text',
    title: 'whitelabel_apps_top_card_title',
  };

  options: dynamicViewPageOptions = {
    labelKey: 'name',
    photoKey: 'icon',
    showPhoto: true,
    showViewModeSelect: true,
  };

  pages: any = {};

  search: searchOptions = {
    itemsKey: 'apps',
    keys: ['name', 'url', 'title', 'description', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[];

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    itemSize: 84,
    placeholders: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    route: 'whitelabel-apps',
    showMenuButton: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'whitelabel_apps',
    viewType: 'list',
  };

  constructor(
    private events: EventsService,
    public service: WhitelabelService,
    private tools: ToolsService,
  ) {
  }

  deleteSelected(event: any | null = null) {
    console.log('delete selected: event', event);
    console.log('delete selected: view', this.view);
  }

  duplicateSelected(event: any | null = null) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'duplicate',
      identifier: 'uid',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
      },
      service: this.service,
    })
      .then(() => {
        //this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnInit() {
    this.pages = {
      add: WhitelabelAppPage,
      edit: WhitelabelAppPage,
    };
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }
  }

}