import { Component, NgZone, OnInit } from '@angular/core';

import { BlogService } from "src/app/services/pipeline/blog.service";
import { CitiesService } from 'src/app/services/geo/cities.service';
import { ConfigService } from "src/app/services/core/config.service";
import { CountriesService } from 'src/app/services/geo/countries.service';
import { EventsService } from "src/app/services/core/events.service";
import { ExternalService } from 'src/app/services/extensions/external.service';
import { ModalService } from "src/app/services/core/modal.service";
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-connect-blog',
  standalone: false,
  templateUrl: './connect-blog.page.html',
  styleUrls: ['./connect-blog.page.scss'],
})
export class ConnectBlogPage implements OnInit {

  appConfig: pipelineAppConfig;

  fallbackImg: string = './assets/img/fallback.webp';
  
  introOverlayConfig: introOverlayConfig = {
    allowManually: false,
    headline: 'connect_blog',
    input_placeholder: 'blog_url',
    input_type: 'url',
    showAiCreate: true,
    showAiSettings: false,
    showInput: true,
    submitLabel: 'connect',
  };

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    loading: false,
    source: {},
    systems: ['csv', 'custom', 'drupal', 'json', 'rss', 'web', 'wordpress', 'xml'],
    title: 'connect_blog',
  };

  constructor(
    private blog: BlogService,
    private cities: CitiesService,
    private configService: ConfigService,
    private countries: CountriesService,
    private events: EventsService,
    private external: ExternalService,
    private media: MediaextendService,
    private modalService: ModalService,
    private tools: ToolsService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  aiCreate() {

    if(!this.view.aiCreateInput || !this.view.aiCreateInput.length) {
      return false;
    }

    this.view.input = this.view.aiCreateInput;

    this.onUrlChanged();
    this.startManually();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  async dismiss(data: any|null = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  initEvents() {
    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  loadCategories() {
    this.blog.getCategories()
      .then((categories: category[]) => {
        this.view.categories = categories;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnInit() {
    this.initEvents();
    this.calcViewVars();
    this.loadCategories();
  }

  onSourcePhotoClick() {
    this.media.applyFromWeb(null, {
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.view.source.photo = imageUrl;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  onUrlChanged() {

    if (this.view.hasOwnProperty('success')) {
      delete this.view.success;
    }

    this.view.loading = false;

    if (
      this.view.input &&
      (this.view.input.length > 4) &&
      (this.view.input.indexOf('.') !== -1)
    ) {

      const validate: any = this.tools.validateUrl(this.view.input);

      if (!validate.success) {
        return false;
      }

      this.view.loading = true;

      this.external.verifySupportedURL(this.view.input, true)
        .then((response: any) => {
          this.zone.run(() => {
            let sourceName: string = `${this.view.input}`.replace('https://', '').replace('http://', '').split('/')[0];

            this.view.loading = false;
            this.view.success = !!response.success;
            this.view.source.name = sourceName;

            if (!!response && !!response.data) {

              if (!!response.data.cms) {
                this.view.source.system = response.data.cms;
              }

              if (!!response.data.photo) {
                this.view.source.photo = response.data.photo;
              }

              if (!!response.data.url) {
                this.view.source.url = response.data.url;
              }

            }

          });
        })
        .catch(() => {
          this.view.loading = false;
          this.view.success = false;

          this.view.source.system = 'custom';
          this.view.source.url = this.view.input;
        });
    }
  }

  pickCountry() {
    this.countries.pick()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  pickRegion() {
    this.cities.pick()
      .then((response: any) => {
        let city: city = (response && response.data && response.data.item ? response.data.item : response);

        if (!!city) {
          this.view.source.region = city.city;
          this.view.source.region_uid = city.uid;
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  startManually() {
    this.view.startManually = true;
  }

  submit() {
    this.view.source.url = this.view.source.url || ('https://' + (this.view.input.replace('https://', '').replace('http://', '')));
    this.view.source.active = false;

    this.external.submitSource(this.view.source)
      .then(() => {
        this.dismiss(null, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });

  }

  thumbnailLoadingFailed() {
    this.view.source.photo = this.fallbackImg;
  }

}