import { Pipe, PipeTransform } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";

@Pipe({
  name: 'fillVars',
  standalone: false
})
export class FillVarsPipe implements PipeTransform {

  appConfig: pipelineAppConfig;

  appNameVar: string = '${appName}';
  appVersionVar: string = '${appVersion}';

  constructor(
    private configService: ConfigService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  transform(str: string) {

    const transform: string = `${str || ''}`
      .replaceAll(this.appNameVar, this.appConfig.appName)
      .replaceAll(this.appVersionVar, this.appConfig.appVersionName)
      ;

    return transform;
  }

}