import { Component, OnInit } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { MicrosoftService } from 'src/app/services/integrations/microsoft.service';
import { MailCrawlerService } from "src/app/services/integrations/mail-crawler.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-mail-crawler',
  standalone: false,
  templateUrl: './mail-crawler.page.html',
  styleUrls: ['./mail-crawler.page.scss'],
})
export class MailCrawlerPage implements OnInit {

  appConfig: pipelineAppConfig;
  
  crawler: mailCrawler;

  crawlerTypes: string[] = [
    'auto',
    'default',
    'exchange',
    'google',
    'icloud',
  ];

  introOverlayConfig: introOverlayConfig = {
    allowManually: true,
    groups: [

      /* Step 1: */
      {
        buttons: [

        ],
        description: 'mail_crawler_ai_helper_text',
        headline: 'connect_mail_crawler',
        inputs: [
          {
            placeholder: 'email_address',
            type: 'email',
          }
        ],
      },

      /* Step 2: */
      {
        buttons: [

        ],
        description: 'mail_crawler_type_ai_helper_text',
        headline: 'mail_crawler_type_ai_helper_title',
        inputs: [
          {
            icon: 'cube-outline',
            label: 'mail_crawler_type',
            type: 'select',
            values: [],
          }
        ],
      },

      /* Step 3: Configuration / Options */
      {
        inputs: [
          {
            label: 'email',
            type: 'email',
            uid: 'email',
            value: '',
          },
          {
            label: 'password',
            type: 'password',
            uid: 'password',
            value: '',
          },
          {
            label: 'mail_crawler_server_in_host',
            type: 'text',
            uid: 'server_in_host',
            value: '',
          },
          {
            label: 'mail_crawler_server_in_port',
            type: 'number',
            uid: 'server_in_port',
            value: 993,
          },
          {
            label: 'mail_crawler_server_out_host',
            type: 'text',
            uid: 'server_out_host',
            value: '',
          },
          {
            label: 'mail_crawler_server_out_port',
            type: 'number',
            uid: 'server_out_port',
            value: 465,
          },
        ],
        description: 'mail_crawler_configuration_ai_helper_text',
        headline: 'mail_crawler_configuration_ai_helper_title',
      },


      /* Step 4: Validation */
      {
        buttons: [

        ],
        description: 'mail_crawler_validation_ai_helper_text',
        headline: 'mail_crawler_validation_ai_helper_title',
        loading: true,
      },

    ],
    showAiCreate: true,
    showAiSettings: false,
    showInput: false,
    showSliderButtons: true,
    submitLabel: 'connect',
  };

  search: searchOptions = {
    keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  state: state = {};

  view: any = {
    crawlerTypeIcons: {
      exchange: 'logo-microsoft',
      google: 'logo-google',
      icloud: 'logo-apple',
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'mail_crawler_top_card',
      text: 'mail_crawler_top_card_text',
      title: 'mail_crawler_top_card_title',
    },
    options: {
    },
    showMenuButton: true,
    title: 'mail_crawler',
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private exchange: MicrosoftService,
    public mail: MailCrawlerService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();

    // apply crawler types to intro overlay component
    if (!!this.introOverlayConfig.groups && !!this.introOverlayConfig.groups[1] && !!this.introOverlayConfig.groups[1].inputs && !!this.introOverlayConfig.groups[1].inputs[0]) {
      this.introOverlayConfig.groups[1].inputs[0].values = this.crawlerTypes.map((type: string) => {
        return {
          name: `mail_crawler_type_${type}`,
          uid: type,
        }
      });
    }

  }

  aiCreate() {

    // calculate crawlerTypes
    this.crawler.type = 'default';

    this.startManually();
  }

  async authExchange() {
    try {
      const auth: any = await this.exchange.connect();
      return auth;
    } catch (e) {
      this.events.publish('error', e);
      return false;
    }
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.crawler = this.crawler || {};
  }

  async create() {
    await this.validate();

    this.mail.createCrawler(this.crawler)
      .then((response: any) => {
        console.log('create response', response);
        this.dismiss();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnInit() {
    this.calcViewVars();
    this.loadProject();

    if (!!this.crawler && !!this.crawler.uid) {
      this.startManually();
    }

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  onCrawlerTypeChanged(event: any = null) {
    switch (this.crawler.type) {
      case 'icloud':
        this.crawler.server_in_type = 'imap';
        break;
      case 'exchange':

        this.crawler.server_in_host = 'outlook.office365.com';
        this.crawler.server_in_port = 993;
        this.crawler.server_in_type = 'imap';

        this.crawler.server_out_host = 'smtp-mail.outlook.com';
        this.crawler.server_out_port = 587;

        this.crawler.use_two_factor_auth = true;
        break;
    }
  }

  startManually() {
    this.view.startManually = true;
  }

  async update() {
    await this.validate();

    this.mail.updateCrawler(this.crawler)
      .then((response: any) => {
        this.dismiss();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async validate() {
    switch (this.crawler.type) {
      case 'exchange':
        const auth: any = await this.authExchange();

        if (!!auth && !!auth.access_token) {
          this.crawler.access_token = auth.access_token;
        }
        break;
    }
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}