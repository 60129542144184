export var apiUrl: string = 'https://api.getgenius.ai/api';
export var hostUrl: string = 'https://api.getgenius.ai';
export var proxyUrl: string = 'https://api.getgenius.ai/api/searchengine/proxy.raw?url=';

export var pipelineAppConfig: pipelineAppConfig = {
    allowAuthUsingCredentials: false,
    allowAuthUsingToken: true,
    allowUserCommentPost: true,
    allowUserCreateChat: true,
    allowUserCreateChatGroup: true,
    allowUserEditPostSettings: false,
    allowUserEditProfileDetails: true,
    allowUserEditSidebar: true,
    allowUserForgotPassword: false,
    allowUserLogin: true,
    allowUserPostContent: false,
    allowUserPostContentAsCreator: true,
    allowUserPostContentAsGuest: false,
    allowUserPostContentAsUser: false,
    allowUserRegister: true,
    allowUserRegisterInApp: false,
    allowUserRegisterInWeb: true,
    allowUserTranslatePost: false,
    allowUserWriteChatMessage: true,
    appDescription: 'GetGenius',
    appId: 'ai.getgenius.studio',
    appName: 'GetGenius',
    appPackageName: 'getgenius-studio',
    appPages: {

        creators_shop_admin: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "creators_shop_admin",
                url: "/creators-shops",
                icon: "storefront-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "shop_products",
                url: "/shop/products",
                icon: "grid-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "shop_categories",
                url: "/shop/categories",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "orders",
                url: "/orders",
                icon: "card-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            }
        ],

        integrations: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integrations",
                url: "/integrations",
                icon: "extension-puzzle-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "apps",
                url: "/whitelabel-apps",
                icon: "phone-portrait-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Moderator', 'Vertrieb'],
                key: "creators_blog_admin",
                url: "/sources/blogs",
                icon: "folder-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "creators_shop_admin",
                url: "/sources/shops",
                icon: "storefront-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_mail",
                url: "/mail/inbox",
                icon: "mail-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integrations_log",
                url: "/integrations/log",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
            },
        ],
        /*
        integration_facebook: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_facebook",
                url: "/integrations/integration/facebook/connections",
                icon: "logo-facebook",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "facebook_api_log",
                url: "/integrations/integration/facebook",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "meta_groups",
                url: "/integrations/integration/meta/groups",
                icon: "people-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_instagram: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_instagram",
                url: "/integrations/integration/instagram/connections",
                icon: "logo-instagram",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "instagram_api_log",
                url: "/integrations/integration/instagram",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_snapchat: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_snapchat",
                url: "/integrations/integration/snapchat/connections",
                icon: "logo-snapchat",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "snapchat_api_log",
                url: "/integrations/integration/snapchat",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_whatsapp: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_whatsapp",
                url: "/integrations/integration/whatsapp/connections",
                icon: "logo-whatsapp",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "whatsapp_api_log",
                url: "/integrations/integration/whatsapp",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        integration_youtube: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_youtube",
                url: "/integrations/integration/youtube/connections",
                icon: "logo-youtube",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "youtube_api_log",
                url: "/integrations/integration/google",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        telegram_bots: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "telegram_bots",
                url: "/integrations/integration/telegram/bots",
                icon: "chatbubble-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "telegram_api_log",
                url: "/integrations/integration/telegram",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        */

        integration_mail: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "mail_inbox",
                url: "/mail/inbox",
                icon: "file-tray-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "mail_crawlers",
                url: "/mail/crawlers",
                icon: "link-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "mail_blacklist",
                url: "/mail/blacklist",
                icon: "hand-left-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
            },
            /*
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "mail_settings",
                url: "/mail/settings",
                icon: "settings-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['integration_mail', 'integrations_enterprise', 'enterprise'],
            },
            */
        ],

        main: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "creator_dashboard",
                url: "/dashboard",
                icon: "grid-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "content_hub",
                url: "/hub",
                icon: "document-text-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
                children: [
                    /*
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "posts_admin",
                        url: "/hub",
                        icon: "document-text-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['basic', 'pro', 'enterprise'],
                    },
                    */
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "newsletters",
                        url: "/newsletters",
                        icon: "mail-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['basic', 'pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "shop_products",
                        url: "/shop/products",
                        icon: "grid-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "ads",
                        url: "/ads",
                        icon: "cash-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['basic', 'pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "ai_url_lookup",
                        url: "/ai/url-lookup",
                        icon: "search-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['basic', 'pro', 'enterprise'],
                    },
                ],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "media",
                url: "/media/library",
                icon: "images-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['basic', 'pro', 'enterprise'],
                /*
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "media_templates",
                        url: "/media/templates",
                        icon: "eye-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                ]
                */
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "campaigns",
                url: "/campaigns",
                icon: "calendar-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "ai_tasks",
                url: "/ai/tasks",
                icon: "terminal-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                key: "statistics_admin",
                url: "/statistics",
                icon: "stats-chart-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['marketing', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                key: "ai",
                url: "/ai/training/log",
                icon: "sparkles-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                        key: "ai_assistants",
                        url: "/ai/assistants",
                        icon: "happy-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                        key: "ai_training_log",
                        url: "/ai/training/log",
                        icon: "pulse-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                        key: "ai_models",
                        url: "/ai/models",
                        icon: "hardware-chip-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                        key: "ai_knowledgebase",
                        url: "/ai/training/knowledgebase",
                        icon: "library-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                ]
            },

            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "settings",
                label: 'getgenius_menu_settings',
                url: "/settings",
                icon: "settings-outline",
                hideIfLoggedOut: true,
                children: [
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Moderator', 'Vertrieb'],
                        key: "account",
                        url: "/account",
                        icon: "person-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['basic', 'pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Moderator'],
                        key: "teams",
                        url: "/teams",
                        icon: "people-circle-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Moderator', 'Vertrieb'],
                        key: "projects_admin",
                        url: "/projects",
                        icon: "briefcase-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                        key: "integrations",
                        url: "/integrations",
                        icon: "extension-puzzle-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['basic', 'pro', 'enterprise'],
                    },
                    {
                        allowedUserTypes: ['Admin', 'Moderator', 'Vertrieb'],
                        key: "translations_admin",
                        url: "/translations",
                        icon: "language-outline",
                        hideIfLoggedOut: true,
                        requiresAboExtension: ['pro', 'enterprise'],
                    },
                ]
            },
        ],
        meta_connections: [
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_facebook",
                url: "/integrations/integration/facebook/connections",
                icon: "logo-facebook",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_instagram",
                url: "/integrations/integration/instagram/connections",
                icon: "logo-instagram",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_whatsapp",
                url: "/integrations/integration/whatsapp/connections",
                icon: "logo-whatsapp",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "meta_comments",
                url: "/integrations/integration/meta/comments",
                icon: "chatbubble-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "meta_log",
                url: "/integrations/integration/meta/log",
                icon: "list-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        social_media: [
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_linkedin",
                url: "/integrations/integration/linkedin/connections",
                icon: "logo-linkedin",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_pinterest",
                url: "/integrations/integration/pinterest/connections",
                icon: "logo-pinterest",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_reddit",
                url: "/integrations/integration/reddit/connections",
                icon: "logo-reddit",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_snapchat",
                url: "/integrations/integration/snapchat/connections",
                icon: "logo-snapchat",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "meta_connections",
                url: "/integrations/integration/facebook/connections",
                icon: "logo-facebook",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_tiktok",
                url: "/integrations/integration/tiktok/connections",
                icon: "logo-tiktok",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_twitch",
                url: "/integrations/integration/twitch/connections",
                icon: "logo-twitch",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_twitter",
                url: "/integrations/integration/twitter/connections",
                icon: "logo-twitter",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Creator', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_xing",
                url: "/integrations/integration/xing/connections",
                icon: "logo-xing",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
            {
                allowedUserTypes: ['Admin', 'Redakteur', 'Moderator', 'Vertrieb'],
                key: "integration_youtube",
                url: "/integrations/integration/youtube/connections",
                icon: "logo-youtube",
                hideIfLoggedOut: true,
                requiresAboExtension: ['enterprise'],
            },
        ],
        teams: [
            {
                allowedUserTypes: ['Admin', 'Moderator'],
                key: "teams",
                url: "/teams",
                icon: "people-circle-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin'],
                key: "customers",
                url: "/customers-admin",
                icon: "person-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
            {
                allowedUserTypes: ['Admin'],
                key: "user_groups_admin",
                url: "/user-groups",
                icon: "hand-right-outline",
                hideIfLoggedOut: true,
                requiresAboExtension: ['pro', 'enterprise'],
            },
        ]
    },
    appSlogan: 'GetGenius',
    appVersionId: 103052,
    appVersionName: '1.3.52',
    contentHostUrl: 'https://getgenius.ai/',
    environments: {
        api: {
            production: 'https://api.getgenius.ai/api',
        },
        app: {
            production: 'https://api.getgenius.ai',
        },
        rendering: {
            production: 'https://api.getgenius.ai/api',
        }
    },
    filterDiscoverPostsByContentHostUrl: false,
    filterHomePostsByContentHostUrl: true,
    filterLocalPostsByContentHostUrl: false,
    filterPeopleByContentHostUrl: true,
    filterSearchResultsByContentHostUrl: true,
    filterUserByTeamUids: true,
    footerBottomText: ``,
    /*
    footerBottomText: `<p>
        Erstellt mit <a href="https://pipeline.page" target="_blank">pipeline</a><br>
        &copy; ${new Date().getFullYear()} pipeline App GmbH<br><br>
        <a href="https://pipeline.page/de/agb" target="_blank">AGB</a><br>
        <a href="https://pipeline.page/de/datenschutz" target="_blank">Datenschutzerklärung</a>
    </p>`,
    */
    footerLoveText: 'Made with \u2764\ufe0f in Washington, D.C.',
    forceAbonnementAfterRegistration: true,
    forceProjectToBeSet: true,
    globalCreateItems: [
        {
            expertMode: true,
            icon: 'musical-note-outline',
            name: 'audio',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/ai/text-to-audio',
        },
        {
            icon: 'images-outline',
            name: 'media',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/media/create',
        },
        {
            icon: 'film-outline',
            name: 'ai_video_creator_length_60',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/ai/video-creator',
        },
        {
            expertMode: true,
            icon: 'recording-outline',
            name: 'prompt_function_action_ai_text_to_speech',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/ai/text-to-speech',
        },
        {
            icon: 'text-outline',
            name: 'post',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/post/new',
        },
        {
            icon: 'calendar-outline',
            name: 'campaign',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/ai/planner',
        },
        {
            icon: 'mail-outline',
            name: 'newsletter',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/newsletters/create',
        },
        {
            icon: 'terminal-outline',
            name: 'ai_task',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/ai/task',
        },
        {
            expertMode: true,
            icon: 'code-outline',
            name: 'code',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/ai/coder',
        },
        /*
        {
            icon: 'extension-puzzle-outline',
            name: 'integration',
            requiresAboExtension: ['enterprise'],
            url: '/integrations',
        },
        */
        {
            icon: 'briefcase-outline',
            name: 'project',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/project',
        },
        {
            icon: 'people-circle-outline',
            name: 'team',
            requiresAboExtension: ['pro', 'enterprise'],
            url: '/team',
        },
        /*
        {
            icon: 'person-outline',
            name: 'user',
            requiresAboExtension: ['basic', 'pro', 'enterprise'],
            url: '/customer',
        },
        */
    ],
    includeRatingsInFeed: false,
    includeReactionsInFeed: false,
    includeRelatedArticlesInFeed: false,
    interruptIfWalletBalanceIsInsufficient: true,
    isWhitelabel: true,
    links: {
        imprint: 'https://www.getgenius.ai/imprint',
        privacy_policy: 'https://www.getgenius.ai/privacy-policy',
        settings: [
            {
                icon: 'book-outline',
                description: 'getgenius_docs_description',
                label: 'getgenius_docs',
                url: 'https://docs.getgenius.ai',
            },
        ],
        terms_of_use: 'https://www.getgenius.ai/enterprise-terms',
    },
    loginHeadlineText: 'login_headline_text',
    loginPageSplineSrc: './assets/spline/dani/dani.splinecode',
    loginTopLogoSize: 'large',
    loginTopLogoUrl: './assets/img/logo-dark.webp',
    maxPostAge: 365,
    menuSideDesktop: 'start',
    menuSideMobile: 'start',
    multiLanguageSupport: true,
    multiUser: true,
    openerURL: 'https://open.getgenius.ai/',
    orderBy: {
        discover: 'post_date_gmt desc',
        home: 'post_date_gmt desc',
        local: 'post_date_gmt desc',
        people: 'RAND()',
        shop: 'name',
        showroom: 'post_date_gmt desc',
    },
    postsVipFactor: 0,
    //projectsSetupRedirectUri: '/media/create',
    //pushAppId: 'fe87ba2c-8645-41c5-b5f7-f0a81efc779b',
    registerFlow: 'passwordless',
    registerRedirectUri: '/setup',
    registerRequiresAbonnement: true,
    routes: {
        discover: '/tabs/shop/discover',
        home: '/creators-dashboard',
        local: '/tabs/local',
        people: '/tabs/people',
        shop: '/tabs/shop-categories-list',
    },
    showAccountBecomeCreator: false,
    showAccountClearCaches: true,
    showAccountSecurity: true,
    showAppearanceColorSchemes: true,
    showAppearanceFontsSelector: false,
    showAppearanceFontSizeSelector: true,
    showAppearancePreferredImageSize: false,
    showAppearanceSidebarSettings: true,
    showAppearanceTabs: false,
    showBasketInSidebar: false,
    showCheckoutPage: false,
    showDashboardAiShortcuts: false,
    showFeedBottomShadow: false,
    showFooterAppVersion: true,
    showFooterBottomText: true,
    showGetGeniusDaniInDashboard: true,
    showGetGeniusDaniInSidebar: false,
    showHeaderAddButton: false,
    showHeaderLargeTitle: true,
    showHeaderMenuButton: true,
    showHeaderToolbarLogo: true,
    showHeaderToolbarLogoInApp: true,
    showHeaderToolbarLogoInWeb: false,
    showHeaderToolbarTitle: false,
    showJobPostsInFeeds: false,
    showJobPostsInSearchResults: false,
    showLocalSourcesSuggestions: false,
    showLoginCredentialsSkipAccount: false,
    showLoginPage: false,
    showLoginPageLeftImg: true,
    showLoginPageLeftImgCase: true,
    showLoginPageSpline: true,
    showLoginTopLogo: true,
    showLoomaBlanketPurchaseOrders: false,
    showLoomaCustomersPersonTypeOrganization: true,
    showLoomaCustomersPersonTypePerson: true,
    showLoomaOrdersSegmentToolbar: false,
    showLoomaProductionOrders: false,
    showLoomaPurchaseOrders: false,
    showLoomaSalesOrders: true,
    showMenuAddButton: true,
    showMenuGlobalSearch: false,
    showMenuHeaderLogo: true,
    showMenuHeaderTitle: false,
    showMenuIfLoggedOut: false,
    showMenuPagesInApp: true,
    showMenuPagesInWeb: true,
    showPodcastsSuggestions: false,
    showPodcastsSuggestionsInFeed: false,
    showPostAuthorCard: false,
    showPostCardAdminFab: true,
    showPostCardExcerpt: true,
    showPostCardHostLabel: false,
    showPostCardOptions: false,
    showPostCardVerifiedBadges: false,
    showPostPagination: false,
    showPostReadInAppCard: false,
    showPostRelatedArticles: true,
    showProfileDisplayName: true,
    showProfileEvents: false,
    showProfileFriends: false,
    showProfileSocialInformation: false,
    showProfileSocialInformationLabels: false,
    showProfileUserGroup: false,
    showProfileUserGroupAsPrimaryLabel: false,
    showProfileUsername: true,
    showRegisterProjectSettings: true,
    showRegisterVideos: true,
    showSalut: false,
    showSettingsFooterLove: true,
    showSettingsLegalLinks: true,
    showSettingsLinks: true,
    showShopCategories: false,
    showShopNewestProducts: true,
    showShopRelatedProductsOnDetailPage: true,
    showShopSaleProducts: true,
    showShopSources: true,
    showShopSuggestedProducts: true,
    showShopTrendingProducts: true,
    showSuggestedProfilesOnFeedPage: false,
    showSuggestedProfilesOnHomePage: false,
    showSuggestedProfilesOnLocalPage: false,
    showTabBar: true,
    showTabBarAsDock: false,
    showTabBarInMenuOnApp: true,
    showTabBarInMenuOnWeb: true,
    showTabBarLabels: true,
    showTabMenu: false,
    sidebar: true,

    stripePublishableKey: 'pk_live_51QT7GiP37nVXc6RoNqWiI3thkPkLo9yWieogSVThrfKseC6SbhNDy3KR51PRRNQtjmUqr011y8pPsgbUBZx4CBU400669vVNQy',
    // EU: pk_live_51QT7GiP37nVXc6RoNqWiI3thkPkLo9yWieogSVThrfKseC6SbhNDy3KR51PRRNQtjmUqr011y8pPsgbUBZx4CBU400669vVNQy
    // US: pk_live_51Mk4tYJ0Lxs7JpVqE0FQ71WAGX98Jfb1WGUBPM8qtxHMPWoiLaavdiO5NezpWE1sRVqu7S07mjcNpJRiiaA9bx7X002jJlnNrC

    tabBarActiveStyle: 'solid',
    tabs: [
        /*
        {
            uid: 'shopAccount',
            checked: true,
            icon: 'person-outline',
            indent: 'shop-account',
            index: 4,
            name: 'account',
            route: 'shop-account',
            url: '/tabs/shop-account',
        },
        */
    ],
    translucentFooters: false,
    translucentHeaders: false,
    useAbonnements: true,
    useAdsExtension: true,
    useAfterEffectsExtension: true,
    useAiExtension: true,
    useAppearance: true,
    useAppleWatchExtension: false,
    useArchive: false,
    useAssetsExtension: true,
    useAuthExtension: false,
    useAvatars: false,
    useBionicReadingExtension: true,
    useBlogAdminEmployeeMotivationExtension: false,
    useBlogsExtension: false,
    useCalendar: false,
    useCategories: false,
    useCategoriesOnDiscoverPage: false,
    useCategoriesOnFeedPages: false,
    useCategoriesOnHomePage: false,
    useCategoriesOnLocalPage: false,
    useCategoriesOnSearchPage: false,
    useChatExtension: false,
    useCollectionsExtension: true,
    useCompaniesExtension: false,
    useCommentsExtension: true,
    useComplexityExtension: false,
    useControlCenterExtension: true,
    useCookiesAlertInApp: false,
    useCookiesAlertInWebApp: true,
    useCouponsExtension: true,
    useCreativesExtension: true,
    useDashboardCardsWizard: true,
    useDashboardGetGeniusSearch: true,
    useDatingExtension: false,
    useDeathAnnouncementsExtension: false,
    useDevicesExtension: true,
    useDiscover: true,
    useDocumentsExtension: false,
    useEventsList: false,
    useExperiments: false,
    useFeedback: false,
    useFeeds: false,
    useFiltersExtension: false,
    useFollow: false,
    useGetGeniusDashboardAiShortcuts: true,
    useGetGeniusExtension: true,
    useGetGeniusWalletExtension: true,
    useGoogleMapsExtension: false,
    useGroups: false,
    useHeadlineProfileAvatar: true,
    useHelpCenterExtension: false,
    useHome: false,
    useInbox: false,
    useInterests: false,
    useIntro: false,
    useIntroCards: true,
    useIntroPeople: false,
    useIntroReadingSettings: false,
    useInviteFriends: false,
    useJobPosts: false,
    useJobTitlesExtension: false,
    useLocal: false,
    useLoginDeviceDetection: true,
    useLoginDeviceSetup: true,
    useLoomaExtension: false,
    useMailExtension: true,
    useMarketplaceExtension: false,
    useMediaCreator: true,
    useMediaExtension: true,
    useMoviesExtension: false,
    useNewsletterExtension: true,
    useOrderByFilters: false,
    usePayPalExtension: false,
    usePeople: false,
    usePointsSystem: false,
    usePostPaywallExtension: false,
    usePostsAdmin: true,
    useProfile: false,
    useProfileDisplayNames: true,
    useProfileTitleExtension: false,
    useProfileUsernames: true,
    useProjectsExtension: true,
    usePushNotifications: false,
    usePushNotificationsChannels: true,
    useQuizExtension: false,
    useReactions: false,
    useRegionSearch: true,
    useRegionSearchSuggestions: false,
    useReportingExtension: true,
    useSearch: false,
    useSecurityExtension: false,
    useSettings: false,
    useSharingExtension: true,
    useShop: true,
    useShopAccount: true,
    useShopCategories: false,
    useShopInternalPaymentProcess: true,
    useShortcuts: false,
    useShowroom: true,
    useSignInWithApple: false,
    useSignInWithFacebook: false,
    useSignInWithGoogle: false,
    useSimplyLocalExtension: false,
    useSplineExtension: true,
    useStatisticsExtension: true,
    useStories: true,
    useStoriesCameraExtension: true,
    useStoriesOnDiscoverPage: true,
    useStoriesOnHomePage: false,
    useStoriesOnLocalPage: false,
    useStoriesOnPeoplePage: true,
    useSurveyExtension: false,
    useTagsExtension: true,
    useTargetGroupsExtension: true,
    useTeamsExtension: true,
    useTesting: false,
    useThemes: true,
    useThreeDeeTouch: false,
    useTransparencyExtension: false,
    useTvExtension: false,
    useUsageExtension: true,
    useVideosExtension: true,
    useWallet: true,
    useWalletEarningsExtension: false,
    useWalletPackagesExtension: true,
    useWalletSubscriptionsExtension: true,
    useWebsitesExtension: true,
    useWeclappExtension: true,
    useWeloveWalletExtension: true,
    useWhitelabelAppsExtension: true,
    useWindowManagerExtension: true,
    windowManagerAllowFullscreen: true,
    windowManagerAllowMinimize: true,
    windowManagerPosition: "start",
};