import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';
import { DaniService } from 'src/app/services/getgenius/dani.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

import { proxyUrl } from 'src/config/variables';

@Injectable({
    providedIn: 'root'
})
export class AiCoderService {

    formattingPrompt: string = `IMPORTANT:
    Your output must start with "[" end end with "]". No further output allowed.
    Your output could look like this (example for a basic HTML project): [
        {
            "icon": "folder-outline",
            "label": "src",
            "type": "folder",
            "children": [
                    {
                        "icon": "folder-outline",
                        "label": "assets",
                        "type": "folder",
                        "children": [
                                {
                                    "icon": "image-outline",
                                    "label": "icon.webp",
                                    "type": "file"
                                }
                        ]
                    },
                    {
                        "icon": "folder-outline",
                        "label": "css",
                        "type": "folder",
                        "children": [
                                {
                                "icon": "logo-css3",
                                "label": "app.css",
                                "type": "file"
                             }
                        ]
                     },
                    {
                        "icon": "folder-outline",
                        "label": "js",
                        "type": "folder",
                        "children": [
                                {
                                "icon": "logo-javascript",
                                "label": "app.js",
                                "type": "file"
                             }
                        ]
                     },
            ]
        },
        {
            "icon": "logo-html5",
            "label": "index.html",
            "type": "file"
        },
        {
            "icon": "document-outline",
            "label": "README.md",
            "type": "file"
        }
    ]`;

    proxyUrl: string;

    constructor(
        private aiBridge: AiBridgeService,

        private AppCMS: AppcmsService,
        private dani: DaniService,
        private folders: FoldersService,
        private tools: ToolsService,
    ) {
        this.proxyUrl = proxyUrl;
    }

    addProxyUrlToResources(htmlString: string, blFull: boolean = true) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const elementsWithLinks = doc.querySelectorAll('[href], [src]');

        elementsWithLinks.forEach(element => {
            // Handle href attributes
            if (element.hasAttribute('href')) {
                const originalHref: string | null = element.getAttribute('href');

                // Check if the href is an absolute URL
                if (!!originalHref && /^(http|https):\/\//i.test(originalHref)) {
                    // Avoid adding the proxy multiple times
                    if (!originalHref.startsWith(this.proxyUrl)) {
                        const proxiedHref = this.proxyUrl + encodeURIComponent(originalHref);
                        element.setAttribute('href', proxiedHref);
                    }
                }
            }

            // Handle src attributes
            if (element.hasAttribute('src')) {
                const originalSrc: string | null = element.getAttribute('src');

                // Check if the src is an absolute URL
                if (!!originalSrc && /^(http|https):\/\//i.test(originalSrc)) {
                    // Avoid adding the proxy multiple times
                    if (!originalSrc.startsWith(this.proxyUrl)) {
                        const proxiedSrc = this.proxyUrl + encodeURIComponent(originalSrc);
                        element.setAttribute('src', proxiedSrc);
                    }
                }
            }
        });

        // Serialize the Document back to an HTML string
        console.log('doc', doc);

        if (!!blFull) {
            return doc.documentElement.outerHTML;
        }

        return doc.body.innerHTML;
    }

    create(project: aiCoderProject, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
            settings: project,
        }), ['ai', 'coder', 'create']);
    }

    createFolder(folder: folder) {
        folder.location = folder.location || 'ai_coder';
        return this.folders.create(folder);
    }

    deleteFolder(folderId: number) {
        return this.folders.delete(folderId);
    }

    deleteProject(projectId: number) {
        return this.AppCMS.loadPluginData('pipeline', {
            code_project_uid: projectId,
        }, ['ai', 'coder', 'delete']);
    }

    duplicateProject(projectId: number) {
        return this.AppCMS.loadPluginData('pipeline', {
            code_project_uid: projectId,
        }, ['ai', 'coder', 'duplicate']);
    }

    executeCreateCode(options: any = {}, params: any = {}) {
        return new Promise(async (resolve, reject) => {
            let exec: any, init: any, response: any = {};

            // step 1: generate project struture
            try {
                if (!!options.init) {

                    const initPrompt: string = `Please return a json list containing a tree of a project structure (all required files) for the requested code project:
                    "${options.input || ''}"\n\n${this.formattingPrompt}`;

                    response.init = await this.aiBridge.execute({
                        context: 'ai_code_generation',
                        post_content: initPrompt
                    });

                    console.log('executeCreateCode: response.init', response.init);

                    try {
                        if (!!response.init && !!response.init.output) {
                            response.json = this.tools.extractJson(response.init.output);

                            if (typeof response.json === 'string') {
                                response.json = JSON.parse(response.json);
                            }

                            response.files = response.json || [];
                            console.log('executeCreateCode: response.files', response.files);

                            if (!!params.hasOwnProperty('onFilesChanged')) {
                                params.onFilesChanged(response.files);
                            }
                        }
                    } catch (e) {
                        console.warn('files calculation failed', e);
                    }

                }
            } catch (e) {
                reject(e);
                return false;
            }

            // step 2: generate files content
            try {
                response.exec = await this.dani.executeCreateCode(options, params);

                if (!!response.exec && !!response.exec.output) {
                    response.output = `${response.exec.output || ''}`.replaceAll('```html', '').replaceAll('```', '');
                }

                resolve(response);
            } catch (e) {
                reject(e);
                return false;
            }
        });
    }

    getCodeProjectByUid(projectId: number, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'coder', projectId]);
    }

    getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
        options.location = options.location || 'ai_coder';
        return this.folders.get(options, blForceRefresh, params);
    }

    getProjects(blForceRefresh: boolean = false, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'coder', 'projects'], {}, blForceRefresh);
    }

    removeProxyUrlToResources(htmlString: string, blFull: boolean = true) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const elementsWithLinks = doc.querySelectorAll('[href], [src]');

        elementsWithLinks.forEach(element => {

            // Handle href attributes
            if (element.hasAttribute('href')) {
                const originalHref: string | null = decodeURIComponent(element.getAttribute('href'));

                if (!!originalHref && /^(http|https):\/\//i.test(originalHref)) {
                    if (!!originalHref.startsWith(this.proxyUrl)) {
                        const unproxiedHref = decodeURIComponent(originalHref.replaceAll(this.proxyUrl, ''));
                        element.setAttribute('href', unproxiedHref);
                    }
                }
            }

            // Handle src attributes
            if (element.hasAttribute('src')) {
                const originalSrc: string | null = decodeURIComponent(element.getAttribute('src'));

                if (!!originalSrc && /^(http|https):\/\//i.test(originalSrc)) {
                    if (!!originalSrc.startsWith(this.proxyUrl)) {
                        const unproxiedSrc = decodeURIComponent(originalSrc.replaceAll(this.proxyUrl, ''));
                        element.setAttribute('src', unproxiedSrc);
                    }
                }
            }
        });

        if (!!blFull) {
            return doc.documentElement.outerHTML;
        }

        return doc.body.innerHTML;
    }

    simulate(input: string) {
        return new Promise(async (resolve, reject) => {
            try {

                let simulate: any = await this.aiBridge.execute({
                    context: 'ai_code_generation',
                    history: [
                        {
                            role: 'user',
                            content: `Simulate the code execution of the following code. Act like a real environment / runtime executing the script.
                        No further output or explainations are allowed. Never apologize. The aim is to achieve the best possible result that delights the customer`
                        },
                        {
                            role: 'user',
                            content: `IMPORTANT:
                        - If the output contains HTML, JavaScript or CSS, correct the code if necessary and output flawless HTML (starting with doctype & html tag) that can be rendered in an iFrame.
                        - If you cannot simulate the code in the provided input language, convert it to HTML(5) / CSS / JavaScript for an output that can be displayed (but make it interactive, fake / rebuild all required components / ui).
                        - If you only want to output text or logging, behave like a UNIX terminal and do not wrap your output in any additional code tags.
                        - Only return the UNIX terminal output after executing the code, not the code itself again.`,
                        },
                    ],
                    max_tokens: (32 * 1024),
                    model: 'o1-mini',
                    post_content: `${input || ''}`,
                });

                simulate = `${simulate || ''}`.replaceAll('```html', '').replaceAll('```', '');

                resolve(simulate);
            } catch (e) {
                reject(e);
            }
        });
    }

    update(project: aiCoderProject, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
            settings: project,
        }), ['ai', 'coder', 'update']);
    }

    updateFolder(folder: folder) {
        return this.folders.update(folder);
    }

}