import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  _detailItem: any | null;

  blDebug: boolean = false;

  debounceBlocks: any = {};

  debounceTimeout: number = 10;

  eventsStorage: any = {};

  constructor(
  ) {
  }

  detailItem(item: any | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  publish(key: string, data: any | null = null) {

    if (!!this.blDebug) {
      console.log('[ EVENTS ] Publish event: key: ', key);
      console.log('[ EVENTS ] Publish event: data: ', data);
    }

    if (!!this.debounceBlocks[key]) {

      if (typeof data === 'object' && typeof this.debounceBlocks[key] === 'object') {
        if (JSON.stringify(this.debounceBlocks[key]) === JSON.stringify(data)) {

          if (!!this.blDebug) {
            console.log('[ EVENTS ] Publish event: blocked: object', true);
          }

          return false;
        }
      }

      if (this.debounceBlocks[key] === data) {

        if (!!this.blDebug) {
          console.log('[ EVENTS ] Publish event: blocked: ', typeof data, true);
        }

        return false;
      }
    }

    this.debounceBlocks[key] = data;

    setTimeout(() => {
      this.debounceBlocks[key] = false;
    }, this.debounceTimeout);

    this.eventsStorage[key] = this.eventsStorage[key] || new Subject<any>();
    this.eventsStorage[key].next(data);
  }

  stop(eventsList: any[] | null = null) {

    if (!eventsList) {
      return false;
    }

    let _event: any, keys: string[] = Object.keys(eventsList);

    keys.forEach((eventKey: string) => {
      try {
        _event = eventsList[eventKey];
        _event.unsubscribe();
      } catch (e) {
        console.warn('> unsubscribing event failed', _event, e);
      }
    });
  }

  subscribe(key: string, callback: any | null = null): Subject<any> {
    this.eventsStorage[key] = this.eventsStorage[key] || new Subject<any>();
    return this.eventsStorage[key].subscribe((data: any) => {
      if (callback) {
        callback(data);
      }
    });
  }

}