import { Injectable } from '@angular/core';

import { CrawlerService } from "src/app/services/utils/crawler.service";
import { ToolsService } from 'src/app/services/utils/tools.service';

import { proxyUrl } from 'src/config/variables';

@Injectable({
    providedIn: 'root'
})
export class ApiExplorerService {

    proxyUrl: string;

    routesByIntegration: any = {

        activecampaign: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                return `https://${(params.account_name || '')}.api-us1.com/api/3/`;
            },
            headersCalculator: (options: any|null = null) => {
                let headers: any = new Headers();

                if (!options || !options.params || !options.params.api_token || !options.params.api_token.length) {
                    return headers;
                }

                headers.append('Api-Token', `${options.params.api_token || ''}`);

                return headers;
            },
            routes: [
                '/accounts',
                '/addresses',
                '/automations',
                '/brandings',
                '/campaigns',
                '/contacts',
                '/deals',
                '/messages',
                '/stages',
                '/templates',
            ],
        },

        civitai: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        cohere: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        colossyan: {
            baseUrl: 'https://app.colossyan.com/api/',
            routes: [
                '/actors',
                '/fonts',
                '/voices',
            ],
        },

        constantcontact: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
                '/account/summary',
                '/contact_lists',
                '/emails',
            ],
        },

        deepl: {
            baseUrl: 'https://api-free.deepl.com', // or https://api.deepl.com
            routes: [
            ],
        },

        elevenlabs: {
            baseUrl: 'https://api.elevenlabs.io/v1/',
            routes: [
                '/chapters',
                '/history',
                '/samples',
                '/voices',
            ],
        },

        facebookextend: {
            baseUrl: 'https://graph.facebook.com/',
            routes: [
            ],
        },

        googleextend: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        heygen: {
            baseUrl: 'https://api.heygen.com/v2/',
            routes: [
                '/avatars',
                '/templates',
                '/voices',
            ],
        },

        huggingface: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        instagram: {
            baseUrl: 'https://graph.facebook.com/',
            routes: [
            ],
        },

        linkedin: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        mailchimp: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        mailerlite: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        meta: {
            baseUrl: 'https://graph.facebook.com/',
            routes: [
            ],
        },

        openai: {
            baseUrl: 'https://api.openai.com/v1/',
            routes: [
            ],
        },

        reddit: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        runwayml: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        snapchat: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        stabilityai: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        tiktok: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        twitch: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        twitter: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        unsplash: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        vimeo: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        whatsapp: {
            baseUrl: 'https://graph.facebook.com/',
            routes: [
            ],
        },

        x: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        xing: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

        youtube: {
            baseUrl: '',
            baseUrlCalculator: (params: any|null = null) => {
                console.log('baseUrlCalculator', params);
            },
            routes: [
            ],
        },

    };

    constructor(
        private crawler: CrawlerService,
        private tools: ToolsService,
    ) {
        this.proxyUrl = proxyUrl;
    }

    calculateApiUrl(integrationName: string, params: any = {}) {
        let apiUrl: string | null = null;

        const settings: any = this.routesByIntegration[integrationName];

        if (settings.hasOwnProperty('baseUrlCalculator')) {
            const calculatedApiUrl: any = settings.baseUrlCalculator(params);

            if (!!calculatedApiUrl) {
                apiUrl = calculatedApiUrl;
            }
        } else
            if (!!settings.baseUrl) {
                apiUrl = settings.baseUrl;
            }

        return apiUrl;
    }

    calculateFetchRouteHeaders(options: any) {
        const settings: any = this.routesByIntegration[options.plugin];

        if (settings.hasOwnProperty('headersCalculator')) {
            return settings.headersCalculator(options);
        }

        return [];
    }

    fetchRoute(options: any = {}) {
        return new Promise(async (resolve, reject) => {
            if (!options.apiUrl || !options.apiUrl.length) {
                reject('error_missing_api_url');
            } else {
                const url: string = `${options.apiUrl || options.baseUrl}${options.route}`;
                const headers: any = options.headers || this.calculateFetchRouteHeaders(options);
                const method: string = (options.method || 'GET');

                try {

                    const responseText: string|null = await this.crawler.fetch(url, {
                        method: method,
                        proxy: !!options.use_proxy,
                        headers: headers,
                    }) as string|null;
                    
                    let responseJson: any|null = null;

                    try {
                        if (!!responseText && !!responseText.length) {
                            responseJson = this.tools.extractJson(responseText as string);
                        }
                    } catch (e) {
                        console.warn('parsing response to json failed', e);
                    }

                    console.log('api-explorer: responseJson', responseJson);

                    resolve({
                        headers: headers,
                        method: method,
                        //response: response,
                        response_json: responseJson,
                        response_text: responseText,
                        success: true,
                        url: url,
                    });

                } catch (e) {
                    reject(e);
                }
            }
        });
    }

    getRoutesByIntegration() {
        return this.routesByIntegration;
    }

}