import { Injectable } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
    providedIn: 'root'
})
export class BillingService {

    addressList: any[];
    addressListKey: string = 'addressList';

    billingInformation: any = {};

    deliveryKeys: any = window.localStorage.getItem('deliveryKeys') ? JSON.parse(window.localStorage.getItem('deliveryKeys')) : {};

    constructor(
        private cacheService: CacheService,
        private userService: UserService,
    ) {

    }

    addAddress(address: any) {

        if (!this.inAddressList(address)) {
            this.addressList.push(address);
        }

        return this.cacheService.set(this.addressListKey, this.addressList || []);
    }

    async getAddressList() {

        if (!this.addressList) {
            const fromCache: cacheItem | null = await this.cacheService.get(this.addressListKey, -1);
            this.addressList = (!!fromCache && !!fromCache.data ? fromCache.data : []);
        }

        return this.addressList;
    }

    getBillingInformation(data: any = {}) {
        let user = this.userService.getUser() || {};

        if (!user.firstname) {
            user.firstname = this.getDeliveryKey('first_name') || '';
        }

        if (!user.lastname) {
            user.lastname = this.getDeliveryKey('last_name') || '';
        }

        if (!user.city) {
            user.city = this.getDeliveryKey('city') || '';
        }

        if (!user.plz) {
            user.plz = this.billingInformation.plz || (this.getDeliveryKey('postcode') || '');
        }

        let _user = Object.assign({
            first_name: user.firstname || '-',
            last_name: user.lastname || '-',
            address_1: this.billingInformation.address_1 || (user.street + ' ' + user.streetNo),
            address_2: this.billingInformation.address_2 || '',
            city: this.billingInformation.city || user.city,
            postcode: user.plz || '',
            country: user.country || 'DE',
            email: user.email || '',
            phone: user.phone || '',
        }, data);

        delete _user.password;

        return _user;
    }

    getDeliveryKey(key: string) {
        return this.deliveryKeys[key] || null;
    }

    inAddressList(address: any) {
        if (this.addressList && this.addressList.length) {
            const blMatch = this.addressList.filter((_address: any) => {
                return _address.route === address.route &&
                    _address.street_number === address.street_number &&
                    _address.postal_code === address.postal_code;
            });
            return !!blMatch.length;
        }
        return false;
    }

    setBillingInformation(key: string, value: any) {
        this.billingInformation[key] = value;
    }

    setDeliveryKey(key: string, value: string) {
        this.deliveryKeys[key] = value;
        window.localStorage.setItem('deliveryKeys', JSON.stringify(this.deliveryKeys));
    }

}