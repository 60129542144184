<!-- Create code intro mode -->
<pipeline-intro-overlay *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)"
  [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions" [config]="introOverlayConfig"
  [hidden]="!!view.startManually" (onContinue)="aiCreate()" (onSkip)="startManually()"
  [(view)]="view"></pipeline-intro-overlay>

<ion-card>

  <!-- Header -->
  <ion-card-header>
    <ion-card-title [innerHTML]="'ai_coder'|translate"></ion-card-title>
  </ion-card-header>

  <!-- Segment toolbar -->
  <ion-segment [(ngModel)]="view.segment" (ionChange)="segmentChanged()">

    <!-- Code -->
    <ion-segment-button value="code">
      <ion-icon name="code-slash-outline"></ion-icon>
      <ion-label [innerHTML]="'code'|translate"></ion-label>
    </ion-segment-button>

    <!-- Preview -->
    <ion-segment-button value="preview">
      <ion-icon name="browsers-outline"></ion-icon>
      <ion-label [innerHTML]="'preview'|translate"></ion-label>
    </ion-segment-button>

  </ion-segment>

  <!-- Loading spinner -->
  <ion-spinner
    [hidden]="!view.rebuild && !view.coding && !(view.segment === 'preview' && !!view.generatingSimulation)"></ion-spinner>

  <!-- Code editor -->
  <pipeline-editor *ngIf="!view.rebuild && !view.coding" [hidden]="view.segment !== 'code'" [input]="view.code"
    (inputChange)="onEditorInputChanged($event)" [language]="view.codeLanguage || 'typescript'"
    [mode]="view.editorMode">
  </pipeline-editor>

  <!-- Code execution / preview / simulation -->
  <iframe class="code-preview" [hidden]="view.segment !== 'preview' || !!view.generatingSimulation"
    [srcdoc]="view.code_preview"></iframe>

  <ion-item lines="none">

    <!-- Copy output to clipboard -->
    <ion-button slot="end" fill="clear" color="dark" (click)="copyText()">
      <ion-icon name="clipboard-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'copy_to_clipboard'|translate" [hidden]="!view.isDesktop"></ion-label>
    </ion-button>

    <!-- Reload -->
    <ion-button slot="end" fill="clear" color="dark" icon-only (click)="refreshView()">
      <ion-icon name="sync-outline"></ion-icon>
    </ion-button>

  </ion-item>

</ion-card>