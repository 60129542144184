import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { ProjectsService } from "src/app/services/core/projects.service";
import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'pipeline-platforms-filter-card',
  standalone: false,
  templateUrl: './platforms-filter-card.component.html',
  styleUrls: ['./platforms-filter-card.component.scss']
})
export class PlatformsFilterCardComponent implements OnDestroy, OnInit {
  @Input() cards: any;
  @Input() service: any;
  @Input() view: any;

  @Output() onChanged = new EventEmitter();
  @Output() onRefresh = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  constructor(
    private events: EventsService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
  ) {

  }

  allPlatforms() {

    this.view.platforms.forEach((_platform: any) => {
      _platform.active = false;
    });

    this.view.currentPlatform = null;

    this.doRefresh()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  calcViewVars() {

  }

  cancelPlatformMode() {
    this.view.createPlatformMode = false;
  }

  createPlatform() {
    this.view.createPlatformMode = true;
  }

  detectChanges() {

  }

  async doRefresh() {
    this.onRefresh.emit();

    await this.loadPlatforms(true);
  }

  expandList(listName: string) {
    this.view.expandedList = listName;
  }

  async loadCards() {
    try {
      if (!this.cards) {
        this.cards = (await this.sidebar.getCards() || (this.cards || {}));
      }
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadPlatforms(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.view.project = await this.projects.getCurrent();

      if (!this.view.project || !this.view.project.uid) {
        this.view.loading = false;
        return false;
      } else {
        this.view.loading = true;
      }

    });
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    this.loadCards();
  }

  onPlatformItemChecked(item: any) {
    console.log('onPlatformItemChecked', item);
  }

  openPlatform(platform: any) {
    console.log('openPlatform', platform);
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.platforms = this.cards.platforms || {};
    this.cards.platforms.open = !this.cards.platforms.open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}