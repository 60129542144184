import { Component, OnInit, ViewChild } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ExportService } from 'src/app/services/utils/export.service';
import { MailCrawlerService } from "src/app/services/integrations/mail-crawler.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-mail-crawler-blacklist',
  standalone: false,
  templateUrl: './mail-crawler-blacklist.page.html',
  styleUrls: ['./mail-crawler-blacklist.page.scss'],
})
export class MailCrawlerBlacklistPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  buttons: button[] = [
    {
      handler: (item: any, event: any|null = null) => {
        this.edit(item);
      },
      icon: 'create-outline',
      label: 'edit',
      uid: 'edit',
    },
    {
      color: 'danger',
      handler: (item: any, event: any|null = null) => {
        this.delete(item);
      },
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    }
  ];

  options: dynamicItemOptions = {
    labelKey: 'name',
    showPhoto: false,
    subLabelKey: 'email',
  };

  search: searchOptions = {
    itemsKey: 'items',
    keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  selectionOptions: selectionOption[];
  
  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'mail_blacklist_top_card',
      text: 'mail_blacklist_top_card_text',
      title: 'mail_blacklist_top_card_title',
    },
    itemSize: 64,
    options: {

    },
    key: ['title', 'description', 'name', 'url', 'uid'],
    showMenuButton: true,
    title: 'mail_blacklist',
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private exportService: ExportService,
    public mail: MailCrawlerService,
    private projects: ProjectsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  add() {
    this.mail.addRule({
      rule: 'blacklist',
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  delete(item: mailRule, event: any|null = null) {
  }

  doRefresh(event: any|null = null) {
    this.loadBlacklist(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  edit(item: mailRule, event: any|null = null) {
    this.mail.editRule(item)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  export() {
    this.exportService
      .setData(this.view.connections)
      .setItemParser((item: any) => {
        return {
          uid: item.uid,
          active: item.active,
          name: item.name,
          url: item.url,
          timestamp: item.timestamp,
        };
      })
      .asCsv()
      .download();
  }

  loadBlacklist(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      this.view.items = [];

      if (!this.view.project || !this.view.project.uid) {
        resolve(this.view);
      } else {
        this.mail.getBlacklist(this.view.options, blForceRefresh)
          .then((response: any) => {

            if (response.items) {
              this.view.items = response.items;
            }

            resolve(this.view);
          })
          .catch(reject);
      }
    })
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnInit() {
    this.calcViewVars();

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });

    this.loadBlacklist()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  onSearchChanged(searchOptions: any|null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  toggleActive(connection: mailRule) {

  }

}
