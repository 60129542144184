import { Injectable } from '@angular/core';

import { BrowserService } from 'src/app/services/utils/browser.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { ModalService } from 'src/app/services/core/modal.service';

import { CookiesPage } from 'src/app/pages/core/cookies/cookies.page';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  key: string = 'cookies_accepted';

  moreUrl: string = 'https://pipeline.page/datenschutz/';

  constructor(
    private browser: BrowserService,
    private cache: CacheService,
    private modalService: ModalService,
  ) {
  }

  accept() {
    return this.cache.set(this.key, true);
  }

  async isAccepted() {
    const bl: cacheItem = await this.cache.get(this.key, 60 * 60 * 24);
    return !!(bl && bl.data && !!bl.data);
  }

  more() {
    this.browser.create(this.moreUrl);
  }

  async showCookieBanner() {
    const blAccepted = await this.isAccepted();

    if (!blAccepted) {
      const modal: any = await this.modalService.create({
        component: CookiesPage,
        componentProps: {
          cookiesService: this,
        },
        animated: true,

        presentingElement: await this.modalService.getTop(),
        cssClass: 'cookiesModal',
      });
      this.modalService.present(modal);
    }
  }

}
