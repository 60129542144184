import { Injectable } from '@angular/core';

import { AppcmsService } from "src/app/services/core/appcms.service";
//import { CsvService } from './csv.service';
import { ModalService } from 'src/app/services/core/modal.service';

import { DataExporterPage } from 'src/app/pages/core/data/data-exporter/data-exporter.page';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  private csv: any = {
    delimiter: ';',
  };

  private data: any;

  private headers: any = {
    csv: "data:text/csv;charset=utf-8,"
  };

  private columnParser: any;

  private itemParser: any;

  private output: string;

  constructor(
    private AppCMS: AppcmsService,
    //private csv: CsvService,
    private modalService: ModalService,
  ) {

  }

  asCsv() {
    let csvContent = this.headers.csv;
    let delimiter: string = this.getCsvDelimiter();
    let rows = this.getData();
    let columnParser: any = this.getColumnParser();
    let itemParser: any = this.getItemParser();

    if (rows && rows.length) {
      rows = rows.map(itemParser);

      // add header keys
      let keys: string[] = Object.keys(rows[0]);

      csvContent += keys.join(delimiter) + "\r\n";

      // add data
      rows.forEach((item: any) => {
        let values = Object.values(item).map(columnParser);
        csvContent += values.join(delimiter) + "\r\n";
      });
    }

    this.setOutput(csvContent);
    return this;
  }

  calculateExportUrl(options: any) {

    const uids: number[] = options.uids || (options.data || []).map((item: any) => {
      return item.uid || item.ID;
    });

    const params = new URLSearchParams({
      source: options.source,
      type: options.type,
      uids: uids,
    } as any);

    return `${this.AppCMS.getApiUrl()}/pipeline/export/execute.json?${params.toString()}`;
  }

  download() {
    let output: string = this.getOutput();

    if (!!output) {
      var encodedUri = encodeURI(output);
      window.open(encodedUri);
    }
  }

  downloadBase64File(base64Data: string, fileName: string, contentType: string | null = null) {
    const dataSplit = base64Data.split('base64,');

    let linkSource: string;

    if (dataSplit.length === 2) {
      contentType = dataSplit[0];
      base64Data = dataSplit[1];
      linkSource = base64Data;
    } else {
      linkSource = `data:${contentType || 'webp'};base64,${base64Data}`;
    }

    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  executeServerSideExport(options: dataExportOptions) {
    return this.AppCMS.loadPluginData('pipeline', {
      config: options,
    }, ['export', 'execute']);
  }

  exportUsingUI(options: any = {}) {
    return new Promise(async (resolve, reject) => {
      options = options || {};
      options.exportService = this;

      if((!!options && !!options.data && !!options.data[0]) && (!options.fields || !options.fields.length)) {
        options.fields = Object.keys(options.data[0]);
      }
      
      const modal: any = await this.modalService.create({
        component: DataExporterPage,
        componentProps: options,
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: 'defaultModal',
      });

      modal.onWillDismiss().then(resolve);

      this.modalService.present(modal);
    });
  }

  getCsvDelimiter() {
    return this.getCsvOption('delimiter');
  }

  getCsvOption(key: string) {
    return this.csv[key];
  }

  getData() {
    return this.data;
  }

  getFallbackColumnParser() {
    return (value: any) => {
      if (typeof value === 'object') {
        //value = JSON.stringify(value);
        value = '[KEIN ZUGRIFF]';
      }
      return value;
    }
  }

  getFallbackItemParser() {
    return (item: any) => {
      return item;
    }
  }

  getColumnParser() {
    if (!this.columnParser) {
      return this.getFallbackColumnParser();
    }
    return this.columnParser;
  }

  getItemParser() {
    if (!this.itemParser) {
      return this.getFallbackItemParser();
    }
    return this.itemParser;
  }

  getOutput() {
    return this.output;
  }

  setCsvDelimiter(delimiter: string) {
    return this.setCsvOption('delimiter', delimiter);
  }

  setCsvOption(key: string, value: string) {
    this.csv[key] = value;
    return this;
  }

  setData(data: any[]) {
    this.data = data;
    return this;
  }

  setItemParser(handler: any) {
    this.itemParser = handler;
    return this;
  }

  setOutput(output: string) {
    this.output = output;
    return this;
  }

}
