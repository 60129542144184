import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { CdkDragMove } from '@angular/cdk/drag-drop';
import { AnimationItem } from 'lottie-web';

import { EventsService } from "src/app/services/core/events.service";
import { TemplatesService } from 'src/app/services/media/templates.service';

@Component({
  selector: 'pipeline-media-template-preview-layer',
  standalone: false,
  templateUrl: './media-template-preview-layer.component.html',
  styleUrls: ['./media-template-preview-layer.component.scss'],
})
export class MediaTemplatePreviewLayerComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('layerElement', { read: ElementRef }) private layerElement: ElementRef;

  @ViewChild('dragHandleCorner', { read: ElementRef }) private dragHandleCorner: ElementRef;
  @ViewChild('dragHandleRight', { read: ElementRef }) private dragHandleRight: ElementRef;
  @ViewChild('dragHandleBottom', { read: ElementRef }) private dragHandleBottom: ElementRef;

  private lastTime: number;

  @Input() index: number;
  @Input() layer: any;
  @Input() template: mediaTemplate;
  @Input() view: any;

  private animationItem: AnimationItem;

  constructor(
    private events: EventsService,
    private templates: TemplatesService,
    private zone: NgZone,
  ) {
  }

  calcCurrentLayerStyle() {
    
  }

  calcLottieState(data: any) {

    if((this.lastTime === data.current)) {
      return false;
    }

    if(!this.layer || !this.layer.settings) {
      return false;
    }

    if(data.current === this.layer.settings.start) {
      this.startAnimation(data);
    } else
    if(data.current === this.layer.settings.end) {
      this.stopAnimation(data);
    }

    this.lastTime = data.current;
  }

  dragMove(dragHandle: string, index: number = 0, $event: CdkDragMove<any>) {
    this.view.isDragging = true;

    if(!!this.layerElement && !!this.layerElement.nativeElement) {
      var element: HTMLElement|null = this.layerElement.nativeElement.querySelector(`.${dragHandle}`);
      
      if(!!element) {
        this.resize(element as HTMLElement, this.layerElement.nativeElement as HTMLElement, index);
      }
    }
  }

  initEvents() {
    this.view.events = {};
    
    this.view.events.mediaTemplatePreviewTimelineCurrentUpdated = this.events.subscribe('media:template:preview:timeline:current:updated', (data: any) => {
      let template = (!!data && !!data.template ? data.template : null);

      if(!!this.layer && (this.layer.type === 'lottie') && this.templates.isCurrent(template, this.template)) {
        this.calcLottieState(data);
      }
    });

    this.view.events.mediaTemplatePreviewRun = this.events.subscribe('media:template:preview:run', (data: any) => {
      let template = (!!data && !!data.template ? data.template : null);

      if(this.templates.isCurrent(template, this.template)) {
        this.startAnimation();
      }
    });

    this.view.events.mediaTemplatePreviewStop = this.events.subscribe('media:template:preview:stop', () => {
      this.stopAnimation();
    });

    this.view.events.templatePreviewLayerEffectChanged = this.events.subscribe('template:preview:layer:effect:changed', (data: any) => {
      if(!!data.layer && !!data.layer.settings && !!this.layer.settings && !!this.layer.settings.id && (this.layer.settings.id === data.layer.settings.id)) {
        this.view.running = true;
      }
    });

  }

  lottieAnimationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;

    if(!!this.layer && !!this.layer.settings && !this.layer.settings.autoplay) {
      this.stopAnimation();
    }
  }
  
  lottieConfigReady() {

  }

  lottieDataReady() {

  }

  lottieDestroy(event: any|null = null) {
    //console.warn('destroy', event);
  }

  lottieDomLoaded() {

  }

  lottieError(event: any|null = null) {
    console.warn('error', event);
  }

  lottieUpdateAnimation(): void {
    /*
    this.options = {
      ...this.options,
      path: this.options.render_path,
    };
    */
  }

  ngAfterViewInit() {
    this.setAllHandleTransform();
  }
  
  ngOnDestroy() {
    if(!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }
  
  ngOnInit() {
    this.view.index = this.index;
    
    this.initEvents();
  }

  onTimelineLayerClick(layer: any, i: number) {
    if(!this.view.isDragging) {
      layer.active = true;
      this.layer.active = true;
      
      this.events.publish('media:template:preview:layer:clicked', {
        layer: layer,
        index: i,
      });
      
    }
  }
  
  resize(dragHandle: HTMLElement, target: HTMLElement, index: number) {
    const dragRect = dragHandle.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    const width = dragRect.left - targetRect.left + dragRect.width;
    const height = dragRect.top - targetRect.top + dragRect.height;

    let iHeight: number = (100 / dragRect.y),
        iWidth: number = (100 / dragRect.x),
        x: any = (iHeight * height),
        y: any = (iWidth * width);
    
    this.layer.settings.height = x;
    this.layer.settings.width = y;

    /*
    this.events.publish('media:template:preview:layer:resized', {
      layer: this.layer,
      index: index,
    });
    */

    this.setAllHandleTransform();
  }
  
  setAllHandleTransform(index: number = 0) {
    if(!!this.layerElement.nativeElement) {
      const rect = this.layerElement.nativeElement.getBoundingClientRect();

      this.setHandleTransform(this.dragHandleCorner.nativeElement, rect, 'both');
      this.setHandleTransform(this.dragHandleRight.nativeElement, rect, 'x');
      this.setHandleTransform(this.dragHandleBottom.nativeElement, rect, 'y');
    }
  }

  setHandleTransform(
    dragHandle: HTMLElement,
    targetRect: ClientRect | DOMRect,
    position: 'x' | 'y' | 'both'
  ) {
    const dragRect = dragHandle.getBoundingClientRect();
    const translateX = targetRect.width - dragRect.width;
    const translateY = targetRect.height - dragRect.height;

    if (position === 'x') {
      dragHandle.style.transform = `translate3d(${translateX}px, 0, 0)`;
    }

    if (position === 'y') {
      dragHandle.style.transform = `translate3d(0, ${translateY}px, 0)`;
    }

    if (position === 'both') {
      dragHandle.style.transform = `translate3d(${translateX}px, ${translateY}px, 0)`;
    }
  }

  startAnimation(data: any = {}) {
    try {
      
      if((this.layer.type !== 'lottie') || !this.animationItem) {
        return false;
      }

      this.zone.runOutsideAngular(() => {
        this.animationItem.play();
      });
      
    } catch(e) {
      console.warn('starting lottie failed', e);
    }
  }

  stopAnimation(data: any = {}) {
    try {
      
      if(!this.layer || !this.layer.type || (this.layer.type !== 'lottie') || !this.animationItem) {
        return false;
      }

      this.zone.runOutsideAngular(() => {
        this.animationItem.stop();
      });

    } catch(e) {
      console.warn('stopping lottie failed', e);
    }
  }

}
