import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { ViewService } from "src/app/services/core/view.service";

@Component({
  selector: 'pipeline-intro-overlay',
  standalone: false,
  templateUrl: './intro-overlay.component.html',
  styleUrls: ['./intro-overlay.component.scss'],
})
export class IntroOverlayComponent implements OnInit {

  @Input() aiSettings: aiSettings = {};

  @Input() aiSettingsOptions: aiSettingsOptions = {
    operations: ['text_generation'],
  };

  appConfig: pipelineAppConfig;

  @Input() config: introOverlayConfig;

  @Input() view: any;

  @Output() onAiSettingsChanged = new EventEmitter();
  @Output() onContinue = new EventEmitter();
  @Output() onInput = new EventEmitter();
  @Output() onIntegrationChanged = new EventEmitter();
  @Output() onSkip = new EventEmitter();
  @Output() onSourceChanged = new EventEmitter();

  constructor(
    private configService: ConfigService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  _onIntegrationChanged(event: any = null) {
    this.onIntegrationChanged.emit(event);
  }

  _onSourceChanged(event: any = null) {
    this.onSourceChanged.emit(event);
  }

  aiCreate() {
    this.onContinue.emit();
  }

  aiSettingsChanged(event: any = null) {
    this.onAiSettingsChanged.emit(event);
  }

  async calcHidden() {
    //this.view.hidden = await this.introService.isIntroOverlayHidden(this.view.uid);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  destroy(event: any | null = null) {
    console.warn('destroy', event);
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.view.hidden = true;
  }

  ngOnInit() {
    this.calcHidden();
    this.calcViewVars();
  }

  @HostListener('window:resize')
  onResize() {
    this.calcViewVars();
  }

  startManually() {
    this.onSkip.emit();
  }

}