import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CreatorsService } from 'src/app/services/pipeline/creators.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class ShopAdminService {

  _detailItem: source;

  systems: string[] = ['csv', 'custom', 'json', 'rss', 'shopify', 'shopware', 'web', 'woocommerce', 'xml'];

  constructor(
    private AppCMS: AppcmsService,
    private creators: CreatorsService,
    private userService: UserService,
  ) {

  }

  acceptProduct(product: product) {
    return this.AppCMS.loadPluginData('pipeline', {
      product: product,
    }, ['shop', 'products', 'accept']);
  }

  deleteSource(source: shopSource) {
    return this.AppCMS.loadPluginData('pipeline', {
      source: source.uid,
    }, ['shop', 'sources', 'delete']);
  }

  detailItem(item: source | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  exportSourceData(sourceId: any) {
    console.log('exportSourceData', sourceId);

    return this.AppCMS.loadPluginData('pipeline', {
      source_uid: sourceId,
    }, ['shop', 'export']);
  }

  get(options: any = {}) {
    return this.creators.getShops(options);
  }

  getAll(options: any = {}) {
    return this.get(options);
  }

  getSystems() {
    return this.systems;
  }

  importSourceData(sourceId: any) {
    console.log('importSourceData', sourceId);

    return this.AppCMS.loadPluginData('pipeline', {
      source_uid: sourceId,
    }, ['shop', 'import']);
  }

  updateSource(source: shopSource) {
    source = JSON.parse(JSON.stringify(source));
    delete source.configured;

    return this.AppCMS.loadPluginData('pipeline', {
      source: source,
      user: this.userService.getUid()
    }, ['shop', 'sources', source.uid, 'update']);
  }

}