import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { AlertService } from 'src/app/services/core/alert.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ExportService } from 'src/app/services/utils/export.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { ImporterService } from 'src/app/services/utils/importer.service';
import { SetupService } from 'src/app/services/utils/setup.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';
import { WindowManagerService } from 'src/app/services/core/window-manager.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';
import { SelectionOptionsPickerComponent } from 'src/app/components/generic/selection/selection-options-picker/selection-options-picker.component';

import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'app-ai-plans',
  standalone: false,
  templateUrl: './ai-plans.page.html',
  styleUrls: ['./ai-plans.page.scss'],
})
export class AiPlansPage implements OnDestroy, OnInit {

  headerOptions: selectionOption[] = [
    {
      icon: 'cloud-download-outline',
      label: 'export',
      uid: 'export',
    },
    {
      icon: 'cloud-upload-outline',
      label: 'import',
      uid: 'import',
    },
  ];

  @ViewChild('headerPopover') headerPopover;

  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  introCard: introCardConfig = {
    uid: 'ai_plans_top_card',
    text: 'ai_plans_top_card_text',
    title: 'ai_plans_top_card_title',
  };

  isHeaderPopoverOpen: boolean = false;

  appConfig: pipelineAppConfig;

  calendarOptions: calendarOptions = {
    showPagination: true,
    showSelectionToolbar: true,
    showTitle: true,
    showViewModes: true,
    viewModes: ["day", "week", "month"],
  };

  cards: any = {};

  cta: any = {
    handler: () => {
      this.add();
    },
    icon: 'add-outline',
    label: 'create',
  };

  fallbackImg: string = './assets/img/fallback.webp';

  search: searchOptions = {
    itemsKey: 'plans',
    keys: ['title', 'description', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    },
    {
      color: 'warning',
      icon: 'play-outline',
      label: 'execute',
      uid: 'execute',
    },
    {
      icon: 'folder-outline',
      label: 'move_folder',
      uid: 'move_folder',
    },
    {
      icon: 'briefcase-outline',
      label: 'move_project',
      uid: 'move_project',
    },
    {
      color: 'warning',
      icon: 'sync-outline',
      label: 'recreate',
      uid: 'recreate',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  @ViewChild(SelectionOptionsPickerComponent) selectionOptionsPicker: any;

  state: state = {};

  view: any = {
    colSize: {},
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    itemsKey: 'plans',
    multiple: true,
    output: '',
    queue: [],
    route: 'ai/plans',
    key: ['title', 'description', 'name', 'url', 'uid'],
    segment: 'plans',
    showLanguagesSelect: true,
    showMenuButton: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'campaigns',
    viewType: 'grid',
    viewTypes: [
      { icon: 'list', label: 'list', uid: 'list', expertMode: false },
      { icon: 'grid', label: 'grid', uid: 'grid', expertMode: false },
    ],
  };

  constructor(
    public ai: AiToolsService,
    private alertService: AlertService,
    private configService: ConfigService,
    private events: EventsService,
    private exportService: ExportService,
    private folders: FoldersService,
    private importService: ImporterService,
    private projects: ProjectsService,
    private setupService: SetupService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private viewService: ViewService,
    private windowManager: WindowManagerService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  async add(event: any | null = null) {
    this.windowManager
      .open({
        route: '/ai/planner',
        uid: 'ai_planner',
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async aiCreate() {

    if (!this.view.project || !this.view.project.uid || (!!this.view.plans && (this.view.plans.length > 1))) {
      return false;
    }

    const items: any[] = (this.view.plans || []);

    try {
      this.view.plans = items.concat([{}, {}, {}, {}, {}, {}]);

      const create: any = await this.setupService.setupCampaigns({ project: this.view.project });
      console.log('ai-plans: aiCreate: response', create);

      if (!!create && !!create.data) {
        this.view.plans = items.concat(create.data || []);
      }
    } catch (e) {
      this.view.plans = items;
      console.warn('ai-plans: aiCreate failed:', e);
    }
  }

  async askDeletePlanContent(idea: aiPlan) {
    return new Promise((resolve, reject) => {
      try {
        this.alertService.requestConfirm({
          message: 'confirm_question_delete_ai_plan_content',
        })
          .then((bl: boolean) => {
            resolve(bl);
          })
          .catch(() => {
            resolve(false);
          });
      } catch (e) {
        resolve(false);
      }
    });
  }

  calcColSize() {
    this.view.isDesktop = this.tools.isDesktop();

    this.view.colSize = {
      left: this.view.sidebarSize || (window.innerWidth > 768 ? 3 : 12),
      item: (this.view.viewType === 'grid' ? (this.view.isDesktop ? (this.view.expertMode && !this.view.isUltraWide ? 4 : this.view.isUltraWide ? 2 : 3) : 6) : 12),
      right: (!!this.view.sidebarSize ? (12 - this.view.sidebarSize) : (window.innerWidth > 768 ? 9 : 12)),
    };
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.calcColSize();
  }

  async delete(idea: aiPlan, blAsk: boolean = true) {
    if (!!blAsk) {
      this.alertService.requestConfirm()
        .then((bl: boolean) => {
          if (!!bl) {
            return this.submitDelete(idea);
          }
        })
        .catch((e: any) => {
          console.warn('not deleting: ', e);
        });
    } else {
      return this.submitDelete(idea);
    }
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    new Promise((resolve, reject) => {
      let i: number = 0;

      this.view.selectedItems.forEach((item: any) => {
        if (!!item.uid) {
          this.delete(item, false)
            .then(() => {
              i++;
              if (i === (this.view.selectedItems.length)) {
                resolve(true);
              }
            })
            .catch(() => {
              i++;
              if (i === (this.view.selectedItems.length)) {
                resolve(true);
              }
            });
        } else {
          i++;
          if (i === (this.view.selectedItems.length)) {
            resolve(true);
          }
        }
      });
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  async doRefresh(event: any | null = null) {
    this.loadPlans(true)
      .then(async () => {
        if (!!event) {
          event.target.complete();
        }

        await this.runSearch();

        return true;
      })
      .catch((error: any) => {

        if (!!event) {
          event.target.complete();
        }

        this.events.publish('error', error);

        return false;
      });
  }

  duplicate(item: any | null = null) {
    this.ai.duplicatePlan(item)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  duplicateSelected(event: any | null = null) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'duplicatePlan',
      identifier: 'uid',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
      },
      service: this.ai,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async export(event: any | null = null, options: any = {}) {
    this.isHeaderPopoverOpen = false;

    this.exportService.exportUsingUI({
      data: (this.view.plans || []),
      service: this.ai,
      source_tool: 'ai_plans',
      source_type: 'tool',
    })
      .then((response: any) => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
  }

  importPlans(event: any | null = null) {
    this.isHeaderPopoverOpen = false;

    const params: dataImportOptions = {
      service: this.ai,
      target_tool: 'ai_plans',
      target_type: 'tool',
    };

    this.importService.importUsingUI(params)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });
  }

  ionViewWillEnter() {
    this.ai.detailItem({} as any);

    this.loadPlans(true)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.calcColSize();
      this.calcViewVars();
    });
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadPlans(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.view.loading = true;
      this.view.project = await this.projects.getCurrent();

      if (!this.view.project || !this.view.project.uid) {
        this.view.loading = false;
        this.view.plans = [];

        resolve(this.view);
        return false;
      }

      this.ai.getPlans(blForceRefresh)
        .then((plans: aiPlan[]) => {
          this.view.loading = false;

          this.view.plans = (plans || []);
          delete this.view.plans_backup;

          this.aiCreate();
          this.calcColSize();
          this.runSearch();

          resolve(plans);
        })
        .catch((error: any) => {
          this.view.loading = false;
          reject(error);
        });
    });
  }

  loadPlansByFolder(event: any | null = null) {
    if (!event) {
      this.doRefresh();
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadQueue(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {

      if (!!this.view.plans && !!this.view.plans.length) {
        let entries: any[] = [];

        this.view.plans.forEach((plan: any) => {
          if (!!plan && !!plan.entries && !!plan.entries.length) {
            entries = entries.concat(plan.entries);
          }
        });

        this.view.queue = entries;
      }

    });
  }

  moveFolder(item: any) {
    this.folders.moveFolder([item], 'plans')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedFolders() {
    this.folders.moveFolder(this.view.selectedItems, 'plans')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedProjects() {

  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcColSize();
    this.calcViewVars();

    this.initEvents();

    this.loadCards();
    this.loadProject();
  }

  onConnectionsFilterChanged(connections: any[] | null = []) {
    this.view.options = this.view.options || {};
    this.view.options.filters = this.view.options.filters || {};
    this.view.options.filters.connections = (connections || []).map((connection: integrationConnection) => {
      return connection.uid;
    });

    this.doRefresh();
  }

  onFolderLoaded(items: any[] | null = null) {
    console.log('onFolderLoaded', items);
  }

  onItemChecked(plan: any) {

    this.view.selectedItems = this.view.plans.filter((_plan: any) => {
      return _plan.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  async onSelectedTagChanged(event: any | null = null) {
    this.view.tag = event;
    //await this.runSearch();
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
      case 'move_folder':
        this.moveSelectedFolders();
        break;
      case 'move_project':
        this.moveSelectedProjects();
        break;
    }
  }

  presentPopover(e: Event, item: any) {
    this.selectionOptionsPicker.show({
      event: e,
      item: item,
    });
  }

  async runItemSelectionOption(event: any) {
    try {

      if (!event || !event.option || !event.option.uid) {
        return false;
      }

      if (typeof this[event.option.uid] === 'function') {
        const exec: any = await this[event.option.uid](event.item);
      }

      this.selectionOptionsPicker.close();
    } catch (e) {
      console.warn('executing single selection on item failed', e);
      this.events.publish('error', e);
    }
  }

  async runSearch() {
    try {
      await this.searchToolbar.runSearch();
      return true;
    } catch (e) {
      console.error('firing toolbar search failed', e);
      return false;
    }
  }

  segmentChanged() {
    switch (this.view.segment) {
      case 'queue':
        this.loadQueue()
          .catch((error: any) => {
            this.events.publish('error', error);
          });
        break;
    }
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  async submitDelete(idea: aiPlan) {

    const askDeleteContent: any = await this.askDeletePlanContent(idea);

    return new Promise((resolve, reject) => {
      this.ai.deletePlan(idea, {
        delete_content: !!askDeleteContent,
      })
        .then(async (response: any) => {
          await this.doRefresh();
          resolve(response);
        })
        .catch(reject);
    });
  }

  thumbnailLoadingFailed(plan: aiPlan) {
    plan.photo = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  use(plan: aiPlan) {

    if (!plan.uid) {
      return false;
    }

    this.ai.detailItem(plan);

    const route: string = `/campaigns/${plan.uid}`;

    //this.navCtrl.navigateForward(route);

    this.windowManager
      .open({
        route: route,
        uid: 'ai_planner',
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}