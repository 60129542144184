import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'pipeline-no-entries-card',
  standalone: false,
  templateUrl: './no-entries-card.component.html',
  styleUrls: ['./no-entries-card.component.scss'],
})
export class NoEntriesCardComponent implements OnInit {
  @Input('cta') cta: any = {};

  constructor(
    private navCtrl: NavController,
  ) {
  }

  ctaClick() {

    if (!this.cta || !this.cta.url) {
      return false;
    }

    this.navCtrl.navigateForward(this.cta.url);
  }

  ngOnInit() { }

}