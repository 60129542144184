import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { AiStoreService } from '../../../../services/ai/ai-store.service';
import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { AiWorkerService } from 'src/app/services/ai/ai-worker.service';

import { AlertService } from 'src/app/services/core/alert.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from "src/app/services/core/events.service";
import { ExportService } from 'src/app/services/utils/export.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { ImporterService } from 'src/app/services/utils/importer.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ModalService } from "src/app/services/core/modal.service";
import { SetupService } from 'src/app/services/utils/setup.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ViewService } from 'src/app/services/core/view.service';
import { WindowManagerService } from 'src/app/services/core/window-manager.service';

import { AiStorePage } from '../ai-store/ai-store.page';
import { AiTaskBenchmarkPage } from '../ai-task-benchmark/ai-task-benchmark.page';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';
import { SelectionOptionsPickerComponent } from 'src/app/components/generic/selection/selection-options-picker/selection-options-picker.component';

import { proxyUrl } from 'src/config/variables';

@Component({
  selector: 'app-ai-tasks',
  standalone: false,
  templateUrl: './ai-tasks.page.html',
  styleUrls: ['./ai-tasks.page.scss'],
})
export class AiTasksPage implements OnDestroy, OnInit {

  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;
  @ViewChild('headerPopover') headerPopover;

  isHeaderPopoverOpen: boolean = false;

  @ViewChild('importPopover') importPopover: any;

  isImportPopoverOpen = false;

  appConfig: pipelineAppConfig;

  cards: any = {
    folders: { open: true },
  };

  cta: any = {
    handler: () => {
      this.add();
    },
    icon: 'add-outline',
    label: 'create',
  };

  headerOptions: selectionOption[] = [
    {
      icon: 'cloud-download-outline',
      label: 'export',
      uid: 'export',
    },
    {
      icon: 'cloud-upload-outline',
      label: 'import',
      uid: 'import',
    },
  ];
  
  introCard: introCardConfig = {
    uid: 'ai_tasks_top_card',
    text: 'ai_tasks_top_card_text',
    title: 'ai_tasks_top_card_title',
  };

  paginationConfig: paginationConfig = {
    itemsKey: 'tasks',
    limit: 500,
  };

  proxyUrl: string;

  search: searchOptions = {
    itemsKey: 'tasks',
    keys: ['name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    },
    {
      icon: 'stats-chart-outline',
      label: 'create_benchmark',
      uid: 'create_benchmark',
    },
    {
      icon: 'sparkles-outline',
      label: 'optimize',
      uid: 'optimize',
    },
    {
      icon: 'folder-outline',
      label: 'move_folder',
      uid: 'move_folder',
    },
    {
      icon: 'briefcase-outline',
      label: 'move_project',
      uid: 'move_project',
    },
    {
      color: 'warning',
      icon: 'play-outline',
      label: 'execute',
      uid: 'execute',
    },
    {
      allowedUserTypes: ['Admin', 'Moderator'],
      color: 'warning',
      icon: 'cloud-upload-outline',
      label: 'publish_to_store',
      uid: 'publish_to_store',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  @ViewChild(SelectionOptionsPickerComponent) selectionOptionsPicker: any;

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    itemsKey: 'tasks',
    multiple: true,
    output: '',
    route: 'ai/tasks',
    showMenuButton: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'ai_tasks',
    viewType: 'grid',
    viewTypes: [
      { icon: 'list', label: 'list', uid: 'list', expertMode: false },
      { icon: 'grid', label: 'grid', uid: 'grid', expertMode: false },
    ],
  };

  constructor(
    private aiStore: AiStoreService,
    public aiTools: AiToolsService,

    private aiWorker: AiWorkerService,
    private alertService: AlertService,
    private configService: ConfigService,
    private events: EventsService,
    private exportService: ExportService,
    private folders: FoldersService,
    private importService: ImporterService,
    private mediaService: MediaextendService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private setupService: SetupService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private viewService: ViewService,
    private windowManager: WindowManagerService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.proxyUrl = proxyUrl;
  }

  async aiCreate() {

    if (!this.view.project || !this.view.project.uid || (!!this.view.tasks && (this.view.tasks.length > 1))) {
      return false;
    }

    const items: any[] = (this.view.tasks || []);

    try {
      this.view.tasks = items.concat([{}, {}, {}, {}, {}]);

      const create: any = await this.setupService.setupAiTasks({ project: this.view.project });
      console.log('ai-tasks: aiCreate: response', create);

      if (!!create && !!create.data) {
        this.view.tasks = items.concat(create.data || []);
      }
    } catch (e) {
      this.view.tasks = items;
      console.warn('ai-tasks: aiCreate failed:', e);
    }
  }

  add(event: any | null = null) {
    this.aiTools.quick();
  }

  async benchmarkSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    const modal: any = await this.modalService.create({
      component: AiTaskBenchmarkPage,
      componentProps: {
        tasks: this.view.selectedItems,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.colSize = {
      left: this.view.sidebarSize || (!!this.view.isDesktop ? 3 : 12),
      item: (this.view.viewType === 'grid' ? (this.view.isDesktop ? (this.view.expertMode && !this.view.isUltraWide ? 4 : this.view.isUltraWide ? 2 : 3) : 6) : 12),
      right: (!!this.view.isUltraWide ? 10 : (window.innerWidth > 768 ? 9 : 12)),
    };

    //this.view.showViewModeSelect = !!this.view.isDesktop;
  }
  
  comfyUI(event: any|null = null, params: any = {}) {
    params.mediaType = 'asset';
    params.multiple = true;
    params.services = ['upload'];

    if (!!this.view.folder_uid) {
      params.folder_uid = this.view.folder_uid;
    }

    this.mediaService.applyFromWeb(null, params)
      .then(async (response: any) => {
        try {
          if (!!response && !!response.items && !!response.items.length) {
            response.items.forEach((item: any) => {
              this.importComfyUIWorkflow(item);
            });
          }
        } catch (e) {
          this.events.publish('error', e);
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  async create_benchmark(item: any | null = null) {

    const modal: any = await this.modalService.create({
      component: AiTaskBenchmarkPage,
      componentProps: {
        tasks: [item],
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  deleteSavedTask(task: aiTask, blAsk: boolean = true) {
    if (!!blAsk) {
      this.alertService.requestConfirm()
        .then((bl: boolean) => {
          if (!!bl) {
            return this.submitDelete(task);
          }
        })
        .catch((e: any) => {
          console.warn('not deleting: ', e);
        });
    } else {
      return this.submitDelete(task);
    }
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    new Promise((resolve, reject) => {
      let i: number = 0;

      this.view.selectedItems.forEach((item: any) => {
        if (!!item.uid) {
          this.deleteSavedTask(item, false)
            .then(() => {
              i++;
              if (i === (this.view.selectedItems.length)) {
                resolve(true);
              }
            })
            .catch(() => {
              i++;
              if (i === (this.view.selectedItems.length)) {
                resolve(true);
              }
            });
        } else {
          i++;
          if (i === (this.view.selectedItems.length)) {
            resolve(true);
          }
        }
      });
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  doRefresh(event: any | null = null) {
    this.loadTasks(true)
      .then(() => {

        if (!!event) {
          event.target.complete();
        }

      })
      .catch((error: any) => {

        if (!!event) {
          event.target.complete();
        }

        if (!!error) {
          this.events.publish('error', error);
        }

      });
  }

  duplicate(item: any | null = null) {
    console.log('duplicate single: item', item);
    console.log('duplicate single: view', this.view);

    this.aiTools.duplicateTask(item.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  duplicateSelected(event: any | null = null) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'duplicateTask',
      identifier: 'uid',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
      },
      service: this.aiTools,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  edit(task: aiTask) {
    this.aiTools.quick(task);
  }

  editTaskCronSettings(task: aiTask) {
    this.aiTools.editTaskCronSettings(task);
  }

  async export(event: any | null = null, options: any = {}) {
    this.isHeaderPopoverOpen = false;

    this.exportService.exportUsingUI({
      data: (this.view.posts || []),
      service: this.aiTools,
      source_tool: 'ai_tasks',
      source_type: 'tool',
    })
      .then((response: any) => {
        console.log('export response', response);

        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
  }

  async importComfyUIWorkflow(item: any) {
    if (!!item && !!item.guid) {

      const json: string = await fetch(this.proxyUrl + item.guid).then((response: any) => response.json()),
        keys: string[] = Object.keys(json);

      const task: aiTask = {
        active: true,
        children: [],
        name: `${item.title || 'comfyui_import'}`.replace('.json', ''),
      };

      keys.forEach((key: string) => {

        const child = json[key],
          inputs: any[] = [], inputKeys: string[] = Object.keys(child.inputs);

        inputKeys.forEach((inputKey: string) => {
          inputs.push({
            name: inputKey,
            uid: inputKey,
            value: child.inputs[inputKey],
          });
        });

        task.children.push({
          name: child._meta.title,
          input: '',
          inputs: inputs,
          output: '',
          promptType: 'function',
          type: 'custom',
        });
      });

      this.aiTools.addTask(task)
        .then(() => {
          this.doRefresh();
        })
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    }
  }

  importTask(e: any | null = null) {
    this.importPopover.event = e;
    this.isImportPopoverOpen = true;

    if (!!e) {
      e.target.complete();
    }
  }

  importUsingUI(event: any|null = null) {
    this.isImportPopoverOpen = false;

    const params: dataImportOptions = {
      service: this.aiTools,
      target_tool: 'ai_tasks',
      target_type: 'tool',
    };

    this.importService.importUsingUI(params)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);

      this.view.colSize = {
        left: this.view.sidebarSize || (!!this.view.isDesktop ? 3 : 12),
        item: (this.view.viewType === 'grid' ? (this.view.isDesktop ? (this.view.expertMode && !this.view.isUltraWide ? 4 : this.view.isUltraWide ? 2 : 3) : 6) : 12),
        right: (!!this.view.isUltraWide ? 10 : (window.innerWidth > 768 ? 9 : 12)),
      };
    });
  }

  ionViewWillEnter() {
    this.initEvents();

    this.loadTasks()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
    this.calcViewVars();
  }

  loadTasks(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        this.view.tasks = [];
        resolve(this.view.tasks);
      } else {
        this.view.loading = true;

        this.aiTools.getTasks(blForceRefresh)
          .then((tasks: aiTask[]) => {
            this.view.loading = false;
            this.view.tasks = tasks;

            this.aiCreate();

            setTimeout(() => {
              this.calcViewVars();
            });

            resolve(tasks);
          })
          .catch((error: any) => {
            this.view.loading = false;
            reject(error);
          });
      }
    });
  }

  loadTasksByFolder(event: any | null = null) {
    if (!event) {
      this.doRefresh();
    }
  }

  moveFolder(item: any) {
    this.folders.moveFolder([item], 'tasks')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedFolders() {
    this.folders.moveFolder(this.view.selectedItems, 'tasks')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedProjects() {

  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.loadCards();
  }

  onConnectionsFilterChanged(connections: any[] | null = []) {
    this.view.options = this.view.options || {};
    this.view.options.filters = this.view.options.filters || {};
    this.view.options.filters.connections = (connections || []).map((connection: integrationConnection) => {
      return connection.uid;
    });

    console.log('this.view.options.filters.connections', this.view.options.filters.connections);
    this.doRefresh();
  }

  onFolderLoaded(items: any[] | null = null) {
    console.log('onFolderLoaded', items);
  }

  onItemChecked(plan: any) {

    this.view.selectedItems = this.view.tasks.filter((_plan: any) => {
      return _plan.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'create_benchmark':
        this.benchmarkSelected();
        break;
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
      case 'move_folder':
        this.moveSelectedFolders();
        break;
      case 'move_project':
        this.moveSelectedProjects();
        break;
      case 'publish_to_store':
        this.publishSelectedToStore();
        break;
    }
  }

  optimize() {
    console.log('optimize: view', this.view);
  }

  presentPopover(e: Event, item: any) {
    this.selectionOptionsPicker.show({
      event: e,
      item: item,
    });
  }

  async publish_to_store(item: any) {
    try {
      await this.aiStore.publishTask(item);
      this.store();
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  publishSelectedToStore() {
    console.log('publishSelectedToStore: view', this.view);

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    console.log('publishSelectedToStore: view.selectedItems', this.view.selectedItems);
  }

  async runItemSelectionOption(event: any) {
    try {

      if (!event || !event.option || !event.option.uid) {
        return false;
      }

      if (typeof this[event.option.uid] === 'function') {
        const exec: any = await this[event.option.uid](event.item);
      }

      this.selectionOptionsPicker.close();
    } catch (e) {
      console.warn('executing single selection on item failed', e);
      this.events.publish('error', e);
    }
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  runSet(task: aiTask) {

    // set project uid property to task to keep it even if project uid changes
    task.project_uid = parseInt(`${(!!this.view.project && !!this.view.project.uid ? this.view.project.uid : 0)}`);

    this.aiWorker.validateSet(task)
      .then(() => {
        this.aiWorker.setCurrentTask(task);
        this.aiWorker.setCurrentProject(this.view.project);

        task.running = true;

        this.aiWorker.executeAITaskSet()
          .catch((error: any) => {
            task.running = false;
            this.events.publish('error', error);
          });

      })
      .catch((error: any) => {
        task.loading = false;
        this.events.publish('error', error);
      });
  }

  shortcodes(event: any | null = null) {

    this.windowManager
      .open({
        route: '/ai/tools',
        uid: 'ai_tools',
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });

    if (!!event) {
      event.target.complete();
    }
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  stopSet(task: aiTask) {
    this.aiWorker.stopSet(this.view.setHandler)
      .then(() => {
        this.view.running = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async store(event: any|null = null) {

    const modal: any = await this.modalService.create({
      component: AiStorePage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  submitDelete(task: aiTask) {
    return new Promise((resolve, reject) => {
      this.aiTools.deleteTask(task)
        .then((response: any) => {
          this.loadTasks(true);
          resolve(response);
        })
        .catch(reject);
    });
  }

  toggleActive(task: aiTask) {
    task.active = !task.active;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}