<ion-content *ngIf="!!config">

  <!-- Loading spinner -->
  <ion-spinner [hidden]="config.progress === 100 || !!config.icon"></ion-spinner>

  <!-- Icon -->
  <ion-icon [name]="config.icon || 'checkmark-outline'" [hidden]="!config.icon && (config.progress !== 100)"></ion-icon>

  <!-- Headline -->
  <h3 *ngIf="!!config.headline" [innerHTML]="config.headline|translate"></h3>

  <!-- Text -->
  <p *ngIf="!!config.text" [innerHTML]="config.text|translate"></p>

</ion-content>