import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { AlertService } from 'src/app/services/core/alert.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'pipeline-dynamic-list',
  standalone: false,
  templateUrl: './dynamic-list.component.html',
  styleUrls: ['./dynamic-list.component.scss']
})
export class DynamicListComponent implements OnInit {
  @Input() buttons: button[];
  @Input() lines: string = 'none';
  @Input() method: string;
  @Input() options: dynamicListOptions;
  @Input() paginationConfig: paginationConfig = {};
  @Input() selectionOptions: selectionOption[];
  @Input() service: any;
  @Input() view: any;
  @Input() items: any[];

  @Output() itemsChanged = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  constructor(
    private alertService: AlertService,
    private events: EventsService,
    private viewService: ViewService,
  ) {

  }

  calcViewVars() {
    const viewType: string | null = (!!this.view && !!this.view.viewType ? this.view.viewType : null);
    this.view = this.viewService.calcVars(this.view);
    this.view.viewType = (viewType || this.view.viewType) || 'list';
  }

  doRefresh(event: any | null = null) {
    try {
      this.loadData(true);
    } catch (e) {
      this.events.publish('error', e);
      console.warn('refreshing failed (1)', e);
    }
  }

  loadData(blForceRefresh: boolean = false) {

    if (!this.method) {
      console.warn('dynamic view: method not defined');
      return false;
    } else
      if (!this.service) {
        console.warn('dynamic view: service not defined');
        return false;
      }

    try {
      this.view.loading = true;

      let params: any = JSON.parse(JSON.stringify((!!this.options && !!this.options.params ? this.options.params || {} : {})));

      this.service[this.method](params, blForceRefresh)
        .then((response: any) => {
          this.items = (response || []);
          this.view.loading = false;

          this.itemsChanged.emit(this.items);
        })
        .catch((error: any) => {
          this.view.loading = false;
          this.events.publish('error', error);
        });
    } catch (e) {
      this.events.publish('error', e);
    }

  }

  ngOnInit() {
    this.options = this.options || {};
    this.options.labelKey = this.options.labelKey || 'label';

    this.calcViewVars();
    this.validateUIState();
  }

  onButtonClick(button: button, item: any) {

    if (!!button && !!button.label && (button.label === 'delete')) {

      try {
        this.alertService.requestConfirm()
          .then((bl: boolean) => {
            if (!!bl) {
              item.disabled = true;
              button.handler(item, button);
            }
          })
          .catch((e: any) => {
            item.disabled = false;
            console.warn('executing button action failed', e);
          });
      } catch (e) {
        console.warn('executing button action failed', e);
      }

      return false;
    }

    try {
      button.handler(item, button);
    } catch (e) {
      console.warn('executing button action failed', e);
    }

  }

  @HostListener('window:resize')
  onResize() {
    this.calcViewVars();
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  validateUIState() {
    if (!this.items || !this.items.length) {
      this.loadData();
    }
  }

}
