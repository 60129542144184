import { NgModule } from '@angular/core';

// pipes
import { FillVarsPipe } from './pipes/fillVars';
//import { TranslateAndFillVarsPipe } from './pipes/translateAndFillVars';

@NgModule({
    declarations: [
        FillVarsPipe,
        //TranslateAndFillVarsPipe
    ],
    exports: [
        FillVarsPipe,
        //TranslateAndFillVarsPipe
    ],
})
export class SharedModule { }