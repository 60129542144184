import { Injectable } from '@angular/core';

import { ColorsService } from 'src/app/services/utils/colors.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateValidationService {

  testsNames: string[] = [
    '_validateTextColorsAgainstBackgroundColor',
    //'_validateAccentColorAgainstTextColor',
    '_validateAccentColorIsNotGray',
  ];

  constructor(
    private colors: ColorsService,
  ) {
  }

  validate(template: mediaTemplate) {
    let validation: any = {}, tests: any = {};

    this.testsNames.forEach((testName: string) => {
      try {
        const testResponse: any = this[testName](template);

        if (!testResponse || !testResponse.success) {
          validation.test = testName;
        }

        tests[testName] = testResponse;
      } catch (e) {
        console.warn('> test failed', testName, e);
      }
    });

    if (!!validation.error) {
      validation.message = `${validation.test}: ${validation.card}`;
    }

    validation.success = !validation.test;
    validation.tests = tests;

    return validation;
  }

  _validateAccentColorIsNotGray(template: mediaTemplate) {
    let validation: any = {};

    const accentColorValid: boolean = this.colors.isColor(template.config.Comp.accent.color),
      accentDarkColorValid: boolean = this.colors.isColor(template.config.Comp.accent.color_dark);

    validation.success = !!accentColorValid && !!accentDarkColorValid;

    return validation;
  }

  _validateAccentColorAgainstTextColor(template: mediaTemplate) {
    let validation: any = {};

    validation.success = true;

    return validation;
  }

  _validateTextColorsAgainstBackgroundColor(template: mediaTemplate) {
    let blValid: boolean = true, validation: any = {};

    if (!!template && !!template.config && !!template.config.Comp && !!template.config.Comp.bg && (!!template.config.Comp.bg.color || !!template.config.Comp.bg.color_dark)) {
      const keys: string[] = ['title', 'subtext', 'call2action', 'call2action_link'];

      keys.forEach((key: string) => {
        if (!!template.config.Comp[key]) {
          const simA: number = this.colors.calcSimilarity(`${template.config.Comp[key].color}`, `${template.config.Comp.bg.color}`);
          const simB: number = this.colors.calcSimilarity(`${template.config.Comp[key].color_dark}`, `${template.config.Comp.bg.color_dark}`);

          if ((simA > 85) || (simB > 80)) {
            blValid = false;
            validation.card = key;
            validation.mode = (simA > 85 ? 'light' : 'dark');
          }
        }
      });
    }

    validation.success = !!blValid;

    return validation;
  }

}