<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!!view.startManually">

      <!-- Connect -->
      <ion-button color="primary" [disabled]="!view.success || !view.verifyOwnership || (!view.source.name)"
        shape="round" fill="solid" (click)="submit()">
        <ion-icon name="link-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'connect'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view" *ngIf="!!view.startManually"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <!-- Connect shop intro mode -->
  <pipeline-intro-overlay *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)"
    [config]="introOverlayConfig" [hidden]="!!view.startManually" (onContinue)="aiCreate()"
    (onSkip)="startManually()" [(view)]="view"></pipeline-intro-overlay>

  <div class="container">

    <ion-card color="success" [hidden]="!view.success">
      <ion-card-header>
        <ion-card-title [innerHTML]="'connect_shop_headline_success'|translate"></ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p [innerHTML]="'connect_shop_text_success'|translate"></p>
      </ion-card-content>
    </ion-card>

    <ion-card color="danger" [hidden]="view.success !== false">
      <ion-card-header>
        <ion-card-title [innerHTML]="'connect_shop_headline_failed'|translate"></ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p [innerHTML]="'connect_shop_text_failed'|translate"></p>
      </ion-card-content>
    </ion-card>

    <ion-card>

      <ion-card-header>
        <ion-card-title [innerHTML]="'connect_shop_headline'|translate"></ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <p [innerHTML]="'connect_shop_text'|translate"></p>
        <ion-searchbar type="url" [(ngModel)]="view.input" [placeholder]="'url'|translate"
          (ionInput)="onUrlChanged()"></ion-searchbar>
      </ion-card-content>

      <!-- Loading -->
      <ion-spinner [hidden]="!view.loading"></ion-spinner>
      <h3 [innerHTML]="'connect_shop_loading_text'|translate" [hidden]="!view.loading"></h3>

      <ion-list *ngIf="view.success">

        <ion-item>

          <ion-thumbnail slot="start" (click)="onSourcePhotoClick()">
            <ion-img [src]="view.source.photo || fallbackImg" (ionError)="thumbnailLoadingFailed()"></ion-img>
          </ion-thumbnail>

          <ion-input [(ngModel)]="view.source.name" [label]="'name'|translate" labelPlacement="floating"></ion-input>

        </ion-item>

        <pipeline-languages-select [(input)]="view.source.language"></pipeline-languages-select>

        <ion-item button (click)="pickCountry()">
          <ion-label position="stacked">
            <h3 [innerHTML]="'country'|translate"></h3>
          </ion-label>
          <ion-note slot="end" [innerHTML]="view.source.country"></ion-note>
        </ion-item>

        <ion-item lines="none" [hidden]="!!view.loading">
          <ion-checkbox [disabled]="!view.success" [(ngModel)]="view.verifyOwnership" justify="start"
            labelPlacement="end" [innerHTML]="'verify_ownership_shop_text'|translate|fillVars"
            class="ion-text-wrap"></ion-checkbox>
        </ion-item>

      </ion-list>

    </ion-card>

  </div>

</ion-content>