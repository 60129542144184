import { Injectable } from '@angular/core';

import { FoldersService } from 'src/app/services/utils/folders.service';

@Injectable({
    providedIn: 'root'
})
export class DropService {

    groups: any = {};

    constructor(
        private folders: FoldersService,
    ) {

    }

    createGroup(groupName: string, config: any = {}) {
        return new Promise((resolve, reject) => {
            //console.log('drop-service: createGroup: groupName', groupName);
            //console.log('drop-service: createGroup: config', config);

            this.groups[groupName] = config;
            console.log('drop-service: createGroup: groups', this.groups);
        });
    }

    drag(groupName: string) {
        return new Promise((resolve, reject) => {
            //console.log('drop-service: drag', groupName);
        });
    }

    dragend(groupName: string) {
        return new Promise((resolve, reject) => {
            //console.log('drop-service: dragend', groupName);
        });
    }

    handleItemDrop(sourceItem: any, dropItem: any) {
        return new Promise(async (resolve, reject) => {
            const dragParent: any = (!!sourceItem && !!sourceItem.offsetParent ? sourceItem.offsetParent : sourceItem);
            const dropParent: any = (!!dropItem && !!dropItem.offsetParent ? dropItem.offsetParent : dropItem);

            let sourceData: any = JSON.parse(JSON.stringify(sourceItem.dataset)),
                sourceDataKeys: string[] = Object.keys(sourceData),
                targetData: any = JSON.parse(JSON.stringify(dropParent.dataset)),
                targetDataKeys: string[] = Object.keys(targetData);

            if (!sourceDataKeys || !sourceDataKeys.length) {
                sourceData = JSON.parse(JSON.stringify(sourceItem.dataset));
            }

            if (!targetDataKeys || !targetDataKeys.length) {
                targetData = JSON.parse(JSON.stringify(dropItem.dataset));
            }

            const sourceType: string | null = (!!sourceData && !!sourceData.type ? sourceData.type : null);
            const sourceUid: number | null = (!!sourceData && !!sourceData.uid ? parseInt(sourceData.uid) : null);
            const targetType: string | null = (!!targetData && !!targetData.type ? targetData.type : null);
            const targetUid: number | null = (!!targetData && !!targetData.uid ? parseInt(targetData.uid) : null);

            let response: any = {
                source: {
                    data: sourceData,
                    element: sourceItem,
                    parent_element: dragParent,
                    type: sourceType,
                    uid: sourceUid,
                },
                target: {
                    data: targetData,
                    element: dropItem,
                    parent_element: dropParent,
                    type: targetType,
                    uid: targetUid,
                },
            };

            let exec: any;

            if (!!sourceUid && !!sourceType && !!targetType && !!targetUid) {

                switch (targetType) {
                    case 'folder':
                        exec = await this.folders.moveItemToFolder([sourceUid], (sourceData.location || sourceType), targetUid);
                        break;
                }

                response.exec = exec;

                resolve(response);
            } else {
                reject('missing_parameters');
            }
        });
    }

}