<ion-card class="sidebar-accordion-card">

    <ion-card-header>

        <ion-card-title (click)="toggleCard()">
            <span [innerHTML]="'filters'|translate"></span>
        </ion-card-title>

        <ion-button class="card-toggle" (click)="toggleCard()" icon-only color="dark" fill="clear">
            <ion-icon [name]="!!cards && !!cards.filters && !!cards.filters.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
        </ion-button>

    </ion-card-header>

    <ion-spinner [hidden]="!view.loadingFilters"></ion-spinner>

    <div [hidden]="!!view.loadingFilters || (!cards || !cards.filters || !cards.filters.open)">

        <ion-list *ngIf="!!view.filters">

            <div *ngFor="let filter of view.filters">

                <!-- Date picker popover -->
                <ion-popover *ngIf="filter.type === 'date'" [id]="filter.uid + 'Popopver'" [keepContentsMounted]="true">
                    <ng-template>
                        <ion-datetime [(ngModel)]="filter.value" [id]="filter.uid + 'Picker'"
                            (ionChange)="filterValueChanged(filter, $event)"
                            (ionInput)="filterValueChanged(filter, $event)"></ion-datetime>
                    </ng-template>
                </ion-popover>

                <!-- Single filter item -->
                <ion-item [disabled]="filter.disabled">

                    <!-- Filter icon -->
                    <ion-icon *ngIf="!!filter.icon" [name]="filter.icon" slot="start"></ion-icon>

                    <!-- Type: Date -->
                    <ion-icon *ngIf="filter.type === 'date' && !filter.icon" name="calendar-outline" slot="start"></ion-icon>
                    
                    <ion-label *ngIf="filter.type === 'date' || filter.type === 'range'" class="ion-text-wrap"
                        [innerHTML]="filter.name|translate"></ion-label>

                    <ion-datetime-button *ngIf="filter.type === 'date'" [disabled]="filter.disabled" [datetime]="filter.uid + 'Picker'"
                        slot="end"></ion-datetime-button>

                    <!-- Type: Range -->
                    <ion-range [(ngModel)]="filter.value" *ngIf="filter.type === 'range' && !!filter.value" [disabled]="filter.disabled"
                        (ionChange)="onFilterRangeChanged(filter, $event)" [min]="filter.min" [max]="filter.max"
                        [dualKnobs]="filter.dualKnobs" [value]="filter.value"></ion-range>

                    <!-- Type: Select -->
                    <ion-select [(ngModel)]="filter.value" *ngIf="filter.type === 'select'" interface="popover" [(ngModel)]="filter.value"
                        [disabled]="filter.disabled || (!filter.values || !filter.values.length)" [multiple]="filter.multiple"
                        [label]="filter.name|translate" [okText]="'okay' | translate"
                        [cancelText]="'cancel' | translate" (ionChange)="filterValueChanged(filter, $event)">
                        <ion-select-option [value]="null" [innerHTML]="'all'|translate" *ngIf="!filter.multiple"></ion-select-option>
                        <ion-select-option *ngFor="let option of filter.values" [innerHTML]="option.label|translate"
                            [value]="option.uid || option.value"></ion-select-option>
                    </ion-select>

                </ion-item>

            </div>

        </ion-list>

    </div>

</ion-card>