import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class LinkshortenerService {


  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  getAll(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('linkshortener', options, ['list'], {}, blForceRefresh);
  }

  lookup(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = url;
    return this.AppCMS.loadPluginData('linkshortener', options, ['lookup'], {}, blForceRefresh);
  }

}