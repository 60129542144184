import { Component, OnInit, ViewChild } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ExportService } from 'src/app/services/utils/export.service';
import { MailCrawlerService } from "src/app/services/integrations/mail-crawler.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { MailCrawlerPage } from '../mail-crawler/mail-crawler.page';

@Component({
  selector: 'app-mail-crawlers',
  standalone: false,
  templateUrl: './mail-crawlers.page.html',
  styleUrls: ['./mail-crawlers.page.scss'],
})
export class MailCrawlersPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  buttons: button[] = [
    {
      handler: (item: any, event: any | null = null) => {
        this.edit(item);
      },
      icon: 'create-outline',
      label: 'edit',
      uid: 'edit',
    },
  ];

  cards: any = {};

  cta: any = {
    handler: () => {
      this.add();
    },
    icon: 'link-outline',
    label: 'connect',
  };

  @ViewChild('headerPopover') headerPopover;

  isHeaderPopoverOpen: boolean = false;

  options: dynamicViewPageOptions = {
    labelKey: 'name',
    showPhoto: false,
    showViewModeSelect: true,
    subLabelKey: 'email',
  };

  paginationConfig: paginationConfig = {
    itemsKey: 'crawlers',
    limit: 100,
  };

  search: searchOptions = {
    itemsKey: 'crawlers',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'search-outline',
      label: 'analyse',
      uid: 'analyse',
    },
    {
      icon: 'sync-outline',
      label: 'sync',
      uid: 'sync',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  state: state = {};

  view: any = {
    crawlerTypeIcons: {
      exchange: 'logo-microsoft',
      icloud: 'logo-apple',
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'mail_crawlers_top_card',
      text: 'mail_crawlers_top_card_text',
      title: 'mail_crawlers_top_card_title',
    },
    itemSize: 64,
    multiple: true,
    options: {

    },
    showFilters: false,
    showLanguagesSelect: false,
    showMenuButton: true,
    showPagination: false,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'mail_crawlers',
    viewType: 'list',
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private exportService: ExportService,
    public mail: MailCrawlerService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  async add() {

    const modal: any = await this.modalService.create({
      component: MailCrawlerPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then((data: any) => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.showFilters = false;

    this.view.colSize = {
      left: this.view.sidebarSize || (window.innerWidth > 768 ? 3 : 12),
      right: (!!this.view.sidebarSize ? (12 - this.view.sidebarSize) : (window.innerWidth > 768 ? 9 : 12)),
    };

    this.view.viewType = 'list';
  }

  delete(crawler: mailCrawler, event: any | null = null) {
    this.mail.deleteCrawler(crawler.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  doRefresh(event: any | null = null) {
    this.loadCrawlers(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  async edit(crawler: mailCrawler, event: any | null = null) {

    const modal: any = await this.modalService.create({
      component: MailCrawlerPage,
      componentProps: {
        crawler: crawler,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then((data: any) => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  export() {
    this.exportService
      .setData(this.view.connections)
      .setItemParser((item: any) => {
        return {
          uid: item.uid,
          active: item.active,
          name: item.name,
          url: item.url,
          timestamp: item.timestamp,
        };
      })
      .asCsv()
      .download();
  }

  importCrawlers() {

  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadCrawlers(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      this.view.crawlers = [];

      if (!this.view.project || !this.view.project.uid) {
        resolve(this.view);
      } else {
        this.view.loadingCrawlers = true;

        this.mail.getCrawlers(this.view.options, blForceRefresh)
          .then((response: any) => {
            let crawlers: mailCrawler[] = [];

            if (!!response && !!response.length) {
              crawlers = response;
            } else
              if (response.items && !!response.items.length) {
                crawlers = response.items;
              }

            this.view.crawlers = (crawlers || []).map((crawler: mailCrawler) => {
              crawler.icon = this.view.crawlerTypeIcons[crawler.type] || 'mail-outline';
              return crawler;
            });

            this.view.loadingCrawlers = false;

            resolve(this.view);
          })
          .catch((error: any) => {
            this.view.loadingCrawlers = false;
            reject(error);
          });
      }
    })
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnInit() {
    this.calcViewVars();
    this.loadCards();

    this.loadCrawlers()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  onFiltersChanged(event: any = null) {
    console.log('onFiltersChanged', event);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
    }

  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}