import { Injectable } from '@angular/core';

import { ModalService } from 'src/app/services/core/modal.service';

import { CreatorBlogsPage } from 'src/app/pages/extensions/blog-admin/creator-blogs/creator-blogs.page';
import { CreatorShopsPage } from 'src/app/pages/extensions/shop/creator-shops/creator-shops.page';

@Injectable({
  providedIn: 'root'
})
export class ConnectorService {

  constructor(
    private modalService: ModalService,
  ) {
  }

  connectBlog(connectorConfig: connectorConfig = {}) {
    return new Promise(async (resolve, reject) => {

      const chooseModal: any = await this.modalService.create({
        component: CreatorBlogsPage,
        componentProps: connectorConfig,
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: 'defaultModal'
      });
      
      chooseModal.onWillDismiss().then((response: any) => {
        resolve(response);
      });

      this.modalService.present(chooseModal);
    });
  }

  connectShop(connectorConfig: connectorConfig = {}) {
    return new Promise(async (resolve, reject) => {

      const chooseModal: any = await this.modalService.create({
        component: CreatorShopsPage,
        componentProps: connectorConfig,
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: 'defaultModal'
      });
      
      chooseModal.onWillDismiss().then((response: any) => {
        resolve(response);
      });

      this.modalService.present(chooseModal);
    });
  }

}