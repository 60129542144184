<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border">

    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button (click)="update()" shape="round" [color]="view.isDesktop ? 'primary' : 'dark'"
        [fill]="view.isDesktop ? 'solid' : 'clear'">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [hidden]="!view.isDesktop" [innerHTML]="'update'|translate"></ion-label>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Segment bar -->
  <ion-toolbar>
    <ion-segment scrollable [(ngModel)]="view.segment" scrollable value="general">

      <!-- General -->
      <ion-segment-button value="general">
        <ion-icon name="information-outline"></ion-icon>
        <ion-label [innerHTML]="'general'|translate"></ion-label>
      </ion-segment-button>

      <!-- Permissions -->
      <ion-segment-button value="permissions">
        <ion-icon name="lock-closed-outline"></ion-icon>
        <ion-label [innerHTML]="'permissions'|translate"></ion-label>
      </ion-segment-button>

      <!-- Credentials -->
      <ion-segment-button value="credentials" [disabled]="source.system === 'csv'">
        <ion-icon name="lock-closed-outline"></ion-icon>
        <ion-label [innerHTML]="'credentials'|translate"></ion-label>
      </ion-segment-button>

      <!-- Synchronization -->
      <ion-segment-button value="synchronization">
        <ion-icon name="sync-outline"></ion-icon>
        <ion-label [innerHTML]="'synchronization'|translate"></ion-label>
      </ion-segment-button>

    </ion-segment>
  </ion-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <!-- General -->
  <div [hidden]="view.segment !== 'general'">

    <ion-card *ngIf="source">

      <ion-card-header>
        <ion-card-title [innerHTML]="'general'|translate"></ion-card-title>
      </ion-card-header>

      <ion-list>

        <!-- Photo + Title -->
        <ion-item>

          <ion-thumbnail fill="outline" color="dark" slot="start" (click)="uploadPhoto()">
            <ion-skeleton-text class="image" animated [hidden]="!view.loadingPhoto"></ion-skeleton-text>
            <ion-img [src]="source.photo || fallbackImg" [hidden]="!!view.loadingPhoto"
              (ionError)="thumbnailLoadingFailed()"></ion-img>
          </ion-thumbnail>

          <ion-input [(ngModel)]="source.title" [label]="'name'|translate" labelPlacement="floating"></ion-input>

        </ion-item>

        <!-- System -->
        <ion-item lines="none">
          <ion-icon name="hardware-chip-outline" slot="start"></ion-icon>

          <ion-select [(ngModel)]="source.system" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
            interface="popover" [placeholder]="'select'|translate" [label]="'system'|translate">
            <ion-select-option *ngFor="let system of view.systems" [innerHTML]="('shop_system_' + system)|translate"
              [value]="system"></ion-select-option>
          </ion-select>

        </ion-item>

        <!-- Shop URL -->
        <ion-item lines="none">
          <ion-icon name="link-outline" slot="start"></ion-icon>
          <ion-input [(ngModel)]="source.url" (ionInput)="validateUrl()" labelPlacement="floating"
            [label]="'shop_url'|translate"></ion-input>
        </ion-item>

      </ion-list>
    </ion-card>

    <ion-card [hidden]="source.system !== 'csv'">

      <ion-card-header>
        <ion-card-title [innerHTML]="'csv'|translate"></ion-card-title>
      </ion-card-header>

      <ion-list>

        <!-- Import File URL (if system = csv) -->
        <ion-item lines="none">
          <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
          <ion-input [(ngModel)]="source.settings.import_file_url" (ionInput)="validateImportFileUrl()"
            labelPlacement="floating" [label]="'import_file_url'|translate"></ion-input>
        </ion-item>

        <!-- Seperator -->
        <ion-item *ngIf="!!view.seperators">
          <ion-icon name="return-down-back-outline" slot="start"></ion-icon>
          <ion-select [(ngModel)]="source.settings.seperator" name="seperator" labelPlacement="stacked"
            interface="popover" [label]="'seperator'|translate" [okText]="'okay' | translate"
            [cancelText]="'cancel' | translate" (ionChange)="seperatorChanged()">
            <ion-select-option *ngFor="let seperator of view.seperators" [innerHTML]="seperator.label|translate"
              [value]="seperator.indent"></ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item>
          <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="source.settings.quote_characters"
            (ionChange)="seperatorChanged()" [innerHTML]="'quote_characters'|translate"></ion-checkbox>
        </ion-item>

        <!-- Quote character -->
        <ion-item [hidden]="!source.settings.quote_characters" *ngIf="!!view.quote_characters">
          <ion-icon name="return-down-back-outline" slot="start"></ion-icon>
          <ion-select [(ngModel)]="source.settings.quote_character" name="seperator" labelPlacement="stacked"
            interface="popover" [label]="'quote_character'|translate" [okText]="'okay' | translate"
            [cancelText]="'cancel' | translate" (ionChange)="seperatorChanged()">
            <ion-select-option *ngFor="let char of view.quote_characters" [innerHTML]="char.label|translate"
              [value]="char.indent"></ion-select-option>
          </ion-select>
        </ion-item>

        <!-- Use first row as fields -->
        <ion-item lines="none">
          <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="source.settings.use_first_row_as_fields"
            (ionChange)="useFirstRowAsFieldsChanged($event)"
            [innerHTML]="'use_first_row_as_fields'|translate"></ion-checkbox>
        </ion-item>

      </ion-list>
      
      <!-- Mappings configurator -->
      <pipeline-mappings-card *ngIf="(source.system === 'csv') && (!!view.loading || (!!view.sourceFields && !!view.targetFields))"
        [loading]="!!view.loading" [mappings]="source.settings.csv_mappings" [(sources)]="view.sourceFields" [(targets)]="view.targetFields"
        (changed)="onMappingsChanged($event)">
      </pipeline-mappings-card>

    </ion-card>

    <ion-card *ngIf="source">

      <ion-card-header>
        <ion-card-title [innerHTML]="'attributes'|translate"></ion-card-title>
      </ion-card-header>

      <ion-list>

        <ion-item>
          <ion-checkbox slot="start" [(ngModel)]="source.active"></ion-checkbox>
          <ion-label [innerHTML]="'active'|translate"></ion-label>
        </ion-item>

        <ion-item>
          <ion-checkbox slot="start" [(ngModel)]="source.settings.hidden"></ion-checkbox>
          <ion-label [innerHTML]="'hidden'|translate"></ion-label>
        </ion-item>

        <ion-item lines="none">
          <ion-checkbox slot="start" [(ngModel)]="source.local"></ion-checkbox>
          <ion-label [innerHTML]="'local'|translate"></ion-label>
        </ion-item>

      </ion-list>

    </ion-card>

    <ion-card *ngIf="source.local">

      <ion-card-header>
        <ion-card-title [innerHTML]="'region'|translate"></ion-card-title>
      </ion-card-header>

      <ion-searchbar [hidden]="!source.local || source.country" [(ngModel)]="search.countryQuery"
        [placeholder]="'country'|translate" (ionInput)="onCountryQueryChanged()"></ion-searchbar>

      <ion-item lines="none" *ngIf="view.country" class="selected">
        <ion-icon name="location" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap">
          <h4 [innerHTML]="view.country.name"></h4>
        </ion-label>
        <ion-button slot="end" fill="outline" color="danger" (click)="removeCountry()">
          <ion-icon name="trash-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'delete'|translate"></ion-label>
        </ion-button>
      </ion-item>

      <ion-list lines="none" *ngIf="view.countries">
        <ion-list-header *ngIf="view.countries && view.countries.length">
          <ion-label [innerHTML]="'results'|translate"></ion-label>
        </ion-list-header>

        <ion-item *ngFor="let country of view.countries"
          [class.selected]="view.country && (view.country.indent === country.indent)" (click)="useCountry(country)">
          <ion-icon name="location-outline" slot="start"></ion-icon>
          <ion-label class="ion-text-wrap">
            <p [innerHTML]="country.name"></p>
          </ion-label>
        </ion-item>
      </ion-list>

      <ion-searchbar [hidden]="!source.local || !source.country || view.city" [(ngModel)]="search.cityQuery"
        [placeholder]="'city'|translate" (ionInput)="onCityQueryChanged()"></ion-searchbar>

      <ion-item lines="none" *ngIf="view.city" class="selected">
        <ion-icon name="location" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap">
          <h4 [innerHTML]="view.city.title"></h4>
          <p>
            <span [innerHTML]="view.city.region"></span>,
            <span [innerHTML]="view.city.state"></span>
          </p>
        </ion-label>
        <ion-button slot="end" fill="outline" color="danger" (click)="removeCity()">
          <ion-icon name="trash-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'delete'|translate"></ion-label>
        </ion-button>
      </ion-item>

      <ion-list lines="none" *ngIf="view.cities">

        <ion-list-header *ngIf="view.cities && view.cities.length">
          <ion-label [innerHTML]="'results'|translate"></ion-label>
        </ion-list-header>

        <ion-item *ngFor="let city of view.cities" [class.selected]="view.city && (view.city.indent === city.indent)"
          (click)="useCity(city)">
          <ion-icon name="location-outline" slot="start"></ion-icon>
          <ion-label class="ion-text-wrap">
            <h4 [innerHTML]="city.title"></h4>
            <p>
              <span [innerHTML]="city.region"></span>,
              <span [innerHTML]="city.state"></span>
            </p>
          </ion-label>
        </ion-item>

      </ion-list>

      <ion-button [hidden]="!source.local || !source.country || !source.region" expand="block"
        (click)="convertToRegionalSource()">
        <ion-label class="ion-text-wrap" [innerHTML]="'convert_to_regional_source'|translate"></ion-label>
        <ion-icon name="arrow-forward" slot="end"></ion-icon>
      </ion-button>

    </ion-card>

    <ion-card *ngIf="source">

      <ion-card-header>
        <ion-card-title [innerHTML]="'localization'|translate"></ion-card-title>
      </ion-card-header>

      <ion-list lines="none">

        <!-- Main Language -->
        <pipeline-languages-select [label]="'main_language'|translate" [(key)]="source.language"
          [options]="languageOptions"></pipeline-languages-select>

        <!-- Main Country -->
        <pipeline-languages-select [label]="'country'|translate" [(key)]="source.country" [options]="languageOptions"
          icon="location-outline"></pipeline-languages-select>

      </ion-list>
    </ion-card>
  </div>

  <!-- Permissions -->
  <div [hidden]="view.segment !== 'permissions'">

    <!-- Indexing -->
    <ion-card *ngIf="source">

      <ion-card-header>
        <ion-card-title [innerHTML]="'indexing'|translate"></ion-card-title>
      </ion-card-header>

      <ion-list>

        <ion-item>
          <ion-checkbox [(ngModel)]="source.settings.permissions.allow_products_listing"
            (ionChange)="updatedPermissions()" justify="start" labelPlacement="end">
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'shop_source_permission_allow_products_listing'|translate"></h3>
              <p [innerHTML]="'shop_source_permission_allow_products_listing_description'|translate|fillVars"></p>
            </ion-label>
          </ion-checkbox>
        </ion-item>

        <ion-item lines="none">
          <ion-checkbox [(ngModel)]="source.settings.permissions.allow_categories_listing"
            (ionChange)="updatedPermissions()" justify="start" labelPlacement="end">
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'shop_source_permission_allow_categories_listing'|translate"></h3>
              <p [innerHTML]="'shop_source_permission_allow_categories_listing_description'|translate|fillVars"></p>
            </ion-label>
          </ion-checkbox>
        </ion-item>

      </ion-list>
    </ion-card>

    <!-- Optimization -->
    <ion-card *ngIf="source">

      <ion-card-header>
        <ion-card-title [innerHTML]="'optimization'|translate"></ion-card-title>
      </ion-card-header>

      <ion-list>

        <ion-item>
          <ion-checkbox [(ngModel)]="source.settings.permissions.allow_product_description_optimization"
            (ionChange)="updatedPermissions()" justify="start" labelPlacement="end">
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'shop_source_permission_allow_product_description_optimization'|translate"></h3>
              <p
                [innerHTML]="'shop_source_permission_allow_product_description_optimization_description'|translate|fillVars">
              </p>
            </ion-label>
          </ion-checkbox>
        </ion-item>

        <ion-item lines="none">
          <ion-checkbox [(ngModel)]="source.settings.permissions.allow_product_image_optimization"
            (ionChange)="updatedPermissions()" justify="start" labelPlacement="end">
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'shop_source_permission_allow_product_image_optimization'|translate"></h3>
              <p
                [innerHTML]="'shop_source_permission_allow_product_image_optimization_description'|translate|fillVars">
              </p>
            </ion-label>
          </ion-checkbox>
        </ion-item>

      </ion-list>
    </ion-card>

  </div>

  <!-- Credentials -->
  <div [hidden]="view.segment !== 'credentials'">

    <!-- WooCommerce -->
    <ion-card *ngIf="source.system === 'woocommerce'">

      <ion-card-header>
        <ion-card-title [innerHTML]="'shop_credentials_woocommerce_headline'|translate"></ion-card-title>
      </ion-card-header>

      <ion-list>

        <ion-item>
          <ion-label position="stacked" [innerHTML]="'shop_credentials_consumer_key'|translate"></ion-label>
          <ion-input type="password" [(ngModel)]="source.credentials.consumer_key"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="stacked" [innerHTML]="'shop_credentials_consumer_secret'|translate"></ion-label>
          <ion-input type="password" [(ngModel)]="source.credentials.consumer_secret"></ion-input>
        </ion-item>

      </ion-list>
    </ion-card>

  </div>

  <!-- Synchronization -->
  <div [hidden]="view.segment !== 'synchronization'">

    <!-- Import -->
    <ion-card>

      <ion-card-header>
        <ion-card-title [innerHTML]="'shop_synchronization_import'|translate"></ion-card-title>
      </ion-card-header>

      <ion-list>

        <ion-item lines="none" [disabled]="!source.active">

          <ion-icon name="sync-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'interval'|translate"></h3>
            <p [innerHTML]="'shop_source_sync_cron_interval_description'|translate"></p>
          </ion-label>

          <ion-select [okText]="'okay'|translate" [cancelText]="'cancel'|translate" interface="popover"
            [disabled]="!source.active" slot="end" [(ngModel)]="source.settings.interval"
            (ionChange)="intervalChanged()" [placeholder]="'choose'|translate">
            <ion-select-option [innerHTML]="'hourly'|translate" value="hourly"></ion-select-option>
            <ion-select-option [innerHTML]="'daily'|translate" value="daily"></ion-select-option>
            <ion-select-option [innerHTML]="'weekly'|translate" value="weekly"></ion-select-option>
            <ion-select-option [innerHTML]="'monthly'|translate" value="monthly"></ion-select-option>
            <ion-select-option [innerHTML]="'custom'|translate" value="custom"></ion-select-option>
          </ion-select>

        </ion-item>

        <ion-item
          [hidden]="!source.active || !source.settings.interval || source.settings.interval == 'hourly' || source.settings.interval == 'daily'">

          <ion-icon name="calendar-outline" slot="start"></ion-icon>

          <ion-input [disabled]="!source.active || !source.settings.interval" [label]="'date'|translate"
            labelPlacement="floating" type="date" [(ngModel)]="source.settings.date"></ion-input>

        </ion-item>

        <ion-item [hidden]="!source.active || !source.settings.interval" lines="none">

          <ion-icon name="time-outline" slot="start"></ion-icon>

          <ion-input [disabled]="!source.active || !source.settings.interval" type="time" [label]="'time'|translate"
            labelPlacement="floating" [(ngModel)]="source.settings.time"></ion-input>

        </ion-item>

      </ion-list>

    </ion-card>

    <!-- Export -->
    <ion-card>

      <ion-card-header>
        <ion-card-title [innerHTML]="'shop_synchronization_export'|translate"></ion-card-title>
      </ion-card-header>

      <!-- Smart URL -->
      <pipeline-shortlink-generator [config]="shortlinkConfig" [url]="view.exportUrl"
        [link]="view.smart_link"></pipeline-shortlink-generator>

    </ion-card>

  </div>

</ion-content>