import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';

import { OrderByModalPage } from 'src/app/pages/core/modals/order-by-modal/order-by-modal.page';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
  selector: 'pipeline-header-toolbar',
  standalone: false,
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss'],
})
export class HeaderToolbarComponent implements OnInit {
  @Input() state: state;
  @Input() view: any;

  @Output() titleChanged = new EventEmitter();

  appConfig: pipelineAppConfig;
  
  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private modalService: ModalService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  _titleChanged(event: any|null = null) {
    this.titleChanged.emit(event.detail.value);
  }
  
  async changeOrderBy() {
    const modal: any = await this.modalService.create({
      component: OrderByModalPage,
      componentProprs: {},
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'orderByModal'
    });

    modal.onWillDismiss().then((response: any) => {
      this.events.publish('orderBy:changed', (response && response.data && response.data.orderBy ? response.data.orderBy : null));
    });

    this.modalService.present(modal);
  }
  
  

  ngOnInit() {

  }

  onSlideSelectDataChanged(state: state, event: any) {
  }

  selectCity() {
    this.events.publish('local:select:city');
  }

  up() {
    this.events.publish('feed:view:up', this.view);
  }

}
