import { Component, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-mail-rule',
  standalone: false,
  templateUrl: './mail-rule.page.html',
  styleUrls: ['./mail-rule.page.scss'],
})
export class MailRulePage implements OnInit {

  rule: mailRule = {};
  
  search: searchOptions = {
    keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  state: state = {};
  
  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'mail_rule_top_card',
      text: 'mail_rule_top_card_text',
      title: 'mail_rule_top_card_title',
    },
    options: {

    },
    rules: [
      'blacklist',
      'delete',
      'forward',
      'mark_as_read',
      'move',
    ],
    title: 'mail_rule',
  };

  constructor(
    private events: EventsService,
    private modalService: ModalService,
    private viewService: ViewService,
  ) {
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  dismiss(data: any|null = null, role: string|null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  ngOnInit() {
    this.calcViewVars();

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  onInputChanged(event: any|null = null) {

  }

}
