<ion-list>

    <!-- Lookup url input bar -->
    <ion-item [hidden]="!config || !config.hasInput">

        <ion-icon name="link-outline" slot="start"></ion-icon>

        <ion-textarea name="shortlinkInputUrl" [label]="'url'|translate" [(ngModel)]="view.input_url" [disabled]="!config || !config.canInput"
            (ionInput)="runSearch($event)" rows="4" [placeholder]="'ai_url_lookup_search_placeholder'|translate"
            labelPlacement="stacked"></ion-textarea>

    </ion-item>

    <!-- Smart link -->
    <ion-item lines="none" *ngIf="!!view.smart_link_host && (!!link || (!!config && !!config.canRequest))"
        [hidden]="(!config || !config.canRequest) && !view.success" [class.success]="view.success">

        <ion-icon name="share-social-outline" slot="start"></ion-icon>

        <!-- Label (if link is not set yet)-->
        <ion-label *ngIf="!link || !link.indent" [innerHTML]="'smart_link'|translate"></ion-label>

        <!-- Textarea (if link is set )-->
        <ion-textarea name="shortlinkOutputUrl" *ngIf="!!link && !!link.indent" [hidden]="!!view.loading" [label]="'smart_link'|translate"
            [(ngModel)]="link.short_url" labelPlacement="stacked" rows="4" [disabled]="true"></ion-textarea>

        <ion-spinner [hidden]="!view.loading"></ion-spinner>

        <!-- Request link -->
        <ion-button *ngIf="(!!config && !!config.canRequest) && (!link || !link.indent)" slot="end" fill="clear"
            color="dark" icon-only (click)="requestSmartLink()">
            <ion-icon name="link-outline" slot="start"></ion-icon>
        </ion-button>

        <!-- Copy link -->
        <ion-button *ngIf="!!view.smart_link_host && !!link && !!link.indent" slot="end" fill="clear" color="dark"
            icon-only (click)="copySmartLink()">
            <ion-icon name="copy-outline" slot="start"></ion-icon>
        </ion-button>

        <!-- Open link -->
        <ion-button *ngIf="!!view.smart_link_host && !!link && !!link.indent" slot="end" fill="clear" color="dark"
            icon-only (click)="openSmartLink()">
            <ion-icon name="open-outline" slot="start"></ion-icon>
        </ion-button>

    </ion-item>

</ion-list>