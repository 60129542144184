import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ConnectorService } from 'src/app/services/utils/connector.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { ModalService } from "src/app/services/core/modal.service";
import { UserService } from 'src/app/services/core/user.service';
import { TeamsService } from 'src/app/services/core/teams.service';
import { AbonnementService } from 'src/app/services/core/abonnement.service';
import { ViewService } from 'src/app/services/core/view.service';

import { TeamPage } from 'src/app/pages/core/teams/team/team.page';

@Component({
  selector: 'app-customer',
  standalone: false,
  templateUrl: './customer.page.html',
  styleUrls: ['./customer.page.scss'],
})
export class CustomerPage implements OnInit {

  appConfig: pipelineAppConfig;

  customer: user = {
    billing: {

    },
  };

  fallbackAvatarImg: string = './assets/img/avatars/1.jpg';
  fallbackImg: string = './assets/img/fallback.webp';

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    itemSize: 64,
    segment: 'general',
    userGroups: [],
    route: 'customer',
  };

  constructor(
    private abo: AbonnementService,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private connector: ConnectorService,
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private teams: TeamsService,
    public userService: UserService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
    
    this.customer = (this.userService.detailItem() || {} as any);
    this.customer.billing = this.customer.billing || {};

    this.customer.classifications = this.customer.classifications || {};

    this.view.blogConnected = (
      this.customer &&
      this.customer.classifications &&
      this.customer.classifications.blogSource &&
      this.customer.classifications.blogSourceUid
    );

    this.view.shopConnected = (
      this.customer &&
      this.customer.classifications &&
      this.customer.classifications.shopSource &&
      this.customer.classifications.shopSourceUid
    );

    this.view.mode = (!!this.customer && !!this.customer.uid ? 'edit' : 'create');

    this.loadUserGroups();
  }

  addAbonnement() {
    this.abo.pick()
    .then((response: any) => {
      if(!!response && !!response.data && !!response.data.item && !!response.data.item.uid) {
        this.abo.updateUserAbonnement(this.customer.uid, response.data.item.uid)
        .then(() => {
          this.loadAbonnements(true);
        })
        .catch((error: any) => {
          this.events.publish('error', error);
        });
      }
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  addTeam() {
    this.teams.pick()
    .then((response: any) => {
      if(!!response && !!response.data && !!response.data.item && !!response.data.item.uid) {
        this.teams.addUserToTeam(response.data.item.uid, this.customer.uid)
        .then(() => {
          this.loadTeams(true);
        })
        .catch((error: any) => {
          this.events.publish('error', error);
        });
      }
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.title = (!!this.customer && !!this.customer.uid ? 'edit_customer' : 'create_customer');
  }

  async connectBlog() {
    let connect = await this.connector.connectBlog({
      user: this.customer,
    });
  }

  async connectShop() {
    let connect = await this.connector.connectShop({
      user: this.customer,
    });
  }

  create() {
    this.customer.email = this.customer.email || this.customer.billing.email;
    let username = this.customer.email;

    this.customer.nickname = this.customer.nickname || username;
    this.customer.password = this.view.password;
    this.customer.password_repeat = this.view.password_repeat;

    this.userService.create(this.customer, false)
    .then(() => {
      this.dismiss(null, 'done');
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  createTeam() {
    
  }

  dismiss(data: any|null = null, role: string|null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh() {

  }

  ionViewWilLLeave() {
    this.userService.detailItem({} as any);
  }

  async loadAbonnements(blForceRefresh: boolean = false) {

    if(!this.customer || !this.customer.uid) {
      return false;
    }

    this.view.loading = true;

    try {

      let extensionResponse: any = await this.abo.getSubscribedExtensions({
        user: {
          email: this.customer.email,
        },
        userId: this.customer.uid,
      }, blForceRefresh);

      this.view.abonnements = (!!extensionResponse && !!extensionResponse.extensions ? extensionResponse.extensions : []);
      console.log('this.view.abonnements', this.view.abonnements);

      this.view.loading = false;
    } catch(e) {
      this.view.abonnements = [];
      this.view.loading = false;
      console.warn('loading plans failed', e);
    }

  }

  loadTeams(blForceRefresh: boolean = false) {

    if(!this.customer || !this.customer.uid) {
      return false;
    }
    
    this.view.loading = true;

    this.teams.getByUserUid(this.customer.uid, blForceRefresh)
    .then((teams: team[]) => {
      this.view.loading = false;
      this.view.teams = teams || [];
      console.log('this.view.teams', this.view.teams);
    })
    .catch((error: any) => {
      this.view.loading = false;
      this.view.teams = [];
      console.warn('loading teams failed', error);
    });
  }

  async loadUserGroups() {
    this.view.loading = true;

    try {
      this.view.userGroups = await this.userService.getUserGroups();
      this.view.loading = false;
    } catch(e) {
      this.view.loading = false;
      console.warn('> load user groups failed', e);
    }
  }

  ngAfterContentInit() {
    this.loadTeams();
    this.loadAbonnements();
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  removeAbonnement() {

  }

  removeUserFromAbo(abo: any) {

  }

  removeUserFromTeam(team: team) {

  }

  resetPassword() {
    this.userService
    .requestNewPassword(this.customer)
    .then(async (response: any) => {

      if(response.success) {

        const alert: any = await this.alertCtrl.create({
          header: 'E-Mail verschickt',
          message: 'Bitte bestätige den Link, den wir Dir an Deine E-Mail Adresse geschickt haben',
          buttons: [{
              text: 'Okay',
          }]
        });

        alert.present();

      } else {
        this.events.publish('error_password_reset_failed');
      }
    })
    .catch((error: any) => {
      this.events.publish('error_password_reset_failed');
    });
  }

  resetPasswordManually() {
    this.view.resetPasswordManuallyMode = true;
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackAvatarImg;
  }

  update() {
    let user: user = (JSON.parse(JSON.stringify(this.customer)) as user);

    if(!!this.view.password && !!this.view.password_repeat) {
      user.password_update = {
        new: `${this.view.password}`,
        new_repeat: `${this.view.password_repeat}`,
        old: `${this.view.password_old}`,
      }
    }
    
    this.userService.update(user)
    .then(() => {
     
      /*
      this.events.publish('toast', {
        message: 'customer_updated',
      });
      */

      this.dismiss(null, 'done');
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  uploadPhoto() {
    this.media.applyFromWeb(null, {
    }).then((response: any) => {
      let imageUrl: string|null = (typeof response === 'string' ? response : null);
      
      if(!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
        imageUrl = response.items[0].thumbnail;
      }
      
      if(!!imageUrl) {
        this.customer.photo = imageUrl;
      }

    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  viewAbo(abo: any) {

  }

  async viewTeam(team: team) {
    this.teams.detailItem(team as team);

    const editModal: any = await this.modalService.create({
      component: TeamPage,
      componentProps: {
        team: team,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    editModal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(editModal);
  }

}
