<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!viewData.showBackButton || !viewData.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="viewData" *ngIf="!!viewData.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!viewData.isModal && !viewData.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!!viewData.startManually">

      <!-- Share -->
      <ion-button shape="round" fill="clear" color="dark" (click)="sharePlan($event)"
        *ngIf="!!viewData.plan && !!viewData.plan.uid && (viewData.uiMode !== 'preview') && (viewData.uiMode !== 'review')"
        [hidden]="!!viewData.loading || (!viewData.startManually && (!viewData.plan || !viewData.plan.uid))">
        <ion-icon name="share-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'share'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

      <!-- Generate -->
      <ion-button shape="round" fill="solid" color="primary" (click)="generate()"
        *ngIf="!!viewData.plan && (!eventsList || !eventsList.length) && (viewData.uiMode !== 'preview') && (viewData.uiMode !== 'review')"
        [disabled]="!viewData.plan.title || !viewData.project || !!viewData.loading || !viewData.topics || !viewData.topics.length"
        [hidden]="!!viewData.loading || (!viewData.startManually && (!viewData.plan || !viewData.plan.uid))">
        <ion-icon name="play" slot="start"></ion-icon>
        <ion-label [innerHTML]="'generate'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

      <!-- Rebuild -->
      <ion-button fill="clear" color="dark" (click)="generate()"
        *ngIf="!!viewData.plan && (!!eventsList && !!eventsList.length) && (viewData.uiMode !== 'preview') && (viewData.uiMode !== 'review')"
        [disabled]="!viewData.plan.title || !viewData.project || !!viewData.loading || !viewData.topics || !viewData.topics.length"
        [hidden]="!!viewData.loading || (!viewData.startManually && (!viewData.plan || !viewData.plan.uid))">
        <ion-icon name="sync-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'rebuild'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

      <!-- Options button -->
      <ion-button (click)="showHeaderPopover($event)" fill="clear" icon-only color="dark"
        [disabled]="!!viewData.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Update -->
      <ion-button shape="round" fill="solid" color="primary" (click)="update()"
        *ngIf="!!eventsList && !!eventsList.length && !!viewData.plan && !!viewData.plan.uid && (viewData.uiMode !== 'preview') && (viewData.uiMode !== 'review')"
        [disabled]="!viewData.project || !!viewData.loading || !viewData.topics || !viewData.topics.length"
        [hidden]="!!viewData.loading || (!viewData.startManually && (!viewData.plan || !viewData.plan.uid))">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate" class="ion-text-wrap"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="viewData"></pipeline-header-toolbar>

  <!-- Progress bar -->
  <ion-toolbar class="ion-no-border container second-toolbar progress-toolbar" *ngIf="!!viewData.startManually && !!viewData.progress">
    <pipeline-progress-bar [progress]="viewData.progress" [view]="viewData"></pipeline-progress-bar>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Popover for Header actions -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list>

        <!-- Import -->
        <ion-item button (click)="importPosts($event)" [disabled]="!!viewData.loading">
          <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'import'|translate"></ion-label>
        </ion-item>

        <!-- Export -->
        <ion-item lines="none" button (click)="export($event)" [disabled]="!!viewData.loading"
          *ngIf="!!eventsList && !!eventsList.length">
          <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'export'|translate"></ion-label>
        </ion-item>
      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Popover for information -->
  <ion-popover #infoPopover [isOpen]="isInfoPopoverOpen" (didDismiss)="isInfoPopoverOpen = false">
    <ng-template>
      <ion-content>
        <p class="ion-padding" *ngIf="!!viewData.infoPopoverContent"
          [innerHTML]="viewData.infoPopoverContent|translate">
        </p>
      </ion-content>
    </ng-template>
  </ion-popover>

  <!-- Popover for sharing -->
  <ion-popover #sharingPopover [isOpen]="isSharingPopoverOpen" (didDismiss)="isSharingPopoverOpen = false">
    <ng-template>
      <ion-content>

        <ion-list>

          <ion-item>

            <ion-select interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
              [label]="'sharing_mode'|translate" [(ngModel)]="viewData.sharingMode"
              (ionChange)="onSharingModeChanged($event)">
              <ion-select-option value="edit" [innerHTML]="'edit'|translate"></ion-select-option>
              <ion-select-option value="preview" [innerHTML]="'preview'|translate"></ion-select-option>
              <ion-select-option value="review" [innerHTML]="'review'|translate"></ion-select-option>
            </ion-select>

          </ion-item>

          <ion-item lines="none" [hidden]="!viewData.sharingMode || !viewData.sharingLink">
            <ion-icon name="link-outline" slot="start"></ion-icon>
            <ion-textarea rows="3" [(ngModel)]="viewData.sharingLink" disabled></ion-textarea>
          </ion-item>

        </ion-list>

        <!-- Sharing link actions -->
        <ion-grid [hidden]="!viewData.sharingMode || !viewData.sharingLink">
          <ion-row>
            <ion-col>
              <ion-button size="block" fill="solid" color="primary" (click)="copySharingLink()">
                <ion-icon name="clipboard-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'copy'|translate"></ion-label>
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button size="block" fill="outline" color="primary" (click)="openSharingLink()">
                <ion-icon name="open-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'open'|translate"></ion-label>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>

      </ion-content>
    </ng-template>
  </ion-popover>

  <!-- Popover for Start date picker -->
  <ion-popover #dateStartModal [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime [(ngModel)]="viewData.plan.date_start" id="dateStartPicker" #dateStartPicker
        (ionChange)="dateChanged($event)" (ionInput)="dateChanged($event)"></ion-datetime>
    </ng-template>
  </ion-popover>

  <!-- Popover for End date picker -->
  <ion-popover #dateEndModal [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime [(ngModel)]="viewData.plan.date_end" id="dateEndPicker" #dateEndPicker
        (ionChange)="dateChanged($event)" (ionInput)="dateChanged($event)"></ion-datetime>
    </ng-template>
  </ion-popover>

  <!-- Create campaign intro mode (new) -->
  <pipeline-intro-overlay
    [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions"
    [(cards)]="cards"
    [config]="introOverlayConfig"
    [hidden]="!!viewData.startManually"
    [mediaPickerOptions]="introOverlayMediaPickerOptions"
    (onContinue)="aiCreate()" (onSkip)="startManually()"
    [(view)]="viewData">
  </pipeline-intro-overlay>
    
  <!-- Create campaign intro mode (legacy) -->
  <div class="tool-intro-wrapper" hidden
    *ngIf="!!viewData.plan && !viewData.plan.uid && !viewData.startManually && (!eventsList || !eventsList.length)">
    <div class="container">

      <ion-card>

        <ion-card-header>
          <ion-card-title [innerHTML]="'create_campaign_ai_helper_text'|translate"></ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <ion-textarea rows="1" [(ngModel)]="viewData.createCampaignInput"
            [disabled]="viewData.loading || (viewData.uiMode === 'preview' || viewData.uiMode === 'review')"
            [placeholder]="'create_campaign_ai_helper_input_placeholder'|translate"></ion-textarea>
        </ion-card-content>

        <!-- Types -->
        <div *ngIf="!!types && !!types.length">

          <ion-card-header>
            <ion-card-title>
              <span [innerHTML]="'ai_planner_types_headline'|translate"></span>
            </ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <p [innerHTML]="'ai_planner_types_text'|translate"></p>
          </ion-card-content>

          <ion-grid class="types-grid">
            <ion-row>

              <ion-col size="6" *ngFor="let type of types; let iType = index; trackBy:trackItems">

                <ion-button class="types-btn" [class.active]="type.checked" size="block"
                  [disabled]="!!viewData.loading || !!viewData.generating" [fill]="type.checked ? 'solid' : 'outline'"
                  color="primary" (click)="toggleType(type, iType)">
                  <ion-icon [name]="type.icon"></ion-icon>
                  <ion-label [innerHTML]="type.name|translate"></ion-label>
                </ion-button>

              </ion-col>

            </ion-row>
          </ion-grid>

        </div>

        <ion-grid>
          <ion-row>

            <!-- AI create -->
            <ion-col [size]="viewData.isDesktop ? 6 : 12">

              <ion-spinner [hidden]="!viewData.loading"></ion-spinner>

              <ion-button size="block" (click)="aiCreate()" shape="round" [hidden]="viewData.loading"
                [disabled]="!viewData.createCampaignInput || !viewData.createCampaignInput.length">
                <ion-icon name="sparkles-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'generate'|translate"></ion-label>
              </ion-button>

            </ion-col>

            <!-- AI settings -->
            <ion-col [size]="viewData.isDesktop ? 6 : 12">

              <pipeline-ai-settings-picker [config]="aiSettings" context="text-generation" size="block" fill="clear"
                [mini]="false" [view]="viewData" (changed)="aiSettingsChanged($event)"></pipeline-ai-settings-picker>

            </ion-col>

            <!-- Create manually -->
            <ion-col size="12">
              <ion-button class="start-manually-btn" (click)="startManually()" color="dark" size="block" fill="clear"
                [disabled]="!!viewData.loading || !!viewData.generating">
                <ion-icon name="create-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'create_campaign_manually'|translate"></ion-label>
              </ion-button>
            </ion-col>

          </ion-row>

        </ion-grid>

      </ion-card>

    </div>
  </div>

  <!-- Main container -->
  <div [class.container]="!eventsList || !eventsList.length"
    [class.container-fluid]="!!eventsList && !!eventsList.length">

    <!-- Intro card (mobile)-->
    <pipeline-intro-card class="ion-padding" *ngIf="!viewData.isDesktop && !!viewData.introCard"
      [hidden]="!!viewData.introCard && viewData.introCard.hidden" class="introCard"
      [(view)]="viewData.introCard"></pipeline-intro-card>

    <!-- Main grid -->
    <ion-grid>
      <ion-row>

        <!-- step 1: configure viewData.planing plan-->
        <ion-col class="sidebar" [size]="viewData.colSize.left" [hidden]="!viewData.hasSidebar">

          <!-- Types (Create & edit mode) -->
          <ion-card class="sidebar-accordion-card" *ngIf="!!types && !!types.length"
            [hidden]="viewData.uiMode === 'review'">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'ai_planner_types_headline'|translate"></span>
              </ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.types" class="card-toggle" (click)="toggleCard('types')" icon-only
                color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.types && !!cards.types.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-card-content [hidden]="!!cards && !!cards.types && !cards.types.open">
              <p [innerHTML]="'ai_planner_types_text'|translate"></p>
            </ion-card-content>

            <ion-grid class="types-grid" [hidden]="!!cards && !!cards.types && !cards.types.open">
              <ion-row>

                <ion-col size="6" *ngFor="let type of types; let iType = index; trackBy:trackItems">

                  <ion-button class="types-btn" [class.active]="type.checked" size="block"
                    [fill]="type.checked ? 'solid' : 'outline'" color="primary" (click)="toggleType(type, iType)">
                    <ion-icon [name]="type.icon"></ion-icon>
                    <ion-label [innerHTML]="type.name|translate"></ion-label>
                  </ion-button>

                </ion-col>

              </ion-row>
            </ion-grid>

          </ion-card>

          <!-- General settings -->
          <ion-card class="sidebar-accordion-card" [hidden]="!viewData.hasSelectedTypes">

            <ion-card-header>

              <ion-card-title [innerHTML]="'information'|translate"></ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.information" class="card-toggle" (click)="toggleCard('information')"
                icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.information && !!cards.information.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-list [hidden]="!!cards && !!cards.information && !cards.information.open">

              <ion-item>
                <ion-icon name="eye-outline" slot="start"></ion-icon>
                <ion-input type="text" [(ngModel)]="viewData.plan.title" [label]="'title'|translate"
                  [disabled]="viewData.uiMode === 'preview' || viewData.uiMode === 'review'" labelPlacement="stacked"
                  [placeholder]="'calendar_name'|translate" (ionChange)="hasChanges()"
                  (ionInput)="hasChanges()"></ion-input>
              </ion-item>

              <ion-item lines="none">

                <ion-icon name="funnel-outline" slot="start"></ion-icon>

                <tag-input (onAdd)="onTagAdd($event)" (onRemove)="onTagRemove($event)"
                  [editable]="(viewData.uiMode !== 'preview') && (viewData.uiMode !== 'review')"
                  [removable]="(viewData.uiMode !== 'preview') && (viewData.uiMode !== 'review')"
                  [disabled]="!viewData.plan.title || (viewData.uiMode === 'preview') || (viewData.uiMode === 'review')"
                  [(ngModel)]="viewData.topics" [identifyBy]="'uid'" [displayBy]="'title'"
                  [placeholder]="'ai_planner_topics_placeholder'|translate"
                  [secondaryPlaceholder]="'ai_planner_topics_secondary_placeholder_short'|translate"
                  [maxItems]="10"></tag-input>

                <ion-button (click)="presentInfoPopover($event, 'ai_planner_information_text')" icon-only fill="clear"
                  color="dark" slot="end">
                  <ion-icon name="information-circle-outline"></ion-icon>
                </ion-button>

              </ion-item>

              <!-- Recommendations -->
              <div
                *ngIf="!!viewData.recommendations && !!viewData.recommendations.topics && !!viewData.recommendations.topics.length">

                <ion-card-header>
                  <ion-card-subtitle [innerHTML]="'recommendations'|translate"></ion-card-subtitle>
                </ion-card-header>

                <ion-card-content *ngIf="!!viewData.recommendations.prompt_suffix">
                  <ion-chip *ngFor="let tag of viewData.recommendations.prompt_suffix"
                    (click)="addRecommendation(tag, 'prompt_suffix')">
                    <ion-label [innerHTML]="tag.title|translate"></ion-label>
                  </ion-chip>
                </ion-card-content>

              </div>

            </ion-list>

          </ion-card>

          <!-- creation settings -->
          <ion-card class="sidebar-accordion-card"
            [hidden]="viewData.loading || (!viewData.plan || !viewData.plan.title || !viewData.plan.date_end) || !viewData.topics || !viewData.topics.length || (viewData.uiMode === 'preview') || (viewData.uiMode === 'review')"
            *ngIf="!!shortcodes && !!viewData.plan && !!viewData.plan.uid">

            <ion-card-header>

              <ion-card-title [innerHTML]="'creation'|translate"></ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.creation" class="card-toggle" (click)="toggleCard('creation')"
                icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.creation && !!cards.creation.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-list lines="none" [hidden]="!!cards && !!cards.creation && !cards.creation.open">

              <ion-item [disabled]="(viewData.uiMode === 'preview') || (viewData.uiMode === 'review')"
                [hidden]="!viewData.expertMode || !viewData.plan || !viewData.plan.title || !viewData.topics || !viewData.topics.length"
                lines="none">

                <ion-icon name="document-text-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'ai_planner_use_project_posts_label'|translate"></ion-label>

                <ion-button class="ion-text-wrap"
                  [disabled]="(viewData.uiMode === 'preview') || (viewData.uiMode === 'review')" fill="outline"
                  size="small" slot="end" color="primary" (click)="filterProjectPosts()">
                  <ion-label [innerHTML]="'select'|translate"></ion-label>
                </ion-button>

              </ion-item>

              <ion-item [disabled]="(viewData.uiMode === 'preview') || (viewData.uiMode === 'review')"
                [hidden]="(!viewData.selectedTypesList || !viewData.selectedTypesList.social) || !viewData.expertMode || !viewData.plan || !viewData.plan.title || !viewData.topics || !viewData.topics.length"
                lines="none">

                <ion-icon name="images-outline" slot="start"></ion-icon>

                <ion-checkbox [(ngModel)]="aiSettings.auto_create_creatives" justify="start" labelPlacement="end"
                  [innerHTML]="'ai_planner_auto_create_creatives'|translate"></ion-checkbox>

              </ion-item>

            </ion-list>

          </ion-card>

          <!-- Channels -->
          <pipeline-channels-picker [(cards)]="cards"
            [disabled]="(viewData.uiMode === 'preview') || (viewData.uiMode === 'review')"
            [hidden]="!viewData.selectedTypesList || !viewData.selectedTypesList.social || !viewData.topics || !viewData.topics.length"
            [item]="viewData.plan" (changed)="onChannelsChanged($event)"></pipeline-channels-picker>

          <!-- Target groups -->
          <pipeline-target-groups-picker [(cards)]="cards"
            [disabled]="(viewData.uiMode === 'preview') || (viewData.uiMode === 'review')"
            [hidden]="!viewData.hasSelectedTypes || !viewData.expertMode || !viewData.topics || !viewData.topics.length"
            *ngIf="!!viewData.selectedTypesList && !!viewData.selectedTypesList.newsletter"></pipeline-target-groups-picker>

          <!-- duration settings -->
          <ion-card class="sidebar-accordion-card"
            [hidden]="!viewData.hasSelectedTypes || !viewData.plan.title || !viewData.topics || !viewData.topics.length">

            <ion-card-header>

              <ion-card-title [innerHTML]="'duration'|translate"></ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.duration" class="card-toggle" (click)="toggleCard('duration')"
                icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.duration && !!cards.duration.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-list [hidden]="!!cards && !!cards.duration && !cards.duration.open">

              <!-- Start date -->
              <ion-item class="ion-text-wrap"
                [disabled]="!viewData.plan.title || (viewData.uiMode === 'preview') || (viewData.uiMode === 'review')">
                <ion-label [innerHTML]="'date_start'|translate"></ion-label>
                <ion-datetime-button datetime="dateStartPicker"
                  [disabled]="!viewData.plan.title || (viewData.uiMode === 'preview') || (viewData.uiMode === 'review')"
                  slot="end"></ion-datetime-button>
              </ion-item>

              <!-- Continuous generation -->
              <ion-item class="ion-text-wrap">
                <ion-checkbox [(ngModel)]="aiSettings.continuous_generation" justify="start" labelPlacement="end"
                  [innerHTML]="'continuous_generation'|translate"></ion-checkbox>
              </ion-item>

              <!-- End date -->
              <ion-item class="ion-text-wrap" [hidden]="aiSettings.continuous_generation"
                [disabled]="!viewData.plan.title || (viewData.uiMode === 'preview') || (viewData.uiMode === 'review')">
                <ion-label [innerHTML]="'date_end'|translate"></ion-label>
                <ion-datetime-button datetime="dateEndPicker"
                  [disabled]="!viewData.plan.title || (viewData.uiMode === 'preview') || (viewData.uiMode === 'review')"
                  slot="end"></ion-datetime-button>
              </ion-item>

              <!-- Weekdays -->
              <ion-item button (click)="chooseWeekdays()" lines="none"
                [disabled]="!viewData.plan.title || (viewData.uiMode === 'preview') || (viewData.uiMode === 'review')"
                [hidden]="!viewData.expertMode">
                <ion-icon name="calendar-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'weekdays'|translate"></ion-label>
                <ion-badge slot="end" *ngFor="let weekday of viewData.plan.weekdays"
                  [innerHTML]="weekday|translate"></ion-badge>
                <ion-note slot="end" *ngIf="!viewData.plan.weekdays || !viewData.plan.weekdays.length"
                  [innerHTML]="'select'|translate"></ion-note>
              </ion-item>

            </ion-list>

          </ion-card>

          <!-- media-->
          <pipeline-media-picker-card [(cards)]="cards"
            [hidden]="(!viewData.plan || !viewData.plan.title || !viewData.plan.date_end) || !viewData.hasSelectedTypes || !viewData.topics || !viewData.topics.length"
            [(item)]="viewData.plan" [(items)]="viewData.mediaList" [options]="mediaPickerOptions" [(view)]="viewData"
            (onMediaChanged)="onMediaChanged($event)"></pipeline-media-picker-card>

          <!-- Call to action -->
          <ion-card class="sidebar-accordion-card"
            [hidden]="!viewData.selectedTypesList || !viewData.selectedTypesList.social || !viewData.topics || !viewData.topics.length">

            <ion-card-header>

              <ion-card-title (click)="toggleCard('cta')" [innerHTML]="'call_to_action'|translate"></ion-card-title>

              <ion-button class="card-toggle" (click)="toggleCard('cta')" icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.cta && !!cards.cta.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-searchbar [(ngModel)]="viewData.ctaInput" [hidden]="!cards || !cards.cta || !cards.cta.open"
              [placeholder]="'ai_planner_cta_input_placeholder'|translate"
              (ionInput)="validateCTAInput()"></ion-searchbar>

          </ion-card>

          <!-- i18n -->
          <ion-card class="sidebar-accordion-card"
            [hidden]="(!viewData.plan || !viewData.plan.title || !viewData.plan.date_end) || !viewData.hasSelectedTypes || !viewData.topics || !viewData.topics.length">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'multilingual'|translate"></span>
              </ion-card-title>

              <!--
              <ion-button *ngIf="!!cards && !!cards.multilingual" class="card-toggle"
                (click)="toggleCard('multilingual')" icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.multilingual && !!cards.multilingual.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>
              -->

            </ion-card-header>

            <!-- Output language -->
            <pipeline-languages-select
              [disabled]="!viewData.plan.title || (viewData.uiMode === 'preview') || (viewData.uiMode === 'review')"
              icon="language-outline" [label]="'languages'|translate" [options]="languageOptions"
              [(input)]="viewData.output_languages" (changed)="onLanguageChange($event)"></pipeline-languages-select>

          </ion-card>

          <!-- ai styles -->
          <pipeline-ai-style-picker [(cards)]="cards"
            [disabled]="(viewData.uiMode === 'preview') || (viewData.uiMode === 'review')"
            [hidden]="(!viewData.plan || !viewData.plan.title || !viewData.plan.date_end) || !viewData.hasSelectedTypes || !viewData.expertMode || !viewData.topics || !viewData.topics.length"
            [(view)]="viewData"></pipeline-ai-style-picker>

          <!-- AI Settings -->
          <pipeline-ai-settings-card [(cards)]="cards" [(config)]="aiSettings" [options]="aiSettingsOptions"
            [(view)]="viewData"
            [hidden]="(!viewData.plan || !viewData.plan.title || !viewData.plan.date_end) || !viewData.hasSelectedTypes || !viewData.topics || !viewData.topics.length"></pipeline-ai-settings-card>

          <!-- prompts settings -->
          <ion-card class="sidebar-accordion-card"
            [hidden]="viewData.loading || !viewData.expertMode || (!viewData.plan || !viewData.plan.title || !viewData.plan.date_end) || !viewData.topics || !viewData.topics.length || (viewData.uiMode === 'preview') || (viewData.uiMode === 'review')"
            *ngIf="!!shortcodes && !!viewData.plan && !!viewData.plan.uid">

            <ion-card-header>

              <ion-card-title [innerHTML]="'prompts'|translate"></ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.prompts" class="card-toggle" (click)="toggleCard('prompts')"
                icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.prompts && !!cards.prompts.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-list lines="none" [hidden]="!!cards && !!cards.prompts && !cards.prompts.open">

              <div *ngFor="let shortcode of shortcodes">

                <ion-item [disabled]="!viewData.plan.title">
                  <ion-icon name="code-download-outline" slot="start" (click)="toggleShortcode(shortcode)"></ion-icon>
                  <ion-label class="ion-text-wrap" [innerHTML]="'ai_prompt_select_' + shortcode.uid|translate"
                    (click)="toggleShortcode(shortcode)"></ion-label>

                  <ion-button slot="end" (click)="chooseAiShortcode(shortcode)" fill="outline" color="dark">
                    <ion-label
                      [innerHTML]="(!!viewData.plan.shortcodes[shortcode.uid] ? viewData.plan.shortcodes[shortcode.uid].name : 'select')|translate"></ion-label>
                  </ion-button>

                  <ion-icon *ngIf="!!viewData.plan.shortcodes[shortcode.uid]"
                    [name]="shortcode.checked ? 'chevron-up-outline' : 'chevron-down-outline'" slot="end"
                    (click)="toggleShortcode(shortcode)"></ion-icon>
                </ion-item>

                <ion-item [hidden]="!shortcode.checked" *ngIf="!!viewData.plan.shortcodes[shortcode.uid]">
                  <ion-icon name="chatbubble-outline" slot="start"></ion-icon>
                  <ion-textarea [(ngModel)]="viewData.plan.shortcodes[shortcode.uid].prompt"
                    [disabled]="viewData.uiMode === 'preview' || viewData.uiMode === 'review'"
                    [label]="'ai_prompt_custom'|translate" rows="5" labelPlacement="stacked"
                    [placeholder]="'ai_prompt_custom_placeholder'|translate"></ion-textarea>
                </ion-item>

              </div>
            </ion-list>

          </ion-card>

        </ion-col>

        <!-- step 2: view / edit viewData.planing plan -->
        <ion-col class="content" [size]="viewData.colSize.right">

          <!-- Intro card (desktop) -->
          <pipeline-intro-card class="introCard" [(view)]="viewData.introCard"
            *ngIf="(!viewData.plan || !viewData.plan.uid) && !!viewData.isDesktop && !!viewData.introCard"
            [hidden]="viewData.introCard.hidden || (!!eventsList && !!eventsList.length)"></pipeline-intro-card>

          <!-- Spline view -->
          <pipeline-spline-viewer [(options)]="splineOptions"
            *ngIf="!!viewData.isDesktop && (!eventsList || !eventsList.length)"></pipeline-spline-viewer>

          <!-- No entries card -->
          <pipeline-no-entries-card
            *ngIf="!!viewData.generated && !!viewData && !!viewData.plan && !!viewData.plan.uid && (eventsList && !eventsList.length)"></pipeline-no-entries-card>

          <!-- Calendar (after entries loaded) -->
          <pipeline-calendar *ngIf="!!eventsList && !!eventsList.length" [(cards)]="cards" [(events)]="eventsList"
            (selectedItemsChanged)="onSelectedEventsChanged($event)" [options]="calendarOptions"
            [selectionOptions]="selectionOptions">
          </pipeline-calendar>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border"
  *ngIf="!(!!viewData.plan && !viewData.plan.uid && !viewData.startManually && (!eventsList || !eventsList.length))">
  <ion-toolbar class="container ion-no-border">

    <!-- Run AI optimization -->
    <ion-card class="aiPromptCard" [hidden]="!viewData.expertMode">
      <form (ngSubmit)="runAiPrompt()">
        <ion-grid>
          <ion-row>

            <ion-col [size]="viewData.isDesktop ? 1 : 2">

              <ion-fab class="addToPostFab" (click)="addEvent()" horizontal="end" vertical="top">
                <ion-fab-button color="primary" size="small">
                  <ion-icon name="add-outline"></ion-icon>
                </ion-fab-button>
              </ion-fab>

            </ion-col>

            <ion-col [size]="viewData.isDesktop ? 11 : 10">
              <ion-item lines="none">

                <ion-textarea name="aiPrompt" #aiPromptPlannerInput rows="2" [(ngModel)]="viewData.aiPrompt"
                  [disabled]="!!viewData.loading"
                  [placeholder]="'ai_prompt_calendar_modification'|translate"></ion-textarea>

                <ion-button (click)="runAiPrompt()" expand="block" [disabled]="viewData.loading"
                  [size]="viewData.isDesktop ? 'medium' : 'small'" color="primary" shape="round">
                  <ion-label [innerHTML]="'continue' | translate" [hidden]="!viewData.isDesktop"></ion-label>
                  <ion-icon name="chevron-forward-outline" slot="end"></ion-icon>
                </ion-button>

              </ion-item>
            </ion-col>

          </ion-row>
        </ion-grid>
      </form>
    </ion-card>

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="viewData"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>