import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { CategoriesService } from 'src/app/services/pipeline/categories.service';
import { CitiesService } from 'src/app/services/geo/cities.service';
import { CountriesService } from 'src/app/services/geo/countries.service';
import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { TargetgroupsService } from "src/app/services/target-groups/target-groups.service";
import { UserService } from 'src/app/services/core/user.service';
import { WhitelabelService } from 'src/app/services/pipeline/whitelabel.service';

import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';

declare var google;

@Component({
  selector: 'app-create-target-group',
  standalone: false,
  templateUrl: './create-target-group.page.html',
  styleUrls: ['./create-target-group.page.scss'],
})
export class CreateTargetGroupPage implements OnInit {

  address: string;

  languageOptions: languagesSelectOptions = {
    cached: false,
    multiple: true,
    mode: 'view',
  };

  latitude: number;
  longitude: number;

  map: any;

  targetGroup: targetGroup = {};

  view: any = {
    ageSelector: {
      min: 18,
      max: 99,
    },
    age: {
      lower: 18,
      upper: 65,
    },
    countries: [1],
    genders: ['all'],
    languages: ['en'],
    platform: {
      apps: [],
      availableApps: [],
      availableDevices: ['all', 'desktop', 'smartphone', 'tablet', 'tvos', 'watchos'],
      devices: [],
    }
  };

  constructor(
    private categories: CategoriesService,
    private cities: CitiesService,
    private countries: CountriesService,
    private events: EventsService,
    private modalService: ModalService,
    private nativeGeocoder: NativeGeocoder,
    private navParams: NavParams,
    private targetGroupsService: TargetgroupsService,
    private userService: UserService,
    private whitelabel: WhitelabelService,
  ) {
  }

  calcViewVars() {
    let currentLanguage: string = this.userService.getLanguage();

    this.targetGroup.genders = ['all'];
    this.targetGroup.languages = [currentLanguage];
    this.targetGroup.min_age = this.view.age.lower;
    this.targetGroup.max_age = this.view.age.upper;
    this.targetGroup.user = this.userService.getUid();

    this.view.gendersData = ['all'].concat(this.userService.getGenders());

    this.view.isModal = this.modalService.isModal();

    this.view.languages = [currentLanguage];

    let targetGroup: targetGroup = this.navParams.get('targetGroup');

    if (targetGroup) {
      this.view.age.upper = targetGroup.max_age;
      this.view.age.lower = targetGroup.min_age;
      this.view.mode = 'edit';
      this.targetGroup = targetGroup;
    }
  }

  create() {
    this.targetGroupsService.create(this.targetGroup)
      .then((response: any) => {
        this.dismiss(response, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async dismiss(data: any|null = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  getAddressFromCoords(lattitude, longitude) {

    const options: NativeGeocoderOptions = {
      useLocale: true,
      maxResults: 5
    };

    this.nativeGeocoder.reverseGeocode(lattitude, longitude, options)
      .then((result: NativeGeocoderResult[]) => {
        this.address = "";
        let responseAddress = [];
        for (let [key, value] of Object.entries(result[0])) {
          if (value.length > 0)
            responseAddress.push(value);

        }
        responseAddress.reverse();
        for (let value of responseAddress) {
          this.address += value + ", ";
        }
        this.address = this.address.slice(0, -2);
      })
      .catch((error: any) => {
        this.address = "Address Not Available!";
      });

  }

  initMap() {
    let latLng = new google.maps.LatLng(51, 9); // Germany

    let mapOptions = {
      center: latLng,
      zoom: 6,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }

    this.map = new google.maps.Map(document.getElementById('targetGroupMap'), mapOptions);

    this.map.addListener('dragend', () => {

      this.latitude = this.map.center.lat();
      this.longitude = this.map.center.lng();

      this.getAddressFromCoords(this.map.center.lat(), this.map.center.lng())
    });
  }

  async loadApps() {
    this.view.platform.availableApps = await this.whitelabel.getAppsByPlanUid(1);
  }

  async loadCountries() {
    this.view.countriesData = await this.countries.getCountries();
  }

  ngOnInit() {
    this.loadCountries();
    this.calcViewVars();

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });

    this.view.languages = [this.userService.getLanguage() || 'en'];

    this.initMap();
    this.loadApps();
  }

  onAgeChanged() {
    this.targetGroup.min_age = this.view.age.lower;
    this.targetGroup.max_age = this.view.age.upper;
  }

  onCountriesChanged() {
    this.targetGroup.countries = this.view.countries;
  }

  onGendersChanged() {
    this.targetGroup.genders = JSON.parse(JSON.stringify(this.view.genders));
  }

  onInterestsChanged() {
    this.targetGroup.interests = this.view.interests;
  }

  onLanguagesChanged() {
    this.targetGroup.languages = this.view.languages;
  }

  pickCategories() {
    this.categories.pick({
      multiple: true,
    })
      .then((categories: category[]) => {
        this.view.categories = categories;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  pickCities() {
    this.cities.pick({
      multiple: true,
    })
      .then((response: any) => {
        let cities: city[] = (!!response && !!response.data && !!response.data.items ? response.data.items : []);
        console.log('> cities', cities);
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  platformAppsChanged() {
    this.targetGroup.platform = this.targetGroup.platform || {};
    this.targetGroup.platform.apps = this.view.platform.apps;
  }

  platformDevicesChanged() {
    this.targetGroup.platform = this.targetGroup.platform || {};
    this.targetGroup.platform.devices = this.view.platform.devices;
  }

  update() {
    this.targetGroupsService.update(this.targetGroup)
      .then((response: any) => {
        this.dismiss(response, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

}