import { Component, Input, OnInit } from '@angular/core';

import { FontsService } from 'src/app/services/utils/fonts.service';

@Component({
  selector: 'pipeline-google-font-preview-card',
  standalone: false,
  templateUrl: './google-font-preview-card.component.html',
  styleUrls: ['./google-font-preview-card.component.scss'],
})
export class GoogleFontPreviewCardComponent implements OnInit {
  @Input() font: font;

  view: any = {
  };

  constructor(
    private fonts: FontsService,
  ) {

  }

  ngOnInit() {
    if (!!this.font && !!this.font.family && !!this.font.category) {
      console.log('font', this.font);

      this.fonts.loadFontToDocument(this.font);
      this.view.font_family = `'${this.font.family}', ${this.font.category}`;
    }
  }

}