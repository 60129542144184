import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import { ChooserService } from 'src/app/services/utils/chooser.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ToolsService } from 'src/app/services/utils/tools.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateEditorService {

  currentTemplate: mediaTemplate;

  layerTypes: any[] = [
    {
      icon: 'musical-notes-outline',
      name: 'audio',
      uid: 'audio',
    },
    {
      icon: 'text-outline',
      name: 'text',
      uid: 'text',
    },
    {
      icon: 'shapes-outline',
      name: 'rectangle',
      uid: 'rectangle',
    },
    {
      icon: 'image-outline',
      name: 'image',
      uid: 'image',
    },
    {
      icon: 'film-outline',
      name: 'video',
      uid: 'video',
    },
    {
      icon: 'extension-puzzle-outline',
      name: 'lottie',
      uid: 'lottie',
    }
  ]

  settingsCards: any[] = [
    {
      active: true,
      icon: 'eye-outline',
      name: 'style',
      settings: [
        {
          icon: 'swap-horizontal-outline',
          label: 'y',
          default: 5,
          size: 6,
          type: 'number',
          min: 0,
          max: 100,
          uid: 'y',
        },
        {
          icon: 'swap-vertical-outline',
          label: 'x',
          default: 5,
          size: 6,
          type: 'number',
          min: 0,
          max: 100,
          uid: 'x',
        },
        {
          label: 'width',
          default: 50,
          size: 6,
          type: 'number',
          min: 0,
          max: 100,
          uid: 'width',
        },
        {
          label: 'height',
          default: 50,
          size: 6,
          type: 'number',
          min: 0,
          max: 100,
          uid: 'height',
        },
        {
          icon: 'color-palette-outline',
          label: 'background-color',
           default: 'transparent',
           type: 'color',
           uid: 'background-color',
        },
      ]
    },
    {
      icon: 'text-outline',
      name: 'content',
      settings: [
        {
          label: 'content',
           default: 'Enter your text here',
           type: 'string',
           uid: 'value',  
        },
        {
          icon: 'color-palette-outline',
          label: 'color',
           default: '#000000',
           type: 'color',
           uid: 'color',  
        },
        {
          label: 'font-family',
           default: 'Arial',
           type: 'choose',
           uid: 'font-family',
           valueKey: '',
           values: []
        },
        {
          label: 'font-size',
           default: '50px',
           type: 'string',
           uid: 'font-size',
        },
        {
          label: 'line-clamp',
           default: '0',
           type: 'number',
           uid: '-webkit-line-clamp',
        },
        {
          label: 'font-weight',
           default: 'normal',
           type: 'select',
           uid: 'font-weight',  
           values: [
            {
              label: '100',
              uid: '100',
            },
            {
              label: '200',
              uid: '200',
            },
            {
              label: '300',
              uid: '300',
            },
            {
              label: '400',
              uid: '400',
            },
            {
              label: '500',
              uid: '500',
            },
            {
              label: '600',
              uid: '600',
            },
            {
              label: '700',
              uid: '700',
            },
            {
              label: '800',
              uid: '800',
            },
            {
              label: '900',
              uid: '900',
            },
           ]
        },
        {
          label: 'line-height',
           default:  '20px',
           type: 'string',
           uid: 'line-height',  
        }
      ],
      type: 'text',
    },
    {
      icon: 'arrow-forward-outline',
      name: 'animations',
      settings: [
        {
          label: 'start',
           default: 1,
           size: 6,
           type: 'number',
           uid: 'start',
        },
        {
          label: 'end',
           default: 15,
           size: 6,
           type: 'number',
           uid: 'end',
        },
        {
          label: 'transition-timing-function',
          default: 'linear',
          size: 12,
          type: 'string',
          uid: 'transition-timing-function',
        },
        {
          label: 'animation_name',
           default: '',
           type: 'select',
           uid: 'animation_name',
           values: [
            {
              "label": "bounce",
              "value": "bounce"
            },
            {
              "label": "flash",
              "value": "flash"
            },
            {
              "label": "pulse",
              "value": "pulse"
            },
            {
              "label": "rubberBand",
              "value": "rubberBand"
            },
            {
              "label": "shakeX",
              "value": "shakeX"
            },
            {
              "label": "shakeY",
              "value": "shakeY"
            },
            {
              "label": "headShake",
              "value": "headShake"
            },
            {
              "label": "swing",
              "value": "swing"
            },
            {
              "label": "tada",
              "value": "tada"
            },
            {
              "label": "wobble",
              "value": "wobble"
            },
            {
              "label": "jello",
              "value": "jello"
            },
            {
              "label": "heartBeat",
              "value": "heartBeat"
            },
            {
              "label": "backInDown",
              "value": "backInDown"
            },
            {
              "label": "backInLeft",
              "value": "backInLeft"
            },
            {
              "label": "backInRight",
              "value": "backInRight"
            },
            {
              "label": "backInUp",
              "value": "backInUp"
            },
            {
              "label": "backOutDown",
              "value": "backOutDown"
            },
            {
              "label": "backOutLeft",
              "value": "backOutLeft"
            },
            {
              "label": "backOutRight",
              "value": "backOutRight"
            },
            {
              "label": "backOutUp",
              "value": "backOutUp"
            },
            {
              "label": "bounceIn",
              "value": "bounceIn"
            },
            {
              "label": "bounceInDown",
              "value": "bounceInDown"
            },
            {
              "label": "bounceInLeft",
              "value": "bounceInLeft"
            },
            {
              "label": "bounceInRight",
              "value": "bounceInRight"
            },
            {
              "label": "bounceInUp",
              "value": "bounceInUp"
            },
            {
              "label": "bounceOut",
              "value": "bounceOut"
            },
            {
              "label": "bounceOutDown",
              "value": "bounceOutDown"
            },
            {
              "label": "bounceOutLeft",
              "value": "bounceOutLeft"
            },
            {
              "label": "bounceOutRight",
              "value": "bounceOutRight"
            },
            {
              "label": "bounceOutUp",
              "value": "bounceOutUp"
            },
            {
              "label": "fadeIn",
              "value": "fadeIn"
            },
            {
              "label": "fadeInDown",
              "value": "fadeInDown"
            },
            {
              "label": "fadeInDownBig",
              "value": "fadeInDownBig"
            },
            {
              "label": "fadeInLeft",
              "value": "fadeInLeft"
            },
            {
              "label": "fadeInLeftBig",
              "value": "fadeInLeftBig"
            },
            {
              "label": "fadeInRight",
              "value": "fadeInRight"
            },
            {
              "label": "fadeInRightBig",
              "value": "fadeInRightBig"
            },
            {
              "label": "fadeInUp",
              "value": "fadeInUp"
            },
            {
              "label": "fadeInUpBig",
              "value": "fadeInUpBig"
            },
            {
              "label": "fadeInTopLeft",
              "value": "fadeInTopLeft"
            },
            {
              "label": "fadeInTopRight",
              "value": "fadeInTopRight"
            },
            {
              "label": "fadeInBottomLeft",
              "value": "fadeInBottomLeft"
            },
            {
              "label": "fadeInBottomRight",
              "value": "fadeInBottomRight"
            },
            {
              "label": "fadeOut",
              "value": "fadeOut"
            },
            {
              "label": "fadeOutDown",
              "value": "fadeOutDown"
            },
            {
              "label": "fadeOutDownBig",
              "value": "fadeOutDownBig"
            },
            {
              "label": "fadeOutLeft",
              "value": "fadeOutLeft"
            },
            {
              "label": "fadeOutLeftBig",
              "value": "fadeOutLeftBig"
            },
            {
              "label": "fadeOutRight",
              "value": "fadeOutRight"
            },
            {
              "label": "fadeOutRightBig",
              "value": "fadeOutRightBig"
            },
            {
              "label": "fadeOutUp",
              "value": "fadeOutUp"
            },
            {
              "label": "fadeOutUpBig",
              "value": "fadeOutUpBig"
            },
            {
              "label": "fadeOutTopLeft",
              "value": "fadeOutTopLeft"
            },
            {
              "label": "fadeOutTopRight",
              "value": "fadeOutTopRight"
            },
            {
              "label": "fadeOutBottomRight",
              "value": "fadeOutBottomRight"
            },
            {
              "label": "fadeOutBottomLeft",
              "value": "fadeOutBottomLeft"
            },
            {
              "label": "flip",
              "value": "flip"
            },
            {
              "label": "flipInX",
              "value": "flipInX"
            },
            {
              "label": "flipInY",
              "value": "flipInY"
            },
            {
              "label": "flipOutX",
              "value": "flipOutX"
            },
            {
              "label": "flipOutY",
              "value": "flipOutY"
            },
            {
              "label": "lightSpeedInRight",
              "value": "lightSpeedInRight"
            },
            {
              "label": "lightSpeedInLeft",
              "value": "lightSpeedInLeft"
            },
            {
              "label": "lightSpeedOutRight",
              "value": "lightSpeedOutRight"
            },
            {
              "label": "lightSpeedOutLeft",
              "value": "lightSpeedOutLeft"
            },
            {
              "label": "rotateIn",
              "value": "rotateIn"
            },
            {
              "label": "rotateInDownLeft",
              "value": "rotateInDownLeft"
            },
            {
              "label": "rotateInDownRight",
              "value": "rotateInDownRight"
            },
            {
              "label": "rotateInUpLeft",
              "value": "rotateInUpLeft"
            },
            {
              "label": "rotateInUpRight",
              "value": "rotateInUpRight"
            },
            {
              "label": "rotateOut",
              "value": "rotateOut"
            },
            {
              "label": "rotateOutDownLeft",
              "value": "rotateOutDownLeft"
            },
            {
              "label": "rotateOutDownRight",
              "value": "rotateOutDownRight"
            },
            {
              "label": "rotateOutUpLeft",
              "value": "rotateOutUpLeft"
            },
            {
              "label": "rotateOutUpRight",
              "value": "rotateOutUpRight"
            },
            {
              "label": "hinge",
              "value": "hinge"
            },
            {
              "label": "jackInTheBox",
              "value": "jackInTheBox"
            },
            {
              "label": "rollIn",
              "value": "rollIn"
            },
            {
              "label": "rollOut",
              "value": "rollOut"
            },
            {
              "label": "zoomIn",
              "value": "zoomIn"
            },
            {
              "label": "zoomInDown",
              "value": "zoomInDown"
            },
            {
              "label": "zoomInLeft",
              "value": "zoomInLeft"
            },
            {
              "label": "zoomInRight",
              "value": "zoomInRight"
            },
            {
              "label": "zoomInUp",
              "value": "zoomInUp"
            },
            {
              "label": "zoomOut",
              "value": "zoomOut"
            },
            {
              "label": "zoomOutDown",
              "value": "zoomOutDown"
            },
            {
              "label": "zoomOutLeft",
              "value": "zoomOutLeft"
            },
            {
              "label": "zoomOutRight",
              "value": "zoomOutRight"
            },
            {
              "label": "zoomOutUp",
              "value": "zoomOutUp"
            },
            {
              "label": "slideInDown",
              "value": "slideInDown"
            },
            {
              "label": "slideInLeft",
              "value": "slideInLeft"
            },
            {
              "label": "slideInRight",
              "value": "slideInRight"
            },
            {
              "label": "slideInUp",
              "value": "slideInUp"
            },
            {
              "label": "slideOutDown",
              "value": "slideOutDown"
            },
            {
              "label": "slideOutLeft",
              "value": "slideOutLeft"
            },
            {
              "label": "slideOutRight",
              "value": "slideOutRight"
            },
            {
              "label": "slideOutUp",
              "value": "slideOutUp"
            }
          ],
        },
        {
          label: 'animation_delay',
           default: 0,
           type: 'number',
           uid: 'animation_delay',
        },
        {
          label: 'animate_repeat',
           default: 0,
           type: 'number',
           uid: '--animate-repeat',
        },
        {
          label: 'animation_speed',
           default: '',
           type: 'select',
           uid: 'animation_speed',
           values: [
            {
              label: 'slow',
              value: 'slow',
            },
            {
              label: 'slower',
              value: 'slower',
            },
            {
              label: 'fast',
              value: 'fast',
            },
            {
              label: 'faster',
              value: 'faster',
            },
           ],
        },
        {
          label: 'animation_loop_infinite',
           type: 'checkbox',
           uid: 'animation_loop',
        },
      ]
    },
    {
      icon: 'sparkles-outline',
      name: 'effect',
      settings: [
        {
          label: 'effect',
           default: 0,
           type: 'choose',
           uid: 'effect_uid',
        },
      ],
    },
    {
      icon: 'extension-puzzle-outline',
      name: 'lottie',
      settings: [
        {
          label: 'lottie_src',
          type: 'string',
          uid: 'lottie_src',  
        },
        {
          label: 'autoplay',
          default: 1,
          type: 'checkbox',
          uid: 'autoplay',  
        },
        {
          label: 'loop',
          default: 1,
          type: 'checkbox',
          uid: 'loop',  
        },
        {
          label: 'lottie_use_proxy',
          type: 'checkbox',
          uid: 'lottie_use_proxy',
        }
      ],
      type: 'lottie',
    },
    {
      icon: 'musical-notes-outline',
      name: 'audio',
      settings: [
        {
          label: 'audio_src',
           type: 'file',
           uid: 'src',
        },
        {
          label: 'audio_loop',
           type: 'checkbox',
           uid: 'audio_loop',
        },
        {
          default: 50,
          label: 'audio_volume',
           type: 'range',
           min: 0,
           max: 100,
           uid: 'audio_volume',
        },
      ],
      type: 'audio',
    },
    {
      icon: 'images-outline',
      name: 'media',
      settings: [
        {
          label: 'image_src',
           type: 'file',
           uid: 'src',  
        },
        {
          label: 'object_fit',
          type: 'select',
          uid: 'object-fit',
          values: [
            {
              "label": "cover",
              "value": "cover"
            },
            {
              "label": "contain",
              "value": "contain"
            },
            {
              "label": "fit",
              "value": "fit"
            },
            {
              "label": "scale-down",
              "value": "scale-down"
            },
          ],
        },
        {
          label: 'object_position',
          type: 'string',
          uid: 'object-position',
          /*
          values: [
            {
              "label": "top left",
              "value": "top left"
            },
            {
              "label": "top center",
              "value": "top center"
            },
            {
              "label": "top right",
              "value": "top right"
            },
            {
              "label": "center left",
              "value": "center left"
            },
            {
              "label": "center center",
              "value": "center center"
            },
            {
              "label": "center right",
              "value": "center right"
            },
            {
              "label": "bottom left",
              "value": "bottom left"
            },
            {
              "label": "bottom center",
              "value": "bottom center"
            },
            {
              "label": "bottom right",
              "value": "bottom right"
            },
          ],
          */
        },
      ],
      type: 'image',
    },
    {
      icon: 'film-outline',
      name: 'media',
      settings: [
        {
          label: 'video_src',
           type: 'file',
           uid: 'src',
        },
        {
          label: 'video_autoplay',
           type: 'checkbox',
           uid: 'video_autoplay',
        },
        {
          label: 'video_controls',
           type: 'checkbox',
           uid: 'video_loop',
        },
        {
          label: 'video_loop',
           type: 'checkbox',
           uid: 'video_loop',
        },
        {
          label: 'video_poster',
           type: 'string',
           uid: 'video_poster',
        },
        {
          label: 'video_muted',
           type: 'checkbox',
           uid: 'video_muted',
        },
      ],
      type: 'video',
    },
    {
      icon: 'information-circle-outline',
      name: 'attributes',
      settings: [
        {
          label: 'id',
          type: 'string',
          uid: 'id',
        },
        {
          label: 'cssClasses',
          type: 'string',
          uid: 'cssClasses',
        },
      ],
    },
  ];

  constructor(
    private chooser: ChooserService,
    private media: MediaextendService,
    private navCtrl: NavController,
    private tools: ToolsService,
  ) {

  }

  addAudio() {
    return new Promise((resolve, reject) => {

      let audio: any = {
        name: 'Audio',
        settings: {
          x: 0,
          y: 0,
          start: 0,
          end: 5,
          width: 0,
          height: 0,
          src: '',
        },
        type: 'audio',
      };

      resolve({
        layer: audio,
      });

    })
  }

  addImage() {
    return new Promise((resolve, reject) => {
      let method: string = (this.tools.isWeb() ? 'applyFromWeb' : 'apply');

      this.media.applyFromWeb(null, {
        multiple: false,
      })
      .then((response: any) => {
        let imageSrc: string = (!!response && !!response.items && !!response.items[0] &&  !!response.items[0].thumbnail ? response.items[0].thumbnail : null);
        
        if(!!imageSrc) {
          
          let image: any = {
            name: 'Image',
            settings: {
              start: 0,
              end: 15,
              width: 50,
              height: 50,
              x: 25,
              y: 25,
              src: imageSrc,
            },
            type: 'image',
          };

          resolve({
            layer: image,
          });

        } else {
          resolve({
            layer: null,
          });
        }
      })
      .catch(reject);
    })
  }

  addLayer(options: any = {}) {
    return new Promise((resolve, reject) => {
     
      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: this.getLayerTypes(),
        labelKey: 'name',
        multiple: false,
        service: this,
        title: 'add_layer',
        valueKey: 'uid',
      });
  
      this.chooser.choose(chooseConfig)
      .then((response: any) => {
        const selection: any = (!!response && !!response.data && !!response.data.items && !!response.data.items[0] ? response.data.items[0] : null);
        const methodName: string|null = (!!selection ? `add${this.tools.capitalize(selection.uid)}` : null);
        
        if(!!methodName) {
          try {  
            this[methodName]().then((response: any) => {

              if(!!response && !!response.layer && !!response.layer.settings && !response.layer.settings.id) {
                response.layer.settings.id = this.calculateLayerId(response.layer, options);
              }

              resolve(response);
            }).catch(reject);
          } catch(e) {
            reject(e);
          }
        } else {
          reject('not_implemented');
        }

      })
      .catch(reject);
    })
  }

  addLottie() {
    return new Promise((resolve, reject) => {

      let lottie: any = {
        name: 'Lottie',
        settings: {
          'background-color': 'transparent',
          x: 0,
          y: 0,
          start: 0,
          end: 15,
          width: 50,
          height: 50,
        },
        type: 'lottie',
      };

      resolve({
        layer: lottie,
      });

    });
  }

  addRectangle() {
    return new Promise((resolve, reject) => {

      let rectangle: any = {
        name: 'Rectangle',
        settings: {
          'background-color': '#999999',
          x: 25,
          y: 25,
          start: 0,
          end: 15,
          width: 50,
          height: 50,
        },
        type: 'rectangle',
      };

      resolve({
        layer: rectangle,
      });

    });
  }

  addText() {
    return new Promise((resolve, reject) => {

      let text: any = {
        name: 'Text',
        settings: {
          'background-color': 'transparent',
          color: '#000000',
          'font-family': 'Arial',
          'font-size': '40px',
          'line-height': '50px',
          value: 'Enter your text here',
          start: 0,
          end: 15,
          width: 50,
          height: 50,
          x: 25,
          y: 25,
        },
        type: 'text',
      };

      resolve({
        layer: text,
      });

    });
  }

  addVideo() {
    return new Promise((resolve, reject) => {
      let method: string = (this.tools.isWeb() ? 'applyFromWeb' : 'apply');

      this.media.applyFromWeb(null, {
        multiple: false,
      })
      .then((response: any) => {
        let item: any = (!!response && !!response.items && !!response.items[0] ? response.items[0] : null),
            videoSrc: string = (!!item && !!item.videoSrc ? item.videoSrc : null);
            
        if(!!videoSrc) {
          
          let video: any = {
            name: 'Video',
            settings: {
              start: 0,
              end: 15,
              width: 50,
              height: 50,
              x: 25,
              y: 25,
              src: videoSrc,
            },
            type: 'video',
          };

          resolve({
            layer: video,
          });
          
        } else {
          resolve({
            layer: null,
          });
        }

      })
      .catch(reject);
    });
  }

  calculateAnimationCss(options: any) {
    
    if(!options.layer) {
      console.warn('cannot calculate animation css, missing layer', options);
      return '';
    }

    options.selector = options.selector || '.element';
    options.animationDirection = options.animationDirection || 'both';
    options.animationDuration = options.animationDuration || 5;
    options.animationName = `${(options.animationName || options.selector).replace(/\W/g, '')}-animation`;

    let existingCss: string = (!!options.layer.settings ? `${options.layer.settings.css}` : ''),
        cleanedExistingCss = existingCss,
        firstLine: string = `/* GENERATED: START */\n`, lastLine: string = `/* GENERATED: END */\n\n`,
        lastLineSplit: string[] = existingCss.split(lastLine),
        css: string = firstLine;
    
    if(lastLineSplit.length === 1) {
    } else {
      cleanedExistingCss = lastLineSplit[1];
    }
    
    css += `${options.selector} {\n`;

    css += `\tanimation: ${options.animationName} ${options.animationDuration}s ${options.animationDirection};\n`;
    css += '}\n\n';

    css += `@keyframes ${options.animationName} {\n`;

    if(!!options.layer.keyframes && !!options.layer.keyframes.length) {
      options.layer.keyframes.forEach((keyframe: any) => {
        if(!!keyframe.properties && !!keyframe.properties.length) {
          css += `\t${keyframe.selector}% {\n`;

          keyframe.properties.forEach((property: any) => {
            if(!!property.key && (property.value !== null) && (property.value !== undefined) && (property.value !== '')) {
              css += `\t\t${property.key}: ${property.value};\n`;
            }
          });

          css += '\t}\n';
        }
      });
    }

    css += '}\n';
    css += lastLine;

    if(!!cleanedExistingCss && (cleanedExistingCss != 'undefined')) {
      css += cleanedExistingCss;
    }

    return css;
  }

  calculateLayerId(layer: any, options: any) {
    return `${layer.type || 'layer'}${(options.layers ? options.layers.length || 0 : 0)+1}`;
  }

  cleanLayerCSS(css: string) {
    css = `${css}`.replace('undefined', '');
    return css;
  }

  editCustomTemplate(template: mediaTemplate) {
    this.setTemplate(template);
    this.navCtrl.navigateForward(`/media/templates/editor/${template.uid || 'create'}`);
  }

  getCurrentTemplate() {
    return this.currentTemplate;
  }

  getLayerTypes() {
    return this.layerTypes;
  }

  getSettingsCards() {
    return this.settingsCards;
  }

  isolateLayerCSS(layer: any, template: mediaTemplate) {
    let css: string = this.cleanLayerCSS(`${layer.settings.css}`);

    if(!!template && !!template.uid && (css.indexOf('stage-template-uid') === -1)) {
      css = `.stage-template-uid-${template.uid} ${css}`;
      css = css.replace('animation: layer', `animation: template-${template.uid}-layer`);
      css = css.replace('@keyframes layer', `@keyframes template-${template.uid}-layer`);
    }

    return css;
  }

  removeUnusedStylesAndScripts() {
    try {
      var elements: any = document.querySelectorAll('.dynamic-script-element, .dynamic-style-element');
      Array.from(elements).forEach((el: any) => el.remove());
    } catch(e) {
      console.warn('failed to remove unused styles', e);
    }
  }
  
  setTemplate(template: mediaTemplate) {
    this.currentTemplate = template;
    return this;
  }

}