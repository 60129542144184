import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Component({
  selector: 'pipeline-media-picker-card',
  standalone: false,
  templateUrl: './media-picker-card.component.html',
  styleUrls: ['./media-picker-card.component.scss']
})
export class MediaPickerCardComponent implements OnDestroy, OnInit {
  @Input() cards: any;
  @Input() disabled: boolean;
  @Input() items: mediaItem[];
  @Input() item: any;
  @Input() options: any = {};
  @Input() view: any;

  @Output() onMediaChanged = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  constructor(
    private events: EventsService,
    private media: MediaextendService,
    private sidebar: SidebarService,
    private tools: ToolsService,
  ) {

  }

  public add() {
    return this.uploadAttachment('photo');
  }

  calcViewVars() {

    if (!this.view.hasOwnProperty('generate')) {
      this.view.generate = {};
    }

    this.updatePreview();
  }

  chooseMediaFromList(media: mediaItem) {
    if (!!media.photo || !!media.thumbnail) {
      this.item.photo = (media.post_mime_type === 'video' ? media.videoSrc : (media.photo || media.thumbnail));
      this.calcViewVars();
    }
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    this.items = (this.items || []);

    if (!this.view.hasOwnProperty('generate')) {
      this.view.generate = {};
    }

    this.calcViewVars();
  }

  onGenerateCheckboxChange() {
    let template_uids: number[] = [];

    if (!!this.view.generate.createImages) {
      template_uids = template_uids.concat(this.view.template_uids.images);
    }

    if (!!this.view.generate.createVideos) {
      template_uids = template_uids.concat(this.view.template_uids.videos);
    }

    this.item.template_uids = template_uids;
  }

  removeMediaFromList(media: mediaItem, index: number | null = null) {

    this.view.mediaList = this.view.mediaList.filter((_mediaItem: mediaItem, _index: number) => {

      if (index !== null) {
        return index !== _index;
      }

      return (_mediaItem.ID !== media.ID) && (_mediaItem.thumbnail !== media.thumbnail);
    });

    this.onMediaChanged.emit(this.view.mediaList);

    this.updatePreview();
  }

  setMode(mode: string) {

    if (this.view.mediaPickerMode === mode) {
      this.view.mediaPickerMode = 'pick';
      return false;
    }

    this.view.mediaPickerMode = mode;
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
    item.thumbnail = this.fallbackImg;
  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.media = this.cards.media || {};
    this.cards.media.open = !this.cards.media.open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  uploadAttachment(key: string) {
    const blIsBackground: boolean = (key === 'bg_src' || key === 'photo');

    this.media.applyFromWeb(null, {
      multiple: blIsBackground,
    })
      .then((response: any) => {
        if (!!response && (typeof response === 'object') && !!response.items) {

          if (!!blIsBackground) {

            this.view.mediaList = (this.view.mediaList || []).concat(response.items || []);
            this.item[key] = (response.items[0].photo || response.items[0].thumbnail);

            const mediaItems: mediaItem[] = this.tools.shuffle(response.items);

            if (!!mediaItems[0] && (!!mediaItems[0].photo || !!mediaItems[0].thumbnail)) {
              this.item.bg_src = (mediaItems[0].photo || mediaItems[0].thumbnail);
            }
          } else
            if (!!response.items[0] && (!!response.items[0].photo || !!response.items[0].thumbnail)) {
              this.item[key] = (response.items[0].photo || response.items[0].thumbnail);
            }

          this.onMediaChanged.emit(this.view.mediaList);
        } else
          if (!!response && (typeof response === 'string')) {
            this.item[key] = response;
          }

        this.updatePreview();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  updatePreview() {
    this.events.publish('creatives:creator:preview:update');
  }

}