import { Injectable } from "@angular/core";
import { ActionSheetController, LoadingController } from "@ionic/angular";

import { Camera } from "@awesome-cordova-plugins/camera/ngx";
import { Crop } from "@ionic-native/crop/ngx";

import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject,
} from "@awesome-cordova-plugins/file-transfer/ngx";

import { hostUrl } from "src/config/variables";

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { AuthService } from "src/app/services/core/auth.service";
import { BrowserService } from "src/app/services/utils/browser.service";
import { CacheService } from 'src/app/services/core/cache.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from "src/app/services/core/events.service";
import { FoldersService } from "../utils/folders.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ToolsService } from "src/app/services/utils/tools.service";
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';

import { WebUploaderPage } from "../../pages/core/web-uploader/web-uploader.page";

@Injectable({
  providedIn: "root",
})
export class MediaextendService {

  appConfig: pipelineAppConfig;

  queueLookupUids: number[];

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private AppCMS: AppcmsService,
    private authService: AuthService,
    private browser: BrowserService,
    private cache: CacheService,
    public camera: Camera,
    private chooser: ChooserService,
    private configService: ConfigService,
    private crop: Crop,
    private events: EventsService,
    private fileTransfer: FileTransfer,
    private folders: FoldersService,
    private loadingCtrl: LoadingController,
    private modalService: ModalService,
    private tools: ToolsService,
    private translations: TranslationService,
    private UserService: UserService
  ) {
    this.appConfig = this.configService.getConfig();
  }

  addAttributeToMedia(attribute: mediaAttribute, mediaId: number) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {
        attribute: attribute,
        mediaId: mediaId,
      },
      ["attributes", 'add']
    );
  }

  addTagToMedia(tag: tag, mediaId: number) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {
        tag: tag,
        mediaId: mediaId,
      },
      ["tags", 'add']
    );
  }

  analyse(mediaIds: number[], options: any = {}, blForceRefresh: boolean = false) {
    options = options || {};
    options.force_refresh = !!blForceRefresh;
    options.uids = mediaIds;

    return this.AppCMS.loadPluginData("mediaextend", options, ['analyse'], {}, blForceRefresh);
  }

  apply(sourceType: any, options: any = {}) {
    return new Promise((resolve, reject) => {

      const options: mediaCaptureOptions = {
        quality: 75,
        destinationType: this.camera.DestinationType.FILE_URI,
        sourceType: sourceType, // 0:Photo Library, 1=Camera, 2=Saved Photo Album
        encodingType: 0, // 0=JPG 1=PNG
      };

      this.camera
        .getPicture(options)
        .then((fileUrl) => {
          this.crop
            .crop(fileUrl, {
              quality: 80,
              targetHeight: 1080,
              targetWidth: 1920,
            })
            .then((newPath) => {
              this.onCapturePhoto(newPath.split("?")[0])
                .then(resolve)
                .catch((error: any) => {
                  console.warn("error 3", error);
                  reject(error);
                });
            })
            .catch((error: any) => {
              console.warn("error 2", error);
              reject(error);
            });
        })
        .catch((error: any) => {
          console.warn("error 1", error);
          if (error === "cordova_not_available") {
            this.applyFromWeb()
              .then(resolve)
              .catch((error: any) => {
                console.warn("error 4", error);
                reject(error);
              });
          } else {
            reject(error);
          }
        });
    });
  }

  applyFromWeb(fileURI: string | null = null, options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const params: any = Object.assign(options, {
        fileURI: fileURI,
        mediaService: this,
        multiple: !!options.multiple,
        services: options.services || ["ai", "database", "upload", "media_library"],
      });

      const webUploader = await this.modalService.create({
        component: WebUploaderPage,
        componentProps: params,
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: "defaultModal",
      });

      webUploader.onDidDismiss().then((response: any) => {
        if (response.data && !!response.data.item) {
          resolve({
            items: [response.data.item],
          });
        } else if (
          response.data &&
          response.data.items &&
          response.data.items.length
        ) {
          resolve({
            items: response.data.items,
          });
        } else if (response.data && (response.data.code || response.data.url)) {
          resolve(response.data.code || response.data.url);
        } else {
          reject(response.data);
        }
      });

      this.modalService.present(webUploader);
    });
  }

  applyYouTubeVideo(options: any = {}) {
    return this.applyFromWeb(
      null,
      Object.assign(options, {
        source: "youtube",
      })
    );
  }

  async choose(options: any = {}) {
    const mediaLookup: any = await this.getMediaList(options);
    const media: any = (!!mediaLookup && !!mediaLookup.data ? mediaLookup.data : mediaLookup) || [];

    const chooseConfig: chooseConfig = Object.assign(options || {}, {
      data: (media || []),
      labelKey: "title",
      multiple: false,
      service: this,
      valueKey: "uid",
    });

    return this.chooser.choose(chooseConfig);
  }

  async chooseEffect(options: any = {}) {

    const chooseConfig: chooseConfig = Object.assign(options || {}, {
      data: await this.getEffects(),
      labelKey: "name",
      multiple: false,
      service: this,
      valueKey: "uid",
    });

    return this.chooser.choose(chooseConfig);
  }

  async chooseFolder(options: any = {}) {

    const chooseConfig: chooseConfig = Object.assign(options || {}, {
      data: await this.getFolders({}, true),
      labelKey: "title",
      multiple: false,
      service: this,
      valueKey: "uid",
    });

    return this.chooser.choose(chooseConfig);
  }

  async chooseImages(options: any = {}) {

    const chooseConfig: chooseConfig = Object.assign(options || {}, {
      data: await this.getImages(options),
      labelKey: "title",
      multiple: false,
      service: this,
      valueKey: "uid",
    });

    return this.chooser.choose(chooseConfig);
  }

  async chooseFromMedia(options: any = {}) {
    return new Promise(async (resolve, reject) => {
      let buttons = [],
        chooseKey = "choose_" + options.location;

      this.translations
        .get([
          "add_attachment",
          "cancel",
          chooseKey,
          "delete_photo",
          "take_photo",
          "upload_photo",
          "view_attachment",
          "view_photo",
        ])
        .subscribe(async (response: any) => {
          if (options.admin) {
            if (this.hasOwnProperty(options.location)) {
              buttons.push({
                text: response[chooseKey] || chooseKey,
                handler: () => {
                  let provider = this[options.location];
                  try {
                    provider.select().then(resolve).catch(reject);
                  } catch (e) {
                    reject(e);
                  }
                },
              });
            }

            buttons.push({
              text: response.take_photo || "take_photo",
              handler: () => {
                this.apply(this.camera.PictureSourceType.CAMERA, options)
                  .then(resolve)
                  .catch(reject);
              },
            });

            buttons.push({
              text: response.upload_photo || "upload_photo",
              handler: () => {
                this.apply(this.camera.PictureSourceType.PHOTOLIBRARY, options)
                  .then(resolve)
                  .catch(reject);
              },
            });

            if (options.photo) {
              buttons.push({
                text: response.delete_photo || "delete_photo",
                color: "danger",
                handler: () => {
                  this.removeImage(options.photo, options.photoType)
                    .then(resolve)
                    .catch(reject);
                },
              });
            }

            buttons.push({
              text: response.cancel || "cancel",
              role: "cancel",
            });
          }

          const actionSheet = await this.actionSheetCtrl.create({
            header: options.admin
              ? response.add_attachment || "add_attachment"
              : response.view_attachment || "view_attachment",
            buttons: buttons,
          });

          await actionSheet.present();
        });
    });
  }

  clearCache() {
    this.camera.cleanup();
  }

  compileVideoParts(params: any) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      params,
      ["compileVideoParts"]
    );
  }

  create(media: mediaQueueItem) {
    let _media: mediaQueueItem = JSON.parse(JSON.stringify(media));

    delete _media.template;

    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        media: _media,
      },
      ["media", "create"]
    );
  }

  createFolder(folder: folder) {
    folder.location = folder.location || 'media_library';
    return this.folders.create(folder);
  }

  deleteFolder(folderId: number) {
    return this.folders.delete(folderId);
  }

  deleteMediaItem(mediaId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        "mediaextend",
        {
          uid: mediaId,
        },
        ["delete"]
      )
        .then((response: any) => {
          this.events.publish('media:item:deleted', mediaId);
          resolve(response);
        })
        .catch(reject);
    });
  }

  deleteQueueItem(itemId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        item: itemId,
      },
      ["media", "queue", "delete"]
    );
  }

  download(mediaId: number, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      options.item = mediaId;

      const params = new URLSearchParams(options);
      const downloadLink: string = `${this.AppCMS.getApiUrl()}/mediaextend/download?${params.toString()}`;
      console.log('downloadLink', downloadLink);

      try {
        if (this.tools.isWeb()) {
          window.open(downloadLink);
        } else {
          const browser: any = await this.browser.create(downloadLink);
        }

        resolve(true);
      } catch (e) {
        console.warn('> downloading item failed', e);
        reject(e);
      }
    });

    /*
    return this.AppCMS.loadPluginData("mediaextend", {
      item: mediaId,
    }, ["download"]);
    */

  }

  fineTuneMediaInput(media: any) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        media: media,
      },
      ["media", "finetune"]
    );
  }

  getAssets(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      ['assets'],
      params,
      blForceRefresh
    );
  }

  getAttributes(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      ["attributes"],
      params,
      blForceRefresh
    );
  }

  getByFolder(
    folderId: number,
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.folders.getFolderItems(folderId, blForceRefresh, params);
  }

  getCreatives(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      ['creatives'],
      params,
      blForceRefresh
    );
  }

  getDefaultEffectEditorCSS(layer: any) {
    const selector =
      !!layer && !!layer.settings && !!layer.settings.id
        ? `#${layer.settings.id}`
        : ".animation-wrapper",
      animationName =
        !!layer && !!layer.settings && !!layer.settings.id
          ? `${layer.settings.id}Animation`
          : "customAnimation",
      string: string = `${selector} {
  animation-name: ${animationName};
  animation-duration: 15s;
  animation-iteration-count: infinite;
}

@keyframes ${animationName} {
  0% {
  }
  100% {
  }
}`;

    return string;
  }

  getDefaultEffectEditorHTML(layer: any) {
    const string: string = "";

    return string;
  }

  getDefaultEffectEditorJS(layer: any) {
    const selector =
      !!layer && !!layer.settings && !!layer.settings.id
        ? `#${layer.settings.id}`
        : ".animation-wrapper",
      string: string = `var element = document.querySelector('${selector}');`;

    return string;
  }

  getEffects(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      options,
      ["media", "effects"],
      params,
      blForceRefresh
    );
  }

  async getFilters() {
    const fromCache: cacheItem = await this.cache.get('media_filters', -1);
    return (!!fromCache && !!fromCache.data ? fromCache.data : null);
  }

  getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    options.filter = options.filter || {};
    options.filter.location = options.filter.location || 'media_library';
    return this.folders.get(options.filter, blForceRefresh, options);
  }

  getFolderByUid(
    folderId: number,
    blForceRefresh: boolean = false,
  ) {
    return this.folders.getByUid(folderId, blForceRefresh);
  }

  getImages(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      ["images"],
      params,
      blForceRefresh
    );
  }

  getMedia(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      ['list'],
      params,
      blForceRefresh
    );
  }

  getMediaItemByUid(
    mediaId: number,
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {},
      [mediaId],
      params,
      blForceRefresh
    );
  }

  getMediaList(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      [],
      params,
      blForceRefresh
    );
  }

  getMediaMetaData(
    mediaId: number,
    options: any = {},
    blForceRefresh: boolean = false
  ) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      options,
      ["media", mediaId, "metadata"],
      {},
      blForceRefresh
    );
  }

  getQueue(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      options,
      ["media", "queue"],
      {},
      blForceRefresh
    );
  }

  getQueueLookupUids() {
    return this.queueLookupUids;
  }

  getQueueItemByUid(
    queueItemId: number,
    blForceRefresh: boolean = false,
    options: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      options,
      ["media", "queue", queueItemId],
      {},
      blForceRefresh
    );
  }

  getVideos(
    options: any = {},
    blForceRefresh: boolean = false,
    params: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      options,
      ["videos"],
      params,
      blForceRefresh
    );
  }

  importFromUrl(url: string, params: any = {}) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {
        url: url,
      },
      ["importFromUrl"],
      params
    );
  }

  importFromWebsite(url: string, params: any = {}) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        url: url,
      },
      ['media', "importFromWebsite"],
      params
    );
  }

  moveFolder(media: any, options: any = {}) {

    if (!!media && (!!media.ID || !!media.uid)) {
      media = [media];
    }

    return this.folders.moveFolder(media as any, 'media_library');
  }

  async onCapturePhoto(fileURI: string) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loadingCtrl.create({
        spinner: "circular",
      });

      loading.present();

      this.upload(fileURI)
        .then((response: any) => {
          loading.dismiss();

          if (
            response.link &&
            response.link.thumbnails &&
            response.link.thumbnails.md
          ) {
            let image =
              response.link.thumbnails.md.src ||
              response.link.thumbnails.lg.src;
            if (image[0] === "/") {
              image = hostUrl + "/" + image;
            }
            resolve(image);
          } else {
            reject(response);
          }
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  record(media: mediaQueueItem) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        media: media,
      },
      ["media", "create"]
    );
  }

  removeAttributeFromMedia(attribute: mediaAttribute, mediaId: number) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {
        attribute: attribute,
        mediaId: mediaId,
      },
      ["attributes", 'remove']
    );
  }

  removeImage(image: string, type: string = "photo") {
    return new Promise((resolve, reject) => {
      let user = this.UserService.getUser() || {};
      switch (type) {
        case "cover":
          user.classifications.cover = "";
          user.classifications.coverImage = "";
          this.UserService.setUser(user, true).then(resolve).catch(reject);
          break;
        case "photo":
          user.photo = "";
          this.UserService.setUser(user, true).then(resolve).catch(reject);
          break;
      }
    });
  }

  removeTagFromMedia(tag: tag, mediaId: number) {
    return this.AppCMS.loadPluginData(
      "mediaextend",
      {
        tag: tag,
        mediaId: mediaId,
      },
      ["tags", 'remove']
    );
  }

  search(query: string, options: any = {}, blForceRefresh: boolean = false) {
    options = options || {};
    options.query = query;

    return this.AppCMS.loadPluginData('mediaextend', options, ['search'], {}, blForceRefresh);
  }

  saveFilters(filters: any) {
    return this.cache.set('media_filters', filters);
  }

  screenshot(options: any = {}) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        options: options,
      },
      ["media", "screenshot"]
    );
  }

  setQueueLookupUids(uids: number[]) {
    this.queueLookupUids = uids;
  }

  update(item: mediaItem) {
    item = JSON.parse(JSON.stringify(item));

    delete item.checked;
    delete item.hidden;

    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        item: item,
      },
      ["media", "update"]
    );
  }

  updateFolder(folder: folder) {
    return this.folders.update(folder);
  }

  updateQueueItem(item: mediaQueueItem) {
    item = JSON.parse(JSON.stringify(item));

    delete item.checked;
    delete item.hidden;
    delete item.template;
    delete item.templateView;

    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        item: item,
      },
      ["media", "queue", "update"]
    );
  }

  upload(fileURI: string) {
    /*
    if(this.tools.isWeb()) {
      return this.uploadUsingWeb(fileURI);
    }
    */

    return new Promise((resolve, reject) => {

      const d = new Date();

      const apiCredentials = this.UserService.getApiCredentials();
      const apiUrl: string = this.AppCMS.getApiUrl();

      let params: any = this.AppCMS.getRequestParams();

      if (!!this.appConfig.allowAuthUsingCredentials) {
        params = Object.assign(params, {
          user: apiCredentials,
        });
      }

      console.log('media: upload: params', params);

      const fileExtension: string = 'jpg';
      const fileName: string = `${this.UserService.getUid()}-${d.getMilliseconds()}.${fileExtension}`;

      let headers: any = {
        "Content-Type": undefined,
      };

      if (!!this.appConfig.allowAuthUsingToken) {
        headers['Authorization'] = `Bearer ${this.authService.currentAccessToken || ''}`;
      }

      console.log('media: upload: headers', headers);

      const mimeType: string = "image/jpeg";

      const options: FileUploadOptions = {
        fileKey: "file",
        fileName: fileName,
        mimeType: mimeType,
        httpMethod: "POST",
        chunkedMode: false,
        headers: headers,
        params: params,
      };

      console.log('media: upload: options', options);

      const url = `${apiUrl}/mediaextend/upload.json`;
      let r: any;

      const fileTransfer: FileTransferObject = this.fileTransfer.create();

      fileTransfer
        .upload(encodeURI(fileURI), encodeURI(url), options)
        .then((_r: any) => {
          r = _r;

          this.clearCache();

          const response = JSON.parse(r.response);

          if (response.status === false) {
            reject(
              response.message || "Ein unbekannter Fehler ist aufgetreten"
            );
          } else {
            resolve(response);
          }
        })
        .catch((e) => {
          console.error("> e", e);
          this.clearCache();
          reject(e);
        });
    });
  }

  uploadAssets(files: any[], params: any = {}) {
    params.type = 'asset';

    return this.uploadUsingWeb(files, null, params);
  }

  uploadCreatives(files: any[], params: any = {}) {
    params.type = 'creative';

    return this.uploadUsingWeb(files, null, params);
  }

  /*
  async uploadUsingWeb(
    files: any[],
    index: number | null = null,
    params: any = {}
  ) {
    return new Promise((resolve, reject) => {
      index = (index || 0);

      if (!files || !files.length) {
        reject('error_missing_files');
        return false;
      }

      const file_data: any = (!!files && files[index] ? files[index] : null),
        user: user = this.UserService.getUser(),
        globalParams: any = this.AppCMS.getRequestParams();

      let form_data: any = new FormData(),
        paramsKeys: string[] = Object.keys(globalParams || []);

      paramsKeys.forEach((key: string) => {
        if (!!globalParams[key]) {
          form_data.append(key, `${globalParams[key]}`);
        }
      });

      form_data.append("file", file_data);
      form_data.append("user[email]", user.email);
      form_data.append("user[password]", user.password);
      form_data.append("user[uid]", `${user.uid}`);
      form_data.append("userId", `${user.uid}`);

      if (!!params.folder_uid) {
        form_data.append("folder", `${params.folder_uid}`);
      }

      const apiUrl: string = this.AppCMS.getApiUrl();
      const url: string = `${apiUrl}/mediaextend/upload.json`;

      if(!!this.appConfig.allowAuthUsingToken) {
        headers['Authorization'] = `Bearer ${this.authService.currentAccessToken || ''}`;
      }

      $.ajax({
        url: url,
        dataType: "json",
        cache: false,
        contentType: false,
        processData: false,
        data: form_data,
        type: "post",
        success: (response: any) => {
          index = (index || 0);

          files[index].response = response;

          if (!response.link && !!response.uploads && !!response.uploads[0] && !!response.uploads[0].link) {
            response.link = response.uploads[0].link;
          }

          if (!!response && !!response.link && !!response.link.thumbnail) {
            files[index].url = response.link.thumbnail;
          }

          this.events.publish("web:uploader:files:updated", files);

          if (index === files.length - 1) {
            resolve(response && response.success ? response.link : response);
          } else {
            this.uploadUsingWeb(files, (index + 1), params).then(resolve).catch(reject);
          }
        },
        error: (error: any) => {
          console.warn("error", error);

          index = (index || 0);
          files[index].error = true;

          if (index === null || !files[index + 1]) {
            reject(error);
          } else {
            this.uploadUsingWeb(files, index + 1, params).then(resolve).catch(reject);
          }
        },
      });

    });
  }
  */

  async uploadUsingWeb(files: any[], index: number = 0, params: any = {}) {
    index = parseInt(`${index || 0}`);

    // Input validation
    if (!files?.length) {
      throw new Error('error_missing_files');
    }

    // Get current file
    const fileData: any = files[index];

    if (!fileData) {
      throw new Error('Invalid file index');
    }

    // Get user and global params
    const user: any = this.UserService.getUser();
    const globalParams: any = this.AppCMS.getRequestParams();

    // Build form data
    const formData = new FormData();

    // Add global params
    Object.entries(globalParams || {}).forEach(([key, value]) => {
      if (value) formData.append(key, `${value}`);
    });

    console.log('media: upload: fileData', fileData);

    // Add file and user data
    formData.append('file', fileData);
    formData.append('userId', `${user.uid}`);

    if (!!this.appConfig.allowAuthUsingCredentials) {
      formData.append('user[email]', user.email);
      formData.append('user[password]', user.password);
      formData.append('user[uid]', `${user.uid}`);
    }

    // Add optional folder
    if (params.hasOwnProperty('folder_uid')) {
      formData.append('folder', `${params.folder_uid || 0}`);
    }

    // Build request URL and headers
    const apiUrl: string = this.AppCMS.getApiUrl();
    const url: string = `${apiUrl}/mediaextend/upload.json`;
    let headers: any = {};

    if (!!this.appConfig.allowAuthUsingToken) {
      headers['Authorization'] = `Bearer ${this.authService.currentAccessToken || ''}`;
    }

    try {

      // Make fetch request
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: formData
      });

      console.log('media: upload: response', response);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('media: upload: data', data);

      // Update file metadata
      files[index].response = data;

      // Handle response link
      if (!data.link && data.uploads?.[0]?.link) {
        data.link = data.uploads[0].link;
      }

      if (data?.link?.thumbnail) {
        files[index].url = data.link.thumbnail;
      }

      // Emit update event
      this.events.publish('web:uploader:files:updated', files);

      // Handle sequential uploads
      if (index === files.length - 1) {
        return data?.success ? data.link : data;
      } else {
        return this.uploadUsingWeb(files, index + 1, params);
      }

    } catch (error) {
      console.warn('Upload error:', error);
      files[index].error = true;

      // Continue with next file if available
      if (files[index + 1]) {
        return this.uploadUsingWeb(files, index + 1, params);
      }

      throw error;
    }
  }

}