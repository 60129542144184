<ion-toolbar class="container ion-no-border">

  <ion-buttons slot="start">

    <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
      <ion-icon name="close"></ion-icon>
    </ion-button>

    <ion-button (click)="toggleSearch()" *ngIf="!view.isModal && !view.hideSearch">
      <ion-icon name="search-outline"></ion-icon>
    </ion-button>

    <ion-button *ngIf="!view.isModal && !!view.showBackButton" fill="clear" icon-only (click)="back()">
      <ion-icon name="arrow-back"></ion-icon>
    </ion-button>

    <ion-menu-button *ngIf="!view.isModal && !view.showBackButton && !!view.showMenuButton"></ion-menu-button>

  </ion-buttons>

  <img *ngIf="(config.showHeaderToolbarLogo && (!view.isDesktop || config.showHeaderToolbarLogoInWeb)) && (!!view.isDesktop || config.showHeaderToolbarLogoInApp) && !view.hideHeaderToolbarLogo" src="./assets/img/logo.webp" class="pipeline-logo light" />
  <img *ngIf="(config.showHeaderToolbarLogo && (!view.isDesktop || config.showHeaderToolbarLogoInWeb)) && (!!view.isDesktop || config.showHeaderToolbarLogoInApp) && !view.hideHeaderToolbarLogo" src="./assets/img/logo-dark.webp" class="pipeline-logo dark" />
  
  <ion-buttons slot="end" *ngIf="!!view.introCard && !!view.introCard.hidden">
    <ion-button fill="clear" icon-only (click)="toggleIntroCard()">
      <ion-icon name="help-circle-outline"></ion-icon>
    </ion-button>
  </ion-buttons>

</ion-toolbar>