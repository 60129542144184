<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Menu button -->
      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Back button -->
      <ion-button (click)="dismiss()" *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Create shortcode -->
      <ion-button (click)="create()" [disabled]="!shortcode.name || !shortcode.input" shape="round" color="primary"
        fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'create'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <!-- AI settings popover -->
  <ion-popover #aiSettingsPopover [isOpen]="isAiSettingsPopoverOpen" (didDismiss)="isAiSettingsPopoverOpen = false">
    <ng-template>

      <pipeline-ai-settings-card *ngIf="!!shortcode && !!aiSettings" [(cards)]="cards" [(config)]="aiSettings"
        [options]="aiSettingsOptions" [(view)]="view"
        (changed)="onAiSettingsChanged($event)"></pipeline-ai-settings-card>

    </ng-template>
  </ion-popover>

  <div class="container">

    <!-- Intro card -->
    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <!-- General information -->
    <ion-card>

      <!-- Shortcode name -->
      <ion-item>
        <ion-icon slot="start" [name]="shortcode.icon" (click)="chooseIcon()"></ion-icon>
        <ion-input [label]="'ai_shortcode_name'|translate" labelPlacement="floating" [(ngModel)]="shortcode.name"
          [placeholder]="'ai_shortcode_name_placeholder'|translate"></ion-input>
      </ion-item>

      <!-- Shortcode type -->
      <ion-item lines="none">
        <ion-icon slot="start" name="build-outline"></ion-icon>

        <ion-select [label]="'prompt_type'|translate" labelPlacement="floating" interface="popover"
          [placeholder]="'select'|translate" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
          [(ngModel)]="shortcode.promptType" (ionChange)="onShortcodePromptTypeChanged()">
          <ion-select-option *ngFor="let promptType of view.promptTypes; trackBy:trackItems"
            [innerHTML]="promptType.name|translate" [value]="promptType.uid"></ion-select-option>
        </ion-select>

      </ion-item>

    </ion-card>

    <!-- Input -->
    <ion-card>

      <ion-item lines="none">
        <ion-label [innerHTML]="'ai_shortcode_input_label'|translate"></ion-label>
      </ion-item>

      <ion-card-content>

        <!-- Task input textarea -->
        <ion-item [hidden]="!!view.loadingInput">

          <ion-textarea rows="12" [(ngModel)]="shortcode.input" [disabled]="!shortcode.name"
            (ionChange)="onShortcodeInputChanged($event)" (ionInput)="onShortcodeInputChanged($event)"
            [placeholder]="'ai_shortcode_input_placeholder'|translate"></ion-textarea>

        </ion-item>

        <!-- Loading spinner -->
        <ion-spinner [hidden]="!view.loadingInput"></ion-spinner>

        <ion-item lines="none">

          <!-- Optimize input prompt -->
          <ion-button slot="end" (click)="optimizeTaskInputPrompt(shortcode, $event)"
            [disabled]="!shortcode.name || !shortcode.input || !!view.loadingInput" fill="clear" icon-only color="dark"
            [disabled]="!!view.loadingInput">
            <ion-icon name="sparkles-outline"></ion-icon>
          </ion-button>

          <!-- Task AI settings -->
          <ion-button slot="end" (click)="showAiSettingsPopover($event)" fill="clear" icon-only color="dark"
            [disabled]="!shortcode.name || !shortcode.input || !!view.loadingInput">
            <ion-icon name="settings-outline"></ion-icon>
          </ion-button>

        </ion-item>

      </ion-card-content>

    </ion-card>

    <!-- Variables -->
    <ion-card [hidden]="shortcode.promptType === 'function'">

      <ion-item lines="none">
        <ion-label [innerHTML]="'variables'|translate"></ion-label>
      </ion-item>

      <ion-card-content *ngIf="!!view.variables">
        <ion-card *ngFor="let variable of view.variables; let iVariable = index; trackBy:trackItems">
          <ion-grid>
            <ion-row>

              <ion-col size="4">
                <ion-item lines="none">
                  <ion-input type="text" [(ngModel)]="variable.input_variable"
                    [placeholder]="'input_variable'|translate" [disabled]="!!variable.required"></ion-input>
                </ion-item>
              </ion-col>

              <ion-col size="3">
                <ion-item lines="none">
                  <ion-select interface="popover" [(ngModel)]="variable.action" [placeholder]="'variable_action'|translate"
                    [okText]="'okay'|translate" [cancelText]="'cancel'|translate">
                    <ion-select-option value="auto" [innerHTML]="'auto_detection'|translate"></ion-select-option>
                    <ion-select-option value="database" [innerHTML]="'database'|translate"></ion-select-option>
                    <ion-select-option value="fetch_url" [innerHTML]="'fetch_url'|translate"></ion-select-option>
                    <ion-select-option value="text_completion"
                      [innerHTML]="'text_completion'|translate"></ion-select-option>
                    <ion-select-option value="text_to_image"
                      [innerHTML]="'text_to_image'|translate"></ion-select-option>
                    <ion-select-option value="text_to_video"
                      [innerHTML]="'text_to_video'|translate"></ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>

              <ion-col size="3">
                <ion-item lines="none">
                  <ion-input type="text" [(ngModel)]="variable.output_variable"
                    [placeholder]="'output_variable'|translate" [disabled]="!variable.action"></ion-input>
                </ion-item>
              </ion-col>

              <ion-col size="2">
                <ion-button fill="clear" color="danger" size="block" icon-only (click)="deleteVariable(iVariable)"
                  [disabled]="!!variable.required">
                  <ion-icon name="trash-outline"></ion-icon>
                </ion-button>
              </ion-col>

            </ion-row>
          </ion-grid>
        </ion-card>
      </ion-card-content>

      <ion-card-content>

        <ion-button (click)="addVariable()" [disabled]="!shortcode.name" fill="clear">
          <ion-icon name="add-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'add'|translate"></ion-label>
        </ion-button>

      </ion-card-content>

    </ion-card>

  </div>

</ion-content>