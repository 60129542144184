import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
  ) {

  }

  getAll(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return this.getCountries(blForceRefresh, options, params);
  }

  getCountries(blForceRefresh: boolean = false, options: any = {}, params: any = {}) {
    
    if(options.query && options.query.length < 3) {
      delete options.query;
    }

    return this.AppCMS.loadPluginData("pipeline", options, ["countries"], params, blForceRefresh);
  }

  pick(options: any|null = null, event: any|null = null) {
    return new Promise(async (resolve, reject) => {
      
      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: await this.getAll(),
        labelKey: 'title',
        service: this,
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig)
      .then((chooseResponse: chooseResponse) => {
        resolve(chooseResponse);
      })
      .catch(reject);
    });
  }

}