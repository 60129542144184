import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { AlertService } from 'src/app/services/core/alert.service';
//import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'pipeline-dynamic-item',
  standalone: false,
  templateUrl: './dynamic-item.component.html',
  styleUrls: ['./dynamic-item.component.scss']
})
export class DynamicItemComponent implements OnInit {
  @Input() buttons: button[];
  @Input() item: any;
  @Input() lines: string = 'none';
  @Input() method: string;
  @Input() mode: string;
  @Input() options: dynamicItemOptions;
  @Input() selectionOptions: selectionOption[];
  @Input() service: any;
  @Input() view: any;

  @Output() itemChanged = new EventEmitter();

  @ViewChild('itemSettingsPopover') itemSettingsPopover;

  fallbackImg: string = './assets/img/fallback.webp';

  isItemSettingsPopoverOpen = false;

  @Output() selectionChanged = new EventEmitter();

  constructor(
    private alertService: AlertService,
    //private viewService: ViewService,
  ) {
    this.buttons = this.buttons || [];
  }

  calcSelectionActions() {

  }

  //calcViewVars() {
  //const viewType: string | null = (!!this.view && !!this.view.viewType ? this.view.viewType : null);
  //this.view = this.viewService.calcVars(this.view);
  //this.view.viewType = (viewType || this.view.viewType) || 'list';
  //}

  ngOnInit() {
    this.buttons = this.buttons || [];

    this.options = this.options || {};
    this.options.labelKey = this.options.labelKey || 'label';
    this.options.subLabelKey = this.options.subLabelKey || 'description';
    this.options.valueKey = this.options.valueKey || 'value';

    //this.calcViewVars();
  }

  onButtonClick(button: button, item: any) {

    if (!!button && !!button.label && (button.label === 'delete')) {

      try {
        this.alertService.requestConfirm()
          .then((bl: boolean) => {
            if (!!bl) {
              button.handler(item, button);
            }
          })
          .catch((e: any) => {
            console.warn('executing button action failed', e);
          });
      } catch (e) {
        console.warn('executing button action failed', e);
      }

      return false;
    }

    try {
      button.handler(item, button);
    } catch (e) {
      console.warn('executing button action failed', e);
    }

  }

  onItemChecked() {
    this.itemChanged.emit(this.item);
  }

  presentPopover(e: Event, item: any) {
    this.view.item = item;

    this.calcSelectionActions();

    this.itemSettingsPopover.event = e;
    this.isItemSettingsPopoverOpen = true;
  }


  async runItemSelectionOption(option: any) {
    this.isItemSettingsPopoverOpen = false;
    
    try {

      if (!option || !option.uid) {
        return false;
      }

      if (!!option.handler) {
        const exec: any = await option.handler(this.view.item);
        console.log('handler response: exec (a)', exec);
      } else
        if (typeof this[option.uid] === 'function') {
          const exec: any = await this[option.uid](this.view.item);
          console.log('handler response: exec (b)', exec);
        } else {
          console.log('handler response: option (c)', option);
          this.selectionChanged.emit(option);
        }
    } catch (e) {
      this.selectionChanged.emit(option);
    }
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
    item.thumbnail = this.fallbackImg;

    if (!!this.options.photoKey) {
      item[this.options.photoKey] = item.photo;
    }
  }

  toggleActive(item: any, event: any | null = null) {
    console.log('toggleActive', item);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}