<!-- mobile intro card -->
<!--<pipeline-intro-card
  *ngIf="!!blInitialized && !!view && !!view.introCard && (!view.isDesktop && (!config || !config.uiMode) && (config.uiMode !== 'widget'))"
  [(view)]="view.introCard" [hidden]="view.introCard.hidden"></pipeline-intro-card>-->

<!-- Add actions popover -->
<ion-popover *ngIf="!!blInitialized && !!config && !!view" #daniChatSettingsPopover [isOpen]="isSettingsPopoverOpen"
  (didDismiss)="isSettingsPopoverOpen = false">
  <ng-template>
    <ion-content>
      <ion-list *ngIf="!!addActions">

        <ion-item button class="btn-item" *ngFor="let option of addActions"
          (click)="runItemSelectionOption(view.item, option)"
          [class.danger]="option.color == 'danger' || option.uid === 'delete'"
          [class.success]="option.color == 'success'" [class.warning]="option.color == 'warning'">
          <ion-icon [name]="option.icon" slot="start"></ion-icon>
          <ion-label [innerHTML]="option.label|translate"></ion-label>
        </ion-item>

      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>

<ion-grid *ngIf="!!blInitialized && !!config && !!view" [class.widget]="config.uiMode === 'widget'"
  [class.mini]="!!config.mini" [class.hasChat]="!!config.hasChat" [class.userCanWrite]="!!config.userCanWrite">

  <ion-row>

    <!-- Left / top column -->
    <ion-col *ngIf="!!splineOptions"
      [size]="!config || !config.uiMode || (config.uiMode !== 'widget') ? (view.isDesktop ? 6 : 12) : 12"
      class="spline-col" [class.full-width]="!!config && (!!config.uiMode || (config.uiMode === 'widget'))"
      [class.has-messages]="state.hasMessages" [class.inConversation]="state.inConversation">

      <!-- Dani Preview -->
      <pipeline-spline-viewer *ngIf="!!blInitialized" [class]="animationClass" [(options)]="splineOptions"
        (click)="toChatPage()"></pipeline-spline-viewer>

    </ion-col>

    <!-- Right / bottom column -->
    <ion-col
      *ngIf="(!!config && (!!config.userCanWrite || !!config.hasChat)) || (!!view.introCard && !view.introCard.hidden)"
      class="chat-col" [class.has-messages]="state.hasMessages" [class.inConversation]="state.inConversation"
      [size]="!config || (!!config && (!config.uiMode || config.uiMode !== 'widget')) ? (view.isDesktop ? 6 : 12) : 12"
      [class.full-width]="!!config && (!!config.uiMode || (config.uiMode === 'widget'))"
      [hidden]="!!view.introCard && !!view.introCard.hidden && (!chat || !config.hasChat)">

      <!-- Chat -->
      <div id="daniChat" [class.widget]="!!config && !!config.uiMode && (config.uiMode === 'widget')" #daniChat
        *ngIf="!!chat && !!config.hasChat || (!!view.isDesktop && (!config || !config.uiMode || (config.uiMode !== 'widget')))">

        <!-- Desktop intro card -->
        <!--<pipeline-intro-card *ngIf="!!view.isDesktop && !!view.introCard && (!config || !config.uiMode) && (config.uiMode !== 'widget')"
          [(view)]="view.introCard" [hidden]="!!view.introCard && !!view.introCard.hidden"></pipeline-intro-card>-->

        <!-- Chat history -->
        <div class="history-wrapper" #historyWrapper *ngIf="!!chat">

          <!-- Chat item -->
          <ion-card *ngFor="let message of chat; let iChatMessage = index; trackBy:trackItems"
            [hidden]="message.role === 'system'" [class.system]="message.role === 'system'"
            [class.me]="message.role === 'user'" [class.dani]="message.role === 'assistant'">

            <!-- Message -->
            <ion-item class="message" class="ion-text-wrap" lines="none">

              <ion-icon [name]="message.icon" *ngIf="!!message.icon" slot="start"></ion-icon>

              <!-- Classic message -->
              <ion-label class="ion-text-wrap" *ngIf="message.mode !== 'input'">
                <h3 [innerHTML]="message.input|translate" class="ion-text-wrap"></h3>
              </ion-label>

              <!-- Input (forms) -->
              <ion-textarea *ngIf="(message.mode === 'input') && (message.type === 'textarea')" rows="5"
                [(ngModel)]="message.value" [placeholder]="(message.placeholder || message.name)|translate"
                [label]="message.name|translate" labelPlacement="stacked"></ion-textarea>

              <!-- Textarea (forms) -->
              <ion-input *ngIf="(message.mode === 'input') && (message.type !== 'textarea')"
                [type]="message.type || 'text'" [(ngModel)]="message.value"
                [placeholder]="(message.placeholder || message.name)|translate" [label]="message.name|translate"
                labelPlacement="stacked"></ion-input>

              <ion-button slot="end" fill="clear" color="white" icon-only (click)="resend(message)"
                *ngIf="message.role === 'user'">
                <ion-icon name="sync-outline"></ion-icon>
              </ion-button>

            </ion-item>

            <!-- Rate actions -->
            <ion-item *ngIf="!!iChatMessage && (message.mode === 'view') && (message.role === 'assistant')"
              lines="none">

              <!-- Rate item buttons -->
              <pipeline-rate-item-buttons [(item)]="chat[iChatMessage]" type="message"
                slot="start"></pipeline-rate-item-buttons>

              <!-- Copy output to clipboard -->
              <ion-button fill="clear" color="dark" *ngIf="!!message.input" (click)="copyText(message.input)">
                <ion-icon name="clipboard-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'copy_to_clipboard'|translate" [hidden]="!view.isDesktop"></ion-label>
              </ion-button>

            </ion-item>

          </ion-card>
        </div>

        <!-- Write input form -->
        <form (ngSubmit)="send(true, $event)">

          <ion-item lines="none" class="write-bar" [hidden]="!config.userCanWrite">

            <!-- Add file -->
            <ion-button slot="start" icon-only color="primary" fill="clear" shape="round" (click)="addFile($event)"
              *ngIf="!!view.isLoggedIn">
              <ion-icon name="add-outline"></ion-icon>
            </ion-button>

            <!-- Stop listening -->
            <ion-button slot="start" class="record-btn stop-record-btn" icon-only color="danger"
              (click)="stopListening()" fill="clear" [hidden]="!blListening">
              <ion-icon name="stop-circle-outline" color="danger"></ion-icon>
            </ion-button>

            <!-- Record mic -->
            <ion-button slot="start" class="record-btn" icon-only (click)="record()" fill="clear" color="dark"
              [hidden]="!!blListening || !blSpeechRecognitionAvailable">
              <ion-icon name="mic-outline"></ion-icon>
            </ion-button>

            <!-- Input text -->
            <ion-input name="aiPrompt" [autocorrect]="'on'" [disabled]="state.speaking" [(ngModel)]="inputPrompt"
              type="text" [placeholder]="'ask_dani'|translate"></ion-input>

            <ion-spinner slot="end" [hidden]="!blLoading"></ion-spinner>

            <ion-button slot="end" icon-only color="primary" (click)="send(false, $event)" shape="round"
              [disabled]="!!blBlockRunAiPrompt || !inputPrompt || !!blLoading">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </ion-button>

          </ion-item>

        </form>

      </div>

    </ion-col>

  </ion-row>
</ion-grid>