import { Component, Input, OnInit } from '@angular/core';

import { AbonnementService } from "src/app/services/core/abonnement.service";

@Component({
  selector: 'getgenius-abo-overlay',
  standalone: false,
  templateUrl: './getgenius-abo-overlay.component.html',
  styleUrls: ['./getgenius-abo-overlay.component.scss'],
})
export class GetgeniusAboOverlayComponent implements OnInit {
  @Input() requiresOne: string[];
  @Input() view: any;

  constructor(
    private aboService: AbonnementService,
  ) {
    
  }
  
  ngOnInit() {
    this.view.abo = this.aboService.getCurrentVersion();
    this.view.aboVerified = !this.requiresOne || (this.requiresOne.indexOf(this.view.abo) !== -1);
  }

}
