<div class="container">

  <pipeline-no-entries-card *ngIf="(!items || (items && !items.length)) && !view.loading"></pipeline-no-entries-card>

  <div *ngIf="!!items && !!items.length">

    <pipeline-pagination *ngIf="!!view.showPagination && !!paginationConfig && !view.loading"
      [config]="paginationConfig" [(view)]="view"></pipeline-pagination>

    <ion-list>

      <pipeline-dynamic-item *ngFor="let item of items; let i = index; trackBy:trackItems" [buttons]="buttons"
        [(item)]="items[i]" [lines]="lines" [options]="options" [selectionOptions]="selectionOptions"
        [view]="view"></pipeline-dynamic-item>
        
    </ion-list>

  </div>

</div>