<ion-header class="ion-no-border">

  <ion-toolbar class="container ion-no-border">
    <ion-buttons slot="start">
        <ion-button (click)="dismiss()" icon-only>
            <ion-icon name="close"></ion-icon>
        </ion-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button (click)="update()" shape="round" [color]="view.isDesktop ? 'primary' : 'dark'" [fill]="view.isDesktop ? 'solid' : 'clear'">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [hidden]="!view.isDesktop" [innerHTML]="'update'|translate"></ion-label>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <ion-toolbar class="container">
    <ion-segment [(ngModel)]="view.segment" scrollable value="general">
      <ion-segment-button value="general">
        <ion-icon name="information-outline"></ion-icon>
        <ion-label [innerHTML]="'general'|translate" class="ion-text-wrap"></ion-label>
      </ion-segment-button>
      <ion-segment-button value="permissions">
        <ion-icon name="lock-closed-outline"></ion-icon>
        <ion-label [innerHTML]="'permissions'|translate" class="ion-text-wrap"></ion-label>
      </ion-segment-button>
      <ion-segment-button value="automation">
        <ion-icon name="sparkles-outline"></ion-icon>
        <ion-label [innerHTML]="'automation'|translate" class="ion-text-wrap"></ion-label>
      </ion-segment-button>
      <ion-segment-button value="notifications">
        <ion-icon name="notifications-outline"></ion-icon>
        <ion-label [innerHTML]="'notifications'|translate" class="ion-text-wrap"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">
    <div [hidden]="view.segment !== 'general'">

      <ion-card *ngIf="source">
  
        <ion-list lines="none">
    
          <ion-list-header>
            <ion-label [innerHTML]="'general'|translate"></ion-label>
          </ion-list-header>
      
          <ion-item>

            <ion-thumbnail slot="start" (click)="onSourcePhotoClick()">
              <ion-img [src]="source.photo || fallbackImg"  (ionError)="thumbnailLoadingFailed()"></ion-img>
            </ion-thumbnail>
  
            <ion-input [(ngModel)]="source.title" [label]="'name'|translate" labelPlacement="floating"></ion-input>
            
          </ion-item>
      
          <ion-item>
            <ion-label position="floating" [innerHTML]="'url'|translate"></ion-label>
            <ion-input [(ngModel)]="source.url"></ion-input>
          </ion-item>

        </ion-list>
      </ion-card>
      
      <ion-card *ngIf="source">
    
        <ion-list lines="none">
          <ion-list-header>
            <ion-label [innerHTML]="'attributes'|translate"></ion-label>
          </ion-list-header>
    
          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="source.active"></ion-checkbox>
            <ion-label [innerHTML]="'active'|translate"></ion-label>
          </ion-item>
    
          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="source.hidden"></ion-checkbox>
            <ion-label [innerHTML]="'hidden'|translate"></ion-label>
          </ion-item>
    
          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="source.local"></ion-checkbox>
            <ion-label [innerHTML]="'local'|translate"></ion-label>
          </ion-item>
    
        </ion-list>
    
      </ion-card>
    
      <ion-card *ngIf="source.local">
    
        <ion-list>
          <ion-list-header>
            <ion-label [innerHTML]="'region'|translate"></ion-label>
          </ion-list-header>
        </ion-list>
    
        <ion-searchbar [hidden]="!source.local || source.country" [(ngModel)]="search.countryQuery" [placeholder]="'country'|translate" (ionInput)="onCountryQueryChanged()"></ion-searchbar>
    
        <ion-item lines="none" *ngIf="view.country" class="selected">
          <ion-icon name="location" slot="start"></ion-icon>
          <ion-label class="ion-text-wrap">
            <h4 [innerHTML]="view.country.name"></h4>
          </ion-label>
          <ion-button slot="end" fill="outline" color="danger" (click)="removeCountry()">
            <ion-icon name="trash-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'delete'|translate"></ion-label>
          </ion-button>
        </ion-item>
    
        <ion-list lines="none" *ngIf="view.countries">
          <ion-list-header *ngIf="view.countries && view.countries.length">
            <ion-label [innerHTML]="'results'|translate"></ion-label>
          </ion-list-header>
    
          <ion-item *ngFor="let country of view.countries" [class.selected]="view.country && (view.country.indent === country.indent)" (click)="useCountry(country)">
            <ion-icon name="location-outline" slot="start"></ion-icon>
            <ion-label class="ion-text-wrap">
              <p [innerHTML]="country.name"></p>
            </ion-label>
          </ion-item>
        </ion-list>
    
        <ion-searchbar [hidden]="!source.local || !source.country || view.city" [(ngModel)]="search.cityQuery" [placeholder]="'city'|translate" (ionInput)="onCityQueryChanged()"></ion-searchbar>
    
        <ion-item lines="none" *ngIf="view.city" class="selected">
          <ion-icon name="location" slot="start"></ion-icon>
          <ion-label class="ion-text-wrap">
            <h4 [innerHTML]="view.city.title"></h4>
            <p>
              <span [innerHTML]="view.city.region"></span>,
              <span [innerHTML]="view.city.state"></span>
            </p>
          </ion-label>
          <ion-button slot="end" fill="outline" color="danger" (click)="removeCity()">
            <ion-icon name="trash-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'delete'|translate"></ion-label>
          </ion-button>
        </ion-item>
    
        <!-- cities results -->
        <ion-list lines="none" *ngIf="view.cities" [hidden]="!!view.city">
    
          <ion-list-header *ngIf="view.cities && view.cities.length">
            <ion-label [innerHTML]="'results'|translate"></ion-label>
          </ion-list-header>
    
          <ion-item *ngFor="let city of view.cities" [class.selected]="view.city && (view.city.indent === city.indent)" (click)="useCity(city)">
            <ion-icon name="location-outline" slot="start"></ion-icon>
            <ion-label class="ion-text-wrap">
              <h4 [innerHTML]="city.title"></h4>
              <p>
                <span [innerHTML]="city.region"></span>,
                <span [innerHTML]="city.state"></span>
              </p>
            </ion-label>
          </ion-item>
          
        </ion-list>
    
        <ion-button [hidden]="!view.city || !source.local || !source.country || !source.region" expand="block" (click)="convertToRegionalSource()">
          <ion-label class="ion-text-wrap" [innerHTML]="'convert_to_regional_source'|translate"></ion-label>
          <ion-icon name="arrow-forward" slot="end"></ion-icon>
        </ion-button>
    
      </ion-card>
    
      <ion-card *ngIf="source">
        <ion-list lines="none">
    
          <ion-list-header>
            <ion-label [innerHTML]="'display'|translate"></ion-label>
          </ion-list-header>
      
          <ion-item button (click)="pickSourceUser()">
            <ion-label [innerHTML]="'source_user'|translate"></ion-label>
            <ion-note [innerHTML]="(view.sourceUserLabel || 'empty')|translate" slot="end"></ion-note>
          </ion-item>
      
        </ion-list>
      </ion-card>

      <ion-card *ngIf="source">
        <ion-list lines="none">
    
          <ion-list-header>
            <ion-label [innerHTML]="'localization'|translate"></ion-label>
          </ion-list-header>
      
          <pipeline-languages-select [(key)]="source.language"></pipeline-languages-select>

        </ion-list>
      </ion-card>
  
      <ion-card *ngIf="source && view.indexing && view.indexing.intervals">
        <ion-list lines="none">
    
          <ion-list-header>
            <ion-label [innerHTML]="'indexing'|translate"></ion-label>
          </ion-list-header>
      
          <ion-item>
            <ion-label [innerHTML]="'indexing_interval'|translate"></ion-label>
            <ion-select interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [(ngModel)]="view.indexing.interval">
              <ion-select-option *ngFor="let interval of view.indexing.intervals" [value]="interval" [innerHTML]="('indexing_interval_' + interval)|translate"></ion-select-option>
            </ion-select>
          </ion-item>
  
          <ion-item>
            <ion-label [innerHTML]="'indexing_max_age'|translate" position="stacked"></ion-label>
            <ion-input type="number" step="1" [(ngModel)]="view.indexing.max_age" [placeholder]="'indexing_max_age_placeholder'|translate"></ion-input>
          </ion-item>
      
        </ion-list>
      </ion-card>
  
    </div>
  
    <div [hidden]="view.segment !== 'permissions'">
      
      <ion-card *ngIf="source">
        
        <ion-list lines="none">
    
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'source_permissions_headline'|translate"></h3>
            </ion-label>
          </ion-item>
  
          <ion-list-header>
            <ion-label [innerHTML]="'content'|translate"></ion-label>
          </ion-list-header>
  
          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="view.permissions.allow_summarization" (ionChange)="updatedPermissions()"></ion-checkbox>
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'source_permission_allow_summarization'|translate"></h3>
              <p [innerHTML]="'source_permission_allow_summarization_description'|translate"></p>
            </ion-label>
          </ion-item>
  
          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="view.permissions.allow_extension" (ionChange)="updatedPermissions()"></ion-checkbox>
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'source_permission_allow_extension'|translate"></h3>
              <p [innerHTML]="'source_permission_allow_extension_description'|translate"></p>
            </ion-label>
          </ion-item>
  
          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="view.permissions.allow_translation" (ionChange)="updatedPermissions()"></ion-checkbox>
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'source_permission_allow_translation'|translate"></h3>
              <p [innerHTML]="'source_permission_allow_translation_description'|translate"></p>
            </ion-label>
          </ion-item>
  
          <ion-list-header>
            <ion-label class="ion-text-wrap" [innerHTML]="'images'|translate"></ion-label>
          </ion-list-header>
  
          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="view.permissions.allow_image_caching" (ionChange)="updatedPermissions()"></ion-checkbox>
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'source_permission_allow_image_caching'|translate"></h3>
              <p [innerHTML]="'source_permission_allow_image_caching_description'|translate"></p>
            </ion-label>
          </ion-item>
  
          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="view.permissions.allow_image_optimization" (ionChange)="updatedPermissions()"></ion-checkbox>
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'source_permission_allow_image_optimization'|translate"></h3>
              <p [innerHTML]="'source_permission_allow_image_optimization_description'|translate"></p>
            </ion-label>
          </ion-item>
  
        </ion-list>
      </ion-card>
    </div>

    <div [hidden]="view.segment !== 'automation'">

      <ion-card *ngIf="source">
        
        <ion-list lines="none">
    
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'source_automation_headline'|translate"></h3>
            </ion-label>
          </ion-item>
  
          <ion-list-header>
            <ion-label [innerHTML]="'indexing'|translate"></ion-label>
          </ion-list-header>
      
          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="view.permissions.auto_activate_after_index" (ionChange)="updatedPermissions()"></ion-checkbox>
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'source_permission_auto_activate_after_index'|translate"></h3>
              <p [innerHTML]="'source_permission_auto_activate_after_index_description'|translate"></p>
            </ion-label>
          </ion-item>

          <ion-list-header>
            <ion-label [innerHTML]="'sharing'|translate"></ion-label>
          </ion-list-header>
      
          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="view.automation.on_post_activate_publish_post_to_stories" (ionChange)="updatedPermissions()"></ion-checkbox>
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'source_setting_on_post_activate_publish_post_to_stories'|translate"></h3>
              <p [innerHTML]="'source_setting_on_post_activate_publish_post_to_stories_description'|translate"></p>
            </ion-label>
          </ion-item>

          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="view.automation.share_posts_config_facebook" (ionChange)="updatedPermissions()"></ion-checkbox>
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'source_setting_share_posts_config_facebook'|translate"></h3>
              <p [innerHTML]="'source_setting_share_posts_config_facebook_description'|translate"></p>
            </ion-label>
          </ion-item>

          <ion-item [hidden]="!view.automation.share_posts_config_facebook">
            <ion-label position="floating" [innerHTML]="'facebook_page_id'|translate"></ion-label>
            <ion-input type="text" [(ngModel)]="view.permissions.share_posts_config_facebook"></ion-input>
          </ion-item>

        </ion-list>
      </ion-card>

    </div>

    <div [hidden]="view.segment !== 'notifications'">

      <ion-card *ngIf="source">
        
        <ion-list lines="none">
    
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'source_notifications_headline'|translate"></h3>
            </ion-label>
          </ion-item>
  
          <ion-list-header>
            <ion-label [innerHTML]="'indexing'|translate"></ion-label>
          </ion-list-header>

          <ion-item>
            <ion-checkbox slot="start" [(ngModel)]="view.notifications.on_post_activate_send_notification_email" (ionChange)="updatedPermissions()"></ion-checkbox>
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="'source_setting_on_post_activate_send_notification_email'|translate"></h3>
              <p [innerHTML]="'source_setting_on_post_activate_send_notification_email_description'|translate"></p>
            </ion-label>
          </ion-item>

        </ion-list>
      </ion-card>

    </div>

  </div>

</ion-content>
