import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { TranslationService } from "src/app/services/core/translation.service";

import { AiToolsService } from './ai-tools.service';

@Injectable({
    providedIn: 'root'
})
export class AiStoreService {

    constructor(
        private aiTools: AiToolsService,
        private alertCtrl: AlertController,
        private configService: ConfigService,
        private events: EventsService,
        private loadingCtrl: LoadingController,
        private translations: TranslationService,
        private AppCMS: AppcmsService,
    ) {

    }

    getShortcodes(blForceRefresh: boolean = false, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'store', 'shortcodes'], {}, blForceRefresh);
    }

    getTasks(blForceRefresh: boolean = false, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'store', 'tasks'], {}, blForceRefresh);
    }

    importShortcode(shortcodeId: number, options: any = {}) {
        return this.AppCMS.loadPluginData("pipeline", Object.assign(options, {
            item: shortcodeId,
        }), ["ai", "store", "importShortcode"]);
    }

    importTask(taskId: number, options: any = {}) {
        return new Promise(async (resolve, reject) => {
            this.translations.get([
                'ai_task_import_autofill_project_variables_title',
                'ai_task_import_autofill_project_variables_message',
                'cancel',
                'okay',
            ])
                .subscribe(async (translations: any) => {

                    const alert: any = await this.alertCtrl.create({
                        header: translations.ai_task_import_autofill_project_variables_title || 'ai_task_import_autofill_project_variables_title',
                        message: translations.ai_task_import_autofill_project_variables_message || 'ai_task_import_autofill_project_variables_message',
                        buttons: [
                            {
                                handler: async () => {
                                    const loading: any = await this.loadingCtrl.create();
                                    loading.present();

                                    this.AppCMS.loadPluginData("pipeline", Object.assign(options, {
                                        autofill: true,
                                        item: taskId,
                                    }), ["ai", "store", "importTask"])
                                        .then((response: any) => {
                                            loading.dismiss();
                                            resolve(response);
                                        })
                                        .catch((error: any) => {
                                            loading.dismiss();
                                            reject(error);
                                        });
                                },
                                text: translations.okay || 'okay',
                            },
                            {
                                handler: async () => {
                                    const loading: any = await this.loadingCtrl.create();
                                    loading.present();

                                    this.AppCMS.loadPluginData("pipeline", Object.assign(options, {
                                        autofill: false,
                                        item: taskId,
                                    }), ["ai", "store", "importTask"])
                                        .then((response: any) => {
                                            loading.dismiss();
                                            resolve(response);
                                        })
                                        .catch((error: any) => {
                                            loading.dismiss();
                                            reject(error);
                                        });
                                },
                                text: translations.cancel || 'cancel',
                                role: 'cancel',
                            }
                        ]
                    });

                    alert.present();
                });
        });
    }

    publishShortcode(shortcode: aiShortcode, options: any = {}) {
        return this.AppCMS.loadPluginData("pipeline", Object.assign(options, {
            shortcode: shortcode,
        }), ["ai", "store", "publishShortcode"]);
    }

    publishTask(task: aiTask, options: any = {}) {
        return new Promise(async (resolve, reject) => {
            this.translations.get([
                'ai_task_publish_clean_project_variables_title',
                'ai_task_publish_clean_project_variables_message',
                'cancel',
                'okay',
            ])
                .subscribe(async (translations: any) => {

                    const alert: any = await this.alertCtrl.create({
                        header: translations.ai_task_publish_clean_project_variables_title || 'ai_task_publish_clean_project_variables_title',
                        message: translations.ai_task_publish_clean_project_variables_message || 'ai_task_publish_clean_project_variables_message',
                        buttons: [
                            {
                                handler: () => {
                                    this.AppCMS.loadPluginData("pipeline", Object.assign(options, {
                                        clean: true,
                                        task: task,
                                    }), ["ai", "store", "publishTask"]).then(resolve).catch(reject);
                                },
                                text: translations.okay || 'okay',
                            },
                            {
                                handler: () => {
                                    this.AppCMS.loadPluginData("pipeline", Object.assign(options, {
                                        clean: false,
                                        task: task,
                                    }), ["ai", "store", "publishTask"]).then(resolve).catch(reject);
                                },
                                text: translations.cancel || 'cancel',
                                role: 'cancel',
                            }
                        ]
                    });

                    alert.present();
                });
        });
    }

}