import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';

import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { UserService } from 'src/app/services/core/user.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ToolsService } from 'src/app/services/utils/tools.service';
import { ViewService } from 'src/app/services/core/view.service';

import { CustomerPage } from '../customer/customer.page';

@Component({
  selector: 'app-customers-admin',
  standalone: false,
  templateUrl: './customers-admin.page.html',
  styleUrls: ['./customers-admin.page.scss'],
})
export class CustomersAdminPage implements OnInit {
  @ViewChild('searchCustomersInput') searchCustomersInput: IonSearchbar;

  appConfig: pipelineAppConfig;

  avatarUrl: string = './assets/img/avatars/1.webp';

  customers: user[];

  headerOptions: selectionOption[] = [
    {
      icon: 'cloud-download-outline',
      label: 'export',
      uid: 'export',
    },
    {
      icon: 'cloud-upload-outline',
      label: 'import',
      uid: 'import',
    },
  ];
  
  search: searchOptions = {
    itemsKey: 'customers',
    keys: ['firstname', 'lastname', 'email', 'phone', 'name', 'post_content', 'title', 'description', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'customers_admin_top_card',
      text: 'customers_admin_top_card_text',
      title: 'customers_admin_top_card_title',
    },
    itemSize: 64,
    multiple: true,
    route: 'customers-admin',
    showViewModeSelect: true,
    title: 'users',
    type: '',
    types: ['Nutzer', 'Creator', 'Admin', 'Moderator'],
  }

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private modalService: ModalService,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.detectChanges();
  }

  async createCustomer() {
    this.userService.detailItem({} as user);

    const modal: any = await this.modalService.create({
      component: CustomerPage,
      componentProps: {
        mode: 'create',
      },
      cssClass: 'defaultModal',
      animated: true,
      presentingElement: await this.modalService.getTop(),
    });

    this.modalService.present(modal);
  }

  delete(customer: any) {
    this.userService.deleteAccount(customer)
      .then(() => {
        this.runSearch()
          .catch((error: any) => {
            this.events.publish('error', error);
          });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'deleteAccount',
      items: this.view.selectedItems,
      service: this.userService,
      useObjectAsIdentifier: true,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  detectChanges() {

  }

  doRefresh(event: any | null = null) {
    this.runSearch()
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
  }

  duplicateSelected(event: any | null = null) {
    console.log('duplicate selected: event', event);
    console.log('duplicate selected: view', this.view);
  }

  focusSearchInput() {
    setTimeout(() => {
      try {
        if (this.searchCustomersInput) {
          this.searchCustomersInput.setFocus();
        }
      } catch (e) {
        console.warn('e', e);
      }
    }, 250);
  }

  ionViewWillEnter() {
    this.avatarUrl = this.userService.getAvatarUrl();
    this.focusSearchInput();
  }

  ngOnInit() {
    this.calcViewVars();

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  onItemChecked(plan: any) {

    this.view.selectedItems = this.view.customers.filter((_customer: user) => {
      return _customer.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(event: any | null = null) {

    if (!event || !event.search.query || !event.search.query.length) {
      return false;
    }

    this.search.query = event.search.query;

    this.runSearch()
      .catch((error: any) => {
        if (!!error && error.toLowerCase().indexOf('too_short') === -1 && error.indexOf('missing_search_input') === -1) {
          this.events.publish('error', error);
        }
      });
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }
  }

  runSearch() {
    return new Promise((resolve, reject) => {
      this.view.customers = [];
      this.view.loading = true;

      this.userService.search(this.search.query)
        .then((customers: user[]) => {
          console.log('results', customers);

          this.view.customers = customers;
          this.view.loading = false;

          this.segmentChanged();
          resolve(customers);
        })
        .catch((e: any) => {
          this.view.loading = false;
          reject(e);
        });
    });
  }

  segmentChanged() {
    if (this.view.customers && this.view.customers.length && !!this.view.type) {
      this.view.customers.forEach((customer: user) => {
        customer.visible = !!(customer && customer.classifications && customer.classifications.type === this.view.type);
      });
    } else {
      this.view.customers.forEach((customer: user) => {
        customer.visible = true;
      });
    }
  }

  async viewCustomer(customer: user) {
    this.userService.detailItem(customer as user);

    const modal: any = await this.modalService.create({
      component: CustomerPage,
      cssClass: 'defaultModal',
      animated: true,
      presentingElement: await this.modalService.getTop(),
    });

    this.modalService.present(modal);
  }

}
