import { Injectable } from '@angular/core';
import { LoadingController } from "@ionic/angular";

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { ModalService } from "src/app/services/core/modal.service";

import { Device } from '@capacitor/device';

import { DeviceSetupPage } from 'src/app/pages/core/devices/device-setup/device-setup.page';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  _detailItem: device | null;

  devicesList: device[];

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private loading: LoadingController,
    private modalService: ModalService,
  ) {
  }

  activate(serialNumber: string) {
    return this.AppCMS.loadPluginData('devices', {
      mode: 'token',
      serialNumber: serialNumber,
    }, ['active']);
  }

  create(device: device) {
    return this.AppCMS.loadPluginData('devices', {
      device: device,
      mode: 'token',
    }, ['create']);
  }

  delete(deviceId: number) {
    return this.AppCMS.loadPluginData('devices', {
      mode: 'token',
    }, [deviceId, 'delete']);
  }

  detailItem(item: device | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  get(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('devices', options, ['list'], {}, blForceRefresh);
  }

  public getAll(options: any = {}, blForceRefresh: boolean = false) {
    return this.get(options, blForceRefresh);
  }

  getByUid(uid: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('devices', {}, [uid], {}, blForceRefresh);
  }

  generateDeviceTitle(user: user, item: any|null = null) {
    user = user || {};

    console.log('generateDeviceTitle: item', item);
    console.log('generateDeviceTitle: user', user);

    let deviceTitle = '';

    return deviceTitle;
  }

  async getDevice() {
    return Device.getInfo();
  }

  async getDeviceName() {
    let device = await this.getDevice();
    return device.name;
  }

  async getDeviceOsVersion() {
    let device = await this.getDevice();
    return device.osVersion;
  }

  async getDeviceUid() {
    return Device.getId();
  }


  pick(options: any|null = null, event: any|null = null) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loading.create();
      loading.present();

      const devices: device[] = (!!options.data ? options.data : (await this.getAll() as device[]));
      loading.dismiss();

      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: devices,
        labelKey: 'description',
        service: this,
        subLabelKey: 'timestamp',
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig).then(resolve).catch(reject);
    });
  }

  setup(options: any = {}) {
    return new Promise(async (resolve, reject) => {
      console.log('device setup: options', options);

      const modal: any = await this.modalService.create({
        component: DeviceSetupPage,
        componentProps: {
          device: options.device || {},
          devicesService: this,
        },
        cssClass: 'smallModal'
      });

      this.modalService.present(modal);

      modal.onWillDismiss().then((response: any) => {
        resolve(response);
      });
    });
  }

  update(device: device) {
    return this.AppCMS.loadPluginData('devices', {
      device: device,
      mode: 'token',
    }, ['update']);
  }

}