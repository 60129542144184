import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";

import { EventsService } from "src/app/services/core/events.service";
import { WindowManagerService } from 'src/app/services/core/window-manager.service';

@Component({
  selector: 'pipeline-ai-settings-picker',
  standalone: false,
  templateUrl: './ai-settings-picker.component.html',
  styleUrls: ['./ai-settings-picker.component.scss']
})
export class AiSettingsPickerComponent implements AfterContentInit, OnDestroy, OnInit {
  @ViewChild('aiSettingsPopover') aiSettingsPopover: any;

  @Input() cards: any = {};
  @Input() category: string = 'text-generation';
  @Input() color: string = 'dark';
  @Input() config: aiSettings = {};
  @Input() context: string;
  @Input() disabled: boolean = false;
  @Input() fill: string = 'clear';
  @Input() mini: boolean = false;
  @Input() size: string;
  @Input() view: any;

  @Output() changed = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  isAiSettingsPopoverOpen: boolean = false;

  models: any = [];

  @Input() options: aiSettingsOptions = {
    inPopover: true,
    operations: ['text_generation'],
  };

  constructor(
    public ai: AiToolsService,

    private events: EventsService,
    private windowManager: WindowManagerService,
  ) {
    this.view = this.view || {};

    if (!!this.context) {
      this.config.context = this.context;
    }
  }

  initViewVars() {
  }

  ngAfterContentInit() {
    this.initViewVars();
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.options = this.options || {};
    this.options.inPopover = true;

    if (!!this.context) {
      this.config.context = this.context;
    }
  }

  modelChanged(value: any) {
    this.changed.emit(value);
  }

  openModal(event: any = null) {
    this.aiSettingsPopover.event = event;
    this.isAiSettingsPopoverOpen = true;
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  viewAll() {
    this.events.publish('menu:load:appPages', { key: 'models_admin' });

    this.windowManager
      .open({
        route: '/ai/models',
        uid: 'ai_models',
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

}