import { Injectable } from '@angular/core';

import { ModalService } from 'src/app/services/core/modal.service';
import { UserService } from 'src/app/services/core/user.service';

import { ChooseAvatarPage } from 'src/app/pages/core/chooser/choose-avatar/choose-avatar.page';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  avatars: avatar[];

  urlPrefix: string = 'https://web.pipeline.page/';

  constructor(
    private modalService: ModalService,
    private userService: UserService,
  ) {
  }

  getAvatars() {
    if(!this.avatars) {
      let genders = this.userService.getGenders();
      let avatars = [], iMax = 5;
      genders.forEach((gender: string) => {
        let i = 1;
        while(i <= iMax) {
          avatars.push({
            src: './assets/img/avatars/' + gender + '/light/' + i + '.webp',
            gender: gender,
            skin: 'light'
          });
          avatars.push({
            src: './assets/img/avatars/' + gender + '/dark/' + i + '.webp',
            gender: gender,
            skin: 'dark'
          });
          i++;
        }
      });
      this.avatars = avatars;
    }
    return this.avatars;
  }

  select() {
    return new Promise(async (resolve, reject) => {
      let avatars = await this.getAvatars();
      const modal: any = await this.modalService.create({
        component: ChooseAvatarPage,
        componentProps: {
          avatars: avatars,
        },
        animated: true,
        
        presentingElement: await this.modalService.getTop(),
        cssClass: 'avatarModal'
      });
      modal.onWillDismiss().then((response: any) => {
        resolve(response && response.data && response.data.src ? this.urlPrefix + response.data.src.replace('./', '/') : null);
      });
      this.modalService.present(modal);
    });
  }

}
