<div class="container">

  <pipeline-no-entries-card *ngIf="(!items || (items && !items.length)) && !view.loading"></pipeline-no-entries-card>

  <div *ngIf="!!items && !!items.length">

    <pipeline-pagination *ngIf="!!view.showPagination && !!paginationConfig && !view.loading"
      [config]="paginationConfig" [(view)]="view"></pipeline-pagination>

    <ion-grid>
      <ion-row>
        <ion-col *ngFor="let item of items; let i = index; trackBy:trackItems"
          [size]="view.viewType !== 'grid' ? 12 : view.isDesktop ? 3 : 12">
          <ion-card>
            <pipeline-dynamic-item [buttons]="buttons" [(item)]="items[i]" [mode]="mode" [options]="options"
              [selectionOptions]="selectionOptions" [view]="view"
              (itemChanged)="onItemChanged(items[i])"></pipeline-dynamic-item>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>

</div>