<!-- No entries-->
<pipeline-no-entries-card *ngIf="data && !data.length"></pipeline-no-entries-card>

<!-- Items list -->
<ion-radio-group [(ngModel)]="item" *ngIf="data && data.length" [disabled]="!!options.multiple">

  <!-- Single item -->
  <ion-item *ngFor="let item of data; trackBy:trackItems" [disabled]="!!item.disabled">

    <!-- Media template preview -->
    <pipeline-media-template-preview
      *ngIf="(!item.photo && !item.thumbnail) && ((options.type === 'template') && !!item.view && !!item.custom && !!item.config && !!item.config.layers)"
      [template]="item" [(view)]="item.view"></pipeline-media-template-preview>

    <!-- Video template preview -->
    <pipeline-video-template-preview
      *ngIf="(!item.photo && !item.thumbnail) && ((options.type === 'template') && !item.custom && !!item.config && !!item.config.Comp && !!item.config._meta)"
      [template]="item"></pipeline-video-template-preview>

    <!-- Item icon -->
    <ion-icon *ngIf="!!item.icon && (!item.photo && !item.thumbnail)" [name]="item.icon" slot="start"></ion-icon>

    <!-- Item photo -->
    <ion-thumbnail *ngIf="(item.photo || item.thumbnail) && !item.videoSrc && (options.showPhoto !== false)"
      slot="start">
      <ion-img [src]="(item.photo || item.thumbnail) || fallbackImg"
        (ionError)="thumbnailLoadingFailed(item)"></ion-img>
    </ion-thumbnail>

    <!-- Item video -->
    <video *ngIf="!!item.guid || !!item.videoSrc" [poster]="fallbackImg" [src]="item.videoSrc || item.guid" playsinline
      webkit-playsinline muted="muted" loop autoplay></video>

    <!-- Mode: multiple -->
    <ion-checkbox alignment="end" [(ngModel)]="item.checked" [disabled]="item.disabled" justify="space-between"
      labelPlacement="start" *ngIf="options.multiple" (ionChange)="onMultiItemSelected(item)">
      <p [innerHTML]="((!!options.labelPrefix ? options.labelPrefix : '') + item[options.labelKey])|translate"></p>
      <small *ngIf="!!options.subLabelKey"
        [innerHTML]="((!!options.subLabelPrefix ? options.subLabelPrefix : '') + item[options.subLabelKey])|translate"></small>
    </ion-checkbox>

    <!-- Mode: single -->
    <ion-radio alignment="end" (click)="select(item)" [disabled]="item.disabled" [value]="item[options.valueKey]"
      justify="space-between" *ngIf="!options.multiple" labelPlacement="start">
      <p [innerHTML]="((!!options.labelPrefix ? options.labelPrefix : '') + item[options.labelKey])|translate"></p>
      <small *ngIf="!!options.subLabelKey"
        [innerHTML]="((!!options.subLabelPrefix ? options.subLabelPrefix : '') + item[options.subLabelKey])|translate"></small>
    </ion-radio>

  </ion-item>

</ion-radio-group>