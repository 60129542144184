import { Injectable } from '@angular/core';
import { LoadingController } from "@ionic/angular";
import { NavController } from '@ionic/angular';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { ConfigService } from "src/app/services/core/config.service";
import { CrawlerService } from 'src/app/services/utils/crawler.service';
import { EventsService } from 'src/app/services/core/events.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

import { AiModelsPage } from 'src/app/pages/core/ai/ai-models/ai-models.page';
import { AiShortcodePage } from 'src/app/pages/core/ai/ai-shortcode/ai-shortcode.page';

@Injectable({
  providedIn: 'root'
})
export class AiToolsService {

  _detailItem: any | null;

  categoryIcons: any = {
    "audio-classification": 'text-outline',
    "audio-to-text": 'recording-outline',
    "automatic-speech-recognition": 'recording-outline',
    checkpoint: "document-outline",
    "depth-estimation": "layers-outline",
    "document-question-answering": "document-outline",
    "feature-extraction": "layers-outline",
    "fill-mask": "sparkles-outline",
    hypernetwork: 'diamond-outline',
    "image-classification": 'text-outline',
    "image-feature-extraction": "sparkles-outline",
    "image-segmentation": 'locate-outline',
    "image-text-to-text": 'text-outline',
    "image-to-3d": 'cube-outline',
    "image-to-image": 'brush-outline',
    "image-to-text": 'text-outline',
    "image-to-video": 'film-outline',
    img2img: 'brush-outline',
    img2txt: 'text-outline',
    img2vid: 'film-outline',
    locon: 'diamond-outline',
    lora: 'diamond-outline',
    "mask-generation": "sparkles-outline",
    motionmodule: 'film-outline',
    "object-detection": "scan-outline",
    other: "ellipsis-horizontal-outline",
    poses: 'image-outline',
    "question-answering": "information-outline",
    "sentence-similarity": "calculator-outline",
    summarization: 'text-outline',
    "table-question-answering": "information-outline",
    "text-classification": 'text-outline',
    "text2text-generation": 'text-outline',
    "text-generation": 'text-outline',
    "text-to-image": 'image-outline',
    "text-to-speech": 'mic-outline',
    "text-to-text": 'text-outline',
    "text-to-video": 'film-outline',
    "text-to-audio": 'mic-outline',
    textualinversion: 'text-outline',
    "time-series-forecasting": "time-outline",
    "token-classification": "calculator-outline",
    translation: "language-outline",
    txt2img: 'image-outline',
    txt2txt: 'text-outline',
    txt2vid: 'film-outline',
    txt2wav: 'mic-outline',
    vae: 'diamond-outline',
    "video-classification": 'text-outline',
    "video-to-text": 'text-outline',
    vid2txt: 'text-outline',
    "voice-activity-detection": "recording-outline",
    upscaler: 'expand-outline',
    "visual-question-answering": "information-outline",
    wav2txt: 'recording-outline',
    wildcards: "document-outline",
    "zero-shot-audio-classification": "recording-outline",
    "zero-shot-classification": "layers-outline",
    "zero-shot-image-classification": 'text-outline',
    "zero-shot-object-detection": "scan-outline",
  };

  contexts: aiContext[] = [
    {
      category: 'all',
      icon: 'home-outline',
      label: 'general',
      uid: 'general',
    },
    {
      category: 'text-generation',
      icon: 'globe-outline',
      label: 'ai_browser_execution',
      uid: 'ai_browser',
    },
    {
      category: 'text-generation',
      icon: 'calendar-outline',
      label: 'ai_plan_creation',
      uid: 'ai_plans',
    },
    {
      category: 'text-generation',
      icon: 'code-slash-outline',
      label: 'ai_code_generation',
      uid: 'ai_code_generation',
    },
    {
      category: 'text-generation',
      icon: 'document-outline',
      label: "blog_post_creation",
      uid: "blog_post_creation",
    },
    {
      category: 'text-generation',
      icon: 'chatbubbles-outline',
      label: 'chat_completions',
      uid: 'chat_completions',
    },
    {
      category: 'text-generation',
      icon: 'terminal-outline',
      label: 'ai_task_execution',
      uid: 'ai_task_execution',
    },
    {
      category: 'image-to-text',
      icon: 'search-outline',
      label: 'image_to_text',
      uid: 'image_to_text',
    },
    {
      category: 'image-to-video',
      icon: 'fil,-outline',
      label: 'image_to_video',
      uid: 'image_to_video',
    },
    {
      category: 'text-classification',
      icon: 'text-outline',
      label: 'text_classification',
      uid: 'text_classification',
    },
    {
      category: 'text-generation',
      icon: 'create-outline',
      label: 'text_to_text',
      uid: 'text_generation',
    },
    {
      category: 'text-generation',
      icon: 'sparkles-outline',
      label: 'text_optimization',
      uid: 'text_optimization',
    },
    {
      category: 'text-to-image',
      icon: 'image-outline',
      label: 'text_to_image',
      uid: 'text_to_image',
    },
    {
      category: 'text-generation',
      icon: 'languages-outline',
      label: 'translation',
      uid: 'text_translation',
    },
    {
      category: 'text-generation',
      icon: 'mail-outline',
      label: 'newsletter_creation',
      uid: 'newsletter_creation',
    },
  ];

  promptFunctionActions: string[] = [
    'ai_image_to_image',
    'ai_image_to_text',
    'ai_image_to_video',
    //'ai_speech_to_speech',
    'ai_text_to_audio',
    'ai_text_to_image',
    'ai_text_to_speech',
    'ai_video_to_video',
    'ai_upscaler',
    'api',
    'code',
    'crawl_url_content',
    'ai_media_search',
  ];

  promptTypes: aiPromptType[] = [
    {
      icon: 'person-outline',
      name: 'prompt_type_user',
      uid: 'user',
    },
    {
      icon: 'terminal-outline',
      name: 'prompt_type_system',
      uid: 'system',
    },
    {
      icon: 'sparkles-outline',
      name: 'prompt_type_assistant',
      uid: 'assistant',
    },
    {
      icon: 'code-slash-outline',
      name: 'prompt_type_function',
      uid: 'function',
    }
  ];

  quickData: any;

  searchOptions: searchOptions;

  store: any = {
    industries: [
      {
        name: 'industry_automotive',
        uid: 'automotive',
      },
      {
        name: 'industry_realestate',
        uid: 'real_estate',
      },
    ],
    job_titles: [
      {
        name: 'job_title_accounting',
        uid: 'accounting',
      },
      {
        name: 'job_title_coding',
        uid: 'coding',
      },
      {
        name: 'job_title_copywriting',
        uid: 'copywriting',
      },
      {
        name: 'job_title_data_analysis',
        uid: 'data_analysis',
      },
      {
        name: 'job_title_ecommerce',
        uid: 'ecommerce',
      },
      {
        name: 'job_title_graphic_design',
        uid: 'graphic_design',
      },
      {
        name: 'job_title_management',
        uid: 'management',
      },
      {
        name: 'job_title_marketing',
        uid: 'marketing',
      },
      {
        name: 'job_title_videography',
        uid: 'videography',
      },
    ],
  };

  taskSavePaths: any[];

  tools: aiShortcode[] = [
    {
      icon: 'speedometer-outline',
      input: 'Optimize the content and return the improved content only.',
      name: 'ai_tools_enhance',
      key: 'enhance',
      token: 50,
      type: "custom",
    },
    {
      icon: 'expand-outline',
      input: 'Extend the content and return the extended content only.',
      name: 'ai_tools_extend',
      key: 'extend',
      token: 100,
      type: "custom",
    },
    {
      icon: 'shield-checkmark-outline',
      input: 'Fact-check the content and return the fact-checked content with additional information and facts about the input added to each important part.',
      name: 'ai_tools_factchecking',
      key: 'factchecking',
      token: 25,
      type: "custom",
    },
    {
      icon: 'dice-outline',
      input: 'Create a quiz based on the input and return the quiz only.',
      name: 'ai_tools_quiz',
      key: 'quiz',
      token: 50,
      type: "custom",
    },
    {
      icon: 'create-outline',
      input: 'Rewrite the content and improve your own version only.',
      name: 'ai_tools_rewrite',
      key: 'rewrite',
      token: 100,
      type: "custom",
    },
    {
      icon: 'crop-outline',
      input: 'Summarize the input and return the summarization only.',
      name: 'ai_tools_shorten',
      key: 'shorten',
      token: 50,
      type: "custom",
    },
    {
      icon: 'bulb-outline',
      input: 'Simplify the input and return the simplified version only.',
      name: 'ai_tools_simplify',
      key: 'simplify',
      token: 50,
      type: "custom",
    },
    {
      icon: 'text-outline',
      input: 'Rewrite the text in a gender-appropriate way and return the improved version only.',
      name: 'ai_tools_gender',
      key: 'gender',
      token: 75,
      type: "custom",
    },
    {
      icon: 'text-outline',
      input: 'Remove all gender-inclusive language and return the improved version only.',
      name: 'ai_tools_ungender',
      key: 'ungender',
      token: 75,
      type: "custom",
    }
  ];

  view: any = {};

  constructor(
    private aiBridge: AiBridgeService,

    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private configService: ConfigService,
    private crawler: CrawlerService,
    private events: EventsService,
    private folders: FoldersService,
    private loading: LoadingController,
    private modalService: ModalService,
    private navCtrl: NavController,
    private sd: StablediffusionService,
    private toolsService: ToolsService,
  ) {

  }

  addIdea(idea: any) {
    idea = JSON.parse(JSON.stringify(idea));

    delete idea.inputs;
    delete idea.loading;
    delete idea.loop;
    delete idea.promptType;
    delete idea.save_path;
    delete idea.save_paths;
    delete idea.try;
    delete idea.type;

    return this.AppCMS.loadPluginData('pipeline', {
      idea: (idea as aiIdea),
    }, ['ai', 'ideas', 'create']);
  }

  addPlan(plan: any) {
    return new Promise((resolve, reject) => {
      console.log('add plan', plan);
    });
  }

  addTask(task: aiTask) {
    return this.AppCMS.loadPluginData('pipeline', {
      task: task,
    }, ['ai', 'tasks', 'create']);
  }

  addToSearchHistory(search: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      search: search,
    }, ['ai', 'search_history', 'create']);
  }

  caption(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'caption'], {}, blForceRefresh);
  }

  chooseModel(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      try {

        const chooseConfig: chooseConfig = Object.assign(options, {
          component: AiModelsPage,
          labelKey: 'name',
          multiple: !!options.multiple,
          subLabelKey: 'provider',
          title: 'choose_ai_model',
          valueKey: 'uid',
        });

        const choose: chooseResponse = await this.chooser.choose(chooseConfig);

        if (choose && choose.data && choose.data.items && choose.data.items.length && (choose.data.items.length > 1)) {
          resolve(choose.data);
        } else
          if (choose && choose.data && choose.data.item) {
            resolve(choose.data.item);
          } else {
            resolve(false);
          }
      } catch (e) {
        reject(e);
      }
    });
  }

  chooseProvider(options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const choose: chooseResponse = await this.chooser.choose(Object.assign(options, {
        data: await this.getAvailableProviders(),
        labelKey: 'name',
        title: 'choose_ai_provider',
        valueKey: 'uid',
      }));

      if (choose && choose.data && choose.data.item && choose.data.item.uid) {
        resolve(choose.data.item.uid);
      } else {
        resolve(false);
      }
    });
  }

  createAssistant(assistant: aiAssistant) {
    return this.AppCMS.loadPluginData('pipeline', {
      assistant: assistant,
    }, ['ai', 'assistants', 'create']);
  }

  createContentIdeas(config: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', { config: config }, ['ai', 'createContentIdeas'], {}, blForceRefresh);
  }

  createFolder(folder: folder) {
    folder.location = folder.location || 'ai';
    return this.folders.create(folder);
  }

  createImagesSearchQuery(input: string, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: {
        post_content: input,
      },
    }, ['ai', 'createImagesSearchQuery'], {}, blForceRefresh);
  }

  createImageVariations(src: string, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {
      url: src,
    }, ['ai', 'createImageVariations'], {}, blForceRefresh);
  }

  createPlan(plan: aiPlan) {
    return this.AppCMS.loadPluginData('pipeline', {
      plan: plan,
    }, ['ai', 'plans', 'create']);
  }

  createShortcode(data: any | null = null) {
    data = data || {};

    return new Promise(async (resolve, reject) => {

      const modal: any = await this.modalService.create({
        component: AiShortcodePage,
        componentProps: {
          ai: this,
        },
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: "defaultModal",
      });

      modal.onWillDismiss().then((response: any) => {
        resolve(response);
      });

      await this.modalService.present(modal);
    });
  }

  deepLoadIdea(idea: aiIdea, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
      idea: idea,
    }), ['ai', 'deepLoadIdea']);
  }

  deleteAssistant(assistant: aiAssistant) {
    return this.AppCMS.loadPluginData('pipeline', {
      assistant_uid: assistant.uid,
    }, ['ai', 'assistants', 'delete']);
  }

  deleteCode(codeProject: aiCoderProject) {
    return this.AppCMS.loadPluginData('pipeline', {
      code_project_uid: codeProject.uid,
    }, ['ai', 'codes', 'delete']);
  }

  deleteIdea(idea: aiIdea) {
    return this.AppCMS.loadPluginData('pipeline', {
      idea_uid: idea.uid,
    }, ['ai', 'ideas', 'delete']);
  }

  deleteModel(model: aiModel) {
    return this.AppCMS.loadPluginData('pipeline', {
      model_uid: model.uid,
    }, ['ai', 'models', 'delete']);
  }

  deletePlan(plan: aiPlan, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
      plan: plan.uid,
    }), ['ai', 'plans', 'delete']);
  }

  deleteSearchHistoryItem(item: searchOptions) {
    return this.AppCMS.loadPluginData('pipeline', {
      item_uid: item.uid,
    }, ['ai', 'search_history', 'delete']);
  }

  deleteShortcode(shortcode: aiShortcode) {
    return this.AppCMS.loadPluginData('pipeline', {
      shortcode_uid: shortcode.uid,
    }, ['ai', 'shortcodes', 'delete']);
  }

  deleteTask(task: aiTask) {
    return this.AppCMS.loadPluginData('pipeline', {
      task_uid: task.uid,
    }, ['ai', 'tasks', 'delete']);
  }

  detailItem(item: any | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  duplicateAssistant(assistant: aiAssistant) {
    return this.AppCMS.loadPluginData('pipeline', {
      assistant_uid: assistant.uid,
    }, ['ai', 'assistants', 'duplicate']);
  }

  duplicateCodeProject(projectId: number) {
    console.log('duplicateCodeProject', projectId);

    return this.AppCMS.loadPluginData('pipeline', {
      code_project_uid: projectId,
    }, ['ai', 'coder', 'duplicate']);
  }

  duplicatePlan(plan: aiPlan) {
    return this.AppCMS.loadPluginData('pipeline', {
      plan_uid: plan.uid,
    }, ['ai', 'plans', 'duplicate']);
  }

  duplicateShortcode(shortcode: aiShortcode) {
    return this.AppCMS.loadPluginData('pipeline', {
      shortcode_uid: shortcode.uid,
    }, ['ai', 'shortcodes', 'duplicate']);
  }

  duplicateTask(taskId: number) {
    console.log('duplicateTask', taskId);

    return this.AppCMS.loadPluginData('pipeline', {
      task_uid: taskId,
    }, ['ai', 'tasks', 'duplicate']);
  }

  async executeFunctionCall(response: any) {
    return new Promise((resolve, reject) => {
      let decoded: any | null = null, decodedArgs: any | null = null;

      if (!!response && !!response.output) {
        decoded = this.toolsService.extractJson(`${(response.output || '').replace(/(\r\n|\n|\r)/gm, "")}`);

        if (!!decoded && !!decoded.name && !!decoded.arguments) {
          decodedArgs = (typeof decoded.arguments === 'string' ? JSON.parse(`${decoded.arguments}`.replace(/(\r\n|\n|\r)/gm, "")) : decoded.arguments);
        }
      }

      if (!decodedArgs) {
        reject('error_missing_arguments');
        return false;
      }

      const call: any = {
        arguments: decodedArgs,
        raw: response,
        name: decoded.name,
      };

      this.events.publish('ai:function:call', call);

      resolve(call);
    });
  }

  editShortcode(shortcode: aiShortcode) {
    return new Promise(async (resolve, reject) => {

      console.log('ai-tools: editShortcode: shortcode', shortcode);

      const modal: any = await this.modalService.create({
        component: AiShortcodePage,
        componentProps: {
          ai: this,
          shortcode: shortcode,
        },
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: "defaultModal",
      });

      modal.onWillDismiss().then((response: any) => {
        resolve(response);
      });

      await this.modalService.present(modal);
    });
  }

  editTaskCronSettings(task: aiTask) {
    this.setQuickData(task);
    this.navCtrl.navigateForward('/ai/task/cron');
  }

  enhance(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'enhance']);
  }

  extend(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'extend']);
  }

  factchecking(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'factchecking']);
  }

  getAIStoreIndustries(blForceRefresh: boolean = false) {
    return this.store.industries;
  }

  getAIStoreJobTitles(blForceRefresh: boolean = false) {
    return this.store.job_titles;
  }

  getAssistantByUid(assistantId: number | string, options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'assistants', assistantId], {}, blForceRefresh);
  }

  getAssistants(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'assistants'], {}, blForceRefresh);
  }

  getAvailableImageModels(options: any = {}, blForceRefresh: boolean = false) {
    options.filter = options.filter || {};
    options.filter.type = 'image';

    return this.getAvailableModels(options, blForceRefresh);
  }

  getAvailableModels(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'models'], {}, blForceRefresh);
  }

  getAvailableProviders(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'providers'], {}, blForceRefresh);
  }

  getCategoryIcons() {
    return this.categoryIcons;
  }

  getCodeProjects(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'coder', 'projects'], {}, blForceRefresh);
  }

  getContexts() {
    return this.contexts;
  }

  getCustomShortcodes(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'shortcodes'], {}, blForceRefresh);
  }

  getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    options.location = options.location || 'ai';
    return this.folders.get(options, blForceRefresh, params);
  }

  getIdeas(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'ideas'], {}, blForceRefresh);
  }

  async getPreferredModels(context: string = 'general') {
    return this.aiBridge.getPreferredModels(context);
  }

  getPromptFunctionActions() {
    return this.promptFunctionActions;
  }

  getPlans(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'plans'], {}, blForceRefresh);
  }

  getPromptTypes() {
    return this.promptTypes;
  }

  getProvider() {
    return this.aiBridge.getProvider();
  }

  getQuickData() {

    if (!this.quickData) {
      this.quickData = {};
    }

    return this.quickData;
  }

  getSearchHistory(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'search_history'], {}, blForceRefresh);
  }

  getSearchOptions() {
    return this.searchOptions;
  }

  getStyles(blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['ai', 'styles'], {}, blForceRefresh);
  }

  getTaskByUid(taskId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['ai', 'tasks', taskId], {}, blForceRefresh);
  }

  getTasks(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'tasks'], {}, blForceRefresh);
  }

  getTaskSavePaths() {
    if (!this.taskSavePaths) {

      const config = this.configService.getConfig();

      let taskSavePaths = [
        {
          icon: 'hand-left-outline',
          name: 'save_none',
          description: 'ai_task_save_path_save_none_description',
          type: 'save_none',
          uid: 'save_none',
        },
        {
          icon: 'calendar-outline',
          name: 'ai_plan',
          description: 'ai_task_save_path_ai_plan_description',
          type: 'ai_plan',
          uid: 'ai_plan',
        },
      ];

      if (config.useWhitelabelAppsExtension) {
        taskSavePaths.push(
          {
            icon: 'phone-portrait-outline',
            name: 'app',
            description: 'ai_task_save_path_app_description',
            type: 'app',
            uid: 'app',
          });
      }

      if (config.useMediaExtension) {
        taskSavePaths.push({
          icon: 'image-outline',
          name: 'image',
          description: 'ai_task_save_path_image_description',
          type: 'image',
          uid: 'image',
        });
      }

      if (config.useNewsletterExtension) {
        taskSavePaths.push({
          icon: 'mail-outline',
          name: 'newsletter',
          description: 'ai_task_save_path_newsletter_description',
          type: 'newsletter',
          uid: 'newsletter',
        });
      }

      if (config.usePostsAdmin) {
        taskSavePaths.push({
          icon: 'create-outline',
          name: 'post',
          description: 'ai_task_save_path_post_description',
          type: 'post',
          uid: 'post',
        });
      }

      if (config.useQuizExtension) {
        taskSavePaths.push({
          icon: 'dice-outline',
          name: 'quiz',
          description: 'ai_task_save_path_quiz_description',
          type: 'quiz',
          uid: 'quiz',
        });
      }

      if (config.useSurveyExtension) {
        taskSavePaths.push({
          icon: 'information-circle-outline',
          name: 'survey',
          description: 'ai_task_save_path_survey_description',
          type: 'survey',
          uid: 'survey',
        });
      }

      if (config.useMediaExtension) {
        taskSavePaths.push({
          icon: 'film-outline',
          name: 'video',
          description: 'ai_task_save_path_video_description',
          type: 'video',
          uid: 'video',
        });
      }

      if (config.useWebsitesExtension) {
        taskSavePaths.push({
          icon: 'desktop-outline',
          name: 'website',
          description: 'ai_task_save_path_website_description',
          type: 'website',
          uid: 'website',
        });
      }

      this.taskSavePaths = taskSavePaths;
    }

    return this.taskSavePaths;
  }

  getTaskSettings(taskId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {
      task_uid: taskId,
    }, ['ai', 'tasks', 'settings'], {}, blForceRefresh);
  }

  getTools() {
    return this.tools;
  }

  gender(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'gender']);
  }

  hasFunctionCallResponse(response: any) {
    let bl: boolean = false, details: any = {};

    try {
      if (!!response && !!response.output) {
        const decoded: any = this.toolsService.extractJson(`${(response.output || '').replace(/(\r\n|\n|\r)/gm, "")}`);

        if (!!decoded && !!decoded.name && !!decoded.arguments) {
          const decodedArgs: any = (typeof decoded.arguments === 'string' ? JSON.parse(`${decoded.arguments}`.replace(/(\r\n|\n|\r)/gm, "")) : decoded.arguments);

          bl = !!decodedArgs;
          details = decoded;
        }
      }
    } catch (e) {
      console.warn('validating if response is function call failed', e);
    }

    return {
      match: !!bl,
      details: details,
    };
  }

  inspectURL(url: string, blForceRefresh: boolean = false, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      try {

        // first, locally crawl url
        const content: any = await this.crawler.fetch(url, {
          proxy: true,
        });

        // then, run server-side inspection
        this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
          content: content,
          url: url,
        }), ['ai', 'inspectURL'], {}, blForceRefresh).then(resolve).catch(reject);

      } catch (e) {
        reject(e);
      }
    });
  }

  lookupImagesOnChildren(children: any[], taskIndex: number) {
    return new Promise((resolve, reject) => {
      if (!!children && !!children.length) {
        children.forEach(async (child: aiTask, childIndex: number) => {
          setTimeout(async () => {
            try {

              const response: any = await this.sd.textToImage(
                `${child.name}`, {
                creative: true,
                negative_prompt: 'text, font, details, person',
                height: 576,
                width: 1024,
              }, true);

              if (!!response && !!response.photos) {
                child.media = (response.photos || []).slice(0, 10);
              }

              if (!!response && !!response.photo) {
                child.photo = `${response.photo || response.thumbnail}`;
                child.thumbnail = `${response.thumbnail || response.photo}`;
              }

              if (!!this.view.tasks && !!this.view.tasks[taskIndex] && !!this.view.tasks[taskIndex].children && !!this.view.tasks[taskIndex].children[childIndex]) {
                this.view.tasks[taskIndex].children[childIndex] = child;
              }

              if (childIndex === (children.length - 1)) {
                resolve(this.view.tasks);
              }
            } catch (e) {
              console.warn('> creating search query for child failed', child, e);

              if (childIndex === (children.length - 1)) {
                resolve(this.view.tasks);
              }
            }
          }, (childIndex * 1000));
        });
      }
    });
  }

  mapContextToCategory(context: string) {
    const mappedContext: string = `${context || ''}`.replaceAll('_', '-');

    const mapToTextContexts: string[] = this.contexts.filter((context: aiContext) => {
      return context.category === 'text-generation';
    }).map((context: aiContext) => {
      return `${context.uid || ''}`;
    }) as string[];

    if (mapToTextContexts.indexOf(context) !== -1) {
      return 'text-generation';
    }

    return mappedContext;
  }

  optimizePrompt(prompt: string, options: any = {}) {
    options = options || {};

    options.context = 'text_optimization';

    options.config = options.config || {
      context: options.context,
    };

    options.history = options.history || [];
    options.post_content = `Improve only the following prompt and return the improved prompt (plain text string, no HTML) only without any further output.`;

    options.history.push({
      role: 'user',
      content: `${prompt || ''}`,
    });

    console.log('optimizePrompt: options', options);

    return this.aiBridge.execute(options);
  }

  pickTaskSavePath(task: any, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      let paths: any[] = this.getTaskSavePaths();

      if (!!options.exclude && !!options.exclude.length) {
        paths = paths.filter((path: any) => {
          return options.exclude.indexOf(path.uid) === -1;
        });
      }

      const chooseConfig: chooseConfig = {
        data: paths,
        labelKey: 'name',
        multiple: !!options.multiple,
        subLabelKey: 'description',
        title: 'choose_target',
        valueKey: 'uid',
      };

      const choose: chooseResponse = await this.chooser.choose(chooseConfig);

      if (choose && choose.data && choose.data.items) {
        resolve(choose.data.items);
      } else
        if (choose && choose.data && choose.data.item && choose.data.item.uid) {
          resolve(choose.data.item.uid);
        } else {
          resolve(false);
        }
    });
  }

  quick(data: any | null = null) {
    this.setQuickData(data || {});

    const url: string = (!!data && !!data.uid ? `/ai/automation/${data.uid}` : '/ai/task');

    this.navCtrl.navigateForward(url);
  }

  quiz(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'quiz']);
  }

  rewrite(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'rewrite']);
  }

  search(options: any = {}, params: any = {}, blForceRefresh: boolean = false) {
    return this.aiBridge.search(options, params, blForceRefresh);
  }

  async setPreferredModels(models: aiModel[]) {
    return this.aiBridge.setPreferredModels(models);
  }

  setQuickData(data: any) {
    this.quickData = data;
    return this;
  }

  setSearchOptions(options: searchOptions) {
    this.searchOptions = options;
    return this;
  }

  shorten(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'shorten']);
  }

  simplify(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'simplify']);
  }

  ungender(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'ungender']);
  }

  updateAssistant(assistant: aiAssistant) {
    return this.AppCMS.loadPluginData('pipeline', {
      assistant: assistant,
    }, ['ai', 'assistants', 'update']);
  }

  updateIdea(idea: aiIdea) {
    return this.AppCMS.loadPluginData('pipeline', {
      idea: idea,
    }, ['ai', 'ideas', 'update']);
  }

  updatePlan(plan: aiPlan) {
    return this.AppCMS.loadPluginData('pipeline', {
      plan: plan,
    }, ['ai', 'plans', 'update']);
  }

  updateTask(task: aiTask) {
    return this.AppCMS.loadPluginData('pipeline', {
      task: task,
    }, ['ai', 'tasks', 'update']);
  }

  updateTaskSettings(task: aiTask) {
    return this.AppCMS.loadPluginData('pipeline', {
      task: task,
    }, ['ai', 'tasks', 'settings', 'update']);
  }

}