import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { EventsService } from 'src/app/services/core/events.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  cacheKey: string = 'current_team';

  currentTeam: team;

  _detailItem: team | null;

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private chooser: ChooserService,
    private events: EventsService,
    private userService: UserService,
  ) {
  }

  addUserToTeam(teamId: number, userId: number = null) {
    return new Promise(async (resolve, reject) => {
      if (!userId) {
        this.userService.pick()
          .then((response: chooseResponse) => {
            const user: user = (response && response.data && response.data.item ? response.data.item : null);

            if (!!user && !!user.uid) {
              this.performAddUserToTeam(teamId, user.uid).then(resolve).catch(reject);
            } else {
              reject();
            }
          })
          .catch(reject);
      } else {
        this.performAddUserToTeam(teamId, userId).then(resolve).catch(reject);
      }
    });
  }

  create(_team: team) {
    let team: team = JSON.parse(JSON.stringify(_team));

    team.active = !!team.active;
    team.public = !!team.public;

    delete team.checked;
    delete team.members;

    return this.AppCMS.loadPluginData('teams', {
      team: team,
    }, ['create']);
  }

  delete(teamId: number) {
    return this.AppCMS.loadPluginData('teams', {
      uid: teamId,
    }, ['delete']);
  }

  detailItem(item: team | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  getAll(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return this.AppCMS.loadPluginData('teams', {
      filter: (typeof options === 'object' ? options : {}),
    }, [], {}, blForceRefresh);
  }

  getAvailable(options: any = {}, blForceRefresh: boolean = false) {
    return this.getAll(Object.assign(options, {
      user: options.user || this.userService.getUid(),
    }), blForceRefresh);
  }

  getByUid(teamId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('teams', {}, [teamId], {}, blForceRefresh);
  }

  getByUserUid(userId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('teams', {}, ['user', userId], {}, blForceRefresh);
  }

  async getCurrentTeam() {

    if (!this.currentTeam) {
      try {
        const fromCache: cacheItem = await this.cache.get(this.cacheKey, -1);

        if (!!fromCache && !!fromCache.data && !!fromCache.data.uid) {
          this.setCurrentTeam(fromCache.data);
        }

      } catch (e) {
        console.warn('loading team from cache failed', e);
      }
    }

    return this.currentTeam;
  }

  async getCurrentTeamUid() {
    const team: team = await this.getCurrentTeam();

    if (!!team && !!team.uid) {
      return team.uid;
    }
  }

  init() {
    return new Promise((resolve, reject) => {

      this.events.subscribe('appcms:user:updated', (data: any) => {
        this.loadUserTeamsData();
      });

      this.loadUserTeamsData().then(resolve).catch(reject);
    });
  }

  async loadUserTeamsData() {
    if (!!this.userService.isLoggedIn()) {
      try {
        const teams: team[] = (await this.getAvailable() as team[]);
        const team: team = await this.getCurrentTeam();

        if (!!teams[0] && !team) {
          this.setCurrentTeam(teams[0]);
        }
      } catch (e) {
        console.warn('> loading team failed', e);
      }
    }
  }

  performAddUserToTeam(teamId: number, userId: number) {
    return this.AppCMS.loadPluginData('teams', {
      relation: {
        team_uid: teamId,
        user_uid: userId,
      }
    }, ['addUserToTeam']);
  }

  pick(options: chooseConfig = null) {
    return new Promise(async (resolve, reject) => {

      const data: team[] = (await this.getAll() || []) as team[];

      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: data,
        labelKey: 'name',
        service: this,
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig).then(resolve).catch(reject);
    });
  }

  removeUserFromTeam(teamId: number, userId: number) {
    return this.AppCMS.loadPluginData('teams', {
      team_uid: teamId,
      user_uid: userId,
    }, ['removeUserFromTeam']);
  }

  setCurrentTeam(team: team | null = null) {
    this.currentTeam = team;

    if (!!team && !!team.uid) {
      this.cache.set(this.cacheKey, this.currentTeam);
      this.AppCMS.setRequestParam('team_uid', team.uid);
    } else {
      this.cache.remove(this.cacheKey);
      this.AppCMS.setRequestParam('team_uid', 0);
    }

    this.events.publish('team:updated', team);
  }

  update(_team: team) {
    let team: team = JSON.parse(JSON.stringify(_team));

    team.active = !!team.active;
    team.public = !!team.public;

    delete team.checked;
    delete team.members;

    return this.AppCMS.loadPluginData('teams', {
      team: team,
    }, ['update']);
  }

}