import { Component, HostListener, Input, OnInit } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'pipeline-progress-bar',
  standalone: false,
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() progress: any;
  @Input() view: any;
  
  appConfig: pipelineAppConfig;

  options: any = {};

  constructor(
    private configService: ConfigService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }
  
  async calcHidden() {
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  configReady() {

  }

  dataReady() {

  }
  
  destroy(event: any|null = null) {
    console.warn('destroy', event);
  }

  dismiss(data: any|null = null, role: string|null = 'dismiss') {
    this.view.hidden = true;
  }

  domLoaded() {

  }

  error(event: any|null = null) {
    console.warn('error', event);
  }

  ngOnChanges() {
    this.calcHidden();
  }

  ngOnInit() {
    this.calcHidden();
    this.calcViewVars();
  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
  }

}
